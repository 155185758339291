<div class="container" >
    <div class="row" style="display: flex;justify-content: space-between;">
        <h3>Refer ใบส่งผู้ป่วย Covid19</h3>
        <button mat-icon-button  mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="body" style="overflow: auto;max-height: 700px; font-family: PTSans;">
        <table class="table table-bordered border-secondary" id="pdfTable" #pdfTable width="100%">

            <tr>
                <td colspan="8" align="center"><b>แบบฟอร์ม Refer ผู้ป่วย Covid19 จากระบบ SaveThaiFightCovid19 เข้า รพ {{dataReferHistory.referHospital1}} </b></td>
            </tr>

            <tr>
                <td colspan="4" width="50%"><b>DOI</b> : {{dataReferHistory.referDoi| date:'shortDate'}}</td>
                <td colspan="4"><b>ชื่อ</b> {{dataUser.userName}} &nbsp;&nbsp; <b>วันเกิด</b> : {{dataUser.userDate}} &nbsp;&nbsp;
                    <p *ngIf="dataUser.userSex == 'M'"><b>เพศ</b> : ชาย</p>
                    <p *ngIf="dataUser.userSex == 'F'"><b>เพศ</b> : หญิง</p>
                </td>
            </tr>

            <tr>
                <td colspan="4" width="50%"><b>date PCR/ATK</b> : {{dataReferHistory.referAtk| date:'shortDate'}}</td>
                <td colspan="4"><b>อายุ</b> : {{dataUser.userAge}} ปี &nbsp;&nbsp; <b>โรงพยาบาลต้นสังกัด</b> : {{dataReferHistory.referHospitalAffiliated}}</td>
            </tr>

            <tr>
                <td colspan="4" width="50%"><b>วันเข้า Home Isolate</b> : {{dataReferHistory.referHomeIsolate| date:'shortDate'}}</td>
                <td colspan="4"><b>ที่อยู่</b> : {{dataUser.userAddress}} {{dataUser.userSubdistrict}} {{dataUser.userDistrict}} {{dataUser.userProvince}} {{dataUser.userPostalcode}} ปี &nbsp;&nbsp; <b>สิทธิการรักษา</b> : {{dataReferHistory.referRightTreatment}}</td>
            </tr>

            <tr>
                <td rowspan="2" colspan="4"><b>Risk Factor</b> : {{dataReferHistory.referRiskFactor}}</td>
                <td colspan="4"><b>เบอร์ติดต่อผู้ป่วย / ญาติ</b> : {{dataUser.reletiveNumber1}}, {{dataUser.reletiveNumber2}} </td>
            </tr>

            <tr>
                <td colspan="4"><b>เบอร์ติดต่อ Admin / ประสาน</b> : {{dataReferHistory.referPhoneAdmin}}</td>
            </tr>

            <tr>
                <td colspan="8"><b>Vital sign ล่าสุด</b> : <b>T</b> {{dataReferHistory.referVitalSign}}
                </td>
            </tr>

            <tr>
                <td colspan="8">
                    <b>ผลการวินิฉัย</b> : {{dataReferHistory.referToppings}}
                </td>
            </tr>

            <tr>
                <td style="vertical-align: top;" colspan="4">
                    <p><b>ยาที่ได้รับ</b></p>
                    <p *ngIf="dataReferHistory.referMedicineReceivedFavipiravir != '' && dataReferHistory.referMedicineReceivedFavipiravir != null"><b>Favipiravir</b> : {{dataReferHistory.referMedicineReceivedFavipiravir}}</p>
                    <p *ngIf="dataReferHistory.referMedicineReceivedDexamethasone != '' && dataReferHistory.referMedicineReceivedDexamethasone != null"><b>Dexamethasone</b> : {{dataReferHistory.referMedicineReceivedDexamethasone}}</p>
                    <p *ngIf="dataReferHistory.referMedicineReceivedPrednisolone != '' && dataReferHistory.referMedicineReceivedPrednisolone != null"><b>Prednisolone</b> : {{dataReferHistory.referMedicineReceivedPrednisolone}}</p>
                    <p *ngIf="dataReferHistory.referMedicineReceivedOmeprazole != '' && dataReferHistory.referMedicineReceivedOmeprazole != null"><b>Omeprazole</b> : {{dataReferHistory.referMedicineReceivedOmeprazole}}</p>
                    <p *ngIf="dataReferHistory.referMedicineReceivedOxygen != '' && dataReferHistory.referMedicineReceivedOxygen != null"><b>Oxygen</b> : {{dataReferHistory.referMedicineReceivedOxygen}}</p>
                    <p *ngIf="dataReferHistory.referMedicineReceivedOthers != '' && dataReferHistory.referMedicineReceivedOthers != null"><b>Others</b> : {{dataReferHistory.referMedicineReceivedOthers}}</p>
                    <p *ngIf="dataReferHistory.referMedicineThai.length != 0"><b>ยาแผนไทย</b> : {{dataReferHistory.referMedicineThai}}</p>
                    <hr>
                    <p><b>ยาประจำ จากผู้ป่วยลงไว้</b></p>
                    <p>- {{dataReferHistory.referRegularMedicationFromPatient}}</p>
                </td>
                <td style="vertical-align: top;" colspan="4">
                    <p><b>Medical Comments / การรักษาที่ดำเนินการแล้ว</b></p>
                    <div class="card-comment" *ngFor="let itemComment of dataComment">
                        <div class="comment-text" style="margin: 0px;">
                            <span class="username">
                                Doctor's name : <b>{{itemComment.dptDoctorName}}</b>
                                <span class="text-muted float-right">{{itemComment.dptDateTime |
                                    date:'medium'}}</span>
                            </span><!-- /.username -->

                            <p><b>Note : </b> {{itemComment.dptDetail}}</p>
                        </div>
                    </div>

                </td>
            </tr>


            <tr>
                <td rowspan="4" colspan="4" style="padding-left: 13px;padding-bottom: 5px;padding-top: 5px;">
                    <b>Comment</b><br>
                    - {{dataReferHistory.referComment}}
                    <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"></textarea> -->
                </td>
                <td colspan="4" style="padding-left: 13px;padding-bottom: 5px;padding-top: 5px; text-align:right">
                    <p>Refer to {{dataReferHistory.referHospital2}} hospital on {{dataReferHistory.referHospitalDate| date:'shortDate'}}</p>
                    <p>ลงชื่อแพทย์ {{dataReferHistory.referNameDoctor}}</p>
                    <p>รหัส {{dataReferHistory.referDoctorId}}</p>
                    <p>วันที่ {{dataReferHistory.referDate| date:'shortDate'}}</p>
                </td>
            </tr>


        </table>
    </div>
    <div class="modal-footer">
        <button mat-raised-button color="primary" (click)="onClickPDF()">Download</button>
    </div>
</div>