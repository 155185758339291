import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../../../../core/services/service.service';

@Component({
    selector: 'dialog-add-dispense-medicine',
    templateUrl: './dialog-add-dispense-medicine.html',
})
export class DialogAddDispenseMedicine {

    loadSaveDispenseMedicine: boolean = true;
    loadSaveDispenseMedicineSuccess: boolean = false;

    constructor(
        private ServiceService: ServiceService,
        public dialogRef: MatDialogRef<DialogAddDispenseMedicine>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        // console.log(data);
        this.saveData(data.dataSave,data.medicineType);
    }

    saveData(dataSave: any,typeSave:string) {
        // console.log(dataSave);
        if(typeSave == "MedicineTH"){
            this.ServiceService.AddMedicalPrescribtionTH_(dataSave).then(resDataUpdate => {
                setTimeout(() => {
                    this.loadSaveDispenseMedicine = false;
                    this.loadSaveDispenseMedicineSuccess = true;
                  }, 2000);
            });
        }else{
            this.ServiceService.AddMedicalPrescribtion_(dataSave).then(resDataUpdate => {
                setTimeout(() => {
                    this.loadSaveDispenseMedicine = false;
                    this.loadSaveDispenseMedicineSuccess = true;
                  }, 2000);
            });
        }
    }
}