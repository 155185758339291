<span class="example-pizza-party">
    <div class="row">
        <div class="col-md-6" style="margin-top: auto;margin-bottom: auto;">
            API Connection Error!!! 
        </div>
        <div class="col-md-6" style="text-align: end;">
          <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="refresh()">
            <i class="fas fa-redo-alt"></i>
          </button>
        </div>
      </div>
</span>