import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DialogEditAdminDistrictDialog } from './edit-admin-district.component';
import * as dataProvinceEN from '../../../shared/interface/myProvince.json';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-admin-district',
  templateUrl: './admin-district.component.html',
  styleUrls: ['./admin-district.component.scss']
})
export class AdminDistrictComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  roleAdmin: any;
  getIdAdminProvince: any;
  getProvinceId: any;
  getNameProvince: any;
  dataAdminDistricts: any =[];
  dataAdminProvinceName!: string;
  dataProvinceNameTHEN: any = (dataProvinceEN as any).default;
  loadData: boolean = true;
  loadDataSuccess: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private ServiceService: ServiceService
  ) {
    this.getIdAdminProvince = this.activatedRoute.snapshot.paramMap.get('idAdminProvince');
    this.getProvinceId = this.activatedRoute.snapshot.paramMap.get('provinceId');
    this.getNameProvince = this.activatedRoute.snapshot.paramMap.get('nameProvince');
    // console.log("this.getProvinceId ",this.getProvinceId);
    // console.log("this.getIdAdminProvince",this.getIdAdminProvince);
    this.roleAdmin = localStorage.getItem("currentRole");

    if(this.roleAdmin == "SuperAdmin" || this.roleAdmin == "AdminProvince"){
      this.loadDataprovinceparam(this.getIdAdminProvince);
    }else{
      this.router.navigate([`/dashboard`], { replaceUrl: true }).then(() => { window.location.reload(); });
    }


  }
  openDialogEditAdminDistrict(idAdminDistrict: string, username: string, password: string, phone: string): void {
    const dialogRef = this.dialog.open(DialogEditAdminDistrictDialog, {
      data: { idAdminDistrict: idAdminDistrict, usernameDistrict: username, passwordDistrict: password, phoneDistrict: phone }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed > ? : ', result);
      if (result != undefined && result != "") {
        for (let i = 0; i < this.dataAdminDistricts.length; i++) {
          if (result[0].admin_Id == this.dataAdminDistricts[i].dataAdmin.admin_Id) {
            this.dataAdminDistricts[i].dataAdmin.admin_Username = result[0].admin_Username;
            this.dataAdminDistricts[i].dataAdmin.admin_Password = result[0].admin_Password;
            this.dataAdminDistricts[i].dataAdmin.admin_Phone = result[0].admin_Phone;
          }
        }
      }

    });
  }
  ngOnInit(): void {
  }

  openDialogAddAdminDistrict() {
    var dataAdminGetOperators: any = [];
    var dataOperator: any = [];
    const dialogRef = this.dialog.open(DialogAddAdminDistrict, {
      data: { id: this.getIdAdminProvince }
    });

    dialogRef.afterClosed().subscribe(async result => {

      if (result != undefined && result != "") {
        // this.dataAdminDistricts.push(result[0]);
        await this.ServiceService.GetListOperatorInAdmin_(result[0].admin_Id).then(async resAdminGetOperators => {
          dataAdminGetOperators = resAdminGetOperators;
          var cuntJob: number = 0;
          if (dataAdminGetOperators.length != 0) {
            for (let j = 0; j < dataAdminGetOperators.length; j++) {

              var resAdminProvince: any;

              await this.ServiceService.GetAdminProvince_(result[0].admin_Id).then(async dataAdminProvince => {
                resAdminProvince = dataAdminProvince;
                var parseJsonAdminProvince = JSON.parse(resAdminProvince);

                for (let l = 0; l < this.dataProvinceNameTHEN.length; l++) {
                  if (this.dataProvinceNameTHEN[l].idProvince == parseJsonAdminProvince[0].admin_ProvinceId) {
                    await this.ServiceService.GetJobToday_("AdminDistrict", this.dataProvinceNameTHEN[l].englishAbbreviation).then(resJobOperator => {
                      var dataJobOperator: any = [];
                      dataJobOperator = resJobOperator;

                      cuntJob = cuntJob + dataJobOperator.length;

                    });
                    break;
                  }
                  if (j == dataAdminGetOperators.length - 1) {
                    dataOperator.push({ dataAdmin: result[0], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
                  }
                }
              });
            }
          } else {
            dataOperator.push({ dataAdmin: result[0], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
          }
        });
        this.dataAdminDistricts.push(dataOperator[0]);
      }
    });
  }

  loadDataprovinceparam(id: string) {
    // console.log(">> ",id);

    var resAdminProvince: any;
    this.ServiceService.GetAdminProvince_(id).then(dataAdminProvince => {
      resAdminProvince = dataAdminProvince;
      var parseJson = JSON.parse(resAdminProvince);
      // console.log("-----------------> ",parseJson);

      this.loadAdminDistricts(parseJson[0].admin_ProvinceId);
      this.dataAdminProvinceName = parseJson[0].admin_Username;
    })
  }

  loadAdminDistricts(AdminProvinceID: string) {
    var resAdminDistrict: any;
    var dataAdminGetOperators: any = [];
    var dataOperator: any = [];
    this.ServiceService.GetAdminDistricts_(AdminProvinceID).then(async dataAdminDistrictAndSubDistrict => {
      resAdminDistrict = dataAdminDistrictAndSubDistrict;
      // console.log("Data successfully ", resAdminDistrict);
      // this.dataAdminDistricts = parseJson;
      if (resAdminDistrict.length != 0) {
        for (let i = 0; i < resAdminDistrict.length; i++) {
          await this.ServiceService.GetListOperatorInAdmin_(resAdminDistrict[i].admin_Id).then(async resAdminGetOperators => {
            dataAdminGetOperators = resAdminGetOperators;
            // console.log("ListOperator : ",dataAdminGetOperators);
            var cuntJob: number = 0;
            if (dataAdminGetOperators.length != 0) {
              for (let j = 0; j < dataAdminGetOperators.length; j++) {

                var resAdminProvince: any;

                await this.ServiceService.GetAdminProvince_(resAdminDistrict[i].admin_Id).then(async dataAdminProvince => {
                  resAdminProvince = dataAdminProvince;
                  var parseJsonAdminProvince = JSON.parse(resAdminProvince);

                  for (let l = 0; l < this.dataProvinceNameTHEN.length; l++) {
                    if (this.dataProvinceNameTHEN[l].idProvince == parseJsonAdminProvince[0].admin_ProvinceId) {
                      await this.ServiceService.GetJobToday_("AdminDistrict", this.dataProvinceNameTHEN[l].englishAbbreviation).then(resJobOperator => {
                        var dataJobOperator: any = [];
                        dataJobOperator = resJobOperator;
                        // console.log(dataJobOperator.length);

                        cuntJob = cuntJob + dataJobOperator.length;

                      });
                      break;
                    }
                    if (j == dataAdminGetOperators.length - 1) {
                      dataOperator.push({ dataAdmin: resAdminDistrict[i], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
                    }
                  }
                });
              }
            } else {
              dataOperator.push({ dataAdmin: resAdminDistrict[i], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
            }
          });
          if (i == resAdminDistrict.length - 1) {
            // console.log("--- ",dataOperator);

            this.dataAdminDistricts = dataOperator;
            setInterval(() => {
              this.loadDataSuccess = true;
              this.loadData = false;
            }, 2000);
          }
        }
      } else {
        setInterval(() => {
          this.loadDataSuccess = true;
          this.loadData = false;
        }, 2000);
      }
    }, (err) => {
      console.log(" : : ", err);
    })

  }

  deleteAdmin(idProvince: string, idParent: string, index: any) {
    var resRemoveAdmins: any;
    if (window.confirm("Do you want Remove Admin? ")) {
      this.ServiceService.removeAdmins_(idProvince, idParent).then((dataRemoveAdmins) => {
        resRemoveAdmins = dataRemoveAdmins;
        // console.log("remon ----> ", resRemoveAdmins);

        this.dataAdminDistricts.splice(index, 1);

      })
    }
  }

}

interface Province {
  value: string;
  viewValue: string;
}
interface District {
  value: string;
  viewValue: string;
}
interface Subdistricts {
  value: string;
  viewValue: string;
}
interface RoleAdmin {
  value: string;
  viewValue: string;
}
export interface DialogData {
  id: string;
}

@Component({
  selector: 'addAdminDistrict-modal',
  templateUrl: './addAdminDistrict-modal.html',
})
export class DialogAddAdminDistrict {
  hide = true;
  addAdminDistrictForm!: FormGroup;

  InvalidUsername = false;

  dataProvince: any = 0;
  dataDistrict: any = 0;
  dataSubdistricts: any = 0;

  provinces: Province[] = [];
  districts: District[] = [];
  subdistricts: Subdistricts[] = [];
  roleAdmins: RoleAdmin[] = [
    { value: 'AdminDistrict', viewValue: 'Admin District' }
  ];

  selectedProvince!: number;
  selectedDistrict!: number;
  selectedSubdistrict!: number;

  nameProvince!: string;
  nameDistricts!: string;
  idProvince!: string;
  idDistrict!: string;
  nameSubdistricts!: string;
  dataConvert!: any;

  dataConvertAddAdmin: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogAddAdminDistrict>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private ServiceService: ServiceService,
    private activatedRoute: ActivatedRoute,
  ) {

    // this.loadProvince();
    // console.log("=== : ",this.data.id);

    this.loadDataprovince(this.data.id);
    this.addAdminDistrictForm = this.formBuilder.group({
      username: '',
      // password: '',
      // confirmPassword: '',
      // address: '',
      // province: '',
      district: '',
      // subdistrict: '',
      // postalcode: '',
      adminRole: '',
      phone: ''
    })
  }

  onSubmit(): any {
    if (this.addAdminDistrictForm.value.username != "" &&
      this.addAdminDistrictForm.value.password != "" &&
      // this.addAdminDistrictForm.value.address != "" &&
      this.nameDistricts != "undefined" &&
      this.addAdminDistrictForm.value.postalcode != "" &&
      this.addAdminDistrictForm.value.adminRole != "" &&
      this.addAdminDistrictForm.value.phone != ""
    ) {
      this.dataConvert = {
        AdminUsername: this.addAdminDistrictForm.value.username,
        AdminPassword: '123456',
        // Admin_Address: this.addAdminDistrictForm.value.address,
        AdminProvinceID: this.idProvince,
        AdminDistrictID: this.idDistrict,
        AdminNamerole: this.nameDistricts,
        AdminParrent: `_${this.data.id}_`,
        // Admin_District: this.nameDistricts,
        // Admin_Subdistrict: this.nameSubdistricts,
        // Admin_Postalcode: this.addAdminDistrictForm.value.postalcode,
        AdminRole: this.addAdminDistrictForm.value.adminRole,
        AdminPhone: this.addAdminDistrictForm.value.phone
      };

      this.ServiceService.AddAdmin(this.dataConvert).subscribe((idAdmin) => {
        // console.log("Data added successfully", idAdmin);
        if(idAdmin != "Duplicate Account"){
          this.dataConvertAddAdmin = [
            {
              admin_Id: "idAdmin",
              admin_Username: this.addAdminDistrictForm.value.username,
              admin_Password: '123456',
              admin_ProvinceId: this.idProvince,
              admin_DistrictId: this.idDistrict,
              admin_Namerole: this.nameDistricts,
              admin_Parrent: `_${this.data.id}_`,
              admin_Role: this.addAdminDistrictForm.value.adminRole,
              admin_Phone: this.addAdminDistrictForm.value.phone
            }
          ]
          this.dialogRef.close(this.dataConvertAddAdmin);
        }else{
          this.InvalidUsername = true;
        }
      }, (err) => {
        console.log(" : : ", err);
      })
    }

  }

  loadDataprovince(id: string) {
    var resAdminProvince: any;
    this.ServiceService.GetAdminProvince_(id).then((dataAdminProvince) => {
      resAdminProvince = dataAdminProvince;
      var parseJson = JSON.parse(resAdminProvince);
      this.loadDistrict(parseJson[0].admin_ProvinceId);
    });

  }

  onDistrictSelection() {
    for (let i = 0; i < this.dataDistrict.length; i++) {
      if (this.selectedDistrict == this.dataDistrict[i].amphurId) {
        this.nameDistricts = this.dataDistrict[i].amphurName;
        this.idProvince = this.dataDistrict[i].provinceId;
        this.idDistrict = this.dataDistrict[i].amphurId;
      }
    }
  }

  // onSubdistrictSelection() {
  //   for (let i = 0; i < this.dataSubdistricts.length; i++) {
  //     if (this.selectedSubdistrict == this.dataSubdistricts[i].DISTRICT_CODE) {
  //       this.nameSubdistricts = this.dataSubdistricts[i].DISTRICT_NAME;
  //       // console.log(this.nameSubdistricts);
  //     }
  //   }
  // }

  // loadProvince() {
  //   this.ServiceService.GetProvince().subscribe(res => {
  //     this.dataProvince = res;
  //     for (let i = 0; i < this.dataProvince.length; i++) {
  //       this.provinces.push({ value: this.dataProvince[i].PROVINCE_ID, viewValue: this.dataProvince[i].PROVINCE_NAME })
  //     }
  //   });
  // }

  loadDistrict(selectedProvince: number) {
    this.districts = [];
    this.subdistricts = [];
    var resDistrict: any;
    this.ServiceService.GetDistrict_(selectedProvince).then(dataDistrict => {
      resDistrict = dataDistrict;
      this.dataDistrict = resDistrict;
      for (let i = 0; i < this.dataDistrict.length; i++) {

        this.districts.push({ value: this.dataDistrict[i].amphurId, viewValue: this.dataDistrict[i].amphurName })
      }
    });
  }

  // loadSubdistrict(selectedDistrict: number) {
  //   this.subdistricts = [];
  //   this.ServiceService.GetSubdistrict(selectedDistrict).subscribe(res => {
  //     this.dataSubdistricts = res;
  //     for (let i = 0; i < this.dataSubdistricts.length; i++) {
  //       this.subdistricts.push({ value: this.dataSubdistricts[i].DISTRICT_CODE, viewValue: this.dataSubdistricts[i].DISTRICT_NAME })
  //     }
  //   });
  // }
}
