<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-1">
                <h1 style="text-align: center;">Operator</h1>
            </div>
            <div class="col-md-11">
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content pl-2 pr-2">
    <div class=" mat-elevation-z8 card card-solid">
        <div class="card-body pb-0">
            <div *ngIf="getRole == 'AdminSubdistricts'; else roleAdmin">
                <h3 class="card-title" style="margin: 0px;">{{getNameProvince}}</h3>
                <h3 class="card-title" style="margin: 0px;"><i class="fas fa-caret-right mr-1 ml-1" style="font-size: 16px;"></i>{{getNameDistrict}}</h3>
                <h3 class="card-title" style="margin: 0px;"><i class="fas fa-caret-right mr-1 ml-1" style="font-size: 16px;"></i>{{getNameSubDistrict}}</h3>
            </div>
            <ng-template #roleAdmin>
                <h3 class="card-title" style="margin: 0px;">{{getNameProvince}}</h3>
            </ng-template>
            <div class="text-right mb-2" *ngIf="!checkAdminViewOnly">
                <button mat-button (click)="openDialogAddOperator()" >Add Operator</button>
            </div>
            <hr>
            <mat-spinner *ngIf="progress" style="margin-left: auto;margin-right: auto;margin-top: 100px;margin-bottom: 100px;"></mat-spinner>
            <div *ngIf="progressSuccess">
                <div class="row d-flex align-items-stretch" *ngIf="adminOperatorsSubDistricts != null">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-flex align-items-stretch" *ngFor="let adminOperatorsSubDistrict of adminOperatorsSubDistricts; let i = index">
                        <mat-card class="mat-elevation-z8 card bg-light" [ngClass]="adminOperatorsSubDistrict.adminStatus == null || adminOperatorsSubDistrict.adminStatus == true ? '' : 'op-disable'" style="width: 522px;">
                            <div class="col-md-12 card-header text-muted border-bottom-0  mat-elevation-z8" style="border-radius: 5px; background-color: #e0dede;">
                              <div class="d-flex justify-content-between">
                                <p class="m-0" style="font-size: 16px;">Admin : <b>{{adminOperatorsSubDistrict.adminNamerole}}</b></p>
                                <div class="d-flex flex-row">
                                  <mat-icon class="mr-1">laptop_mac</mat-icon>
                                  <span *ngIf="adminOperatorsSubDistrict.operatorOnlineStatus == false" class="dotOffline mt-1 mr-3"></span>
                                  <span *ngIf="adminOperatorsSubDistrict.operatorOnlineStatus == true" class="dotOnline mt-1 mr-3"></span>
                                  <mat-icon class="mr-1">phone_iphone</mat-icon>
                                  <span *ngIf="adminOperatorsSubDistrict.operatorOnlineAppStatus == false" class="dotOffline mt-1"></span>
                                  <span *ngIf="adminOperatorsSubDistrict.operatorOnlineAppStatus == true" class="dotOnline mt-1"></span>
                                </div>
                              </div>
                                <!-- <div class="row">
                                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8 " style="margin-top: auto;margin-bottom: auto;font-size: 16px;">
                                        Admin : <b>{{adminOperatorsSubDistrict.adminNamerole}}</b>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3" style="padding-top: 5px;">
                                      <div class="d-flex justify-content-end">
                                        <mat-icon class="mr-1">laptop_mac</mat-icon>
                                        <span *ngIf="adminOperatorsSubDistrict.operatorOnlineStatus == false" class="dotOffline mt-1 mr-3"></span>
                                        <span *ngIf="adminOperatorsSubDistrict.operatorOnlineStatus == true" class="dotOnline mt-1 mr-3"></span>
                                        <mat-icon class="mr-1">phone_iphone</mat-icon>
                                        <span *ngIf="adminOperatorsSubDistrict.operatorOnlineAppStatus == false" class="dotOffline mt-1"></span>
                                        <span *ngIf="adminOperatorsSubDistrict.operatorOnlineAppStatus == true" class="dotOnline mt-1"></span>
                                      </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="card-body pt-0" style="margin-top: 25px">
                                <div class="row">
                                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                                        <h2 class="lead"><b>{{adminOperatorsSubDistrict.operatorFirstname}} {{adminOperatorsSubDistrict.operatorLastname}}</b></h2>
                                        <p class="text-muted text-sm"><b>Operator : </b> {{adminOperatorsSubDistrict.adminNamerole}}</p>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                            <li class="small"><span class="fa-li"><i
                                                        class="fas fa-lg fa-building"></i></span> Address : {{adminOperatorsSubDistrict.operatorAddress}},
                                                        {{adminOperatorsSubDistrict.operatorSubdistrict}} {{adminOperatorsSubDistrict.operatorDistrict}} {{adminOperatorsSubDistrict.operatorProvince}} {{adminOperatorsSubDistrict.operatorPostalcode}}</li>
                                            <li class="small"><span class="fa-li"><i class="fas fa-lg fa-phone"></i></span>
                                                Phone : {{adminOperatorsSubDistrict.operatorPhone}}</li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5 text-center" *ngIf="adminOperatorsSubDistrict.operatorPic != null">
                                        <img src="{{imageUrl1}}/{{adminOperatorsSubDistrict.operatorPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                        alt="imf profile operator" class=" mat-elevation-z8 img-circle img-fluid imgAdminOperator">
                                    </div>
                                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5 text-center" *ngIf="adminOperatorsSubDistrict.operatorPic == null">
                                        <img src="assets/adminlte/dist/img/nouser.svg" alt="imf profile operator" class=" mat-elevation-z8 img-circle img-fluid imgAdminOperator">
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="text-right">
                                    <!-- <button class="mr-1 " mat-raised-button color="primary" routerLink="/detailOperator/{{adminOperatorsSubDistrict.operatorAdminId}}"><i class="fas fa-eye"></i> View
                                        Profile</button>
                                    <button class="mr-1" mat-raised-button color="accent" (click)="openDialogEditOperator(adminOperatorsSubDistrict.operatorAdminId,i)"><i class="fas fa-pencil-alt"></i>
                                        Edit</button>
                                    <button class="mr-1" mat-raised-button color="warn" (click)="deleteOperator(adminOperatorsSubDistrict.operatorId,i)"><i class="fas fa-trash"></i>
                                        Delete</button> -->
                                    <button class="mr-1 " (click)="forceOffline(adminOperatorsSubDistrict)"><i class="fas fa-sign-out-alt"></i></button>
                                    <button class="mr-1 " mat-raised-button [color]="adminOperatorsSubDistrict.adminStatus == null || adminOperatorsSubDistrict.adminStatus == true ? 'primary' : 'basic'" routerLink="/detailOperator/{{adminOperatorsSubDistrict.operatorAdminId}}"><i class="fas fa-eye"></i></button>
                                    <button class="mr-1" mat-raised-button [color]="adminOperatorsSubDistrict.adminStatus == null || adminOperatorsSubDistrict.adminStatus == true ? 'accent' : 'basic'" *ngIf="!checkAdminViewOnly" (click)="openDialogEditOperator(adminOperatorsSubDistrict.operatorAdminId,i)"><i class="fas fa-pencil-alt"></i></button>
                                    <button class="mr-1" mat-raised-button [color]="adminOperatorsSubDistrict.adminStatus == null || adminOperatorsSubDistrict.adminStatus == true ? 'warn' : 'basic'" *ngIf="!checkAdminViewOnly" (click)="deleteOperator(adminOperatorsSubDistrict.operatorId,i)"><i class="fas fa-trash"></i></button>
                                    <button class="mr-1" mat-raised-button color="basic" *ngIf="!checkAdminViewOnly" (click)="changeStatusOperator(adminOperatorsSubDistrict.adminId, adminOperatorsSubDistrict.adminStatus == null || adminOperatorsSubDistrict.adminStatus == true ? false : true)"><i class="fas" [ngClass]="adminOperatorsSubDistrict.adminStatus == null || adminOperatorsSubDistrict.adminStatus == true ? 'fa-ban' : 'fa-undo'"></i></button>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
            <div *ngIf="!progress && !haveData">
                No Data
            </div>
        </div>
    </div>
    <!-- /.card -->

</section>
