import { Component, OnInit, LOCALE_ID, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { DialogEditOperator } from './edit-operator.component';
import * as dataProvinceEN from '../../../shared/interface/myProvince.json';
import { GlobalConstants } from 'src/app/common/global-constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-operators-list',
  templateUrl: './operators-list.component.html',
  styleUrls: ['./operators-list.component.scss']
})
export class OperatorsListComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  roleAdmin: any;
  getNameSubDistrict: any;
  getNameProvince: any;
  getNameDistrict: any;
  getParrent: any;
  getIdSubDistrict: any;
  adminOperatorsSubDistricts: any;
  getRole: any;
  getAdminId: any;
  checkAdminViewOnly: boolean = false;

  progressSuccess: boolean = false;
  progress: boolean = true;
  haveData: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    public dialog: MatDialog
  ) {
    this.roleAdmin = localStorage.getItem("currentRole");
    if (this.roleAdmin == "AdminViewOnly") {
      this.checkAdminViewOnly = true;
    }

    if(this.roleAdmin == "Operator"){
      this.router.navigate([`/dashboard`], { replaceUrl: true }).then(() => { window.location.reload(); });
    }

    if (this.activatedRoute.snapshot.paramMap.get('adminRole') != null) {
      this.getParrent = this.activatedRoute.snapshot.paramMap.get('parrent');
      this.getRole = this.activatedRoute.snapshot.paramMap.get('adminRole');
      this.getAdminId = this.activatedRoute.snapshot.paramMap.get('adminId');
      this.getNameProvince = this.activatedRoute.snapshot.paramMap.get('nameProvince');
      this.loadAdminOperatorsSubDistrict(this.getAdminId, this.getRole);
    } else {
      this.getRole = "AdminSubdistricts";
      this.getNameProvince = this.activatedRoute.snapshot.paramMap.get('nameProvince');
      this.getNameDistrict = this.activatedRoute.snapshot.paramMap.get('nameDistrict');
      this.getNameSubDistrict = this.activatedRoute.snapshot.paramMap.get('nameSubDistrict');
      this.getIdSubDistrict = this.activatedRoute.snapshot.paramMap.get('idSubDistrict');
      this.getParrent = this.activatedRoute.snapshot.paramMap.get('parrent');
      this.loadAdminOperatorsSubDistrict(this.getIdSubDistrict, "null");
    }

  }

  openDialogAddOperator(): void {
    const dialogRef = this.dialog.open(DialogAddOperator, {
      data: { idSubDistrict: this.getIdSubDistrict, idProvince: this.getAdminId, parrent: this.getParrent, role: this.getRole, nameProvince: this.getNameProvince }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed > ? : ', result);
      if (result != undefined && result != "") {
        this.adminOperatorsSubDistricts.push(result[0]);
        // console.log("this.adminOperatorsSubDistricts ", this.adminOperatorsSubDistricts);
      }
    });
  }

  ngOnInit(): void {
  }

  openDialogEditOperator(idOperator: string, index: any): void {
    // console.log("idOperator ", idOperator);

    const dialogRef = this.dialog.open(DialogEditOperator, {
      data: { idSubDistrict: this.getIdSubDistrict, parrent: this.getParrent, idOperator: idOperator, index: index }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('********************* > ? : ', result);
      if (result != undefined) {
        // console.log('222222222222222222222 result > ? : ', result[0].Operator_ID);
        // console.log('333333333333333333333 > ? : ', this.adminOperatorsSubDistricts);
        for (let i = 0; i < this.adminOperatorsSubDistricts.length; i++) {
          if (result[0].Operator_ID == this.adminOperatorsSubDistricts[i].operatorId) {
            // console.log("this.adminOperatorsSubDistricts[i].Operator_ID ", this.adminOperatorsSubDistricts[i].operatorId);
            this.adminOperatorsSubDistricts[i].operatorFirstname = result[0].Operator_Firstname
            this.adminOperatorsSubDistricts[i].operatorFirstname = result[0].Operator_Firstname
            this.adminOperatorsSubDistricts[i].operatorLastname = result[0].Operator_Lastname
            if (result[0].Operator_Pic != null) {
              this.adminOperatorsSubDistricts[i].operatorPic = result[0].Operator_Pic
            }
            this.adminOperatorsSubDistricts[i].operatorAddress = result[0].Operator_Address
            this.adminOperatorsSubDistricts[i].operatorSubdistrict = result[0].Operator_Subdistrict
            this.adminOperatorsSubDistricts[i].operatorDistrict = result[0].Operator_District
            this.adminOperatorsSubDistricts[i].operatorProvince = result[0].Operator_Province
            this.adminOperatorsSubDistricts[i].operatorPostalcode = result[0].Operator_Postalcode
            this.adminOperatorsSubDistricts[i].operatorPhone = result[0].Operator_Phone
          }
        }
      }
    });
  }


  loadAdminOperatorsSubDistrict(id: string, role: string) {
    // console.log("-----------idSubDistrict----> ", id);

    if (role != "null") {
      // console.log("role ",role);

      var resAdminGetOperators: any;
      var dataRole: any;
      this.ServiceService.GetAdminRole_().then(resRole => {
        dataRole = resRole;
        // console.log(dataRole);

        for (let i = 0; i < dataRole.length; i++) {
          if(dataRole[i].typeAdmin == role){
            // console.log("> ",dataRole[i].typeAdmin);

            this.ServiceService.GetIdOperatorByAbbreviation_(dataRole[i].typeKeyword).then(dataIdOperatorByAbbreviation => {
              resAdminGetOperators = dataIdOperatorByAbbreviation;
              // console.log("res >__________--> ", resAdminGetOperators);
              this.adminOperatorsSubDistricts = resAdminGetOperators;
              console.log(this.adminOperatorsSubDistricts);

              if(this.adminOperatorsSubDistricts.length < 1) {
                this.haveData = false;
              }
              this.progressSuccess = true;
              this.progress = false;
              // setInterval(() => {
              // }, 2000);
              this.adminOperatorsSubDistricts = this.adminOperatorsSubDistricts.sort((item: any) => {
                return item.adminStatus == true || item.adminStatus == null ? -1 : 1;
              });
            });
          }
        }
      });
    } else {
      var resAdminGetOperators :any;
      this.ServiceService.GetAdminGetOperators_(id, "0", false).then(dataAdminGetOperators => {
        resAdminGetOperators = dataAdminGetOperators;
        // console.log("res >__________--> ", resAdminGetOperators);
        setInterval(() => {
          this.adminOperatorsSubDistricts = resAdminGetOperators;
          this.progress = false;
          this.progressSuccess = true;
        }, 2000);
      });
    }
  }

  deleteOperator(id: any, i: any) {
    Swal.fire({
      title: "Delete Operator?",
      text: "ลบ Operator ออกจากระบบ (ข้อมูลทั้งหมดที่เกี่ยวข้องกับ Operator นี้ เช่น ข้อมูล Case และอื่นๆ จะถูกออกลบทั้งหมด)",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3B71CA',
      cancelButtonColor: '#9FA6B2',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ServiceService.removeOperator_(id).then((res) => {
          this.adminOperatorsSubDistricts.splice(i, 1);
          Swal.fire({
            icon: 'success',
            title: 'Operator deleted.',
            showConfirmButton: false,
            timer: 1500
          });
        })
      }
    });
  }

  changeStatusOperator(OperatorId: string, Status: boolean) {
    Swal.fire({
      title: Status ? "Enable Operator?" : "Disable Operator",
      text: Status ? "เปิดการใช้งาน Operator นี้" : "ปิดการใช้งาน Operator นี้ (Operator จะไม่สามารถเข้าใช้งานระบบได้)",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3B71CA',
      cancelButtonColor: '#9FA6B2',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ServiceService.SetStatusOperator_(OperatorId, Status).then(res => {
          if (res.status) {
            this.adminOperatorsSubDistricts[this.adminOperatorsSubDistricts.findIndex((index: any) => index.adminId == OperatorId)].adminStatus = Status;
            this.adminOperatorsSubDistricts = this.adminOperatorsSubDistricts.sort((item: any) => {
              return item.adminStatus == true || item.adminStatus == null ? -1 : 1;
            });
            Swal.fire({
              icon: 'success',
              title: Status ? "Operator Enabled!" : "Operator Disabled!",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }, err => {
          console.log("err -> ", err);
        });
      }
    });
  }

  forceOffline(Operator: any) {
    Swal.fire({
      title: 'Set Offline Operator?',
      text: "เปลี่ยนสถานะ Operator ให้เป็น Offline ใช้ในกรณีที่ Operator มีสถานะออนไลน์ค้างอยู่ในระบบ (จะกลับมาออนไลน์เมื่อ Operator ทำการ Login เข้ามายังระบบอีกครั้ง)",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3B71CA',
      cancelButtonColor: '#9FA6B2',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ServiceService.forceOpOfflineByID_(localStorage.getItem("currentIdAdmin") ?? "", Operator.operatorId).then(res => {
          if (JSON.parse(res).status) {
            this.adminOperatorsSubDistricts[this.adminOperatorsSubDistricts.findIndex((index: any) => index.operatorId == Operator.operatorId)].operatorOnlineStatus = false;
            Swal.fire({
              icon: 'success',
              title: 'Operator offlined.',
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong.',
              text: JSON.parse(res).message,
              confirmButtonText: "OK"
            });
          }
        }, err => {
          console.log("err -> ", err);
        });
      }
    });
  }
}

interface Gender {
  value: string;
  viewValue: string;
}
interface Province {
  value: string;
  viewValue: string;
}

interface District {
  value: string;
  viewValue: string;
}

interface Subdistricts {
  value: string;
  viewValue: string;
}

export interface DialogData {
  idSubDistrict: string;
  idProvince: string;
  role: string;
  parrent: string;
  nameProvince: string;

  operatorEmail: string;
  operatorUsername: string;
  operatorPassword: string;
  operatorFirstname: string;
  operatorLastname: string;
  operatorGender: string;
  operatorAge: string;
  operatorDateofbirth: string;
  operatorPhone: string;
  operatorAddress: string;
  operatorProvince: string;
  operatorDistrict: string;
  operatorSubdistrict: string;
  operatorPostalcode: string;
}

@Component({
  selector: 'addOperator-modal',
  templateUrl: './addOperator-modal.html',
})
export class DialogAddOperator implements OnInit {

  noImg: boolean = true;
  duplicateUsernameCheck: boolean = false;
  duplicateEmailCheck: boolean = false;
  duplicateUsername: string = "";
  dataProvince: any = 0;
  dataDistrict: any = 0;
  dataSubdistricts: any = 0;

  provinces: Province[] = [];
  districts: District[] = [];
  subdistricts: Subdistricts[] = [];

  selectedProvince!: number;
  selectedDistrict!: number;
  selectedSubdistrict!: number;

  nameProvince!: string;
  nameDistricts!: string;
  nameSubdistricts!: string;
  idProvince!: string;
  idDistricts!: string;
  idSubdistricts!: string;

  dataConvert!: any;
  dataConvertAddOperator: any[] = [];

  idAdminSubDistrict!: string;
  idAdminRole!: string;
  idAdminParrent!: string;
  idAdminNamerole!: string;
  idAdminProvinceID!: string;
  idAdminDistrictID!: string;
  idAdminSubDistrictID!: string;

  fileData!: File;
  previewUrl: any = null;
  fileUploadProgress!: string;

  englishAbbreviation!: string;
  dataProvinceNameTHEN: any = (dataProvinceEN as any).default;

  hideP1: boolean = true;
  hideP2: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DialogAddOperator>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(LOCALE_ID) private locale: string,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    // private http: HttpClient,
    private ngZone: NgZone,
    private ServiceService: ServiceService,
    private activatedRoute: ActivatedRoute,
  ) { }
  ngOnInit(): void {
    this.loadSelectDialogProvince();
    this.loadOperatorSubDistrict(this.data.idSubDistrict, this.data.parrent, this.data.idProvince, this.data.role);
// console.log("ddd ",this.data);

    if (this.data.role != "AdminSubdistricts") {
      var dataRole : any;
      this.ServiceService.GetAdminRole_().then(resRole => {
        dataRole = resRole;
        for (let i = 0; i < dataRole.length; i++) {
          if(dataRole[i].typeAdmin == this.data.role){
            this.englishAbbreviation = dataRole[i].typeKeyword;
          }
        }
      });
      // this.englishAbbreviation = this.dataProvinceNameTHEN[0].englishAbbreviation;
    } else {
      for (let i = 0; i < this.dataProvinceNameTHEN.length; i++) {
        if (this.data.nameProvince == this.dataProvinceNameTHEN[i].nameProvince) {
          this.englishAbbreviation = this.dataProvinceNameTHEN[i].englishAbbreviation;
        }
      }
    }
  }

  operatorEmail = new FormControl('', [Validators.required, Validators.email]);
  operatorUsername = new FormControl('', [Validators.required]);
  operatorPassword = new FormControl('', [Validators.required, Validators.minLength(8)]);
  operatorPasswordConfirm = new FormControl('', [Validators.required, Validators.minLength(8)]);
  operatorFirstname = new FormControl('', [Validators.required]);
  operatorLastname = new FormControl('', [Validators.required]);
  operatorGender = new FormControl('', [Validators.required]);
  operatorAge = new FormControl('', [Validators.required]);
  operatorDateofbirth = new FormControl('', [Validators.required]);
  operatorPhone = new FormControl('', [Validators.required, Validators.minLength(10)]);
  operatorAddress = new FormControl('', [Validators.required]);
  operatorProvince = new FormControl('', [Validators.required]);
  operatorDistrict = new FormControl('', [Validators.required]);
  operatorSubdistrict = new FormControl('', [Validators.required]);
  operatorPostalcode = new FormControl('', [Validators.required, Validators.minLength(5)]);

  getErrorMessageEmail() {
    if (this.operatorEmail.hasError('required')) {
      return 'You must enter a value';
    }
    return this.operatorEmail.hasError('email') ? 'Not a valid email' : '';
  }
  getErrorMessageUsername() {
    return this.operatorUsername.hasError('required') ? 'You must enter a value' : '';
  }
  getErrorMessagePassword() {
    if (this.operatorPassword.hasError('required')) {
      return this.operatorPassword.hasError('required') ? 'You must enter a value' : '';
    }
    return this.operatorPassword.hasError('minlength') ? 'You must more than 8 characters long' : '';
  }
  getErrorMessagePasswordConfirm() {
    if (this.operatorPasswordConfirm.hasError('required')) {
      return this.operatorPasswordConfirm.hasError('required') ? 'You must enter a value' : '';
    }
    return this.operatorPasswordConfirm.hasError('minlength') ? 'You must more than 8 characters long' : '';
  }
  getErrorMessageFirstname() {
    return this.operatorFirstname.hasError('required') ? 'You must enter a value' : '';
  }
  getErrorMessageLastname() {
    return this.operatorLastname.hasError('required') ? 'You must enter a value' : '';
  }
  getErrorMessageGender() {
    return this.operatorGender.hasError('required') ? 'You must select a value' : '';
  }
  getErrorMessageAge() {
    return this.operatorAge.hasError('required') ? 'You must enter a value' : '';
  }
  getErrorMessageDateofbirth() {
    return this.operatorDateofbirth.hasError('required') ? 'You must Choose a date' : '';
  }
  getErrorMessagePhone() {
    if (this.operatorPhone.hasError('required')) {
      return 'You must enter a value';
    }
    return this.operatorPhone.hasError('minlength') ? 'You must phone a invalid' : '';
  }
  getErrorMessageAddress() {
    return this.operatorAddress.hasError('required') ? 'You must enter a value' : '';
  }
  getErrorMessageProvince() {
    return this.operatorProvince.hasError('required') ? 'You must select a value' : '';
  }
  getErrorMessageDistrict() {
    return this.operatorDistrict.hasError('required') ? 'You must select a value' : '';
  }
  getErrorMessageSubdistrict() {
    return this.operatorSubdistrict.hasError('required') ? 'You must select a value' : '';
  }
  getErrorMessagePostalcode() {
    if (this.operatorPostalcode.hasError('required')) {
      return 'You must enter a value';
    }
    return this.operatorPostalcode.hasError('minlength') ? 'You must postalcode a invalid' : '';
  }

  genders: Gender[] = [
    { value: 'M', viewValue: 'Male' },
    { value: 'FM', viewValue: 'FeMale' }
  ];


  onSubmit(): void {
    if (this.operatorEmail.status != "INVALID" &&
      this.operatorUsername.invalid != true &&
      this.operatorPassword.invalid != true &&
      this.operatorPasswordConfirm.invalid != true &&
      this.operatorPassword.value == this.operatorPasswordConfirm.value &&
      this.operatorFirstname.invalid != true &&
      this.operatorLastname.invalid != true &&
      this.operatorGender.invalid != true &&
      this.operatorAge.invalid != true &&
      this.operatorDateofbirth.invalid != true &&
      this.operatorPhone.value.length == 10 &&
      this.operatorPostalcode.value.length == 5 &&
      this.operatorAddress.invalid != true &&
      this.operatorProvince.invalid != true &&
      this.operatorDistrict.invalid != true &&
      this.operatorSubdistrict.invalid != true
    ) {
      const formData = new FormData();
      formData.append('FormFiles', this.fileData);
      var img: any;
      this.ServiceService.UploadIMGProfile_(formData).then((resImg) => {
        img = resImg;
        this.dataConvert = {
          OperatorAdminId: this.idAdminSubDistrict,
          OperatorEmail: this.operatorEmail.value,
          OperatorUsername: `${this.englishAbbreviation}-${this.operatorUsername.value}`,
          OperatorPassword: this.operatorPassword.value,
          OperatorFirstname: this.operatorFirstname.value,
          OperatorLastname: this.operatorLastname.value,
          OperatorPic: img,
          OperatorGender: this.operatorGender.value,
          OperatorAge: this.operatorAge.value,
          OperatorDateofbirth: formatDate(this.operatorDateofbirth.value, 'MM/dd/yyyy', this.locale),
          OperatorAddress: this.operatorAddress.value,
          OperatorProvinceId: this.idProvince,
          OperatorProvince: this.nameProvince,
          OperatorDistrictId: this.idDistricts,
          OperatorDistrict: this.nameDistricts,
          OperatorSubdistrictId: this.idSubdistricts,
          OperatorSubdistrict: this.nameSubdistricts,
          OperatorPostalcode: this.operatorPostalcode.value,
          OperatorPhone: this.operatorPhone.value,

          OperatorRole: this.idAdminRole,
          OperatorParrent: this.idAdminParrent,
          OperatorNamerole: this.idAdminNamerole,
          OperatorProvinceId_Admin: this.idAdminProvinceID,
          OperatorDistrictId_Admin: this.idAdminDistrictID,
          OperatorSubDistrictId_Admin: this.idAdminSubDistrictID

        };
        // console.log(this.dataConvert);
        var id: any;
        this.ServiceService.AddOperator_(this.dataConvert).then((dataId) => {
          id = dataId;
          // console.log("Data added successfully -idOperatorAdmin-> ", id);
          if (id.status == false) {
            // alert(id.message);
            if (id.message == "Duplicate Username") {
              this.duplicateUsernameCheck = true;
              this.duplicateEmailCheck = false;
            } else {
              this.duplicateEmailCheck = true;
              this.duplicateUsernameCheck = false;
            }
            this.duplicateUsername = id.message;
          } else {
            this.dataConvertAddOperator = [
              {
                operatorAdminId: id.operatoIDAdmin,
                operatorEmail: this.operatorEmail.value,
                operatorUsername: this.operatorUsername.value,
                operatorPassword: this.operatorPassword.value,
                operatorFirstname: this.operatorFirstname.value,
                operatorLastname: this.operatorLastname.value,
                operatorPic: img,
                operatorGender: this.operatorGender.value,
                operatorAge: this.operatorAge.value,
                operatorDateofbirth: formatDate(this.operatorDateofbirth.value, 'MM/dd/yyyy', this.locale),
                operatorAddress: this.operatorAddress.value,
                operatorProvince: this.nameProvince,
                operatorDistrict: this.nameDistricts,
                operatorSubdistrict: this.nameSubdistricts,
                operatorPostalcode: this.operatorPostalcode.value,
                operatorPhone: this.operatorPhone.value,
                operatorId: id.operatoID,
                operatorRole: this.idAdminRole,
                operatorParrent: this.idAdminParrent,
                operatorNamerole: this.idAdminNamerole,
                operatorProvinceId_Admin: this.idAdminProvinceID,
                operatorDistrictId_Admin: this.idAdminDistrictID,
                operatorSubDistrictId_Admin: this.idAdminSubDistrictID,
                adminNamerole: this.idAdminNamerole
              }
            ]
            this.dialogRef.close(this.dataConvertAddOperator);
          }
        }, (err) => {
          console.log(" : : ", err);
        })

      }, (err) => {
        console.log(" : : ", err);
      });

    } else {
      //todo
    }

  }

  loadOperatorSubDistrict(id: string, parrent: string, idP: string, role: string) {
    var resAdminOperatorSubDistricts: any;
    if (role != "AdminSubdistricts") {
      this.ServiceService.GetAdminOperatorSubDistricts_(parrent, "0", idP, true).then(dataAdminOperatorSubDistricts => {
        resAdminOperatorSubDistricts = dataAdminOperatorSubDistricts;
        // console.log("res22222222AdminHuawei >> ", res);
        this.idAdminSubDistrict = resAdminOperatorSubDistricts[0].admin_Id;
        this.idAdminRole = "Operator";
        this.idAdminParrent = resAdminOperatorSubDistricts[0].admin_Parrent;
        this.idAdminNamerole = resAdminOperatorSubDistricts[0].admin_Namerole;
        this.idAdminProvinceID = resAdminOperatorSubDistricts[0].admin_ProvinceId;
        this.idAdminDistrictID = resAdminOperatorSubDistricts[0].admin_DistrictId;
        this.idAdminSubDistrictID = resAdminOperatorSubDistricts[0].admin_SubDistrictId;
        // console.log("res00 ---------->> ", res[0].Admin_ProvinceID);
      });
    } else {
      this.ServiceService.GetAdminOperatorSubDistricts_(parrent, id, "0", false).then(dataAdminOperatorSubDistricts => {
        resAdminOperatorSubDistricts = dataAdminOperatorSubDistricts;
        // console.log("res22222222 >> ", res);
        this.idAdminSubDistrict = resAdminOperatorSubDistricts[0].admin_Id;
        this.idAdminRole = "Operator";
        this.idAdminParrent = resAdminOperatorSubDistricts[0].admin_Parrent;
        this.idAdminNamerole = resAdminOperatorSubDistricts[0].admin_Namerole;
        this.idAdminProvinceID = resAdminOperatorSubDistricts[0].admin_ProvinceId;
        this.idAdminDistrictID = resAdminOperatorSubDistricts[0].admin_DistrictId;
        this.idAdminSubDistrictID = resAdminOperatorSubDistricts[0].admin_SubDistrictId;
        // console.log("res00 ---------->> ", res[0].Admin_ProvinceID);
      });
    }
  }

  loadSelectDialogProvince() {
    var resProvinceSelect: any;
    this.ServiceService.GetProvinceSelect_().then(dataProvinceSelect => {
      resProvinceSelect = dataProvinceSelect;
      this.dataProvince = resProvinceSelect;
      for (let i = 0; i < this.dataProvince.length; i++) {
        this.provinces.push({ value: this.dataProvince[i].provinceId, viewValue: this.dataProvince[i].provinceName })
      }
    });
  }

  loadSelectDistrict(selectedProvince: number) {
    this.districts = [];
    this.subdistricts = [];
    var resDistrict: any;
    if(selectedProvince != undefined){
      this.ServiceService.GetDistrict_(selectedProvince).then(dataDistrict => {
        resDistrict = dataDistrict;
        this.dataDistrict = resDistrict;
        for (let i = 0; i < this.dataDistrict.length; i++) {

          this.districts.push({ value: this.dataDistrict[i].amphurId, viewValue: this.dataDistrict[i].amphurName })
        }
      });
    }
  }

  loadSelectSubdistrict(selectedDistrict: number) {
    this.subdistricts = [];
    var resSubdistrict: any;
    if(selectedDistrict != undefined){
      this.ServiceService.GetSubdistrict_(selectedDistrict).then(dataSubdistrict => {
        resSubdistrict = dataSubdistrict;
        this.dataSubdistricts = resSubdistrict;
        for (let i = 0; i < this.dataSubdistricts.length; i++) {
          this.subdistricts.push({ value: this.dataSubdistricts[i].districtCode, viewValue: this.dataSubdistricts[i].districtName })
        }
      });
    }
  }

  onProvinceSelection() {
    this.loadSelectDistrict(this.selectedProvince);

    for (let i = 0; i < this.dataProvince.length; i++) {
      if (this.selectedProvince == this.dataProvince[i].provinceId) {
        this.nameProvince = this.dataProvince[i].provinceName;
        this.idProvince = this.dataProvince[i].provinceId;
        // console.log(this.nameProvince);
      }
    }
  }

  onDistrictSelection() {
    this.loadSelectSubdistrict(this.selectedDistrict);

    for (let i = 0; i < this.dataDistrict.length; i++) {
      if (this.selectedDistrict == this.dataDistrict[i].amphurId) {
        this.nameDistricts = this.dataDistrict[i].amphurName;
        this.idDistricts = this.dataDistrict[i].amphurId;
        // console.log(this.nameDistricts);
      }
    }
  }

  onSubdistrictSelection() {
    for (let i = 0; i < this.dataSubdistricts.length; i++) {
      if (this.selectedSubdistrict == this.dataSubdistricts[i].districtCode) {
        this.nameSubdistricts = this.dataSubdistricts[i].districtName;
        this.idSubdistricts = this.dataSubdistricts[i].districtId;
        // console.log(this.nameSubdistricts);
      }
    }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    this.noImg = false;
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

}
