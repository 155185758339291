import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginfaillComponent } from 'src/app/shared/snackBar/loginfaill/loginfaill.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  dataUseramePassword: any;
  hide = true;
  durationInSeconds = 5;
  keywords: any[] = [];
  deviceInfo:any;
  isDesktopDevice: boolean = false;
  isTablet: boolean = false;
  isMobile: boolean = false;

  constructor(
    private deviceService: DeviceDetectorService,
    public formBuilder: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private ServiceService: ServiceService,
    private _snackBar: MatSnackBar
  ) {
    this.loginForm = this.formBuilder.group({
      loginUsername: '',
      loginPassword: ''
    })
  }

  ngOnInit(): void {
    this.getAdminRole();
    this.epicFunction();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    // console.log("deviceInfo : ",this.deviceInfo);
    // console.log("isMobile : ",this.isMobile);
    // console.log("isTablet : ",this.isTablet);
    // console.log("isDesktopDevice : ",this.isDesktopDevice);
  }

  async getAdminRole() {
    await this.ServiceService.GetAdminRole_().then(res => {
      if (res.length) this.keywords = res;
    }, err => {
      console.log("err -> ", err);
    });
  }

  onSubmitLogin(): any {
    var resUpdateStatusOperator: any;
    var reslogin: any;
    this.dataUseramePassword = {
      AdminUsername: this.loginForm.value.loginUsername,
      AdminPassword: this.loginForm.value.loginPassword,
      AdminDeviceType: this.isDesktopDevice == true ? "computer":"mobile"
    }
    
    if (this.loginForm.value.loginUsername != '' && this.loginForm.value.loginPassword != '') {
      this.ServiceService.Login_(this.dataUseramePassword).then(async dataLogin => {
        // console.log(dataLogin);
        
        reslogin = dataLogin;
        // console.log("res ", reslogin);
        if (reslogin.status) {
          localStorage.setItem('currentUser', reslogin.token);
          localStorage.setItem('currentIdAdmin', reslogin.idAdmin);
          localStorage.setItem('currentRole', reslogin.role);
          localStorage.setItem('currentUsername', this.loginForm.value.loginUsername);
          if (reslogin.role != "SuperAdmin") {
            if (reslogin.role == "Filter" || reslogin.role == "AdminViewOnly" || reslogin.role == "Operator") localStorage.setItem('showAdmin', this.keywords.find((item: any) => item.typeKeyword == this.loginForm.value.loginUsername.split("-")[0]).typeAdmin);
            else localStorage.setItem('showAdmin', reslogin.role);
          }
          else {
            var resultRole: any;
            await this.ServiceService.GetAdminRole_().then(resRole => {
              resultRole = resRole;
              for (let i = 0; i < resultRole.length; i++) {
                if (resultRole[i].typeIsActive == true) {
                  localStorage.setItem('showAdmin', resultRole[i].typeAdmin);
                  break;
                }
              }
            });
          }
          // this.router.navigate(["dashboard"]);
          // this.router.navigateByUrl('/dashboard', { replaceUrl: true }).then(() => {window.location.reload();});
          // this.ngZone.run(() => this.router.navigateByUrl('/dashboard').then(() => {window.location.reload();}));
          if (reslogin.role == "Operator") {
            this.ServiceService.UpdateStatusOperator_(reslogin.idAdmin, true).then(dataUpdateStatusOperator => {
              resUpdateStatusOperator = dataUpdateStatusOperator;
              this.router.navigate([`/detailOperator/${reslogin.idAdmin}`], { replaceUrl: true }).then(() => { window.location.reload(); });
            });
          } else if (reslogin.role == "Filter") {
            this.router.navigate([`/check-smartwatch-ticker/${reslogin.idAdmin}`], { replaceUrl: true }).then(() => { window.location.reload(); });
          } else {
            this.router.navigate([`/dashboard`], { replaceUrl: true }).then(() => { window.location.reload(); });
          }
        }
        else {
          this._snackBar.open(reslogin.message, '', {
            duration: 5000
          });
          // this._snackBar.openFromComponent(LoginfaillComponent, {
          //   duration: this.durationInSeconds * 1000,
          // });
        }
      })
    }

  }
}
