import { Component, OnInit, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../../../../core/services/service.service';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';

@Component({
    selector: 'chart-covid.component',
    templateUrl: './chart-covid.component.html',
})
export class DialogCovid19Chart implements OnInit {
    idUser: any;
    dataCovidSymptom: any=[];

    loadDateSy:boolean = false;

    infromationCovid19 =
        {
            cFeverish: false,
            cSoreThroat: false,
            cMusclePain: false,
            cRunny: false,
            cSputum: false,
            cHeadache: false,
            cNoseNoSmell: false,
            cRash: false,
            cLiquidStool: false,
            cCoughAlittle: false,
            cTongueNoTaste: false,
            cConjunctivitis: false,
            cCanNotEat: false,
            cCoughAlot: false,
            cCoughingUpBlood: false,
            cBreathingDiff: false,
            cTriedDailyActivities: false,
            cTiredSitStill: false,
            cLieDownBreath: false
        };

    constructor(
        private router: Router,
        private ServiceService: ServiceService,
        private mapsAPILoader: MapsAPILoader,
        public dialogRef: MatDialogRef<DialogCovid19Chart>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        // console.log(data);
        this.idUser = data.dataSos;
    }

    ngOnInit(): void {
        this.loadCovidDisease(this.idUser);
        console.log(this.infromationCovid19);

    }

    async loadCovidDisease(idUser: string) {
        var data_CovidSymptom: any;

        await this.ServiceService.GetCovidSymptomV4_(idUser).then(resCovidSymptom => {
            console.log(resCovidSymptom);
            data_CovidSymptom = resCovidSymptom;
            data_CovidSymptom = data_CovidSymptom.sort((a: { cId: number; }, b: { cId: number; }) => a.cId > b.cId ? 1 : a.cId < b.cId ? -1 : 0);
            // this.dataCovidSymptom = data_CovidSymptom;
            for (let i = 0; i < data_CovidSymptom.length; i++) {

                this.dataCovidSymptom.push({dataCovid:data_CovidSymptom[i],dataSymptom: JSON.parse(data_CovidSymptom[i].cCurrentSymptoms)})
                
                if(i == data_CovidSymptom.length -1){
                    this.loadDateSy = true;
                }
            }
            // console.log("this.dataCovidSymptom : ",this.dataCovidSymptom);
            

            for (let i = 0; i < data_CovidSymptom.length; i++) {
                if (data_CovidSymptom[i].cFeverish == true) {
                    this.infromationCovid19.cFeverish = true;
                }

                if (data_CovidSymptom[i].cSoreThroat == true) {
                    this.infromationCovid19.cSoreThroat = true;
                }

                if (data_CovidSymptom[i].cMusclePain == true) {
                    this.infromationCovid19.cMusclePain = true;
                }

                if (data_CovidSymptom[i].cRunny == true) {
                    this.infromationCovid19.cRunny = true;
                }

                if (data_CovidSymptom[i].cSputum == true) {
                    this.infromationCovid19.cSputum = true;
                }

                if (data_CovidSymptom[i].cHeadache == true) {
                    this.infromationCovid19.cHeadache = true;
                }

                if (data_CovidSymptom[i].cNoseNoSmell == true) {
                    this.infromationCovid19.cNoseNoSmell = true;
                }

                if (data_CovidSymptom[i].cRash == true) {
                    this.infromationCovid19.cRash = true;
                }

                if (data_CovidSymptom[i].cLiquidStool == true) {
                    this.infromationCovid19.cLiquidStool = true;
                }

                if (data_CovidSymptom[i].cCoughAlittle == true) {
                    this.infromationCovid19.cCoughAlittle = true;
                }

                if (data_CovidSymptom[i].cTongueNoTaste == true) {
                    this.infromationCovid19.cTongueNoTaste = true;
                }

                if (data_CovidSymptom[i].cConjunctivitis == true) {
                    this.infromationCovid19.cConjunctivitis = true;
                  }

                if (data_CovidSymptom[i].cCanNotEat == true) {
                    this.infromationCovid19.cCanNotEat = true;
                }

                if (data_CovidSymptom[i].cCoughAlot == true) {
                    this.infromationCovid19.cCoughAlot = true;
                }

                if (data_CovidSymptom[i].cCoughingUpBlood == true) {
                    this.infromationCovid19.cCoughingUpBlood = true;
                }

                if (data_CovidSymptom[i].cBreathingDiff == true) {
                    this.infromationCovid19.cBreathingDiff = true;
                }

                if (data_CovidSymptom[i].cTriedDailyActivities == true) {
                    this.infromationCovid19.cTriedDailyActivities = true;
                }

                if (data_CovidSymptom[i].cTiredSitStill == true) {
                    this.infromationCovid19.cTiredSitStill = true;
                }

                if (data_CovidSymptom[i].cLieDownBreath == true) {
                    this.infromationCovid19.cLieDownBreath = true;
                }

            }
        });
    }
}