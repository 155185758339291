import { Component, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ServiceService } from '../../../../../core/services/service.service';
import { io } from 'socket.io-client';
import { number } from '@amcharts/amcharts4/core';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'confirm-see-user-modal.component',
    templateUrl: './confirm-see-user-modal.component.html',
})
export class DialogConfirmSeeUser {
    dataConfirmViewProfile: any;
    idOperatorAdmin: any;
    private socket: any;
    dataUpdateStatus: any;
    dataConvert: any;
    constructor(
        private ngZone: NgZone,
        private ServiceService: ServiceService,
        private router: Router,
        public dialogRef: MatDialogRef<DialogConfirmSeeUser>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
        this.socket = io(GlobalConstants.socket, { transports: ['websocket', 'polling', 'flashsocket'] });
        // console.log(data);
        this.dataConfirmViewProfile = data;
    }

    onClickConfirm(dataC_: any): void {
        var dataUpdateStatus: any;
        // console.log(dataC_);
        if (dataC_.cDangerLevel != "userPhone") {

            this.ServiceService.UpdateDocCovidStatusSeeUSer_(dataC_.daId, true).then(resUpdateStatus => {
                dataUpdateStatus = resUpdateStatus;
                // console.log(resUpdateStatus);
                if (dataUpdateStatus.status == true) {
                    this.socket.emit('dataAlertPending_Covid19', { msg: "doctor-confirmAlert", status: "read", idOperator: this.idOperatorAdmin });
                    this.router.navigateByUrl(`/userCovid19/${dataC_.daUserId}`);
                    this.dialogRef.close();
                }
            });

        } else {
            var resDataOperator: any;
            this.ServiceService.GetIdOperatorByIdOperatorAdmin_(this.idOperatorAdmin).then(dataDataOperator => {
                resDataOperator = dataDataOperator;
                // console.log("resDataOperator ***** ", resDataOperator);

                this.dataUpdateStatus = {
                    OjobId: dataC_.idJob,
                    OperatorId: resDataOperator[0].operatorId,
                    ObjectId: "",
                    OjobDate: "",
                    OjobStatus: "end",
                    OjobHeartRate: "",
                    OjobTemp: "",
                    OjobBattery: ""
                };
                this.ServiceService.updateStatusSosAlert_(this.dataUpdateStatus).then(res => {
                    this.socket.emit('dataAlertPending_Covid19', { msg: "doctor-confirmAlert", status: "read", idOperator: this.idOperatorAdmin });
                    this.router.navigateByUrl(`/userCovid19/${dataC_.dataCovid19.userId}`);
                    this.dialogRef.close();
                });
            });
        }

    }

    startPracticeJob(dataSos:any) {
        // console.log("dataSos > ", dataSos.alertMsg);
        // console.log("dataSos > ", dataSos.daType);
        var resIDOperatoAdmin: any;
        var dataDataOperator: any;
        var idCase: any;
        if(dataSos.daType != undefined){
            idCase = dataSos.daType;
        }else if(dataSos.alertMsg != undefined){
            idCase = dataSos.alertMsg;
        }

        this.ServiceService.GetIdOperatorByIdOperatorAdmin_(this.idOperatorAdmin).then(resDataOperator => {
            dataDataOperator = resDataOperator;
            // console.log("dataDataOperator ",dataDataOperator);

            this.dataConvert = {
                OjobId: Number(idCase),
                OperatorId: dataDataOperator[0].operatorId,
                ObjectId: "",
                OjobDate: "",
                OjobStatus: "work",
                OjobHeartRate: "",
                OjobTemp: "",
                OjobBattery: ""
            };

            var dataUpdateStatus: any;
            this.ServiceService.UpdateDocCovidStatusSeeUSer_(dataSos.daId, true).then(resUpdateStatus => {
                dataUpdateStatus = resUpdateStatus;
                // console.log(resUpdateStatus);
                if (dataUpdateStatus.status == true) {
                    this.socket.emit('dataAlertPending_Covid19', { msg: "doctor-confirmAlert", status: "read", idOperator: this.idOperatorAdmin });
                }
            });

            this.ServiceService.updateStatusSosAlert_(this.dataConvert).then(dataIDOperatoAdmin => {
                resIDOperatoAdmin = dataIDOperatoAdmin;
                this.ngZone.run(() => this.router.navigateByUrl(`/caseProfile/${this.idOperatorAdmin}/${Number(idCase)}`));

                this.socket.emit('dataMessageJobWork', Number(idCase));
                this.socket.emit('dataMessageJob', "sos");
                this.dialogRef.close();
            });


        });

    }
}
