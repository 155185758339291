import { Component, OnInit, LOCALE_ID, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as dataProvinceEN from '../../../shared/interface/myProvince.json';
import { GlobalConstants } from 'src/app/common/global-constants';

interface Gender {
    value: string;
    viewValue: string;
}
interface Province {
    value: string;
    viewValue: string;
}

interface District {
    value: string;
    viewValue: string;
}

interface Subdistricts {
    value: string;
    viewValue: string;
}

export interface DialogEditData {
    idSubDistrict: string;
    parrent: string;

    idOperator: string;
    index: any;
}

@Component({
    selector: 'editOperator-modal',
    templateUrl: './editOperator-modal.html',
})
export class DialogEditOperator implements OnInit {
    imageUrl1: string = GlobalConstants.imageUrl1;
    imageUrl2: string = GlobalConstants.imageUrl2;
    fileData!: File;
    previewUrl: any = null;
    fileUploadProgress!: string;
    imgProfileOperator: any;

    dataProvince: any = 0;
    dataDistrict: any = 0;
    dataSubdistricts: any = 0;

    provinces: Province[] = [];
    districts: District[] = [];
    subdistricts: Subdistricts[] = [];

    selectedProvince!: number;
    selectedDistrict!: number;
    selectedSubdistrict!: number;

    nameProvince!: string;
    nameDistricts!: string;
    nameSubdistricts!: string;

    editOperatorSubDistrictForm!: FormGroup;
    dataConvert!: any;
    dataConvertEditOperator: any[] = [];

    idOperator!: string;
    idAdminSubDistrict!: string;
    idAdminRole!: string;
    idAdminParrent!: string;
    idAdminNamerole!: string;
    idAdminProvinceID!: string;
    idAdminDistrictID!: string;
    idAdminSubDistrictID!: string;

    idProvince!: number;
    idDistrict!: number;
    idSubdistrict!: number;

    passOld: any;
    noImg: boolean = true;

    englishAbbreviation!: string;
    roleAdmin: any;
    dataProvinceNameTHEN: any = (dataProvinceEN as any).default;

    hidePassword = true;
    hidePasswordConfirm = true;
    duplicateUsernameCheck: boolean = false;
    duplicateEmailCheck: boolean = false;
    passwordCheck: boolean = false;
    duplicateUsername: string = "";

    constructor(
        public dialogRef: MatDialogRef<DialogEditOperator>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditData,
        @Inject(LOCALE_ID) private locale: string,
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        private router: Router,
        private ngZone: NgZone,
        private ServiceService: ServiceService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.roleAdmin = localStorage.getItem("currentRole");
    }
    ngOnInit(): void {
        var dataAdmin: any;
        // console.log("this.data.idOperator ********* ",this.data.idOperator);

        this.ServiceService.GetAdminProvince_(this.data.idOperator).then(resDataAdmin => {
            dataAdmin = resDataAdmin;
            var parseJson = JSON.parse(dataAdmin);
            // console.log("9999 ", parseJson);
            // console.log("this.roleAdmin  >> ", this.roleAdmin);


            if (this.roleAdmin != "AdminProvince" && this.roleAdmin != "AdminDistrict" && this.roleAdmin != "AdminSubdistricts") {
                var dataRole: any;
                this.ServiceService.GetAdminRole_().then(resRole => {
                    dataRole = resRole;
                    // console.log(dataRole);

                    for (let i = 0; i < dataRole.length; i++) {
                        var splitted = parseJson[0].admin_Username.split("-");

                        if (splitted[0] == dataRole[i].typeKeyword) {
                            this.englishAbbreviation = dataRole[i].typeKeyword;
                        }

                        // if (parseJson[0].admin_Username.substring(0, 3) == dataRole[i].typeKeyword) {
                        //     this.englishAbbreviation = dataRole[i].typeKeyword;
                        // } else if (parseJson[0].admin_Username.substring(0, 2) == dataRole[i].typeKeyword) {
                        //     this.englishAbbreviation = dataRole[i].typeKeyword;
                        // }
                    }
                });
            } else {
                for (let i = 0; i < this.dataProvinceNameTHEN.length; i++) {
                    if (parseJson[0].admin_Username.substring(0, 3) == this.dataProvinceNameTHEN[i].englishAbbreviation) {
                        this.englishAbbreviation = this.dataProvinceNameTHEN[i].englishAbbreviation;
                    } else if (parseJson[0].admin_Username.substring(0, 2) == "HW") {
                        this.englishAbbreviation = this.dataProvinceNameTHEN[0].englishAbbreviation;
                    }
                }
            }
            // if (this.roleAdmin == "AdminHuawei") {
            //     this.englishAbbreviation = this.dataProvinceNameTHEN[0].englishAbbreviation;
            // } else {
            // for (let i = 0; i < this.dataProvinceNameTHEN.length; i++) {
            //     if (parseJson[0].admin_Username.substring(0, 3) == this.dataProvinceNameTHEN[i].englishAbbreviation) {
            //         this.englishAbbreviation = this.dataProvinceNameTHEN[i].englishAbbreviation;
            //     }else if(parseJson[0].admin_Username.substring(0, 2) == "HW"){
            //         this.englishAbbreviation = this.dataProvinceNameTHEN[0].englishAbbreviation;
            //     }
            // }
            // }
        });
        this.editOperatorSubDistrictForm = this.formBuilder.group({
            operatorEmail: '',
            operatorUsername: '',
            operatorPassword: '',
            operatorConfirmPassword: '',
            operatorFirstname: '',
            operatorLastname: '',
            operatorGender: '',
            operatorAge: '',
            operatorDateofbirth: '',
            operatorPhone: '',
            operatorAddress: '',
            operatorProvince: '',
            operatorDistrict: '',
            operatorSubdistrict: '',
            operatorPostalcode: ''
        })
        var provinceID: any;
        var districtID: any;
        var subDistrictID: any;
        var resAdminGetOperator: any;
        this.ServiceService.GetAdminGetOperator_(this.data.idOperator).then(dataAdminGetOperator => {
            resAdminGetOperator = dataAdminGetOperator;
            // console.log("res[0].Operator_Gender ", res);
            this.imgProfileOperator = resAdminGetOperator[0].operatorPic;
            this.passOld = resAdminGetOperator[0].operatorPassword;
            this.idOperator = resAdminGetOperator[0].operatorId;
            this.ServiceService.GetProvinceByName_(resAdminGetOperator[0].operatorProvinceId).then(dataProvinceID => {
                provinceID = dataProvinceID;
                this.idProvince = provinceID[0].provinceId;
                this.nameProvince = provinceID[0].provinceName;
                this.ServiceService.GetDistrictByName_(resAdminGetOperator[0].operatorDistrictId).then(datadistrictID => {
                    districtID = datadistrictID;
                    // console.log("districtID : ",districtID);
                    // console.log("districtID : ",districtID[0].amphurId);
                    // console.log("districtID : ",districtID[0].amphurName);

                    this.idDistrict = districtID[0].amphurId;
                    this.nameDistricts = districtID[0].amphurName;
                    this.ServiceService.GetSubdistrictByName_(resAdminGetOperator[0].operatorSubdistrictId).then(dataSubDistrictID => {
                        subDistrictID = dataSubDistrictID;
                        // console.log("subDistrictID : ",subDistrictID);

                        this.idSubdistrict = subDistrictID[0].districtId;
                        this.nameSubdistricts = subDistrictID[0].districtName;
                        // console.log(" res[0].idSubdistrict ", this.idSubdistrict);
                        var username: string = "";
                        // console.log("resAdminGetOperator[0].operatorUsername ", resAdminGetOperator[0].operatorUsername);

                        // if (this.roleAdmin == "AdminHuawei") {
                        //     username = resAdminGetOperator[0].operatorUsername.substring(3);
                        // } else {
                        var dataRole: any;
                        this.ServiceService.GetAdminRole_().then(resRole => {
                            dataRole = resRole;

                            for (let i = 0; i < dataRole.length; i++) {
                                // console.log(dataRole[i].typeKeyword);
                                var splitted_abbreviation = resAdminGetOperator[0].operatorUsername.split("-");

                                if (splitted_abbreviation[0] == dataRole[i].typeKeyword) {
                                    username = splitted_abbreviation[1];
                                }

                                // if (resAdminGetOperator[0].operatorUsername.substring(0, 2) == dataRole[i].typeKeyword) {
                                //     username = resAdminGetOperator[0].operatorUsername.substring(3);
                                // } else {
                                //     username = resAdminGetOperator[0].operatorUsername.substring(4);
                                // }

                                if (i == dataRole.length - 1) {

                                    // console.log(">> ", resAdminGetOperator[0].operatorUsername);

                                    // console.log(username);

                                    this.loadOperatorSubDistrict(this.data.idSubDistrict, this.data.parrent)
                                    // }

                                    this.editOperatorSubDistrictForm.setValue({
                                        operatorEmail: resAdminGetOperator[0].operatorEmail,
                                        operatorUsername: username,
                                        operatorPassword: "",
                                        operatorConfirmPassword: "",
                                        operatorFirstname: resAdminGetOperator[0].operatorFirstname,
                                        operatorLastname: resAdminGetOperator[0].operatorLastname,

                                        operatorGender: resAdminGetOperator[0].operatorGender,

                                        operatorAge: resAdminGetOperator[0].operatorAge,
                                        // operatorDateofbirth: new Date(2017, 0, 1),
                                        operatorDateofbirth: new Date(formatDate(resAdminGetOperator[0].operatorDateofbirth, 'yyyy,d,M', this.locale)),
                                        operatorPhone: resAdminGetOperator[0].operatorPhone,
                                        operatorAddress: resAdminGetOperator[0].operatorAddress,

                                        operatorProvince: this.idProvince,
                                        operatorDistrict: this.idDistrict,
                                        operatorSubdistrict: this.idSubdistrict,

                                        operatorPostalcode: resAdminGetOperator[0].operatorPostalcode
                                    })
                                }
                            }
                        })
                    })
                })
            })
        });

        this.loadSelectDialogProvince();
    }

    genders: Gender[] = [
        { value: 'M', viewValue: 'Male' },
        { value: 'FM', viewValue: 'FeMale' }
    ];

    onUpdate(): void {
        const formData = new FormData();
        formData.append('FormFiles', this.fileData);
        var img: any;
        this.ServiceService.UploadIMGProfile_(formData).then((resImg) => {
            // console.log(">>>>>>>>> ", resImg);

            img = resImg;
            var checkData = false;
            if (this.editOperatorSubDistrictForm.value.operatorPassword == "" && this.editOperatorSubDistrictForm.value.operatorConfirmPassword == "") {
                checkData = true;
                this.dataConvert = {
                    OperatorId: this.idOperator,
                    OperatorAdminId: this.idAdminSubDistrict,
                    OperatorEmail: this.editOperatorSubDistrictForm.value.operatorEmail,
                    OperatorUsername: `${this.englishAbbreviation}-${this.editOperatorSubDistrictForm.value.operatorUsername}`,
                    OperatorPassword: "",
                    OperatorFirstname: this.editOperatorSubDistrictForm.value.operatorFirstname,
                    OperatorLastname: this.editOperatorSubDistrictForm.value.operatorLastname,
                    operatorPic: img,
                    OperatorGender: this.editOperatorSubDistrictForm.value.operatorGender,
                    OperatorAge: this.editOperatorSubDistrictForm.value.operatorAge,
                    OperatorDateofbirth: formatDate(this.editOperatorSubDistrictForm.value.operatorDateofbirth, 'MM/dd/yyyy', this.locale),
                    OperatorAddress: this.editOperatorSubDistrictForm.value.operatorAddress,
                    OperatorProvinceId: this.idProvince,
                    OperatorProvince: this.nameProvince,
                    OperatorDistrictId: this.idDistrict,
                    OperatorDistrict: this.nameDistricts,
                    OperatorSubdistrictId: this.idSubdistrict,
                    OperatorSubdistrict: this.nameSubdistricts,
                    OperatorPostalcode: this.editOperatorSubDistrictForm.value.operatorPostalcode,
                    OperatorPhone: this.editOperatorSubDistrictForm.value.operatorPhone,

                    OperatorRole: this.idAdminRole,
                    OperatorParrent: this.idAdminParrent,
                    OperatorNamerole: this.idAdminNamerole,
                    OperatorProvinceId_Admin: this.idAdminProvinceID,
                    OperatorDistrictId_Admin: this.idAdminDistrictID,
                    OperatorSubDistrictId_Admin: this.idAdminSubDistrictID

                };
            } else if (this.editOperatorSubDistrictForm.value.operatorPassword == this.editOperatorSubDistrictForm.value.operatorConfirmPassword) {
                checkData = true;
                this.dataConvert = {
                    OperatorId: this.idOperator,
                    OperatorAdminId: this.idAdminSubDistrict,
                    OperatorEmail: this.editOperatorSubDistrictForm.value.operatorEmail,
                    OperatorUsername: `${this.englishAbbreviation}-${this.editOperatorSubDistrictForm.value.operatorUsername}`,
                    OperatorPassword: this.editOperatorSubDistrictForm.value.operatorPassword,
                    OperatorFirstname: this.editOperatorSubDistrictForm.value.operatorFirstname,
                    OperatorLastname: this.editOperatorSubDistrictForm.value.operatorLastname,
                    OperatorPic: img,
                    OperatorGender: this.editOperatorSubDistrictForm.value.operatorGender,
                    OperatorAge: this.editOperatorSubDistrictForm.value.operatorAge,
                    OperatorDateofbirth: formatDate(this.editOperatorSubDistrictForm.value.operatorDateofbirth, 'MM/dd/yyyy', this.locale),
                    OperatorAddress: this.editOperatorSubDistrictForm.value.operatorAddress,
                    OperatorProvinceId: this.idProvince,
                    OperatorProvince: this.nameProvince,
                    OperatorDistrictId: this.idDistrict,
                    OperatorDistrict: this.nameDistricts,
                    OperatorSubdistrictId: this.idSubdistrict,
                    OperatorSubdistrict: this.nameSubdistricts,
                    OperatorPostalcode: this.editOperatorSubDistrictForm.value.operatorPostalcode,
                    OperatorPhone: this.editOperatorSubDistrictForm.value.operatorPhone,

                    OperatorRole: this.idAdminRole,
                    OperatorParrent: this.idAdminParrent,
                    OperatorNamerole: this.idAdminNamerole,
                    OperatorProvinceId_Admin: this.idAdminProvinceID,
                    OperatorDistrictId_Admin: this.idAdminDistrictID,
                    OperatorSubDistrictId_Admin: this.idAdminSubDistrictID

                };
            } else {
                // alert("Incorrect password")
                this.passwordCheck = true;
                checkData = false;
            }
            if (checkData) {
                // console.log(this.dataConvert);

                var idOperator: any;
                this.ServiceService.EditOperator_(this.dataConvert).then((dataidOperator) => {
                    idOperator = dataidOperator;
                    if (idOperator == "Duplicate Email") {
                        this.duplicateEmailCheck = true;
                        this.duplicateUsernameCheck = false;
                        this.duplicateUsername = idOperator;
                    } else if (idOperator == "Duplicate Username") {
                        this.duplicateUsernameCheck = true;
                        this.duplicateEmailCheck = false;
                        this.duplicateUsername = idOperator;
                    } else {
                        this.dataConvertEditOperator = [
                            {
                                Operator_ID: idOperator,
                                Operator_AdminID: this.idAdminSubDistrict,
                                Operator_Email: this.editOperatorSubDistrictForm.value.operatorEmail,
                                Operator_Username: this.editOperatorSubDistrictForm.value.operatorUsername,
                                Operator_Password: this.editOperatorSubDistrictForm.value.operatorPassword,
                                Operator_Firstname: this.editOperatorSubDistrictForm.value.operatorFirstname,
                                Operator_Lastname: this.editOperatorSubDistrictForm.value.operatorLastname,
                                Operator_Pic: img,
                                Operator_Gender: this.editOperatorSubDistrictForm.value.operatorGender,
                                Operator_Age: this.editOperatorSubDistrictForm.value.operatorAge,
                                Operator_Dateofbirth: formatDate(this.editOperatorSubDistrictForm.value.operatorDateofbirth, 'MM/dd/yyyy', this.locale),
                                Operator_Address: this.editOperatorSubDistrictForm.value.operatorAddress,
                                Operator_Province: this.nameProvince,
                                Operator_District: this.nameDistricts,
                                Operator_Subdistrict: this.nameSubdistricts,
                                Operator_Postalcode: this.editOperatorSubDistrictForm.value.operatorPostalcode,
                                Operator_Phone: this.editOperatorSubDistrictForm.value.operatorPhone,

                                Operator_Role: this.idAdminRole,
                                Operator_Parrent: this.idAdminParrent,
                                Operator_Namerole: this.idAdminNamerole,
                                Operator_ProvinceID: this.idAdminProvinceID,
                                Operator_DistrictID: this.idAdminDistrictID,
                                Operator_SubDistrictID: this.idAdminSubDistrictID
                            }
                        ]

                        this.dialogRef.close(this.dataConvertEditOperator);
                    }
                }, (err) => {
                    console.log(" : : ", err);
                });
            }

        }, (err) => {
            console.log(" : : ", err);
        });
    }

    loadOperatorSubDistrict(id: string, parrent: string) {
        var resAdminOperatorSubDistricts: any;
        // console.log("id : ",id);
        // console.log("parrent : ",parrent);

        this.ServiceService.GetAdminOperatorSubDistricts_(parrent, id, "0", false).then(dataAdminOperatorSubDistricts => {
            resAdminOperatorSubDistricts = dataAdminOperatorSubDistricts;
            // console.log("res >> ", resAdminOperatorSubDistricts[0]);
            if (resAdminOperatorSubDistricts[0] != undefined) {
                this.idAdminSubDistrict = resAdminOperatorSubDistricts[0].admin_Id;
                this.idAdminRole = "Operator";
                this.idAdminParrent = resAdminOperatorSubDistricts[0].admin_Parrent;
                this.idAdminNamerole = resAdminOperatorSubDistricts[0].admin_Namerole;
                this.idAdminProvinceID = resAdminOperatorSubDistricts[0].admin_ProvinceId;
                this.idAdminDistrictID = resAdminOperatorSubDistricts[0].admin_DistrictId;
                this.idAdminSubDistrictID = resAdminOperatorSubDistricts[0].admin_SubDistrictId;
                // console.log("res00 ---------->> ", res[0].Admin_ProvinceID);
            }
        });
    }

    loadSelectDialogProvince() {
        var resProvinceSelect: any;
        this.ServiceService.GetProvinceSelect_().then(dataProvinceSelect => {
            resProvinceSelect = dataProvinceSelect;
            this.dataProvince = resProvinceSelect;
            for (let i = 0; i < this.dataProvince.length; i++) {
                this.provinces.push({ value: this.dataProvince[i].provinceId, viewValue: this.dataProvince[i].provinceName })
            }
        });
    }

    loadSelectDistrict(selectedProvince: number) {
        if (selectedProvince != undefined) {
            this.districts = [];
            this.subdistricts = [];
            var resDistrict: any;
            this.ServiceService.GetDistrict_(selectedProvince).then(dataDistrict => {
                resDistrict = dataDistrict;
                this.dataDistrict = resDistrict;
                for (let i = 0; i < this.dataDistrict.length; i++) {

                    this.districts.push({ value: this.dataDistrict[i].amphurId, viewValue: this.dataDistrict[i].amphurName })
                }
            });
        }
    }

    loadSelectSubdistrict(selectedDistrict: number) {
        if (selectedDistrict != undefined) {
            this.subdistricts = [];
            var resSubdistrict: any;
            this.ServiceService.GetSubdistrict_(selectedDistrict).then(dataSubdistrict => {
                resSubdistrict = dataSubdistrict;
                this.dataSubdistricts = resSubdistrict;
                for (let i = 0; i < this.dataSubdistricts.length; i++) {
                    this.subdistricts.push({ value: this.dataSubdistricts[i].districtId, viewValue: this.dataSubdistricts[i].districtName })
                }
            });
        }
    }

    onProvinceSelection() {
        this.loadSelectDistrict(this.selectedProvince);

        for (let i = 0; i < this.dataProvince.length; i++) {
            if (this.selectedProvince == this.dataProvince[i].provinceId) {
                this.nameProvince = this.dataProvince[i].provinceName;
                this.idProvince = this.dataProvince[i].provinceId;
                // console.log(this.nameProvince);
            }
        }
    }

    onDistrictSelection() {
        this.loadSelectSubdistrict(this.selectedDistrict);

        for (let i = 0; i < this.dataDistrict.length; i++) {

            if (this.selectedDistrict == this.dataDistrict[i].amphurId) {

                this.nameDistricts = this.dataDistrict[i].amphurName;
                this.idDistrict = this.dataDistrict[i].amphurId;
                // console.log(this.nameDistricts);
            }
        }
    }

    onSubdistrictSelection() {
        for (let i = 0; i < this.dataSubdistricts.length; i++) {
            if (this.selectedSubdistrict == this.dataSubdistricts[i].districtId) {
                // console.log(this.dataSubdistricts[i]);
                this.nameSubdistricts = this.dataSubdistricts[i].districtName;
                this.idSubdistrict = this.dataSubdistricts[i].districtId;
                // console.log(this.nameSubdistricts);
            }
        }
    }


    fileProgress(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
        this.preview();
    }

    preview() {
        this.noImg = false;
        // Show preview
        var mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
            this.previewUrl = reader.result;
        }
    }

}
