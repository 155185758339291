<h1 mat-dialog-title>Edit {{data.nameEdit}}</h1>
<div mat-dialog-content>
    <form [formGroup]="editVitalSignForm">
        <mat-form-field appearance="outline">
            <mat-label *ngIf="data.nameEdit != 'BP'">Edit {{data.nameEdit}}</mat-label>
            <mat-label *ngIf="data.nameEdit == 'BP'">Edit Pressure high</mat-label>
            <input type="number" matInput formControlName="EditData1">
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="data.nameEdit == 'BP'">
            <mat-label>Edit Pressure low</mat-label>
            <input type="number" matInput formControlName="EditData2">
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions style="display: flex;justify-content: flex-end;">
    <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Cancel</button>
    <button mat-button (click)="onClickUpdateDataVitalSign()">Update</button>
</div>