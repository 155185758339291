import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  isAuthenticated = false;
  constructor(private _router: Router) { }  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  
      
        if (localStorage.getItem('currentUser')) {  
          this.isAuthenticated = true;
            return true;  
        }  
        this._router.navigate(['login']);  
        return false;  
    }  

}

