<div class="container-fluid m-0 pt-1 pb-1 pl-3 pr-3">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>รหัสผ่านผู้ใช้ของคุณ</mat-label>
    <input matInput [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password" required (keydown.enter)="passwordAuth()">
    <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? "visibility_off" : "visibility"}}</mat-icon>
    <mat-error *ngIf="wrongPassword">รหัสผ่านไม่ถูกต้อง</mat-error>
  </mat-form-field>
  <div class="d-flex justify-content-end">
    <button mat-raised-button color="primary" (click)="passwordAuth()" [disabled]="!password" class="mr-2">ยืนยันการเข้าถึง</button>
    <button mat-raised-button (click)="dialogRef.close()">ยกเลิก</button>
  </div>
</div>
