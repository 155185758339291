<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <button mat-icon-button data-widget="pushmenu" >
        <mat-icon style="color: #b8b8b8;">menu</mat-icon>
      </button>
      <!-- <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a> -->
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
      <a href="index3.html" class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a href="#" class="nav-link">Contact</a>
    </li> -->
  </ul>
  <div *ngIf="!selectAdminRoleDropdown">
    <mat-form-field class="ml-2" style="height: 55px;" appearance="outline" *ngIf="adminRole == 'SuperAdmin'">
      <mat-label>Select Admin</mat-label>
      <mat-select [(ngModel)]="selectedValue" (selectionChange)="onChangeAdminRole(selectedValue)">
        <mat-option *ngFor="let roleAdmin of roleAdmins" [value]="roleAdmin.value">
          {{roleAdmin.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- SEARCH FORM -->
  <!-- <form class="form-inline ml-3">
    <div class="input-group input-group-sm">
      <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form> -->

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Notifications Dropdown Menu -->
    <li class="nav-item dropdown" *ngIf="operator">
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" data-widget="fullscreen">
        <i class="fas fa-expand-arrows-alt"></i>
      </button>
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" data-toggle="dropdown">
        <mat-icon>notification_important</mat-icon>
        <span class="badge badge-danger navbar-badge swing" *ngIf="countSosAll > 0">{{countSosAll}}</span>
      </button>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header" style="background-color: #F1F1F1;">{{countSosAll}}
          Notifications</span>
        <div class="dropdown-divider"></div>
        <a routerLink="/alerts/{{idOperatorAdmin}}" class="dropdown-item">
          <i class="fas fa-exclamation-triangle" style="padding-right: 10px;font-size: 20px;color: #cf311c;"></i> New
          <b>SOS</b> {{countSosStart}} case
          <!-- <span class="float-right text-muted text-sm">3 mins</span> -->
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/alerts/{{idOperatorAdmin}}" class="dropdown-item">
          <i class="fas fa-exclamation-circle" style="padding-right: 10px;font-size: 20px;color: #ffd740;"></i> Job
          <b>Working</b> {{countSosWorking}} case
          <!-- <span class="float-right text-muted text-sm">12 hours</span> -->
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/alerts/{{idOperatorAdmin}}" class="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>

    <li class="nav-item dropdown" *ngIf="admin">
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" data-widget="fullscreen">
        <i class="fas fa-expand-arrows-alt"></i>
      </button>
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" data-toggle="dropdown">
        <mat-icon>notification_important</mat-icon>
        <span class="badge badge-danger navbar-badge swing" *ngIf="countSosAll > 0">{{countSosAll}}</span>
      </button>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header" style="background-color: #F1F1F1;">{{countSosAll}}
          Notifications</span>
        <div class="dropdown-divider"></div>
        <a routerLink="/liveMonitor" class="dropdown-item">
          <i class="fas fa-exclamation-triangle" style="padding-right: 10px;font-size: 20px;color: #cf311c;"></i> New
          <b>SOS</b> {{countSosStart}} case
          <!-- <span class="float-right text-muted text-sm">3 mins</span> -->
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/liveMonitor" class="dropdown-item">
          <i class="fas fa-exclamation-circle" style="padding-right: 10px;font-size: 20px;color: #ffd740;"></i> Job
          <b>Working</b> {{countSosWorking}} case
          <!-- <span class="float-right text-muted text-sm">12 hours</span> -->
        </a>
        <div class="dropdown-divider"></div>
        <a routerLink="/liveMonitor" class="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>
    <li class="nav-item">
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </li>
  </ul>
</nav>