<div [class]="!value ? 'mt-3' : 'container-fluid p-3'">

  <div class="row">
    <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
      <h1 style="color: #222;" style="margin: 0px;">BodyTemp Chart</h1>
    </div>
    <div class="col-md-4 text-right">

      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Enter a date range</mat-label>

        <mat-date-range-input [formGroup]="rangeDateStartAndEnd" [rangePicker]="picker_start_end">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onChengDate()">
        </mat-date-range-input>

        <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
        <mat-date-range-picker #picker_start_end></mat-date-range-picker>
      </mat-form-field>
    </div>

  </div>

  <div *ngIf="dataChartBodyTemp.length != 0">

  <div class="card mt-2">
    <div class="card-body">
      <div #chartBodyTemp style="width: 100%; height: 600px"></div>
    </div>
  </div>

  <div class="row">

    <div class="col-md-4 mt-3">
      <div class="card">
        <div class="card-body">
          <h6 class="nospace">Average (เฉลี่ย)</h6>
          <h3 class="nospace txtindigo">{{averBodyTempValue | number : '1.2-2'}} องศา</h3>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="card">
        <div class="card-body">
          <h6 class="nospace">Max (มากสุด)</h6>
          <h3 class="nospace txtindigo">{{maxBodyTempValue}} องศา</h3>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="card">
        <div class="card-body">
          <h6 class="nospace">Min (น้อยสุด)</h6>
          <h3 class="nospace txtindigo">{{minBodyTempValue}} องศา</h3>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="card">
        <div class="card-body">
          <h4 class="nospace"><span style="color: green;">BodyTemp ปกติ (36-37.5)</span></h4>
          <h3 class="nospace txtindigo">{{ countNor }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก {{
              dataChartBodyTemp.length }} ครั้ง คิดเป็น {{ (countNor / dataChartBodyTemp.length) * 100 | number :
              '1.2-2' }}%</span></h3>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="card">
        <div class="card-body">
          <h4 class="nospace"><span style="color: red;">BodyTemp มากกว่า 37.5 องศา</span></h4>
          <h3 class="nospace txtindigo">{{ countMax }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก {{
              dataChartBodyTemp.length }} ครั้ง คิดเป็น {{ (countMax / dataChartBodyTemp.length) * 100 | number :
              '1.2-2' }}%</span></h3>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <div class="card">
        <div class="card-body">
          <h4 class="nospace"><span style="color: red;">BodyTemp น้อยกว่า 36 องศา</span></h4>
          <h3 class="nospace txtindigo">{{ countMin }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก {{
              dataChartBodyTemp.length }} ครั้ง คิดเป็น {{ (countMin / dataChartBodyTemp.length) * 100 | number :
              '1.2-2' }}%</span></h3>
        </div>
      </div>
    </div>


    <div class="col-md-12 mt-3">
      <div class="card">
        <div class="card-body">

          <div class="row">
            <div class="col-md-8">
              <h6 class="nospace">BodyTemp 24Hr Average Chart</h6>
              <span *ngFor="let datelist of datetimeShowList" class="badge bg-primary" style="margin-right: 2px;">{{
                datelist.datetxtShow }}</span>
            </div>
            <div class="col-md-4 text-right">
              <mat-form-field style="width: 100%;" appearance="outline">
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" [value]="setNow"
                  (dateChange)="onChangeDateTimeEvent($event, false)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <!-- <div #chartBodyTempAver24 style="width: 100%; height: 600px"></div> -->
          <div #chartBodyTempAver24Line class="mt-2" style="width: 100%; height: 500px"></div>

        </div>
      </div>

      <!-- <div class="mt-3">
        <table class="table table-striped mt-1">
          <thead>
            <tr style="background-color: rgb(208, 242, 253);">
              <th scope="col" width="80px">#</th>
              <th scope="col">Ticker Time</th>
              <th scope="col">type</th>
              <th scope="col">ค่าที่ตรวจวัดได้</th>
              <th scope="col">Description</th>
              <th scope="col">ส่วนต่าง (+ - ไม่เกิน {{ g_diffvalue }}) <button
                  style="margin-top: 0px; margin-bottom: 0px;" type="button"  (click)="openDialogGlovbalval(pageGetDatatype)"  class="btn btn-primary btn-sm">Edit Value</button> </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let datelist of tickerDataToTableList index as i">
              <th scope="row">{{i+1}}</th>
              <td>{{ datelist.atTime }}</td>
              <td>{{ datelist.atTickerGobalOrAvg }}</td>
              <td style="color: red; font-weight: bold;">{{ datelist.atValue }}</td>
              <td colspan="1" style="color: rgb(12, 110, 175); font-weight: bold;">{{ datelist.atLogTxtDesc }}</td>
              <td>{{ g_diffvalue }}</td>
            </tr>

          </tbody>
        </table>
      </div> -->
    </div>


    <div class="col-md-6 mt-3">
      <div class="card">
        <div class="card-body">
          <h6 class="nospace">BodyTemp 7 Days Average Chart</h6>
          <div #chartBodyTempAver7day style="width: 100%; height: 400px"></div>
        </div>
      </div>
    </div>


    <div class="col-md-6 mt-3">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="nospace">BodyTemp Average Percent Chart <span *ngIf="countvalErr > 0">{{ countvalErr }}</span></h6>
          <div #chartBodyTempValPercen style="width: 100%; height: 400px"></div>
        </div>
      </div>
    </div>


  </div>
</div>

  <div *ngIf="dataBodyTempAll.length == 0 && !loading" style="display: flex;justify-content: center;"> <h2>*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>

  <br>
  <br>

</div>
