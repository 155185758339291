import { Injectable, Component, OnInit, NgZone } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  Event as NavigationEvent,
} from '@angular/router';
import { io } from 'socket.io-client';
import { ToastrService } from 'ngx-toastr';
import { ServiceService } from './service.service';
//import { Socket } from 'ngx-socket-io';
import { MapsAPILoader } from '@agm/core';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private socket: any;
  private socketJob: any;
  public data: any;
  dataConvert!: any;
  operatorAdminIdLocal!: any;
  roleAdmin!: any;
  private geoCoder!: google.maps.Geocoder;
  resSensor: any;
  resDataOperator: any;
  resDetailDevice: any;
  resUserPhone: any;
  dataSos: any;
  tempSOS: any = '';
  dataOperator: any;
  roles: any[] = [];
  event$;

  constructor(
    // private socket_: Socket,
    public global: GlobalConstants,
    private mapsAPILoader: MapsAPILoader,
    private toastr: ToastrService,
    private ServiceService: ServiceService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        if (
          event.url.startsWith('/caseOP') ||
          event.url.startsWith('/caseProfileNew')
        ) {
          // this.showHead= false;
        } else {
          // Connect Socket with server URL
          this.socket = io(GlobalConstants.socket, {
            autoConnect: true,
            reconnection: true, // Enable reconnection
            reconnectionAttempts: Infinity, // Limit the number of reconnection attempts
            reconnectionDelay: 1000,
            transports: ['websocket', 'polling', 'flashsocket'],
          });
        }
      }
    });

    // this.socket = io('http://localhost:2022/',{  transports: ['websocket', 'polling', 'flashsocket'] });
    // this.socket = io('http://183.88.230.105:2022/', { transports: ['websocket', 'polling', 'flashsocket'] });

    this.operatorAdminIdLocal = localStorage.getItem('currentIdAdmin');
    this.roleAdmin = localStorage.getItem('currentRole');

    this.getdataOperator(this.operatorAdminIdLocal);
  }
  async ngOnInit() {
    await this.getAdminRole();
    if (this.roleAdmin == 'Operator') {
      this.socket.emit('Operator-Online', this.operatorAdminIdLocal);
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.socket.on('connect', (data: any) => {
      console.log('socket connected');
    });

    this.socket.on('datamessage', async (data: any) => {
      console.log('EWbsocket-------->> ', data);
      // console.log("SOS : ", data);//OJob_ID
      if (
        data.OperatorMonitorType ==
        this.roles.find(
          (item: any) => item.typeAdmin == localStorage.getItem('showAdmin')
        ).typeKeyword
      ) {
        var objectIDSOS = data.ObjectID.split(',');
        // console.log("-------------------- : ",objectIDSOS.length);
        // console.log("-------------------- : ",objectIDSOS);
        // console.log("----------------[0]- : ",objectIDSOS[0]);

        await this.ServiceService.GetLogSos_(objectIDSOS[0]).then(
          async (dataSensor) => {
            this.resSensor = dataSensor;
            // console.log("resSensor *** ", dataSensor);
            this.dataSos = {
              OjobId: data.OJob_ID,
              OperatorId: data.Operator_ID,
              ObjectId: objectIDSOS[0],
              OjobDate: data.OJob_Date,
              OjobStatus: data.OJob_Status,
              OjobAddress: '',
              OjobImei: this.resSensor[0].watch_imeicode,
            };
            var dataGlobal = this.global.dataSos;
            // console.log("global watch_imeicode : ",this.resSensor[0].watch_imeicode);
            // console.log("global : ",this.global.dataSos);
            // console.log("dataGlobal --> ",dataGlobal);

            // console.log(data.OJob_ID ," : sssssssss : ",this.global.dataSos.findIndex((x: { idJob: number; }) => Number(x.idJob) === Number(data.OJob_ID)));
            var checkSos = dataGlobal.findIndex(
              (x: { idJob: number }) => Number(x.idJob) === Number(data.OJob_ID)
            );
            // console.log("checkSos ",checkSos);
            var dataJobNewSos: any;
            var arrayCountSOS: any;
            await this.ServiceService.GetJobOperatorByJobId_(data.OJob_ID).then(
              (resDataJob) => {
                dataJobNewSos = resDataJob;
                // console.log("resDataJob neww Sos ",dataJobNewSos[0].objectId);
                arrayCountSOS = dataJobNewSos[0].objectId.split(',');
              }
            );
            // console.log('The array has ', arrayCountSOS.length, );

            if (arrayCountSOS.length == 1) {
              await this.ServiceService.GetIdOperatorAdminByIdOperator_(
                data.Operator_ID
              ).then(
                async (dataDataOperator) => {
                  this.resDataOperator = dataDataOperator;
                  if (this.roleAdmin == 'Operator') {
                    if (
                      this.resDataOperator[0].operatorAdminId ==
                      this.operatorAdminIdLocal
                    ) {
                      await this.ServiceService.GetDeviceBySerial_(
                        this.resSensor[0].watch_imeicode
                      ).then(
                        async (dataDetailDevice) => {
                          this.resDetailDevice = dataDetailDevice;
                          if (this.resDetailDevice[0] == undefined) {
                            await this.ServiceService.GetUserPhone_(
                              this.resSensor[0].watch_imeicode
                            ).then(
                              (dataUserPhone) => {
                                this.resUserPhone = dataUserPhone;
                                this.showSuccess(
                                  'phone',
                                  this.resUserPhone[0],
                                  this.resSensor[0],
                                  data,
                                  this.roleAdmin
                                );
                              },
                              (error) => {
                                console.log(
                                  error.status + ' ' + error.statusText
                                );
                              }
                            );
                          } else {
                            this.showSuccess(
                              'watch',
                              this.resDetailDevice[0],
                              this.resSensor[0],
                              data,
                              this.roleAdmin
                            );
                          }
                        },
                        (error) => {
                          console.log(error.status + ' ' + error.statusText);
                        }
                      );
                    }
                  } else {
                    await this.ServiceService.GetDeviceBySerial_(
                      this.resSensor[0].watch_imeicode
                    ).then(
                      async (dataDetailDevice) => {
                        this.resDetailDevice = dataDetailDevice;
                        // console.log("dataDetailDevice 5555 : ",dataDetailDevice);

                        if (this.resDetailDevice[0] == undefined) {
                          await this.ServiceService.GetUserPhone_(
                            this.resSensor[0].watch_imeicode
                          ).then(
                            (dataUserPhone) => {
                              this.resUserPhone = dataUserPhone;
                              this.showSuccess(
                                'phone',
                                this.resUserPhone[0],
                                this.resSensor[0],
                                data,
                                this.roleAdmin
                              );
                            },
                            (error) => {
                              console.log(
                                error.status + ' ' + error.statusText
                              );
                            }
                          );
                        } else {
                          this.showSuccess(
                            'watch',
                            this.resDetailDevice[0],
                            this.resSensor[0],
                            data,
                            this.roleAdmin
                          );
                        }
                      },
                      (error) => {
                        console.log(error.status + ' ' + error.statusText);
                      }
                    );
                  }
                },
                (error) => {
                  console.log(error.status + ' ' + error.statusText);
                }
              );
            }
          }
        );
      }
    });

    this.socket.on('alert_covidsym', async (dataAlertCovid19: any) => {
      // console.log("dataAlertCovid19 ",dataAlertCovid19);

      if (
        this.roleAdmin == 'Operator' &&
        this.dataOperator[0].operatorId == dataAlertCovid19.daOperatorId
      ) {
        var dataDoctorPatient: any;
        await this.ServiceService.GetDataDoctorPatient_(
          dataAlertCovid19.daUserId
        ).then((resDoctorPatient) => {
          dataDoctorPatient = resDoctorPatient;
          if (dataDoctorPatient.length != 0) {
            this.showAlertCovid19(dataDoctorPatient[0]);
          } else {
            //todo
          }
        });
      }
    });
  }

  async getAdminRole() {
    await this.ServiceService.GetAdminRole_().then(
      (res) => {
        if (res.length) this.roles = res;
      },
      (err) => {
        console.log('err -> ', err);
      }
    );
  }

  async showSuccess(
    typeDevice: string,
    message: any,
    sensor: any,
    dataSos: any,
    roleAdmin: string
  ) {
    var objectIDSOS_ = dataSos.ObjectID.split(',');
    // console.log("roleAdmin ",roleAdmin);

    if (roleAdmin == 'Operator') {
      this.socket.emit('dataMessageJob', 'sos');
      if (typeDevice == 'phone') {
        this.playAudio(message.userName);
        this.toastr
          .warning(
            `Name : ${message.userName}<br>Age : ${message.userAge}, Gender : ${
              message.userSex
            }<br><i class="fas fa-heartbeat"></i> : ${
              sensor.heartrate_val
            }, <i class="fas fa-temperature-low"></i> : ${
              sensor.temperature_val
            }<br> <i class="fas fa-clock"></i> : ${new Date(sensor.time_log)
              .toLocaleString()
              .slice(0, 19)
              .replace('T', ' ')}`,
            'SOS',
            {
              enableHtml: true,
              newestOnTop: true,
              timeOut: 60000,
              extendedTimeOut: 2000,
              progressBar: true,
            }
          )
          .onTap.subscribe(() =>
            this.startPracticeJob(
              dataSos.OJob_ID,
              objectIDSOS_[0],
              dataSos.Operator_ID,
              dataSos.OJob_Status
            )
          );
      } else {
        this.playAudio(message.deviceName);
        this.toastr
          .warning(
            `Name : ${message.deviceName}<br>Age : ${
              message.deviceAge
            }, Gender : ${
              message.deviceSex
            }<br><i class="fas fa-heartbeat"></i> : ${
              sensor.heartrate_val
            }, <i class="fas fa-temperature-low"></i> : ${
              sensor.temperature_val
            }<br> <i class="fas fa-clock"></i> : ${new Date(sensor.time_log)
              .toLocaleString()
              .slice(0, 19)
              .replace('T', ' ')}`,
            'SOS',
            {
              enableHtml: true,
              newestOnTop: true,
              timeOut: 60000,
              extendedTimeOut: 2000,
              progressBar: true,
            }
          )
          .onTap.subscribe(() =>
            this.startPracticeJob(
              dataSos.OJob_ID,
              objectIDSOS_[0],
              dataSos.Operator_ID,
              dataSos.OJob_Status
            )
          );
      }
    } else {
      this.socket.emit('dataMessageJob', 'sos');
      if (typeDevice == 'phone') {
        console.log('phone');
        await this.playAudio(message.userName);
        this.toastr.warning(
          `Name : ${message.userName}<br>Age : ${message.userAge}, Gender : ${
            message.userSex
          }<br><i class="fas fa-heartbeat"></i> : ${
            sensor.heartrate_val
          }, <i class="fas fa-temperature-low"></i> : ${
            sensor.temperature_val
          }<br> <i class="fas fa-clock"></i> : ${new Date(sensor.time_log)
            .toLocaleString()
            .slice(0, 19)
            .replace('T', ' ')}`,
          'SOS',
          {
            enableHtml: true,
            newestOnTop: true,
            timeOut: 10000,
            extendedTimeOut: 3000,
            progressBar: true,
          }
        );
      } else {
        console.log('Watch');
        await this.playAudio(message.deviceName);
        this.toastr.warning(
          `Name : ${message.deviceName}<br>Age : ${
            message.deviceAge
          }, Gender : ${
            message.deviceSex
          }<br><i class="fas fa-heartbeat"></i> : ${
            sensor.heartrate_val
          }, <i class="fas fa-temperature-low"></i> : ${
            sensor.temperature_val
          }<br> <i class="fas fa-clock"></i> : ${new Date(sensor.time_log)
            .toLocaleString()
            .slice(0, 19)
            .replace('T', ' ')}`,
          'SOS',
          {
            enableHtml: true,
            newestOnTop: true,
            timeOut: 10000,
            extendedTimeOut: 3000,
            progressBar: true,
          }
        );
      }
    }
  }

  async showAlertCovid19(dataDoctorPatient: any) {
    await this.playAudio(dataDoctorPatient.dpCovidStatus);
    // console.log("dataDoctorPatient ", dataDoctorPatient);
    if (dataDoctorPatient.dpCovidStatus == 'Red') {
      this.toastr.warning(
        `Name : ${dataDoctorPatient.userName}<br>Symptom Group : ${dataDoctorPatient.dpCovidStatus}<br>`,
        'Symptom Covid19',
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      );
    } else if (dataDoctorPatient.dpCovidStatus == 'Yellow') {
      this.toastr.info(
        `Name : ${dataDoctorPatient.userName}<br>Symptom Group : ${dataDoctorPatient.dpCovidStatus}<br>`,
        'Symptom Covid19',
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      );
    } else if (dataDoctorPatient.dpCovidStatus == 'Green') {
      this.toastr.success(
        `Name : ${dataDoctorPatient.userName}<br>Symptom Group : ${dataDoctorPatient.dpCovidStatus}<br>`,
        'Symptom Covid19',
        {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 10000,
          extendedTimeOut: 3000,
          progressBar: true,
        }
      );
    }
  }

  async startPracticeJob(
    oJobID: string,
    ObjectID: string,
    Operator_ID: string,
    status: string
  ) {
    // console.log("id> ", ObjectID);
    // console.log("id> ", oJobID);
    // if (status != "work") {
    this.dataConvert = {
      OjobId: oJobID,
      OperatorId: Operator_ID,
      ObjectId: '',
      OjobDate: '',
      OjobStatus: 'work',
      OjobHeartRate: '',
      OjobTemp: '',
      OjobBattery: '',
    };
    await this.ServiceService.updateStatusSosAlert_(this.dataConvert).then(
      (resIDOperatoAdmin) => {
        console.log('resIDOperatoAdmin ', resIDOperatoAdmin);
        this.socket.emit('dataMessageJobWork', oJobID);
        this.socket.emit('dataMessageJob', 'sos');
        this.ngZone.run(() =>
          this.router.navigateByUrl(
            `/caseProfile/${resIDOperatoAdmin}/${oJobID}`
          )
        );
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
    // } else {
    //   // this.ngZone.run(() => this.router.navigateByUrl(`/caseProfile/${this.idOperator}/${id}`));

    // }
  }
  playAudio(alertCondition: any) {
    let audio = new Audio();
  
    if (alertCondition=="Health Check") {
      audio.src = '../../../../assets/adminlte/dist/sound/alert02.wav'; 
    } else {
      audio.src = '../../../../assets/adminlte/dist/sound/alert01.wav';
    }
  
    audio.load();
    audio.play();
  }
  updateAddressSOS(dataSensor: any, dataSOS: any) {
    return new Promise<any>(async (resolve, rejects) => {
      // console.log("SOS : ", dataSOS);//OJob_ID
      // console.log("resSensor *** ", dataSensor);
      await this.getAddress(
        Number(dataSensor.lat),
        Number(dataSensor.lng)
      ).then(
        async (dataLocation) => {
          await this.ServiceService.UpdateAddressJob(
            Number(dataSOS.OJob_ID),
            dataLocation
          ).then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (error) => {
              console.log(error.status + ' ' + error.statusText);
            }
          );
        },
        (error) => {
          console.log(
            error.status + ' ----------LOCATION---------- ' + error.statusText
          );
        }
      );
    });
  }

  getAddress(latitude: number, longitude: number) {
    return new Promise<any>((resolve, rejects) => {
      this.geoCoder.geocode(
        { location: { lat: latitude, lng: longitude } },
        (results: any, status: any) => {
          // console.log("results ---> ",results);
          // console.log("results ---> ",results[3].formatted_address);
          if (status === 'OK') {
            // console.log("-------------results --------------> ", status);
            resolve(results[1].formatted_address);
          } else {
            // console.log("ell-------------results --------------> ", status);
            rejects(status);
          }
        }
      );
    });
  }

  getdataOperator(idOperatorAdmin: string) {
    this.ServiceService.GetIdOperatorByIdOperatorAdmin_(idOperatorAdmin).then(
      (resDataOpertor) => {
        // console.log("resDataOpertor : ",resDataOpertor);
        this.dataOperator = resDataOpertor;
      }
    );
  }
}
