<h2 mat-dialog-title>Detail sos</h2>
<div class="row">
    <div class="col-md-6">
        <agm-map style="height: 407px;" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"></agm-marker>
        </agm-map>

    </div>
    <div class="col-md-6" *ngIf="logSos != null">
        <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
            <mat-tab label="Detail">
                <div class="card card-widget widget-user-2" style="overflow:hidden; margin-bottom: 0px;">

                    <div class="row">
                        <div class="col-sm-4 border-right">
                            <div class="description-block">
                                <i class="fas fa-heartbeat" style="font-size: 50px;"></i>
                                <h5 class="description-header">
                                    -
                                </h5>
                            </div>
                        </div>
                        <div class="col-sm-4 border-right">
                            <div class="description-block">
                                <i class="fas fa-battery-half" style="font-size: 50px;"></i>
                                <h5 class="description-header">-</h5>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="description-block">
                                <i class="fas fa-thermometer-three-quarters" style="font-size: 50px;"></i>
                                <h5 class="description-header">
                                    -
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="invoice col-3" style="height: 90px;">
                                <div>
                                    <div class="row" style="padding-top: 10px;padding-left: 10px;">
                                        <div class="col-12">
                                            <h4 style="margin: 0px;">
                                                <i class="fas fa-clock"></i> Start Job
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row invoice-info" style="margin-top: 10px;">
                                        <div class="col-sm-12 invoice-col">
                                            <span class="right badge badge-danger"
                                                style="font-size: 14px;margin-left: 11px;"><i
                                                    class="fas fa-exclamation-triangle"></i>
                                                {{logSos.dataJob.ojobDate | date :'short'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice col-3" style="height: 90px;">
                                <div>
                                    <div class="row" style="padding-top: 10px;padding-left: 10px;">
                                        <div class="col-12">
                                            <h4 style="margin: 0px;">
                                                <i class="fas fa-clock"></i> Working Job
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row invoice-info" style="margin-top: 10px;">
                                        <div class="col-sm-12 invoice-col">
                                            <span class="right badge badge-warning"
                                                style="font-size: 14px;margin-left: 11px;"><i
                                                    class="fas fa-hourglass-half"></i>
                                                {{logSos.dataJob.ojobDateWork | date :'short'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice col-3" style="height: 90px;">
                                <div>
                                    <div class="row" style="padding-top: 10px;padding-left: 10px;">
                                        <div class="col-12">
                                            <h4 style="margin: 0px;">
                                                <i class="fas fa-clock"></i> Success Job
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row invoice-info" style="margin-top: 10px;">
                                        <div class="col-sm-12 invoice-col">
                                            <span class="right badge bg-success"
                                                style="font-size: 14px;margin-left: 11px;"><i class="fas fa-clock"></i>
                                                {{logSos.dataJob.ojobDateEnd | date :'short'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice col-3" style="height: 90px;">
                                <div>
                                    <div class="row" style="padding-top: 10px;padding-left: 10px;">
                                        <div class="col-12">
                                            <h4 style="margin: 0px;">
                                                <i class="fas fa-bell"></i> Number of times
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row invoice-info" style="margin-top: 10px;">
                                        <div class="col-sm-12 invoice-col">
                                            <span class="right badge badge-danger"
                                                style="font-size: 14px;margin-left: 11px;"><i
                                                    class="fas fa-exclamation-circle"></i>
                                                {{logSos.sosCount }} sos</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice" style="height: 175px;">
                        <div class="row" style="padding-top: 10px;padding-left: 10px;">
                            <div class="col-12">
                                <h4 style="margin: 0px;">
                                    <i class="fas fa-map-marked-alt"></i> Location Sos
                                </h4>
                            </div>
                        </div>
                        <div class="row invoice-info" style="margin-top: 5px; margin-top: 10px;"
                            style="padding-left: 15px;margin-right: 15px;">
                            <div class="col-sm-12 invoice-col">
                                <div>Address: <b>{{logSos.dataJob.ojobAddress}}</b></div>
                                <div>Latitude: <b>{{logSos.dataSOS[0].lat}}</b></div>
                                <div>Longitude: <b>{{logSos.dataSOS[0].lng}}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Operator">
                <div class="card card-widget widget-user-2" *ngIf="logSos.dataOperator.length > 0"
                    style="overflow:hidden; margin-bottom: 0px;">
                    <div class="col-12">
                        <div class="row">
                            <div class="widget-user-header col-11">
                                <div class="widget-user-image">
                                    <img class="img-circle elevation-2 mat-elevation-z8"
                                        src="{{imageUrl1}}/{{logSos.dataOperator[0].operatorPic}}"
                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                        style="object-fit: cover; height: 60px;width: 60px;" alt="User Avatar">
                                </div>
                                <h3 class="widget-user-username">
                                    {{logSos.dataOperator[0].operatorFirstname}}
                                    {{logSos.dataOperator[0].operatorLastname}}
                                </h3>
                                <span style="font-size: 14px;margin-left: 11px;">
                                    <i class="fas fa-envelope"></i>
                                    {{logSos.dataOperator[0].operatorEmail}}</span>
                            </div>
                            <div class="col-1" style="margin: auto;">
                                <button mat-mini-fab color="primary"
                                    (click)="onclickToProfileOperator(logSos.dataOperator[0].operatorAdminId)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="invoice" style="height: 120px;">
                        <div class="row" style="padding-top: 10px;padding-left: 10px;">
                            <div class="col-12">
                                <h4 style="margin: 0px;">
                                    <i class="fas fa-user-shield"></i> Operator
                                </h4>
                            </div>
                        </div>
                        <div class="row invoice-info" style="margin-top: 10px; padding-left: 20px;margin-right: 20px;">
                            <div class="col invoice-col">
                                <div>Age: <b>{{logSos.dataOperator[0].operatorAge}}</b></div>
                                <div>Dateofbirth: <b>{{logSos.dataOperator[0].operatorDateofbirth}}</b></div>
                                <div>Gender: <b>{{logSos.dataOperator[0].operatorGender}}</b></div>
                            </div>
                            <div class="col invoice-col">
                                <div>Phone: <b>{{logSos.dataOperator[0].operatorPhone}}</b></div>
                                <div>Address: <b>{{logSos.dataOperator[0].operatorAddress}}
                                        {{logSos.dataOperator[0].operatorSubdistrict}}
                                        {{logSos.dataOperator[0].operatorDistrict}}
                                        {{logSos.dataOperator[0].operatorProvince}}
                                        {{logSos.dataOperator[0].operatorPostalcode}}</b></div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice" style="height: 145px;">
                        <div class="row" style="padding-top: 10px;padding-left: 10px;">
                            <div class="col-12">
                                <h4 style="margin: 0px;">
                                    <i class="fas fa-comments"></i> Comment
                                </h4>
                            </div>
                        </div>
                        <div class="col-md-12" style="overflow:hidden; margin-bottom: 0px;">
                            <div class="card-comment" style="padding-left: 15px;padding-right: 15px;"
                                *ngFor="let item of logSos.dataJobTaskOperator;let i =index;">
                                <div class="comment-text">
                                    <span class="username">
                                        {{i+1}}. {{item.ojobTaskDetail}}
                                        <span class="text-muted float-right">{{item.ojobTaskDate | date
                                            :'short'}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="logSos.dataOperator.length <= 0">
                    <p style="
                    text-align: center;
                    margin-top: 30px;
                    margin-bottom: 30px;
                ">no information operator</p>
                </div>
            </mat-tab>
            <mat-tab label="People see help!">
                <div class="col-md-12" style="overflow:hidden; margin-bottom: 0px;height: 358px;"
                    *ngIf="hideContentUserInfomation == true">
                    <div class="user-block col-md-12 mt-2" style="font-size: 16px;"
                        *ngFor="let dataPeopleSeeHelp of dataPeopleSeeHelps">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <img class="img-circle img-bordered-sm"
                                                src="{{imageUrl2}}/{{dataPeopleSeeHelp.user_Image}}"
                                                onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                alt="user image">
                                        </div>
                                        <div class="col-md-10" style="margin-top: auto;margin-bottom: auto;">
                                            <span style="color: #696868;">
                                                <b>
                                                    {{dataPeopleSeeHelp.name}}
                                                </b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="text-align: center;margin-top: auto;margin-bottom: auto;">
                                <span style="color: #696868;"><i class="fas fa-phone-volume"></i><b>
                                        {{dataPeopleSeeHelp.userPhone}}</b></span>
                            </div>
                            <div class="col-md-2" style="text-align: center;margin-top: auto;margin-bottom: auto;">
                                <i class="fas fa-eye"></i>
                            </div>
                        </div>
                        <hr class="mt-2" style="margin: 0px;">
                    </div>
                </div>
                <div class="col-md-12" style="overflow:hidden; margin-bottom: 0px;"
                    *ngIf="hideContentUserInfomation == false">
                    <div class="user-block col-md-12 mt-2" style="font-size: 60px; text-align: center;color: #5e6161;">
                        <i class="fas fa-eye-slash" style="margin-top: 145px;margin-bottom: 145px;"></i>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
