import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../../../core/services/service.service';
import { io } from 'socket.io-client';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'confirm-mall-user-modal.component',
    templateUrl: './confirm-mall-user-modal.component.html',
})
export class DialogConfirmMallUser {
    dataUserCovid: any;
    dataUpdateDoctorMallUser: any;
    loadDataUpdateDoctor: boolean = false;
    updateSuccess: boolean = false;
    updateFailed: boolean = false;
    checkConfirm: boolean = true;
    idOperatorAdmin: any;
    private socket: any;
    constructor(
        private ServiceService: ServiceService,
        public dialogRef: MatDialogRef<DialogConfirmMallUser>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.socket = io(GlobalConstants.socket, { transports: ['websocket', 'polling', 'flashsocket'] });
        // console.log("sssssssssssss ",data);
        this.dataUserCovid = data;
        this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
    }

    onClickConfirm(): void {
        this.loadDataUpdateDoctor = true;
        this.checkConfirm = false;
        this.checkDataUserSelect(this.dataUserCovid, this.idOperatorAdmin);
    }

    onClickOK(status: String) {
        if (status != "Failed") {
            this.dialogRef.close(this.dataUserCovid.dmId);
        } else {
            this.dialogRef.close("Failed");
        }
    }

    onClickCancel() {
        this.dialogRef.close("Cancel");
    }

    checkDataUserSelect(data: any, operatorAdminID: string) {
        this.ServiceService.UpdateDataDoctorMallUser_(data.dmId, "StartTreat", operatorAdminID,this.dataUserCovid.userId).then(resDataUpdateDoctorMallUser => {
            // console.log(resDataUpdateDoctorMallUser);
            this.dataUpdateDoctorMallUser = resDataUpdateDoctorMallUser;
            if (this.dataUpdateDoctorMallUser.status == true) {
                setTimeout(() => {
                    this.loadDataUpdateDoctor = false;
                    this.updateSuccess = true;
                    this.updateFailed = false;
                    this.socket.emit('dataAlertPending_Covid19', { msg: "doctor-selectUser", status: "read", idOperator: this.idOperatorAdmin });
                }, 2000);
            } else {
                setTimeout(() => {
                    this.loadDataUpdateDoctor = false;
                    this.updateSuccess = false;
                    this.updateFailed = true;
                }, 2000);
            }
        });
    }

}
