<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-1">
                <h1 style="text-align: center;">Profile</h1>
            </div>
            <div class="col-md-11">
                <hr>
            </div>
        </div>
    </div>
</section>
<section class="content ">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12" *ngIf="dataUser != null">
                <mat-card class="mat-elevation-z8">
                    <!-- /.card-header -->
                    <div class="card-body" style="display: block;">
                        <div class="row">
                            <div class="col-md-12 col-xl-2">
                                <div class="text-center">
                                    <div
                                        *ngIf="dataUser.userPic != null && dataUser.userPic != '' && dataUser.userPic != undefined ; else elseImg">
                                        <img class="profile-user-img img-fluid img-circle set-img-size-1470"
                                            src="{{imageUrl2}}/{{dataUser.userPic}}"
                                            style="width: 160px;height: 160px; object-fit: cover;"
                                            alt="User profile picture">
                                    </div>
                                    <ng-template #elseImg>
                                        <img src="assets/adminlte/dist/img/nouser.svg" class="img-circle elevation-2 set-img-size-1470" alt="img-profile"
                                            style="width: 160px;height: 160px; object-fit: cover;"
                                            alt="User profile picture">
                                    </ng-template>
                                </div>

                                <p *ngIf="dataUser.userPhone != null && dataUser.userPhone != ''"
                                    class="text-muted text-center mt-2 set-font-size-1470" style="font-size: 18px;">
                                    Phone : <b>{{ dataUser.userPhone }}</b></p>
                                <p *ngIf="dataUser.userPhone == null || dataUser.userPhone == ''"
                                    class="text-muted text-center mt-2 set-font-size-1470" style="font-size: 18px;">
                                    Phone : <b>-</b></p>
                                <p *ngIf="dataUser.reletiveNumber1 != null && dataUser.reletiveNumber1 != ''"
                                    class="text-muted text-center mt-2 set-font-size-1470" style="font-size: 18px;">
                                    Relative1 : <b>{{ dataUser.reletiveNumber1 }}</b></p>
                                <p *ngIf="dataUser.reletiveNumber1 == null || dataUser.reletiveNumber1 == ''"
                                    class="text-muted text-center mt-2 set-font-size-1470" style="font-size: 18px;">
                                    Relative1 : <b>-</b></p>
                                <p *ngIf="dataUser.reletiveNumber2 != null && dataUser.reletiveNumber2 != ''"
                                    class="text-muted text-center mt-2 set-font-size-1470" style="font-size: 18px;">
                                    Relative2 : <b>{{ dataUser.reletiveNumber2 }}</b></p>
                                <p *ngIf="dataUser.reletiveNumber2 == null || dataUser.reletiveNumber2 == ''"
                                    class="text-muted text-center mt-2 set-font-size-1470" style="font-size: 18px;">
                                    Relative2 : <b>-</b></p>
                            </div>
                            <div class="col-md-12 col-xl-8">
                                <div class="row">
                                    <div class="col-6" style="margin-top: auto;">
                                        <div class=" row"style="font-size: 22px;display: flex;">
                                            <div *ngIf="disablebtn != true" >
                                                <a matTooltip="เพิ่มรายการโปรด" *ngIf="btnFavorite == false"
                                                href="javascript:void(0)" style="color: black;"
                                                (click)="onClickFavorite(dataUser.userId,'',true)"><i
                                                class="tada far fa-star" style="font-size: 25px;"></i></a>
                                                <a matTooltip="ยกเลิกรายการโปรด" *ngIf="btnFavorite == true"
                                                href="javascript:void(0)" style="color: black;"
                                                (click)="onClickFavorite(dataUser.userId,'',false)"><i
                                                class="tada fas fa-star"
                                                style="font-size: 25px; color: #e3bb1c;"></i></a>
                                            </div>
                                            <div class="mr-1" style="margin-top:auto;font-weight: 500;">
                                                {{dataUser.userName}}
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-6" style="text-align: end;">
                                        <button matTooltip="Refer user covid19" mat-raised-button color="primary"
                                            *ngIf="disablebtn == false"
                                            (click)="openDialogReferUserCovid19(dataUser,dataCommentDoctorPatient)"
                                            style="background-color: #18939c;margin-right: 10px;"><i
                                                class="fas fa-file-download"></i> Refer</button>
                                        <button matTooltip="Refer user covid19" mat-raised-button color="primary"
                                            *ngIf="disablebtn == true"
                                            (click)="openDialogHistoryReferUserCovid19(dataUser,dataCommentDoctorPatient)"
                                            style="background-color: #18939c;margin-right: 10px;"><i
                                                class="fas fa-file-download"></i> Refer</button>
                                        <button matTooltip="Close the case" mat-button color="warn"
                                            (click)="endCase(dataUser.userId)" disabled="{{disablebtn}}">Close
                                            Case</button>
                                    </div>

                                </div>
                                <div class="col-md-12" style="padding-top: 10px;" *ngIf="dataUser !=null">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="text-muted set-font-size-1470 set-font-size-1330" style="font-size: 16px;">
                                                Email : <b>{{ dataUser.userEmail }}</b>
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="text-muted set-font-size-1470" style="font-size: 16px;">
                                                Date of birth : <b>{{ dataUser.userDate | date:'shortDate'}}</b>
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="text-muted set-font-size-1470" style="font-size: 16px;">
                                                Age : <b>{{dataUser.userAge}}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="dataUser !=null">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="text-muted">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">Weight :
                                                    <b>{{ dataUser.userWeight
                                                        }} kg.</b></span>
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="text-muted">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">Height :
                                                    <b>{{ dataUser.userHeight
                                                        }} cm.</b></span>
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <!-- <img class="img-circle mat-elevation-z8 mr-1" src="assets\adminlte\dist\img\logon.png" style="width: 32px;height: 32px;" alt="icon-BMI"> -->

                                            <span class="tag tag-danger" style="font-size: 16px;"><b>BMI :
                                                </b></span>
                                            <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">
                                                {{bmi}}</span>
                                        </div>
                                        <!-- <div class="col-md">
                                            <p class="text-muted">
                                                <span class="tag tag-danger" style="font-size: 16px;">Blood type
                                                    : <b>{{
                                                        dataUser.userBloodtype }}</b></span>
                                            </p>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="dataUser !=null">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="text-muted set-font-size-1470" style="font-size: 16px;">
                                                Gender : <b *ngIf="dataUser.userSex == 'M'">Male</b> <b
                                                    *ngIf="dataUser.userSex == 'F'">FeMale</b>
                                                <b *ngIf="dataUser.userSex == '' || dataUser.userSex == null">-</b>
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="text-muted set-font-size-1470" style="font-size: 16px;">
                                                Blood Type : <b>{{dataUser.userBloodtype}}</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="dataUser !=null">
                                    <div class="row">
                                        <div class="col-md">
                                            <p class="text-muted set-font-size-1470" style="font-size: 16px;">
                                                Address :<b>{{ dataUser.userAddress }} {{ dataUser.userSubdistrict }} {{
                                                    dataUser.userDistrict }} {{ dataUser.userProvince }} {{
                                                    dataUser.userPostalcode }}</b>
                                            </p>
                                        </div>
                                        <!-- <div class="col-md">
                                            <p class="text-muted">
                                                <span class="tag tag-danger" style="font-size: 16px;">Subdistrict :
                                                    <b>{{ dataUser.userSubdistrict }}</b></span>
                                            </p>
                                        </div>
                                        <div class="col-md">
                                            <p class="text-muted">
                                                <span class="tag tag-danger" style="font-size: 16px;">District :
                                                    <b>{{ dataUser.userDistrict }}</b></span>
                                            </p>
                                        </div>
                                        <div class="col-md">
                                            <p class="text-muted">
                                                <span class="tag tag-danger" style="font-size: 16px;">Province :
                                                    <b>{{ dataUser.userProvince }}</b></span>
                                            </p>
                                        </div>
                                        <div class="col-md">
                                            <p class="text-muted">
                                                <span class="tag tag-danger" style="font-size: 16px;">Postalcode
                                                    : <b>{{ dataUser.userPostalcode }}</b></span>
                                            </p>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                        <div class="example-button-row">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Select Symptom</mat-label>
                                                <mat-select [(value)]="selectedSymptomCovid" disabled="{{disablebtn}}"
                                                    (selectionChange)="onChangeSymptomCovid(selectedSymptomCovid)">
                                                    <mat-option value="Green">Zone Green</mat-option>
                                                    <mat-option value="Yellow">Zone Yellow</mat-option>
                                                    <mat-option value="Red">Zone Red</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="row"
                                            style="margin-top: auto;margin-bottom: auto;margin-left: 10px;">
                                            <span style="margin-top: auto;margin-bottom: auto;margin-right: 8px;"><b>Symptom
                                                    Doctor</b></span>
                                            <span class="starus-covid-green"
                                                style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                                *ngIf="selectedSymptomCovid == 'Green'"></span>
                                            <span class="starus-covid-yellow"
                                                style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                                *ngIf="selectedSymptomCovid == 'Yellow'"></span>

                                            <span class="starus-covid-red"
                                                style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                                *ngIf="selectedSymptomCovid == 'Red'"></span>
                                            <span
                                                *ngIf="selectedSymptomCovid != 'Red' && selectedSymptomCovid != 'Yellow' && selectedSymptomCovid != 'Green'">-</span>

                                            <span
                                                style="margin-top: auto;margin-bottom: auto;margin-right: 8px;margin-left: 10px;"><b>Symptom
                                                    User</b></span>
                                            <span class="starus-covid-green"
                                                style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                                *ngIf="userCovidStatus == 'Green'"></span>

                                            <span class="starus-covid-yellow"
                                                style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                                *ngIf="userCovidStatus == 'Yellow'"></span>

                                            <span class="starus-covid-red"
                                                style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                                *ngIf="userCovidStatus == 'Red'"></span>
                                        </div>

                                    </div>
                                    <div class="row mt-2">
                                        <div class="example-button-row">
                                            <button mat-raised-button style="margin-right: 5px;" color="primary"
                                                (click)="openDetailSosLogBody(dataUser.userId,false)"><i
                                                    class="fas fa-child"></i> Health
                                                Information</button>
                                            <button mat-raised-button style="margin-right: 5px;" color="primary"
                                                (click)="openDialogPersonalHealth(dataUser.userId)"><i
                                                    class="fas fa-notes-medical"></i>
                                                Personal Health</button>
                                            <button mat-raised-button style="margin-right: 5px;" color="accent"
                                                (click)="openDetailSosLogBody(dataUser.userId,true)"><i
                                                    class="far fa-chart-bar"></i>
                                                Health
                                                Information Covid 19</button>
                                        </div>
                                    </div>
                                    <div class="row mt-3" *ngIf="dataPersonalHealth != '' && dataPersonalHealth != null">
                                        <div style="margin-top: auto;margin-bottom: auto;">
                                            <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>Risk Factor :
                                                </b></span>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคเบาหวาน'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>DM
                                                    </b></span>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคความดัน'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>HT
                                                    </b></span>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคหัวใจ'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>CVD
                                                    </b></span>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคไต'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>CKD
                                                    </b></span>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคตับ'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>CLD
                                                    </b></span>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคปอด'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>COPD
                                                    </b></span>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคอ้วน'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>DLP
                                                    </b></span>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of dataPersonalHealth">
                                            <div class="ml-2" *ngIf="item == 'โรคเส้นเลือดสมอง'"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px; color: #d00d0d;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>CVA
                                                    </b></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <!-- <div style="margin-top: auto;margin-bottom: auto;">
                                            <span class="tag tag-danger" style="font-size: 16px;"><b>Vital sign :
                                                </b></span>
                                        </div> -->
                                        <div *ngIf="dataDoctorPatient != null" class="row">
                                            <div class="ml-2" style="margin-top: auto;margin-bottom: auto;">
                                                <b class="set-font-size-1470" style="font-size: 16px;">Vital sign : </b>
                                            </div>
                                            <div class="ml-2"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>T
                                                    </b></span>
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">
                                                    {{dataDoctorPatient[0].cTemp}} </span>
                                                <button mat-icon-button disabled="{{disablebtn}}"
                                                    (click)="openDialogEditVitalSign(dataDoctorPatient[0].cId,'T',dataDoctorPatient[0].cTemp,'')">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </div>
                                            <div class="ml-2"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>P
                                                    </b></span>
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">
                                                    {{dataDoctorPatient[0].cHeartrate}} </span>
                                                <button mat-icon-button disabled="{{disablebtn}}"
                                                    (click)="openDialogEditVitalSign(dataDoctorPatient[0].cId,'P',dataDoctorPatient[0].cHeartrate,'')">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </div>
                                            <div class="ml-2"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>BP
                                                    </b></span>
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">
                                                    {{dataDoctorPatient[0].cPressureHigh}} /
                                                    {{dataDoctorPatient[0].cPressureLow}}</span>
                                                <button mat-icon-button disabled="{{disablebtn}}"
                                                    (click)="openDialogEditVitalSign(dataDoctorPatient[0].cId,'BP',dataDoctorPatient[0].cPressureHigh ,dataDoctorPatient[0].cPressureLow)">
                                                    <mat-icon>edit</mat-icon>
                                                </button>

                                            </div>
                                            <div class="ml-2"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>SpO2 At Rest
                                                    </b></span>
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">
                                                    {{dataDoctorPatient[0].cSpo2}} </span>
                                                <button mat-icon-button disabled="{{disablebtn}}"
                                                    (click)="openDialogEditVitalSign(dataDoctorPatient[0].cId,'SpO2 At Rest',dataDoctorPatient[0].cSpo2,'')">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </div>
                                            <div class="ml-2"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>SpO2 After Ex
                                                    </b></span>
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">
                                                    {{dataDoctorPatient[0].cSpo2AfterExercise}}</span>
                                                <button mat-icon-button disabled="{{disablebtn}}"
                                                    (click)="openDialogEditVitalSign(dataDoctorPatient[0].cId,'SpO2 After Ex',dataDoctorPatient[0].cSpo2AfterExercise,'')">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </div>
                                            <div class="ml-2"
                                                style="margin-top: auto;margin-bottom: auto;padding-left: 10px;">
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;"><b>SpO2 On O2
                                                    </b></span>
                                                <span class="tag tag-danger set-font-size-1470" style="font-size: 16px;">
                                                    {{dataDoctorPatient[0].cSpo2Ventilator}}</span>
                                                <button mat-icon-button disabled="{{disablebtn}}"
                                                    (click)="openDialogEditVitalSign(dataDoctorPatient[0].cId,'SpO2 On O2',dataDoctorPatient[0].cSpo2Ventilator,'')">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <hr style="padding:0px;margin-top: 5px;margin-bottom:5px"> -->
                                    <div class="row mt-3" *ngIf="lastCovidDispense.length != 0">
                                        <div style="margin-top: auto;margin-bottom: auto; ">
                                            <span class="set-font-size-1470" *ngIf="lastCovidDispense[0].medPres.mpStatus == 'request'"><b>Medicine : </b><span style="padding: 3px; border:1px solid #00aeff; border-radius: 5px; background-color: #e4f0f7; color: #222; font-weight: 500;">กำลังส่งคำขอ</span></span>
                                            <span class="set-font-size-1470" *ngIf="lastCovidDispense[0].medPres.mpStatus == 'waiting'"><b>Medicine : </b><span style="padding: 3px; border:1px solid #FFA900; border-radius: 5px; background-color: #fffaf0; color: #222; font-weight: 500;">กำลังจัดเตรียมยา</span></span>
                                            <span class="set-font-size-1470" *ngIf="lastCovidDispense[0].medPres.mpStatus == 'accept'"><b>Medicine : </b><span style="padding: 3px; border:1px solid #00B74A; border-radius: 5px; background-color: #effff5; color: #222; font-weight: 500;">จ่ายยาแล้ว</span></span>
                                        </div>
                                    </div>
                                    <div class="row mt-2" *ngIf="lastCovidDispense.length != 0">
                                        <div *ngIf="lastCovidDispense.length != 0" class="row" >
                                            <span style="padding-left: 7px;font-size:20px"><i class="fas fa-pills"></i>&nbsp;&nbsp;<b>:</b>&nbsp;&nbsp;</span>
                                            <span *ngIf="lastCovidDispense[0].medPres.mpFavi == true"
                                                style="background-color: #1266F1;" class="badge span16 set-font-size-1470">Favi</span>
                                            <span *ngIf="lastCovidDispense[0].medPres.mpDexa == true"
                                                style="background-color: #B23CFD;" class="badge span16 set-font-size-1470">Dexa</span>
                                            <span *ngIf="lastCovidDispense[0].medPres.mpPrednisolone == true"
                                                style="background-color: #00B74A;"
                                                class="badge span16 set-font-size-1470">Prednisolone</span>
                                            <span *ngIf="lastCovidDispense[0].medPres.mpOmeprazole == true"
                                                style="background-color: #FF3D00;"
                                                class="badge span16 set-font-size-1470">Omeprazole</span>
                                            <span *ngIf="lastCovidDispense[0].medPres.mpOxygen == true"
                                                style="background-color: #39C0ED;" class="badge span16 set-font-size-1470">Oxygen</span>
                                            <span *ngIf="lastCovidDispense[0].medPres.mpOthers == true"
                                                style="background-color: #212121;" class="badge span16 set-font-size-1470">Others</span>
                                        </div>
                                    </div>
                                    <!-- <hr style="padding:0px;margin-top: 5px;margin-bottom:5px"> -->
                                    <div class="row mt-3" *ngIf="lastCovidDispenseTH.length != 0">
                                        <div style="margin-top: auto;margin-bottom: auto;">
                                            <div style="margin-top: auto;margin-bottom: auto;">
                                                <span class="set-font-size-1470" *ngIf="lastCovidDispenseTH[0].medPres.mptStatus == 'request'"><b>Medicine Thai : </b><span style="padding: 3px; border:1px solid #00aeff; border-radius: 5px; background-color: #e4f0f7; color: #222; font-weight: 500;">กำลังส่งคำขอ</span></span>
                                                <span class="set-font-size-1470" *ngIf="lastCovidDispenseTH[0].medPres.mptStatus == 'waiting'"><b>Medicine Thai : </b><span style="padding: 3px; border:1px solid #FFA900; border-radius: 5px; background-color: #fffaf0; color: #222; font-weight: 500;">กำลังจัดเตรียมยา</span></span>
                                                <span class="set-font-size-1470" *ngIf="lastCovidDispenseTH[0].medPres.mptStatus == 'accept'"><b>Medicine Thai : </b><span style="padding: 3px; border:1px solid #00B74A; border-radius: 5px; background-color: #effff5; color: #222; font-weight: 500;">จ่ายยาแล้ว</span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2" *ngIf="lastCovidDispenseTH.length != 0">
                                        <div *ngIf="lastCovidDispenseTH.length != 0" class="row">
                                            <span style="padding-left: 7px;font-size:20px"><i class="fas fa-tablets"></i>&nbsp;&nbsp;<b>:</b>&nbsp;&nbsp;</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptStatiaCz == true"
                                                style="background-color: #006400;" class="badge span16 set-font-size-1470">STATIA C
                                                Z</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptStatia == true"
                                                style="background-color: #DAA520;" class="badge span16 set-font-size-1470">STATIA</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mpt5roots == true"
                                                style="background-color: #6B8E23;" class="badge span16 set-font-size-1470">5 ราก</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptGreenMedicine == true"
                                                style="background-color: #D2691E;" class="badge span16 set-font-size-1470">ยาเขียว</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptAummahiva == true"
                                                style="background-color: #FA8072;"
                                                class="badge span16 set-font-size-1470">ยาอํามฤควาที</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptPrasapoyai == true"
                                                style="background-color: #D2B48C;"
                                                class="badge span16 set-font-size-1470">ยาประสะเปราะใหญ่</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptJanlelar == true"
                                                style="background-color: #DA70D6;"
                                                class="badge span16 set-font-size-1470">ยาจันทน์ลีลา</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptHomyen == true"
                                                style="background-color: #357ec2;" class="badge span16 set-font-size-1470">ยาหอมเย็น</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptHomron == true"
                                                style="background-color: #556B2F;" class="badge span16 set-font-size-1470">ยาหอมร้อน</span>
                                            <span *ngIf="lastCovidDispenseTH[0].medPres.mptFatalaijon == true"
                                                style="background-color: #a0358e;"
                                                class="badge span16 set-font-size-1470">ฟ้าทลายโจร</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12 col-xl-2" style="margin: auto;">
                                <form [formGroup]="noteForm" style="display: contents;">
                                    <mat-form-field class="example-full-width" appearance="outline"
                                        style="width: -webkit-fill-available;">
                                        <mat-label>Add Important Note!</mat-label>
                                        <textarea matInput placeholder="note" rows="21"
                                            formControlName="noteUserDoctor"></textarea>
                                        <mat-hint *ngIf="noteDoctor == true" style="color: #d00d0d;">Please enter a
                                            Note!
                                        </mat-hint>
                                    </mat-form-field>
                                </form>
                                <button mat-raised-button color="primary" style="width: -webkit-fill-available;"
                                    disabled="{{disablebtn}}" (click)="addNoteUserCovid19(dataUser.userId)">
                                    <i class="fas fa-save"></i> Save</button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</section>

<section class="content mt-2" style="padding-left: 7px;padding-right: 7px;">

    <div class="row">

        <div class="col-lg-6 col-xl-4" style="padding-left: 8px;padding-right: 8px;">
            <div class="card card-widget mat-elevation-z8">
                <div class="card-header">
                    <div class="user-block">
                        <span><b class="set-font-size-1470"> Health Information Covid 19</b></span>
                    </div>
                    <!-- <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div> -->
                </div>
                <p *ngIf="recordFirstSymptoms != undefined || recordLatestSymptoms != undefined" class="set-font-size-1470"
                    style="padding: 0px;margin-top: 20px;margin-bottom: 0px;margin-left: 33px;">เสียงนับ 1-20</p>
                <div *ngIf="recordFirstSymptoms != undefined" style="display: flex;justify-content: center;">
                    <audio
                        class="size-sound-1800 size-sound-1700 size-sound-1600 size-sound-1500 size-sound-1400 size-sound-1300 size-sound-1199 size-sound-1050 size-sound-1100"
                        controls style="height: 35px;margin-top: 2px; margin-right: 5px;">
                        <source src="{{imageUrl2}}/{{recordFirstSymptoms.sound}}" type="audio/mp4">
                    </audio>
                    <p class="set-font-size-1470" style="margin-right: 8px; font-size: 14px;">First sound <br>Date :
                        <b>{{recordFirstSymptoms.dateSound | date:'short'}}</b>
                    </p>
                </div>
                <div *ngIf="recordLatestSymptoms != undefined" style="display: flex;justify-content: center;">
                    <audio
                        class="size-sound-1800 size-sound-1700 size-sound-1600 size-sound-1500 size-sound-1400 size-sound-1300 size-sound-1199 size-sound-1050 size-sound-1100"
                        controls style="height: 35px;margin-top: 2px; margin-right: 5px;">
                        <source src="{{imageUrl2}}/{{recordLatestSymptoms.sound}}" type="audio/mp4">
                    </audio>
                    <p class="set-font-size-1470" style="margin-right: 8px;font-size: 14px;">Latest sound <br>Date :
                        <b>{{recordLatestSymptoms.dateSound | date:'short'}}</b>
                    </p>
                </div>
                <hr style="margin-top: 0;margin-bottom: 0;margin-left: 35px;margin-right: 35px;"
                    *ngIf="recordFirstSymptoms2 != undefined || recordLatestSymptoms2 != undefined">
                <p *ngIf="recordFirstSymptoms2 != undefined || recordLatestSymptoms2 != undefined" class="set-font-size-1470"
                    style="padding: 0px;margin-top: 15px;margin-bottom: 0px;margin-left: 33px;">เสียงอ้าปาก</p>
                <div *ngIf="recordFirstSymptoms2 != undefined" style="display: flex;justify-content: center;">
                    <audio
                        class="size-sound-1800 size-sound-1700 size-sound-1600 size-sound-1500 size-sound-1400 size-sound-1300 size-sound-1199 size-sound-1050 size-sound-1100"
                        controls style="height: 35px;margin-top: 2px; margin-right: 5px;">
                        <source src="{{imageUrl2}}/{{recordFirstSymptoms2.sound}}" type="audio/mp4">
                    </audio>
                    <p class="set-font-size-1470" style="margin-right: 8px; font-size: 14px;">First sound <br>Date :
                        <b>{{recordFirstSymptoms2.dateSound | date:'short'}}</b>
                    </p>
                </div>
                <div *ngIf="recordLatestSymptoms2 != undefined" style="display: flex;justify-content: center;">
                    <audio
                        class="size-sound-1800 size-sound-1700 size-sound-1600 size-sound-1500 size-sound-1400 size-sound-1300 size-sound-1199 size-sound-1050 size-sound-1100"
                        controls style="height: 35px;margin-top: 2px; margin-right: 5px;">
                        <source src="{{imageUrl2}}/{{recordLatestSymptoms2.sound}}"
                            type="audio/mp4">
                    </audio>
                    <p class="set-font-size-1470" style="margin-right: 8px;font-size: 14px;">Latest sound <br>Date :
                        <b>{{recordLatestSymptoms2.dateSound | date:'short'}}</b>
                    </p>
                </div>
                <div class="card-footer card-comments"
                    style="max-height: 400px;overflow: auto;background-color: #FFFFFF;padding-bottom: 0px;">

                    <div class="row" style="width: max-content;max-height: 625px; margin: auto;"
                        *ngIf="dataCovidSymptom != ''">
                        <div>
                            <div class=" border-right border-bottom border-left border-bottom border-top"
                                style="height: 70px;width: 250px;padding-top: 25px; margin-right: 7px;text-align: center;">
                                <div>อาการ</div>
                            </div>
                            <div class=" border-right border-bottom border-left border-top"
                                *ngIf="infromationCovid19.cFeverish == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>มีไข้</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cSoreThroat == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>เจ็บคอ</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cMusclePain == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>ปวดกล้ามเนื้อ</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cRunny == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>มีน้ำมูก</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cSputum == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>มีเสมหะ</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cHeadache == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>ปวดหัว</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cNoseNoSmell == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>จมูกไม่ได้กลิ่น</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cRash == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>มีผื่น</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cLiquidStool == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>ถ่ายเหลว</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cCoughAlittle == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>ไอเล็กน้อย</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cTongueNoTaste == true"
                                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>ลิ้นไม่รับรส</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cConjunctivitis == true"
                                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                                <div>ตาแดง</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cCanNotEat == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                                <div>ทานอาหารไม่ได้</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cCoughAlot == true"
                                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                                <div>ไอเยอะ</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cCoughingUpBlood == true"
                                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                                <div>ไอเป็นเลือด</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cBreathingDiff == true"
                                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                                <div>หายใจลำบาก แน่นหน้าอก</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cTriedDailyActivities == true"
                                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                                <div>เหนื่อยในขณะที่ทำกิจวัตรประจำวัน</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cTiredSitStill == true"
                                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #ff00004d;">
                                <div>เหนื่อยขณะนั่งเฉยๆ</div>
                            </div>
                            <div class=" border-right border-bottom border-left"
                                *ngIf="infromationCovid19.cLieDownBreath == true"
                                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #ff00004d;">
                                <div>นอนราบแล้วหายใจไม่ออก</div>
                            </div>
                        </div>
                        <div *ngIf="dataCovidSymptom != ''">
                            <div class="row" style="min-width: auto;">
                                <div>
                                    <div class=" border-right border-top"
                                        style="font-weight: bold;font-size: 10px; width: 40px; height: 70px;padding: 5px;">
                                        <div style="margin-left: auto;margin-right: auto;text-align: center;"></div>
                                    </div>
                                    <div class=" border-right " *ngIf="infromationCovid19.cFeverish == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cSoreThroat == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right " *ngIf="infromationCovid19.cMusclePain == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right " *ngIf="infromationCovid19.cRunny == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cSputum == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cHeadache == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cNoseNoSmell == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cRash == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cLiquidStool == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right " *ngIf="infromationCovid19.cCoughAlittle == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cTongueNoTaste == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cConjunctivitis == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cCanNotEat == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right " *ngIf="infromationCovid19.cCoughAlot == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right " *ngIf="infromationCovid19.cCoughingUpBlood == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cBreathingDiff == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right " *ngIf="infromationCovid19.cTriedDailyActivities == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cTiredSitStill == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class=" border-right" *ngIf="infromationCovid19.cLieDownBreath == true"
                                        style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                    <div class="border-top" style="width: 40px; height: 30px;padding: 5px;">

                                    </div>
                                </div>

                                <div *ngFor="let itemCovid of dataCovidSymptom">
                                    <div *ngIf="itemCovid.dataCovid.cFeverish == true ||
                                        itemCovid.dataCovid.cSoreThroat == true ||
                                        itemCovid.dataCovid.cMusclePain == true ||
                                        itemCovid.dataCovid.cRunny == true ||
                                        itemCovid.dataCovid.cSputum == true ||
                                        itemCovid.dataCovid.cHeadache == true ||
                                        itemCovid.dataCovid.cNoseNoSmell == true ||
                                        itemCovid.dataCovid.cRash == true ||
                                        itemCovid.dataCovid.cLiquidStool == true ||
                                        itemCovid.dataCovid.cCoughAlittle == true ||
                                        itemCovid.dataCovid.cTongueNoTaste == true ||
                                        itemCovid.dataCovid.cConjunctivitis == true ||
                                        itemCovid.dataCovid.cCanNotEat == true ||
                                        itemCovid.dataCovid.cCoughAlot == true ||
                                        itemCovid.dataCovid.cCoughingUpBlood == true ||
                                        itemCovid.dataCovid.cBreathingDiff == true ||
                                        itemCovid.dataCovid.cTriedDailyActivities == true ||
                                        itemCovid.dataCovid.cTiredSitStill == true ||
                                        itemCovid.dataCovid.cLieDownBreath == true   ">
                                        <div class=" border-right border-bottom border-bottom border-top"
                                            style="font-weight: bold;font-size: 10px; width: 40px; height: 70px;padding: 1px;">
                                            <div style="margin-left: auto;margin-right: auto;text-align: center;">
                                                {{itemCovid.dataCovid.cDate |
                                                date:'short'}}</div>
                                        </div>
                                        <!-- -------------------------------------------------------------------------Green--------------------- -->
                                        <div class=" border-right border-bottom border-top"
                                            *ngIf="infromationCovid19.cFeverish == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cFeverish == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div *ngIf="itemSymptom.symptom == 'cFeverish'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cSoreThroat == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cSoreThroat == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cSoreThroat'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cMusclePain == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cMusclePain == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cMusclePain'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cRunny == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cRunny == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div *ngIf="itemSymptom.symptom == 'cRunny'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cSputum == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cSputum == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div *ngIf="itemSymptom.symptom == 'cSputum'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cHeadache == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cHeadache == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div *ngIf="itemSymptom.symptom == 'cHeadache'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cNoseNoSmell == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cNoseNoSmell == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cNoseNoSmell'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cRash == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cRash == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div *ngIf="itemSymptom.symptom == 'cRash'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cLiquidStool == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cLiquidStool == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cLiquidStool'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cCoughAlittle == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cCoughAlittle == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cCoughAlittle'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cTongueNoTaste == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cTongueNoTaste == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cTongueNoTaste'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cConjunctivitis == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cConjunctivitis == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cConjunctivitis'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <!-- -------------------------------------------------------------------------Green--------------------- -->
                                        <!-- -------------------------------------------------------------------------Yellow--------------------- -->
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cCanNotEat == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cCanNotEat == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div *ngIf="itemSymptom.symptom == 'cCanNotEat'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cCoughAlot == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cCoughAlot == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div *ngIf="itemSymptom.symptom == 'cCoughAlot'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cCoughingUpBlood == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cCoughingUpBlood == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cCoughingUpBlood'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cBreathingDiff == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cBreathingDiff == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cBreathingDiff'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cTriedDailyActivities == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cTriedDailyActivities == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cTriedDailyActivities'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <!-- -------------------------------------------------------------------------Yellow--------------------- -->
                                        <!-- -------------------------------------------------------------------------Red--------------------- -->
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cTiredSitStill == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cTiredSitStill == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cTiredSitStill'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class=" border-right border-bottom"
                                            *ngIf="infromationCovid19.cLieDownBreath == true"
                                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                                            <div class="symptom-covid-green"
                                                *ngIf="itemCovid.dataCovid.cLieDownBreath == true"
                                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                                <div
                                                    *ngIf="itemSymptom.symptom == 'cLieDownBreath'; then thenSymptomBlock">
                                                </div>
                                                <ng-template #thenSymptomBlock>
                                                    <i class=" fas fa-long-arrow-alt-down"
                                                        *ngIf="itemSymptom.status == 0"
                                                        style="padding-top: 3px;color: #2aa745;"></i>
                                                    <i *ngIf="itemSymptom.status == 1"
                                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <!-- -------------------------------------------------------------------------Red--------------------- -->
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style="margin-top: 110px;" *ngIf="dataCovidSymptom == ''">
                            <div style="min-width: auto; text-align: center;">
                                <img src="assets\adminlte\dist\img\covid-image.png" style="width: 460px;"
                                    alt="covid-image">
                                <p style="font-weight: 600;font-size: 14px;">ไม่มีข้อมูล</p>
                            </div>
                        </div>
                    </div>
                    <div style="padding-bottom: 50px;margin-top: 50px;" *ngIf="dataCovidSymptom == ''">
                        <div style="text-align: center;">
                            <!-- <img src="assets\adminlte\dist\img\covid-image.png" style="width: 460px;"
                                    alt="covid-image"> -->
                            <p style="font-weight: 600;font-size: 14px;">No Information</p>
                        </div>
                    </div>
                    <div class="row" style="margin: auto;margin-bottom: 10px;"
                    *ngIf="infromationCovid19.cFeverish == false &&
                    infromationCovid19.cSoreThroat == false &&
                    infromationCovid19.cMusclePain == false &&
                    infromationCovid19.cRunny == false &&
                    infromationCovid19.cSputum == false &&
                    infromationCovid19.cHeadache == false &&
                    infromationCovid19.cNoseNoSmell == false &&
                    infromationCovid19.cRash == false &&
                    infromationCovid19.cLiquidStool == false &&
                    infromationCovid19.cCoughAlittle == false &&
                    infromationCovid19.cTongueNoTaste == false &&
                    infromationCovid19.cConjunctivitis == false &&
                    infromationCovid19.cCanNotEat == false &&
                    infromationCovid19.cCoughAlot == false &&
                    infromationCovid19.cCoughingUpBlood == false &&
                    infromationCovid19.cBreathingDiff == false &&
                    infromationCovid19.cTriedDailyActivities == false &&
                    infromationCovid19.cTiredSitStill == false &&
                    infromationCovid19.cLieDownBreath == false &&
                    dataCovidSymptom != '' " >
                        <p class="set-font-size-1470">ไม่ได้กรอกอาการ 3 วัน ล่าสุดมา แต่อาจมีการกรอกค่า T, P, BP, SpO2 At Rest, SpO2 After Ex, SpO2 On O2</p>
                        <p class="set-font-size-1470" style="font-style: italic">*สามารถดูอาการเพิ่มเติมได้ที่ <b>Health Information Covid 19</b></p>
                    </div>
                    <div class="row" style="width: max-content; margin: auto;margin-top: 5px;margin-bottom: 10px;"
                        *ngIf="dataCovidSymptom != ''">
                        <i class="fas fa-long-arrow-alt-down mr-3 set-font-size-1470" style="color: #2aa745;"> ดีขึ้นจากเมื่อวาน</i>
                        <i class="mr-3 ml-3 set-font-size-1470" style="padding-left: 2px;"><b>-</b> เหมือนเดิม</i>
                        <i class="fas fa-long-arrow-alt-up ml-3 set-font-size-1470" style="color: #d00d0d;"> แย่ลงจากเมื่อวาน</i>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-lg-6 col-xl-4" style="padding-left: 8px;padding-right: 8px;">
            <div class="card card-widget mat-elevation-z8">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4"><span><b class="set-font-size-1470">Prescription</b></span></div>

                        <div class="col-8" style="text-align:end" *ngIf="dataPrescription == -1">
                            <span *ngIf="lastCovidDispense.length != 0"><b>Latest Accept By Admin :
                                </b>{{lastCovidDispense[0].medPres.mpAdminacceptDate | date:'short'}}</span>
                        </div>
                        <div class="col-8" style="text-align:end" *ngIf="dataPrescription == 1">
                            <span *ngIf="lastCovidDispenseTH.length != 0"><b>Latest Accept By Admin :
                                </b>{{lastCovidDispenseTH[0].medPres.mptDate | date:'short'}}</span>
                        </div>
                    </div>
                </div>
                <mat-tab-group mat-stretch-tabs class="example-stretched-tabs" (focusChange)="myTabFocusChange($event)">
                    <mat-tab label="Medical Prescribtion">
                        <div class="row text-center" style="padding-top: 15px;padding-left: 15px;padding-right: 15px;">
                            <div class="col-6">
                                <button mat-raised-button color="primary" disabled="{{disablebtn}}"
                                    style="margin-right: 5px;margin-left: auto;margin-top: 5px;margin-bottom: 5px; width: -webkit-fill-available;"
                                    (click)="onSubmitDispenseMedicine()">Submit Order</button>
                            </div>
                            <div class="col-6">
                                <button mat-raised-button color="primary"
                                    routerLink="/covidDispenseMedicineList/{{getIdUser}}"
                                    style="margin-right: 5px;margin-left: auto;margin-top: 5px;margin-bottom: 5px; width: -webkit-fill-available; background-color: indigo;color: white;">Prescription
                                    History</button>
                            </div>
                        </div>



                        <div class="card-footer card-comment-doctor"
                            style="background-color: #FFFFFF; max-height: 653px;">
                            <form [formGroup]="dispenseMedicine">


                                <div>
                                    <mat-checkbox color="primary" (change)="onCheckFavi($event)"
                                        disabled="{{disablebtn}}" [checked]="radioStatus">
                                        Favipiravir </mat-checkbox>
                                </div>
                                <fieldset [disabled]='disabledFavi'>
                                    <div style="margin-left: 25px;">
                                        <p> Prescription date <b>{{dateTime_ | date:'shortDate'}}</b> today</p>
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">
                                                Start date
                                            </p>
                                            <mat-form-field appearance="outline" style="width: 160px;">
                                                <!-- <mat-label>Choose a date</mat-label> -->
                                                <input matInput [matDatepicker]="pickerFavi"
                                                    formControlName="FavipiravirStartdate">
                                                <mat-datepicker-toggle matSuffix [for]="pickerFavi">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerFavi></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div>
                                        <mat-checkbox color="primary" (change)="onCheckFaviFirst5days($event)"
                                            [checked]="radioStatusFaviN5" [disabled]='disabledFaviFirst5days'>First
                                            5 days
                                            <span style="font-size: 12px;color:#636363"> *กรุณาเลือก Favipiravir
                                                ก่อน</span>
                                        </mat-checkbox>
                                    </div>
                                    <mat-radio-group aria-label="Select an option">
                                        <div style="margin-left: 25px;">
                                            <div class="row" style="margin: 0px;">
                                                <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">
                                                    Start
                                                </p>
                                                <mat-form-field appearance="outline" style="width: 160px;">
                                                    <!-- <mat-label>Choose a date</mat-label> -->
                                                    <input matInput [matDatepicker]="pickerFirst5daysStart"
                                                        formControlName="FavipiravirFirst5daysStart"
                                                        [disabled]='disabledFaviFirst5days'>
                                                    <mat-datepicker-toggle matSuffix [for]="pickerFirst5daysStart">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #pickerFirst5daysStart></mat-datepicker>
                                                </mat-form-field>
                                                <p style="margin-top: auto;margin-bottom: auto;padding: 5px;">to</p>
                                                <mat-form-field appearance="outline" style="width: 160px;">
                                                    <!-- <mat-label>Choose a date</mat-label> -->
                                                    <input matInput [matDatepicker]="pickerFirst5daysEnd"
                                                        formControlName="FavipiravirFirst5daysEnd"
                                                        [disabled]='disabledFaviFirst5days'>
                                                    <mat-datepicker-toggle matSuffix [for]="pickerFirst5daysEnd">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #pickerFirst5daysEnd></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div>

                                                <div style="display: grid;">
                                                    <mat-radio-button color="primary" value="1"
                                                        (change)="onChangeDayWeight('W>=90')" [checked]="radioStatus"
                                                        [disabled]='disabledFaviFirst5days'>
                                                        W >= 90 kg: (D1) 200mg 12 tabs bid, (D2-5) 200mg 5 tabs bid.
                                                    </mat-radio-button>
                                                    <mat-radio-button color="primary" value="2"
                                                        (change)="onChangeDayWeight('W<90')" [checked]="radioStatus"
                                                        [disabled]='disabledFaviFirst5days'>
                                                        W < 90 kg: 200mg/tabs 9 tabs เช้าเย็น (D1) 4 tabs เช้าเย็น
                                                            (D2-5)</mat-radio-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <mat-checkbox color="primary" (change)="onCheckFaviNext5days($event)"
                                                disabled="{{disablebtn}}" [checked]="radioStatusFaviF5"
                                                [disabled]='disabledFaviNext5days'>
                                                Next 5 days
                                                <span style="font-size: 12px;color:#636363"> *กรุณาเลือก Favipiravir
                                                    ก่อน</span>
                                            </mat-checkbox>
                                        </div>
                                        <div style="margin-left: 25px;">
                                            <div class="row" style="margin: 0px;">
                                                <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">
                                                    Start
                                                </p>
                                                <mat-form-field appearance="outline" style="width: 160px;">
                                                    <!-- <mat-label>Choose a date</mat-label> -->
                                                    <input matInput [matDatepicker]="pickerNext5daysStart"
                                                        formControlName="FavipiravirNext5daysStart"
                                                        [disabled]='disabledFaviNext5days'>
                                                    <mat-datepicker-toggle matSuffix [for]="pickerNext5daysStart">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #pickerNext5daysStart></mat-datepicker>
                                                </mat-form-field>
                                                <p style="margin-top: auto;margin-bottom: auto;padding: 5px;">to</p>
                                                <mat-form-field appearance="outline" style="width: 160px;">
                                                    <!-- <mat-label>Choose a date</mat-label> -->
                                                    <input matInput [matDatepicker]="pickerNext5daysEnd"
                                                        formControlName="FavipiravirNext5daysEnd"
                                                        [disabled]='disabledFaviNext5days'>
                                                    <mat-datepicker-toggle matSuffix [for]="pickerNext5daysEnd">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #pickerNext5daysEnd></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <div style="display: grid;">
                                                    <mat-radio-button color="primary" value="3"
                                                        (change)="onChangeDayWeight('W>=90')" [checked]="radioStatus"
                                                        [disabled]='disabledFaviNext5days'>
                                                        W
                                                        >= 90 200mg/tab 5 tab เช้าเย็น
                                                        D6-10</mat-radio-button>
                                                    <mat-radio-button color="primary" value="4"
                                                        (change)="onChangeDayWeight('W<90')" [checked]="radioStatus"
                                                        [disabled]='disabledFaviNext5days'>
                                                        W
                                                        < 90 200mg/tab 4 tab เช้าเย็น D6-10</mat-radio-button>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-radio-group>
                                    <div class="mt-2">
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note
                                                : </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Note </mat-label>
                                                    <input matInput placeholder="note"
                                                        formControlName="FavipiravirNote">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr>
                                <div class="mt-2">
                                    <mat-checkbox color="primary" (change)="onCheckDexamethasone($event)"
                                        disabled="{{disablebtn}}" [checked]="radioStatus">Dexamethasone
                                    </mat-checkbox>
                                </div>
                                <fieldset [disabled]='disabledDexamethasone'>
                                    <div style="margin-left: 25px;">
                                        <p>Prescription date <b>{{dateTime_ | date:'shortDate'}}</b> today</p>
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">
                                                Start date
                                            </p>
                                            <mat-form-field appearance="outline" style="width: 160px;">
                                                <!-- <mat-label>Choose a date</mat-label> -->
                                                <input matInput [matDatepicker]="pickerDexamethasone"
                                                    formControlName="DexamethasoneStartdate">
                                                <mat-datepicker-toggle matSuffix [for]="pickerDexamethasone">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerDexamethasone></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="margin-left: 25px;">
                                        <div class="row" style="margin: 0px;">
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput formControlName="DexamethasoneMG">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;">mg</p>
                                        </div>
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;">frequency
                                            </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput
                                                        formControlName="DexamethasoneFrequency">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;">times/day
                                                x</p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput formControlName="DexamethasoneTimes">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;">day</p>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note
                                                : </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Note </mat-label>
                                                    <input matInput placeholder="note"
                                                        formControlName="DexamethasoneNote">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr>
                                <div class="mt-2">
                                    <mat-checkbox color="primary" (change)="onCheckPrednisolone($event)"
                                        disabled="{{disablebtn}}" [checked]="radioStatus">Prednisolone
                                    </mat-checkbox>
                                </div>
                                <fieldset [disabled]='disabledPrednisolone'>


                                    <div style="margin-left: 25px;">
                                        <p>Prescription date <b>{{dateTime_ | date:'shortDate'}}</b> today</p>
                                        <div class="row" style="margin: 0px;">
                                            <p
                                                style="margin-top: auto;margin-bottom: auto;padding-right: 3px;padding: 3px;">
                                                Start date
                                            </p>
                                            <mat-form-field appearance="outline" style="width: 160px;">
                                                <!-- <mat-label>Choose a date</mat-label> -->
                                                <input matInput formControlName="PrednisoloneStartdate"
                                                    [matDatepicker]="pickerPrednisolone">
                                                <mat-datepicker-toggle matSuffix [for]="pickerPrednisolone">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerPrednisolone></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="margin-left: 25px;">
                                        <div class="row" style="margin: 0px;">
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput formControlName="PrednisoloneMG">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;"> mg </p>
                                        </div>
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;"> frequency
                                            </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput
                                                        formControlName="PrednisoloneFrequency">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;"> times/day
                                                x </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput formControlName="PrednisoloneTimes">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;"> day </p>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note
                                                : </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Note </mat-label>
                                                    <input matInput formControlName="PrednisoloneNote"
                                                        placeholder="note">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr>
                                <div class="mt-2">
                                    <mat-checkbox color="primary" (change)="onCheckOmeprazole($event)"
                                        disabled="{{disablebtn}}" [checked]="radioStatus">Omeprazole
                                    </mat-checkbox>
                                </div>
                                <fieldset [disabled]='disabledOmeprazole'>
                                    <div style="margin-left: 25px;">
                                        <p>Prescription date <b>{{dateTime_ | date:'shortDate'}}</b> today</p>
                                        <div class="row" style="margin: 0px;">
                                            <p
                                                style="margin-top: auto;margin-bottom: auto;padding-right: 3px;padding: 3px;">
                                                Start date
                                            </p>
                                            <mat-form-field appearance="outline" style="width: 160px;">
                                                <!-- <mat-label>Choose a date</mat-label> -->
                                                <input matInput formControlName="OmeprazoleStartDate"
                                                    [matDatepicker]="pickerOmeprazole">
                                                <mat-datepicker-toggle matSuffix [for]="pickerOmeprazole">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerOmeprazole></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="margin-left: 25px;">
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;"> frequency
                                            </p>

                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput formControlName="OmeprazoleMG">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;"> mg/day x
                                            </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline"
                                                    style="width: 90px;">
                                                    <!-- <mat-label>Note </mat-label> -->
                                                    <input type="number" matInput formControlName="Omeprazoledays">
                                                </mat-form-field>
                                            </div>
                                            <p style="margin-top: auto;margin-bottom: auto;padding: 3px;"> days </p>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note
                                                : </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Note </mat-label>
                                                    <input matInput placeholder="note" formControlName="OmeprazoleNote">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr>
                                <div class="mt-2">
                                    <mat-checkbox color="primary" (change)="onCheckOxygen($event)"
                                        disabled="{{disablebtn}}" [checked]="radioStatus">
                                        Oxygen</mat-checkbox>
                                </div>
                                <fieldset [disabled]='disabledOxygen'>
                                    <div class="mt-2">
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note
                                                : </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Note </mat-label>
                                                    <input matInput placeholder="note" formControlName="OxygenNote">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr>
                                <div class="mt-2">
                                    <mat-checkbox color="primary" (change)="onCheckOthers($event)"
                                        disabled="{{disablebtn}}" [checked]="radioStatus">
                                        Others</mat-checkbox>
                                </div>
                                <fieldset [disabled]='disabledOthers'>
                                    <div class="mt-2">
                                        <div class="row" style="margin: 0px;">
                                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note
                                                : </p>
                                            <div style="font-size: 12px">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label>Note </mat-label>
                                                    <input matInput placeholder="note" formControlName="OthersNote">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <hr>
                            </form>

                        </div>
                    </mat-tab>
                    <mat-tab label="Medical Thai Prescribtion ">
                        <div class="row text-center" style="padding-top: 15px;padding-left: 15px;padding-right: 15px;">
                            <div class="col-6">
                                <button mat-raised-button color="primary" disabled="{{disablebtn}}"
                                    style="margin-right: 5px;margin-left: auto;margin-top: 5px;margin-bottom: 5px; width: -webkit-fill-available;"
                                    (click)="onSubmitDispenseMedicineTH()">Submit Order</button>
                            </div>
                            <div class="col-6">
                                <button mat-raised-button color="primary"
                                    routerLink="/covidDispenseMedicineList/{{getIdUser}}"
                                    style="margin-right: 5px;margin-left: auto;margin-top: 5px;margin-bottom: 5px; width: -webkit-fill-available; background-color: indigo;color: white;">Prescription
                                    History</button>
                            </div>
                        </div>
                        <div style="padding: 20px;max-height: 653px; overflow: auto;">
                            <form [formGroup]="dispenseMedicineTH">
                                <mat-checkbox color="primary" (change)="onCheckMptStatiaCz($event)"
                                    disabled="{{disablebtn}}">STATIA C Z</mat-checkbox>
                                <p>บำรุงตับบำรุงระบบภูมิคุ้มกันร่างกายและ ขับเชื้อโรคออกจากร่างกาย</p>
                                <fieldset [disabled]="selectMedicineStatiaCZNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา STATIA C Z</mat-label>
                                        <textarea matInput formControlName="medicineStatiaCZNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>
                                <mat-checkbox color="primary" (change)="onCheckMptStatia($event)"
                                    disabled="{{disablebtn}}">STATIA</mat-checkbox>
                                <p>ขับเสมหะออกจากปอด เสริมภูมิคุ้มกันบรรเทาอาการไอปวดเมื่อยร่างกาย</p>
                                <fieldset [disabled]="selectMedicineStatiaNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา STATIA</mat-label>
                                        <textarea matInput formControlName="medicineStatiaNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>
                                <mat-checkbox color="primary" (change)="onCheckMpt5roots($event)"
                                    disabled="{{disablebtn}}">5 ราก</mat-checkbox>
                                <p>กระทุ้งเวลาที่เกาะตามอวัยวะ ลดไข้ต้านการอักเสบ</p>
                                <fieldset [disabled]="selectMedicine5rootsNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา 5 ราก</mat-label>
                                        <textarea matInput formControlName="medicine5rootsNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>

                                <mat-checkbox color="primary" (change)="onCheckMptGreenMedicine($event)"
                                    disabled="{{disablebtn}}">ยาเขียว</mat-checkbox>
                                <p>บรรเทาอาการไข้ร้อนในกระหายน้ำ(ช่วยกระทุ้งไวรัสและขับออกแบบอ่อนๆ)</p>
                                <fieldset [disabled]="selectMedicineGreenMedicineNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา ยาเขียว</mat-label>
                                        <textarea matInput formControlName="medicineGreenMedicineNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>

                                <mat-checkbox color="primary" (change)="onCheckMptAummahiva($event)"
                                    disabled="{{disablebtn}}">ยาอํามฤควาที</mat-checkbox>
                                <p>ขับเสมหะบรรเทาอาการไอ</p>
                                <fieldset [disabled]="selectMedicineAummahivaNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา ยาอํามฤควาที</mat-label>
                                        <textarea matInput formControlName="medicineAummahivaNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>

                                <mat-checkbox color="primary" (change)="onCheckMptPrasapoyai($event)"
                                    disabled="{{disablebtn}}">ยาประสะเปราะใหญ่</mat-checkbox>
                                <p>ลดอาการจามน้ำมูกไหล</p>
                                <fieldset [disabled]="selectMedicinePrasapoyaiNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา ยาประสะเปราะใหญ่</mat-label>
                                        <textarea matInput formControlName="medicinePrasapoyaiNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>

                                <mat-checkbox color="primary" (change)="onCheckMptJanlelar($event)"
                                    disabled="{{disablebtn}}">ยาจันทน์ลีลา</mat-checkbox>
                                <p>แก้ไข้ตัวร้อน</p>
                                <fieldset [disabled]="selectMedicineJanlelarNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา ยาจันทน์ลีลา</mat-label>
                                        <textarea matInput formControlName="medicineJanlelarNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>

                                <mat-checkbox color="primary" (change)="onCheckMptHomyen($event)"
                                    disabled="{{disablebtn}}">ยาหอมเย็น</mat-checkbox>
                                <fieldset [disabled]="selectMedicineHomyenNote">
                                    <p>บำรุงหัวใจ</p>
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา ยาหอมเย็น</mat-label>
                                        <textarea matInput formControlName="medicineHomyenNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>

                                <mat-checkbox color="primary" (change)="onCheckMptHomron($event)"
                                    disabled="{{disablebtn}}">ยาหอมร้อน</mat-checkbox>
                                <p>กระตุ้นประสาท</p>
                                <fieldset [disabled]="selectMedicineHomronNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา ยาหอมร้อน</mat-label>
                                        <textarea matInput formControlName="medicineHomronNote"></textarea>
                                    </mat-form-field>
                                </fieldset>
                                <hr>

                                <mat-checkbox color="primary" (change)="onCheckMptFatalaijon($event)"
                                    disabled="{{disablebtn}}">ฟ้าทลายโจร</mat-checkbox>
                                <p>เจ็บคอ</p>
                                <fieldset [disabled]="selectMedicineFatalaijonNote">
                                    <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                        <mat-label>รายละเอียดจ่ายยา ฟ้าทลายโจร</mat-label>
                                        <textarea matInput formControlName="medicineFatalaijonNote"></textarea>
                                    </mat-form-field>
                                </fieldset>

                            </form>
                        </div>

                    </mat-tab>
                </mat-tab-group>

            </div>
        </div>

        <div class="col-lg-12 col-xl-4" style="padding-left: 8px;padding-right: 8px;">
            <!-- Box Comment -->
            <div class="card card-widget mat-elevation-z8">
                <div class="card-header">
                    <div class="user-block">
                        <span><b>Comment.</b></span>
                    </div>
                    <div class="card-tools">
                        <mat-checkbox color="primary" (change)="onCheckAlertAdminComment($event)"
                            disabled="{{disablebtn}}" style="margin-right: 20px;" [checked]="radioStatus">
                            Alert Admin</mat-checkbox>
                    </div>
                </div>
                <form [formGroup]="commentForm" (ngSubmit)="addCommentUserCovid19()" style="display: contents;">
                    <mat-form-field class="example-full-width" appearance="outline"
                        style="padding-left: 15px;padding-right: 15px;">
                        <mat-label>Username</mat-label>
                        <input matInput placeholder="Ex. Pizza" formControlName="commentUserDoctor">
                        <mat-hint *ngIf="nameDoctor == true" style="color: #d00d0d;">Please enter a name</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="outline"
                        style="padding-left: 15px;padding-right: 15px;padding-bottom: 8px;">
                        <mat-label>Leave a comment</mat-label>
                        <textarea matInput placeholder="Press enter to post comment"
                            formControlName="commentUserDetail"></textarea>
                        <mat-hint *ngIf="commentDoctor == true" style="color: #d00d0d;">Please enter a comment
                        </mat-hint>
                    </mat-form-field>
                    <button mat-raised-button color="primary" disabled="{{disablebtn}}"
                        style="margin-left: 15px;margin-right: 15px;margin-bottom: 12px;"><i
                            class="far fa-comments"></i>
                        Comment</button>
                </form>
                <div class="card-footer card-comments card-comment-doctor">
                    <div class="card-comment" *ngFor="let itemComment of dataCommentDoctorPatient">
                        <div class="comment-text" style="margin: 0px;">
                            <span class="username">
                                Doctor's name : <b>{{itemComment.dptDoctorName}}</b>
                                <span class="text-muted float-right">{{itemComment.dptDateTime |
                                    date:'medium'}}</span>
                            </span><!-- /.username -->
                            <b>Note : </b> {{itemComment.dptDetail}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</section>
