import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../../../../core/services/service.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
    selector: 'edit-vital-sign.component',
    templateUrl: './edit-vital-sign.component.html',
})
export class DialogEditVitalSign {
    editVitalSignForm!: FormGroup;
    constructor(
        private ServiceService: ServiceService,
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<DialogEditVitalSign>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        // console.log(data.dataEdit1);
        // console.log(data.dataEdit2);
        this.editVitalSignForm = this.formBuilder.group({
            EditData1: data.dataEdit1,
            EditData2: data.dataEdit2
        });
    }

    onClickUpdateDataVitalSign(): void {
        
        var dataUpdate: any
        if (this.data.nameEdit == "T") {
            dataUpdate = {
                CId: this.data.iddataEdit,
                CTemp: this.editVitalSignForm.value.EditData1
            }
        } else if (this.data.nameEdit == "BP") {
            dataUpdate = {
                CId: this.data.iddataEdit,
                CPressureHigh: this.editVitalSignForm.value.EditData1,
                CPressureLow: this.editVitalSignForm.value.EditData2,
            }
        } else if (this.data.nameEdit == "P") {
            dataUpdate = {
                CId: this.data.iddataEdit,
                CHeartrate: this.editVitalSignForm.value.EditData1
            }
        } else if (this.data.nameEdit == "SpO2 At Rest") {
            dataUpdate = {
                CId: this.data.iddataEdit,
                CSpo2: this.editVitalSignForm.value.EditData1
            }
        } else if (this.data.nameEdit == "SpO2 After Ex") {
            dataUpdate = {
                CId: this.data.iddataEdit,
                CSpo2AfterExercise: this.editVitalSignForm.value.EditData1
            }
        } else if (this.data.nameEdit == "SpO2 On O2") {
            dataUpdate = {
                CId: this.data.iddataEdit,
                CSpo2Ventilator: this.editVitalSignForm.value.EditData1
            }
        }
        this.ServiceService.UpdateVitalSign_(dataUpdate).then(resUpdate => {
            // console.log(resUpdate);
            var dataResult = {
                data1: this.editVitalSignForm.value.EditData1,
                data2: this.editVitalSignForm.value.EditData2,
            }
            this.dialogRef.close(dataResult);
        });
    }

}