import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { DialogEditProfileOperator } from './edit-operator-profile.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-operators-profile',
  templateUrl: './operators-profile.component.html',
  styleUrls: ['./operators-profile.component.scss'],
})
export class OperatorsProfileComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  idOperatorAdmin: any;
  dataDetailAdmin: any;
  dataHistoryJobs: any = [];
  dataHistoryJobsTwo: any = [];
  CountSosOperator: any;
  CountWorkingJobOperator: any;
  CountTodayJob: any;
  loadData: boolean = true;
  loadDataSuccess: boolean = false;
  resultDataDoctorMall: any;
  resultDataFavorite: any = [];
  resultDataNoFavorite: any = [];
  resultDataBackUp: any = [];
  selected = '20';
  selectedFavorite = 'allFavorite';
  status: string = 'all';
  loadDataUser: boolean = true;
  tableShow: boolean = false;
  cardShow: boolean = false;
  noDataInformation: boolean = false;
  favorite: boolean = false;
  noFavorite: boolean = false;
  allFavorite: boolean = true;
  showNote: number = 0;
  searchOrigin: any = [];
  searchValue: any;
  selectCountPage: string = "20";
  noData: boolean = false;
  opCount: number = 0;
  opCountTwo: number = 0;
  roleAdmin: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    public dialog: MatDialog
  ) {
    this.roleAdmin = localStorage.getItem("currentRole");
    this.idOperatorAdmin =
      this.activatedRoute.snapshot.paramMap.get('idOperatorAdmin');
    this.loadDataOperators(this.idOperatorAdmin);
  }

  ngOnInit(): void {
    this.realCountJob(this.idOperatorAdmin);
    this.loadJobEndHistoryOperator(this.idOperatorAdmin, 'end', this.selectCountPage);
    this.loadDataDoctorMall(
      'StartTreat',
      this.status,
      this.selected,
      this.idOperatorAdmin
    );
  }

  loadDataOperators(idOperatorAdmin: string) {
    var resAdminGetOperator: any;
    this.ServiceService.GetAdminGetOperator_(idOperatorAdmin).then(
      (dataAdminGetOperator) => {
        resAdminGetOperator = dataAdminGetOperator;
        // console.log("toda !!!!!! res >> ", resAdminGetOperator);
        this.dataDetailAdmin = resAdminGetOperator;
      }
    );
  }

  loadJobEndHistoryOperator(idOperatorAdmin: string, status: string, limit: string) {
    var resIdOperator: any;
    var dataEndComment: any;
    var dataEndJob: any;
    this.ServiceService.GetIdOperatorByIdOperatorAdmin_(idOperatorAdmin).then(
      (dataIdOperator) => {
        resIdOperator = dataIdOperator;
        var idOperator = resIdOperator[0].operatorId;
        this.loadCountSosOperator(idOperator, 'start', this.selectCountPage);
        this.loadCountWorkingJobOperator(idOperator, 'work', this.selectCountPage);
        this.GetJobTodayOperator(idOperator);

        this.ServiceService.GetJobEndHistoryOperator_(idOperator, status, limit).then(
          async (dataEndj) => {
            dataEndJob = dataEndj;
            if (dataEndJob.length > 0) {
              for (let i = 0; i < dataEndJob.length; i++) {
                var objectId = dataEndJob[i].objectId.split(',');
                await this.ServiceService.GetLogSos_(objectId[0]).then(
                  (dataLogSos) => {
                    var newID = this.padLeadingZeros(dataEndJob[i].ojobId);
                    dataEndJob[i].ojobId = newID;

                    if (dataEndJob[i].userName != null) {
                      this.dataHistoryJobs.push({
                        dataEndJob: dataEndJob[i],
                        typeSos: dataLogSos,
                      });
                    } else {
                      this.ServiceService.GetDeviceBySerial_(
                        dataEndJob[i].ojobImei
                      ).then((dataDevice) => {
                        // console.log("dataDevice ",dataDevice);
                        for (let j = 0; j < dataDevice.length; j++) {
                          if (dataDevice[j].devicePrimary) {
                            dataEndJob[i].userName = dataDevice[j].deviceName;
                            dataEndJob[i].userPic = dataDevice[j].devicePic;
                            break;
                          }
                        }
                        this.dataHistoryJobs.push({
                          dataEndJob: dataEndJob[i],
                          typeSos: dataLogSos,
                        });
                      });
                    }

                    if (i == dataEndJob.length - 1) {
                      this.loadData = false;
                      this.loadDataSuccess = true;
                      this.opCount = this.dataHistoryJobs.length;
                      if (limit == 'all') {
                        this.opCountTwo = this.dataHistoryJobs.length;
                      }
                    }
                  }
                );
              }
              this.searchOrigin = this.dataHistoryJobs;
              this.noData = false;
            } else {
              this.loadData = false;
              this.loadDataSuccess = true;
              this.noData = true;
            }
          }
        );
      }
    );
  }

  realCountJob(idOperatorAdmin: string) {
    var resIdOperator: any;
    var dataEndComment: any;
    var dataEndJob: any;
    this.ServiceService.GetIdOperatorByIdOperatorAdmin_(idOperatorAdmin).then(
      (dataIdOperator) => {
        resIdOperator = dataIdOperator;
        var idOperator = resIdOperator[0].operatorId;
        this.ServiceService.GetJobEndHistoryOperator_(idOperator, 'end', 'all').then(
          async (dataEndj) => {
            dataEndJob = dataEndj;
            if (dataEndJob.length > 0) {
              for (let i = 0; i < dataEndJob.length; i++) {
                var objectId = dataEndJob[i].objectId.split(',');
                await this.ServiceService.GetLogSos_(objectId[0]).then(
                  (dataLogSos) => {
                    var newID = this.padLeadingZeros(dataEndJob[i].ojobId);
                    dataEndJob[i].ojobId = newID;

                    if (dataEndJob[i].userName != null) {
                      this.dataHistoryJobsTwo.push({
                        dataEndJob: dataEndJob[i],
                        typeSos: dataLogSos,
                      });
                    } else {
                      this.ServiceService.GetDeviceBySerial_(
                        dataEndJob[i].ojobImei
                      ).then((dataDevice) => {
                        // console.log("dataDevice ",dataDevice);
                        for (let j = 0; j < dataDevice.length; j++) {
                          if (dataDevice[j].devicePrimary) {
                            dataEndJob[i].userName = dataDevice[j].deviceName;
                            dataEndJob[i].userPic = dataDevice[j].devicePic;
                            break;
                          }
                        }
                        this.dataHistoryJobsTwo.push({
                          dataEndJob: dataEndJob[i],
                          typeSos: dataLogSos,
                        });
                      });
                    }

                    if (i == dataEndJob.length - 1) {
                      this.opCountTwo = this.dataHistoryJobsTwo.length;
                    }
                  }
                );
              }
            }
          }
        );
      }
    );
  }

  padLeadingZeros(num: Number) {
    var s = num + '';
    while (s.length < 8) s = '0' + s;
    return s;
  }

  loadCountSosOperator(idOperator: string, status: string, limit: string) {
    var dataEndJob: any;
    this.ServiceService.GetJobEndHistoryOperator_(idOperator, status, limit).then(
      (dataEndJ) => {
        dataEndJob = dataEndJ;
        this.CountSosOperator = dataEndJob;
      }
    );
  }

  loadCountWorkingJobOperator(idOperator: string, status: string, limit: string) {
    var dataWorking: any;
    this.ServiceService.GetJobEndHistoryOperator_(idOperator, status, limit).then(
      (dataWork) => {
        dataWorking = dataWork;
        this.CountWorkingJobOperator = dataWorking;
      }
    );
  }

  GetJobTodayOperator(idOperator: string) {
    var dataTodayJob: any;
    this.ServiceService.GetJobTodayOperator_(idOperator).then(
      (data_TodayJob) => {
        dataTodayJob = data_TodayJob;
        this.CountTodayJob = dataTodayJob;
      }
    );
  }

  openDialogEditOperator(): void {
    // console.log("idOperator ", idOperator);

    const dialogRef = this.dialog.open(DialogEditProfileOperator, {
      data: { idOperatorAdmin: this.idOperatorAdmin },
      // data: { idSubDistrict: this.getIdSubDistrict, idProvince: this.getAdminId, parrent: this.getParrent, role: this.getRole, nameProvince: this.getNameProvince }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.dataDetailAdmin[0].operatorUsername = result[0].Operator_Username;
        this.dataDetailAdmin[0].operatorFirstname =
          result[0].Operator_Firstname;
        this.dataDetailAdmin[0].operatorLastname = result[0].Operator_Lastname;
        this.dataDetailAdmin[0].operatorEmail = result[0].Operator_Email;
        this.dataDetailAdmin[0].operatorDateofbirth =
          result[0].Operator_Dateofbirth;
        this.dataDetailAdmin[0].Operator_Gender = result[0].operatorGender;
        if (result[0].Operator_Pic != null) {
          this.dataDetailAdmin[0].operatorPic = result[0].Operator_Pic;
        }
        this.dataDetailAdmin[0].operatorAddress = result[0].Operator_Address;
        this.dataDetailAdmin[0].operatorSubdistrict =
          result[0].Operator_Subdistrict;
        this.dataDetailAdmin[0].operatorDistrict = result[0].Operator_District;
        this.dataDetailAdmin[0].operatorProvince = result[0].Operator_Province;
        this.dataDetailAdmin[0].operatorPostalcode =
          result[0].Operator_Postalcode;
        this.dataDetailAdmin[0].operatorPhone = result[0].Operator_Phone;
        this.dataDetailAdmin[0].operatorAge = result[0].Operator_Age;
      }
    });
  }

  onChangeStatus(status: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;

    this.resultDataDoctorMall = [];
    if (status == 'red') {
      this.status = 'Red';
    } else if (status == 'yellow') {
      this.status = 'Yellow';
    } else if (status == 'green') {
      this.status = 'Green';
    } else {
      this.status = 'all';
    }
    this.loadDataDoctorMall(
      'StartTreat',
      this.status,
      this.selected,
      this.idOperatorAdmin
    );
  }

  onChangeFavorite(event: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;
    // console.log(event);
    this.resultDataDoctorMall = [];

    if (event == 'noFavorite') {
      this.resultDataDoctorMall = this.resultDataNoFavorite;
      this.favorite = false;
      this.noFavorite = true;
      this.allFavorite = false;
    } else if (event == 'favorite') {
      this.resultDataDoctorMall = this.resultDataFavorite;
      this.favorite = true;
      this.noFavorite = false;
      this.allFavorite = false;
    } else {
      this.resultDataDoctorMall = this.resultDataBackUp;
      this.favorite = false;
      this.noFavorite = false;
      this.allFavorite = true;
    }

    if (this.resultDataDoctorMall.length != 0) {
      setTimeout(() => {
        this.noDataInformation = false;
        this.loadDataUser = false;
        this.tableShow = true;
        this.cardShow = false;
      }, 2000);
    } else {
      setTimeout(() => {
        this.noDataInformation = true;
        this.loadDataUser = false;
        this.tableShow = true;
        this.cardShow = false;
      }, 2000);
    }
  }

  loadDataDoctorMall(
    status: string,
    codivStatus: string,
    countLists: string,
    operatorAdminID: string
  ) {
    this.loadData_(status, codivStatus, countLists, operatorAdminID).then(
      (resultDaTa) => {
        this.resultDataDoctorMall = [];
        this.resultDataBackUp = [];
        this.resultDataFavorite = [];
        this.resultDataNoFavorite = [];
        // console.log("resDoctorMall ", resultDaTa);
        if (resultDaTa != false) {
          for (let i = 0; i < resultDaTa.length; i++) {
            if (resultDaTa[i].dpFavorite == true) {
              this.resultDataFavorite.push(resultDaTa[i]);
            } else {
              this.resultDataNoFavorite.push(resultDaTa[i]);
            }
          }
          if (this.favorite == true) {
            this.resultDataDoctorMall = this.resultDataFavorite;
          } else if (this.noFavorite == true) {
            this.resultDataDoctorMall = this.resultDataNoFavorite;
          } else if (this.allFavorite == true) {
            this.resultDataDoctorMall = resultDaTa;
          }
          this.resultDataBackUp = resultDaTa;
          setTimeout(() => {
            this.noDataInformation = false;
            this.loadDataUser = false;
            this.tableShow = true;
            this.cardShow = false;
          }, 2000);
        } else {
          setTimeout(() => {
            this.noDataInformation = true;
            this.loadDataUser = false;
            this.tableShow = true;
            this.cardShow = false;
          }, 2000);
        }
      }
    );
  }

  async loadData_(
    status: string,
    codivStatus: string,
    countLists: string,
    operatorAdminID: string
  ) {
    return new Promise<any>(async (resolve, rejects) => {
      var dataDoctorMall_: any;
      await this.ServiceService.GetDataDoctorMallList_(
        status,
        codivStatus,
        countLists,
        operatorAdminID
      ).then((resDoctorMall) => {
        dataDoctorMall_ = resDoctorMall;
        if (dataDoctorMall_.length != 0) {
          resolve(dataDoctorMall_);
        } else {
          resolve(false);
        }
      });
    });
  }

  openNote(ojobTaskId: number) {
    this.showNote = ojobTaskId;
  }

  filterName(userName: any) {
    this.dataHistoryJobs = this.searchOrigin;
    this.dataHistoryJobs = this.dataHistoryJobs.filter((usn: any) => usn.dataEndJob.userName != null && usn.dataEndJob.userName.toLowerCase().includes(userName)), debounceTime(1000), distinctUntilChanged();
    if (this.dataHistoryJobs.length <= 0) {
      this.noData = true;
    } else {
      this.noData = false;
    }
  }

  onChangeCountPage(count: any) {
    this.opCount = 0;
    this.selectCountPage = count;
    this.searchValue = "";
    this.dataHistoryJobs = [];
    this.loadJobEndHistoryOperator(this.idOperatorAdmin, 'end', this.selectCountPage);
  }
}
