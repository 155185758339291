<h2 mat-dialog-title>รายละเอียด</h2>
<mat-dialog-content class="mat-typography">
    <div *ngIf="medPresTHDetail != null">
        <div class="card" *ngIf="AlertType == 'MedPresTH'">
            <div class="card-header">
                <div class="row" style="display: flex;justify-content: space-between;">
                    <div class="mt-auto mb-auto">
                        <span class="badge badge-success"> การจ่ายยา </span> ผู้ป่วย : <b>{{userName}}</b>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>สถานะ</mat-label>
                            <mat-select [(value)]="selectedStatusDispenseMedicine" (selectionChange)="onChangeStatusDispenseMedicine(selectedStatusDispenseMedicine)">
                                <mat-option value="waiting">กำลังจัดเตรียมยา</mat-option>
                                <mat-option value="accept">จ่ายยา</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card card-widget mat-elevation-z8">
                <div class="card-header">
                    <div class="user-block">
                        <span><b>รายการยา</b></span>
                    </div>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-footer card-comments card-comment-doctor">
                    <div style="padding: 20px;">
                        <div *ngIf="selectMptStatiaCz == true">
                            <p style="margin: 0px;"><b>STATIA C Z</b></p>
                            <p style="margin: 0px;">บำรุงตับบำรุงระบบภูมิคุ้มกันร่างกายและ ขับเชื้อโรคออกจากร่างกาย</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptStatiaCzNote != null && this.medPresTHDetail.medPres.mptStatiaCzNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptStatiaCzNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptStatiaCzNote != null || this.medPresTHDetail.medPres.mptStatiaCzNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptStatia == true">
                            <p style="margin: 0px;"><b>STATIA</b></p>
                            <p style="margin: 0px;">ขับเสมหะออกจากปอด เสริมภูมิคุ้มกันบรรเทาอาการไอปวดเมื่อยร่างกาย</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptStatiaNote != null && this.medPresTHDetail.medPres.mptStatiaNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptStatiaNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptStatiaNote != null || this.medPresTHDetail.medPres.mptStatiaNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMpt5roots == true">
                            <p style="margin: 0px;"><b>5 ราก</b></p>
                            <p style="margin: 0px;">กระทงเวลาที่เกาะตามอวัยวะ ลดไข้ต้านการอักเสบ</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mpt5rootsNote != null && this.medPresTHDetail.medPres.mpt5rootsNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mpt5rootsNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mpt5rootsNote != null || this.medPresTHDetail.medPres.mpt5rootsNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptGreenMedicine == true">
                            <p style="margin: 0px;"><b>ยาเขียว</b></p>
                            <p style="margin: 0px;">บรรเทาอาการไข้ร้อนในกระหายน้ำ(ช่วยกระทุ้งไวรัสและขับออกแบบอ่อนๆ)</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptGreenMedicineNote != null && this.medPresTHDetail.medPres.mptGreenMedicineNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptGreenMedicineNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptGreenMedicineNote != null || this.medPresTHDetail.medPres.mptGreenMedicineNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptAummahiva == true">
                            <p style="margin: 0px;"><b>ยาอํามฤควาที</b></p>
                            <p style="margin: 0px;">ขับเสมหะบรรเทาอาการไอ</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptAummahivaNote != null && this.medPresTHDetail.medPres.mptAummahivaNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptAummahivaNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptAummahivaNote != null || this.medPresTHDetail.medPres.mptAummahivaNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptPrasapoyai == true">
                            <p style="margin: 0px;"><b>ยาประสะเปราะใหญ่</b></p>
                            <p style="margin: 0px;">ลดอาการจามน้ำมูกไหล</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptPrasapoyaiNote != null && this.medPresTHDetail.medPres.mptPrasapoyaiNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptPrasapoyaiNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptPrasapoyaiNote != null || this.medPresTHDetail.medPres.mptPrasapoyaiNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptJanlelar == true">
                            <p style="margin: 0px;"><b>ยาจันทน์ลีลา</b></p>
                            <p style="margin: 0px;">แก้ไข้ตัวร้อน</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptJanlelarNote != null && this.medPresTHDetail.medPres.mptJanlelarNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptJanlelarNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptJanlelarNote != null || this.medPresTHDetail.medPres.mptJanlelarNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptHomyen == true">
                            <p style="margin: 0px;"><b>ยาหอมเย็น</b></p>
                            <p style="margin: 0px;">บำรุงหัวใจ</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptHomyenNote != null && this.medPresTHDetail.medPres.mptHomyenNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptHomyenNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptHomyenNote != null || this.medPresTHDetail.medPres.mptHomyenNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptHomron == true">
                            <p style="margin: 0px;"><b>ยาหอมร้อน</b></p>
                            <p style="margin: 0px;">กระตุ้นประสาท</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptHomronNote != null && this.medPresTHDetail.medPres.mptHomronNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptHomronNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptHomronNote != null || this.medPresTHDetail.medPres.mptHomronNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                        <div *ngIf="selectMptFatalaijon == true">
                            <p style="margin: 0px;"><b>ฟ้าทลายโจร</b></p>
                            <p style="margin: 0px;">เจ็บคอ</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptFatalaijonNote != null && this.medPresTHDetail.medPres.mptFatalaijonNote != ''"><b>รายละเอียดจ่ายยา : </b>{{this.medPresTHDetail.medPres.mptFatalaijonNote}}</p>
                            <p *ngIf="this.medPresTHDetail.medPres.mptFatalaijonNote != null || this.medPresTHDetail.medPres.mptFatalaijonNote != ''"><b>รายละเอียดจ่ายยา : </b>-</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="medPresDetail != null">
        <div class="card" *ngIf="AlertType == 'MedPres'">
            <div class="card-header">
                <div class="row" style="display: flex;justify-content: space-between;">
                    <div class="mt-auto mb-auto">
                        <span class="badge badge-success"> การจ่ายยา </span> ผู้ป่วย : <b>{{userName}}</b>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>สถานะ</mat-label>
                            <mat-select [(value)]="selectedStatusDispenseMedicine" (selectionChange)="onChangeStatusDispenseMedicine(selectedStatusDispenseMedicine)">
                                <mat-option value="waiting">กำลังจัดเตรียมยา</mat-option>
                                <mat-option value="accept">จ่ายยา</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card card-widget mat-elevation-z8">
                <div class="card-header">
                    <div class="user-block">
                        <span><b>รายการยา</b></span>
                    </div>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-footer card-comments card-comment-doctor">
                    <form [formGroup]="drugDateForm">
                        <div class="row" style="margin-left: 2px;" *ngIf="medPresDetail.medPres.mpFavi == true || medPresDetail.medPres.mpDexa == true || medPresDetail.medPres.mpPrednisolone == true || medPresDetail.medPres.mpOmeprazole == true">
                            <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;"><i class="fas fa-calendar-alt"></i><b> Start date</b></p>
                            <mat-form-field appearance="outline" style="width: 160px;">
                                <mat-label>Choose a date</mat-label>
                                <input matInput [matDatepicker]="pickerStartDate"
                                    formControlName="nameStartdate" (dateChange)="onSelectedStartDate()">
                                <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerStartDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div id="Favi" *ngIf="medPresDetail.medPres.mpFavi == true">
                            <div>
                                <i class="fas fa-check"></i> <b color="primary"> Favipiravir </b>
                            </div>
                            <div style="margin-left: 25px;">
                                <p> Prescription date <b> {{medPresDetail.medPres.mpFaviPrecriptionDate| date:'shortDate'}} </b>
                                </p>
                            </div>
                            <div id="Favi_F5D" *ngIf="medPresDetail.medPres.mpFaviDay == 'f5d'">
                                <div>
                                    <i class="fas fa-check"> </i> <b color="primary"> First 5 days </b>
                                </div>
                                <div style="margin-left: 25px;">
                                    <div class="row" style="margin: 0px;">
                                        <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Start</p>
                                        <mat-form-field appearance="outline" style="width: 160px;">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="pickerFirst5daysStart" (dateChange)="onSelectedFirst5daysStart()"
                                                formControlName="nameFavipiravirFirst5daysStart">
                                            <mat-datepicker-toggle matSuffix [for]="pickerFirst5daysStart"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFirst5daysStart></mat-datepicker>
                                        </mat-form-field>
                                        <p style="margin-top: auto;margin-bottom: auto;padding: 5px;">to</p>
                                        <mat-form-field appearance="outline" style="width: 160px;">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="pickerFirst5daysEnd"
                                                formControlName="nameFavipiravirFirst5daysEnd">
                                            <mat-datepicker-toggle matSuffix [for]="pickerFirst5daysEnd"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFirst5daysEnd></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <b>
                                            <p *ngIf="medPresDetail.medPres.mpFaviDayWeight == 'W>=90'">W >= 90 200mg/tab 12
                                                tab D1 | 5 tab เช้าเย็น D2-5</p>
                                            <p *ngIf="medPresDetail.medPres.mpFaviDayWeight == 'W<90'">W < 90 200mg/tab 9
                                                    tab D1 | 4 tab เช้าเย็น D2-5</p>
                                        </b>
    
                                    </div>
                                </div>
                            </div>
                            <div id="Favi_N5D" *ngIf="medPresDetail.medPres.mpFaviDay == 'n5d'">
                                <div class="mt-2">
                                    <i class="fas fa-check"></i> <b color="primary">Next 5 days </b>
                                </div>
                                <div style="margin-left: 25px;">
                                    <div class="row" style="margin: 0px;">
                                        <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Start</p>
    
                                        <mat-form-field appearance="outline" style="width: 160px;">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="pickerNext5daysStart" (dateChange)="onSelectedNext5daysStart()"
                                                formControlName="namePickerNext5daysStart">
                                            <mat-datepicker-toggle matSuffix [for]="pickerNext5daysStart"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerNext5daysStart></mat-datepicker>
                                        </mat-form-field>
    
                                        <p style="margin-top: auto;margin-bottom: auto;padding: 5px;">to</p>
    
                                        <mat-form-field appearance="outline" style="width: 160px;">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="pickerNext5daysEnd"
                                                formControlName="namePickerNext5daysEnd">
                                            <mat-datepicker-toggle matSuffix [for]="pickerNext5daysEnd"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerNext5daysEnd></mat-datepicker>
                                        </mat-form-field>
    
                                    </div>
                                    <div>
                                        <b>
                                            <p *ngIf="medPresDetail.medPres.mpFaviDayWeight == 'W>=90'"> W >= 90 kg: (D1)
                                                200mg 12 tabs bid, (D2-5) 200mg 5 tabs bid.</p>
                                            <p *ngIf="medPresDetail.medPres.mpFaviDayWeight == 'W<90'">W < 90 kg: 200mg/tabs
                                                    9 tab (D1) | 4 tabs เช้าเย็น (D2-5)</p>
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2" *ngIf="medPresDetail.medPres.mpFaviNote != null">
                                <div class="row" style="margin: 0px;">
                                    <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note :
                                        <b>{{medPresDetail.medPres.mpFaviNote}}</b>
                                    </p>
    
    
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div id="Dexa" *ngIf="medPresDetail.medPres.mpDexa == true">
                            <div class="mt-2">
                                <i class="fas fa-check"></i> <b color="primary"> Dexamethasone</b>
                            </div>
                            <div style="margin-left: 25px;">
                                <p>Prescription date <b> {{medPresDetail.medPres.mpDexaPrecriptionDate| date:'shortDate'}} </b>
                                    today</p>
                                <div class="row" style="margin: 0px;">
                                    <!-- <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Start date</p>
                                    <mat-form-field appearance="outline" style="width: 160px;">
                                        <mat-label>Choose a date</mat-label>
                                        <input matInput [matDatepicker]="pickerDexamethasone"
                                            [(ngModel)]="DexamethasoneStartdate" [ngModelOptions]="{standalone: true}">
                                        <mat-datepicker-toggle matSuffix [for]="pickerDexamethasone">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerDexamethasone></mat-datepicker>
                                    </mat-form-field> -->
                                </div>
                            </div>
                            <div style="margin-left: 25px;">
                                <div class="row" style="margin: 0px;">
                                    <p style="margin-top: auto;margin-bottom: auto;"><b> {{medPresDetail.medPres.mpDexaMg }}
                                        </b> mg &nbsp;</p>
                                    <p style="margin-top: auto;margin-bottom: auto;"> frequency <b>
                                            {{medPresDetail.medPres.mpDexaFrequency }} &nbsp;</b></p>
                                    <p style="margin-top: auto;margin-bottom: auto;"> times/day x &nbsp;</p>
                                    <p style="margin-top: auto;margin-bottom: auto;"> <b> {{medPresDetail.medPres.mpDexaDay
                                            }} </b> day </p>
                                </div>
                            </div>
                            <div class="mt-2" *ngIf="medPresDetail.medPres.mpDexaNote != null">
                                <div class="row" style="margin: 0px;">
                                    <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note :
                                        <b>{{medPresDetail.medPres.mpDexaNote}}</b>
                                    </p>
    
                                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Note </mat-label>
                                    <input matInput placeholder="note">
                                </mat-form-field> -->
    
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div id="Presdnisolone" *ngIf="medPresDetail.medPres.mpPrednisolone == true">
                            <div class="mt-2">
                                <i class="fas fa-check"></i> <b color="primary">Prednisolone</b>
                            </div>
                            <div style="margin-left: 25px;">
                                <p>Prescription date <b> {{medPresDetail.medPres.mpPrednisolonePrecriptionDate|
                                        date:'shortDate'}} </b> today</p>
                                <div class="row" style="margin: 0px;">
                                    <!-- <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Start date</p>
                                    <mat-form-field appearance="outline" style="width: 160px;">
                                        <mat-label>Choose a date</mat-label>
                                        <input matInput [matDatepicker]="pickerPrednisolone"
                                            [(ngModel)]="PrednisoloneStartdate" [ngModelOptions]="{standalone: true}">
                                        <mat-datepicker-toggle matSuffix [for]="pickerPrednisolone"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerPrednisolone></mat-datepicker>
                                    </mat-form-field> -->
                                </div>
                            </div>
                            <div style="margin-left: 25px;">
    
                                <div class="row" style="margin: 0px;">
                                    <div class="row" style="margin: 0px;">
                                        <p style="margin-top: auto;margin-bottom: auto;"><b>
                                                {{medPresDetail.medPres.mpPrednisoloneMg }} </b> mg &nbsp;</p>
                                        <p style="margin-top: auto;margin-bottom: auto;"> frequency <b>
                                                {{medPresDetail.medPres.mpPrednisoloneFrequency }} &nbsp;</b></p>
                                        <p style="margin-top: auto;margin-bottom: auto;"> times/day x &nbsp;</p>
                                        <p style="margin-top: auto;margin-bottom: auto;"> <b>
                                                {{medPresDetail.medPres.mpPrednisoloneDay }} </b> day </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2" *ngIf="medPresDetail.medPres.mpPrednisoloneNote != null">
                                <div class="row" style="margin: 0px;">
                                    <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note :
                                        <b>{{medPresDetail.medPres.mpPrednisoloneNote}}</b>
                                    </p>
    
    
    
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div id="Omeprazole" *ngIf="medPresDetail.medPres.mpOmeprazole == true">
                            <div class="mt-2">
                                <i class="fas fa-check"></i> <b color="primary">Omeprazole</b>
                            </div>
                            <div style="margin-left: 25px;">
                                <p>Prescription date <b> {{medPresDetail.medPres.mpOmeprazolePrecriptionDate| date:'shortDate'}}
                                    </b> today</p>
                                <div class="row" style="margin: 0px;">
                                    <!-- <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Start date</p>
                                    <mat-form-field appearance="outline" style="width: 160px;">
                                        <mat-label>Choose a date</mat-label>
                                        <input matInput [matDatepicker]="pickerOmeprazole" [(ngModel)]="OmeprazoleStartDate"
                                            [ngModelOptions]="{standalone: true}">
                                        <mat-datepicker-toggle matSuffix [for]="pickerOmeprazole"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerOmeprazole></mat-datepicker>
                                    </mat-form-field> -->
                                </div>
                            </div>
                            <div style="margin-left: 25px;">
    
                                <div class="row" style="margin: 0px;">
                                    <div class="row" style="margin: 0px;">
                                        <p style="margin-top: auto;margin-bottom: auto;"><b>
                                                {{medPresDetail.medPres.mpOmeprazoleMg }} </b> mg/day x&nbsp;</p>
                                        <p style="margin-top: auto;margin-bottom: auto;"> <b>
                                                {{medPresDetail.medPres.mpOmeprazoleDay }} </b> day </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2" *ngIf="medPresDetail.medPres.mpOmeprazoleNote != null">
                                <div class="row" style="margin: 0px;">
                                    <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note :
                                        <b>{{medPresDetail.medPres.mpOmeprazoleNote}}</b>
                                    </p>
    
                                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Note </mat-label>
                                    <input matInput placeholder="note">
                                </mat-form-field> -->
    
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div id="Oxygen" *ngIf="medPresDetail.medPres.mpOxygen == true">
                            <div class="mt-2">
                                <i class="fas fa-check"></i> <b color="primary">Oxygen</b>
                            </div>
                            <div class="mt-2" *ngIf="medPresDetail.medPres.mpOxygenNote != null">
                                <div class="row" style="margin: 0px;">
                                    <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note :
                                        <b>{{medPresDetail.medPres.mpOxygenNote}}</b>
                                    </p>
    
                                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Note </mat-label>
                                    <input matInput placeholder="note">
                                </mat-form-field> -->
    
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div id="Others" *ngIf="medPresDetail.medPres.mpOthers == true">
                            <div class="mt-2">
                                <i class="fas fa-check"></i> <b color="primary">Others</b>
                            </div>
                            <div class="mt-2" *ngIf="medPresDetail.medPres.mpOthersNote != null">
                                <div class="row" style="margin: 0px;">
                                    <p style="margin-top: auto;margin-bottom: auto;padding-right: 3px;">Note :
                                        <b>{{medPresDetail.medPres.mpOthersNote}}</b>
                                    </p>
    
                                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Note </mat-label>
                                    <input matInput placeholder="note">
                                </mat-form-field> -->
    
                                </div>
                            </div>
                            <hr>
                        </div>
                        <!-- <button mat-raised-button color="primary"
                            style="width: -webkit-fill-available;">Submit</button> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
    

    <div *ngIf="doctorTaskDetail != null">
        <div class="card" *ngIf="AlertType == 'DoctorTask'">
            <div class="card-header">
                <span class="badge badge-warning"> การแสดงความคิดเห็น </span> ผู้ป่วย : <b>{{userName}}</b>
            </div>
            <div class="card-body">
                <blockquote class="blockquote mb-0">
                    <p>แพทย์ : {{doctorTaskDetail.dptDoctorName}}</p>
                    <footer class="blockquote-footer">{{doctorTaskDetail.dptDetail}}</footer>
                </blockquote>
            </div>
            <p class="text-right mr-3">วันที่ : {{doctorTaskDetail.dptDateTime | date:'short'}}</p>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button *ngIf="AlertType == 'DoctorTask'"
        (click)="AcceptDoctorPatientTask(AlertID,true)" cdkFocusInitial>Accept</button>
    <button mat-button *ngIf="AlertType == 'MedPres'" (click)="AcceptMedPres(AlertID,true)"
        cdkFocusInitial>Save</button>
    <button mat-button *ngIf="AlertType == 'MedPresTH'" (click)="AcceptMedTHPres(AlertID,true)"
        cdkFocusInitial>Save</button>
</mat-dialog-actions>