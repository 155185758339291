import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { io } from 'socket.io-client';
import { ServiceService } from '../../../../core/services/service.service';
import { CaaDetailModal } from './modal/caa-detail-modal.component';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-covid-admin-alert',
  templateUrl: './covid-admin-alert.component.html',
  styleUrls: ['./covid-admin-alert.component.scss']
})
export class CovidAdminAlertComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  private socket: any;
  loadDataCodiv19Alert: boolean = true;
  noDataAlert: boolean = false;
  roleAdmin: any;
  idOperatorAdmin: any;
  resultDataLogCodiv19: any = [];
  constructor(
    private ServiceService: ServiceService,
    public dialog: MatDialog
  ) {
    this.socket = io(GlobalConstants.socket, { transports: ['websocket', 'polling', 'flashsocket'] });
    this.roleAdmin = localStorage.getItem("currentRole");
    this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
  }

  ngOnInit(): void {
    if (this.roleAdmin == "Operator") {
      this.loadDataLogCovid19(this.idOperatorAdmin);
    }
  }

  onClickOpenDetail(alertId:any,alertType:any,subID: any,userName:any,index:number) {
    var index_ = index;
    const dialogRef = this.dialog.open(CaaDetailModal, {
      width: '550px',
      data: {
        Type: alertType,
        ID : subID,
        UserName : userName,
        AlertID : alertId
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("this.resultDataLogCodiv19 : ",result);
      if(result != undefined) {
        if(result.type == "dispenseMedicine"){
          if(result.status == true){
            this.resultDataLogCodiv19.splice(index_, 1);
            this.socket.emit('dataAlertPending_Covid19', {msg:"doctor-dispenseMedicine",status:"read",idOperator:this.idOperatorAdmin});
          }else{
            this.socket.emit('dataAlertPending_Covid19', {msg:"doctor-dispenseMedicine",status:"prepareMedicine",idOperator:this.idOperatorAdmin});
          }
        }else{
          if(result.status == true){
            this.resultDataLogCodiv19.splice(index_, 1);
            this.socket.emit('dataAlertPending_Covid19', {msg:"doctor-comment",status:"read",idOperator:this.idOperatorAdmin});
          }
        }
      }

    });

  }

  convertJsonStrToJon(jsonStr:any){
    let json = JSON.parse(jsonStr);
    let strMed = "";
    if(json['MpFavi'])
      strMed = strMed + "Favi ";
    if(json['MpDexa'])
      strMed = strMed + "Dexa ";
    if(json['MpPrednisolone'])
      strMed = strMed + "Prednisolone ";
    if(json['MpOmeprazole'])
      strMed = strMed + "Omeprazole ";
    if(json['Oxygen'])
      strMed = strMed + "Oxygen ";
    if(json['MpOthers'])
      strMed = strMed + "อื่นๆ ";

    return strMed;
  }

  convertMedPresTHJsonStrToJon(jsonStr:any){
    let json = JSON.parse(jsonStr);

    let strMed = "";
    if(json.Mpt5roots == true)
      strMed = strMed + "5 ราก ";
    if(json.MptAummahiva == true)
      strMed = strMed + "ยาอํามฤควาที ";
    if(json.MptFatalaijon == true)
      strMed = strMed + "ฟ้าทลายโจร ";
    if(json.MptGreenMedicine == true)
      strMed = strMed + "ยาเขียว ";
    if(json.MptHomron == true)
      strMed = strMed + "ยาหอมร้อน ";
    if(json.MptHomyen == true)
      strMed = strMed + "ยาหอมเย็น ";
    if(json.MptJanlelar == true)
      strMed = strMed + "ยาจันทน์ลีลา ";
    if(json.MptPrasapoyai == true)
      strMed = strMed + "ยาประสะเปราะใหญ่ ";
    if(json.MptStatia == true)
      strMed = strMed + "STATIA ";
    if(json.MptStatiaCz == true)
      strMed = strMed + "STATIA C Z ";

    return strMed;
  }

  loadDataLogCovid19(idOperatorAdmin: any) {
    this.dataLogCodiv19(idOperatorAdmin).then(resLogCodiv19 => {
      // console.log("resLogCodiv19 new : ", resLogCodiv19);

      if (resLogCodiv19 != false) {
        this.resultDataLogCodiv19 = resLogCodiv19;
        setInterval(() => {
          this.loadDataCodiv19Alert = false;
        }, 2000);
      }else{
        setInterval(() => {
          this.loadDataCodiv19Alert = false;
          this.noDataAlert = true;
        }, 2000);
      }

    });
  }

  dataLogCodiv19(idOperatorAdmin: any) {
    // console.log(idOperatorAdmin);

    var adminCovAlertList: any;
    return new Promise<any>(async (resolve, rejects) => {

      this.ServiceService.GetAdminCovAlert(idOperatorAdmin).then(adminCovAlert => {
        adminCovAlertList = adminCovAlert;
        if (adminCovAlertList.length != 0) {
          resolve(adminCovAlertList);
        } else {
          resolve(false);
        }
      });
    });
  };

}
