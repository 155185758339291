<h2 mat-dialog-title>PersonalHealth</h2>

<div class="col-md-12" *ngIf="dataPersonalHealth == null">
  <div class="mb-2">
    <div style="display: flex;">
      <div class="container rubberBand"
        style="color: #bdbbb7; margin-top: 160px; margin-bottom: 160px; text-align: center;"><i
          class="fas fa-calendar-times" style="font-size: 50px;"></i>
        <p>No Notification.</p>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content style="width: 1100px;">
  <div class="col-12">
    <div class="row">
      <div class="col-6">
        <table class="table" *ngIf="dataPersonalHealth != null">

          <tbody>
            <tr>
              <td style="width: 20%;">โรคประจำตัว</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pCongenitalDisease != null && dataPersonalHealth[0].pCongenitalDisease != ''">
                {{dataPersonalHealth[0].pCongenitalDisease}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pCongenitalDisease == null || dataPersonalHealth[0].pCongenitalDisease == ''">
                -</td>
              <!-- <td style="width: 20%;">ผลข้างเคียง</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pSideEffect != null && dataPersonalHealth[0].pSideEffect != ''">{{dataPersonalHealth[0].pSideEffect}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pSideEffect == null || dataPersonalHealth[0].pSideEffect == ''">-</td> -->
            </tr>
            <tr>
              <td style="width: 20%;">ยาที่กินประจำ</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pRegularMedication != null && dataPersonalHealth[0].pRegularMedication != ''">
                {{dataPersonalHealth[0].pRegularMedication}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pRegularMedication == null || dataPersonalHealth[0].pRegularMedication == ''">
                -</td>
              <!-- <td style="width: 20%;">วันที่ได้รับเชื้อ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pDateInfection != null && dataPersonalHealth[0].pDateInfection != ''">{{dataPersonalHealth[0].pDateInfection | date:'mediumDate'}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pDateInfection == null || dataPersonalHealth[0].pDateInfection == ''">-</td> -->
            </tr>
            <tr>
              <td style="width: 20%;">แพ้ยาอะไร</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAllergicDrug != null && dataPersonalHealth[0].pAllergicDrug != ''">
                {{dataPersonalHealth[0].pAllergicDrug}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAllergicDrug == null || dataPersonalHealth[0].pAllergicDrug == ''">-</td>
              <!-- <td style="width: 20%;">วันที่เริ่มมีอาการ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOnsetDate != null && dataPersonalHealth[0].pOnsetDate != ''">{{dataPersonalHealth[0].pOnsetDate | date:'mediumDate'}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOnsetDate == null || dataPersonalHealth[0].pOnsetDate == ''">-</td> -->
            </tr>
            <tr>
              <td style="width: 20%;">อาการเริ่มต้น</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pInitSymptom != null && dataPersonalHealth[0].pInitSymptom != ''">
                {{dataPersonalHealth[0].pInitSymptom}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pInitSymptom == null || dataPersonalHealth[0].pInitSymptom == ''">-</td>
              <!-- <td style="width: 20%;">โรงพยาบาลต้นสังกัด</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAffHospital != null && dataPersonalHealth[0].pAffHospital != ''">{{dataPersonalHealth[0].pAffHospital}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAffHospital == null || dataPersonalHealth[0].pAffHospital == ''">-</td> -->
            </tr>
            <tr>
              <td style="width: 20%;">ยาที่ทานมาก่อน</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pBeforeMedicine != null && dataPersonalHealth[0].pBeforeMedicine != ''">
                {{dataPersonalHealth[0].pBeforeMedicine}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pBeforeMedicine == null || dataPersonalHealth[0].pBeforeMedicine == ''">-
              </td>
              <!-- <td style="width: 20%;">อายุเด็ก</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgeChild != null && dataPersonalHealth[0].pAgeChild != ''">{{dataPersonalHealth[0].pAgeChild}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgeChild == null || dataPersonalHealth[0].pAgeChild == ''">-</td> -->
            </tr>
            <tr>
              <td style="width: 20%;">อาการปัจจุบัน</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pCurrentSymptiom != null && dataPersonalHealth[0].pCurrentSymptiom != ''">
                {{dataPersonalHealth[0].pCurrentSymptiom}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pCurrentSymptiom == null || dataPersonalHealth[0].pCurrentSymptiom == ''">-
              </td>
              <!-- <td style="width: 20%;">อายุครรภ์</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgePregnant != null && dataPersonalHealth[0].pAgePregnant != ''">{{dataPersonalHealth[0].pAgePregnant}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgePregnant == null || dataPersonalHealth[0].pAgePregnant == ''">-</td> -->
            </tr>
            <tr>
              <td style="width: 20%;">ประจำเดือน</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pMenstruation != null && dataPersonalHealth[0].pMenstruation != ''">
                {{dataPersonalHealth[0].pMenstruation | date:'shortDate'}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pMenstruation == null || dataPersonalHealth[0].pMenstruation == ''">-</td>
              <!-- <td style="width: 20%;">อื่นๆ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOther != null && dataPersonalHealth[0].pOther != ''">{{dataPersonalHealth[0].pOther}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOther == null || dataPersonalHealth[0].pOther == ''">-</td>-->
            </tr>
            <tr>
              <td style="width: 20%;">ครอบครัว</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pCovidFamilyCount != null && dataPersonalHealth[0].pCovidFamilyCount != ''">
                {{dataPersonalHealth[0].pCovidFamilyCount}} คน</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pCovidFamilyCount == null || dataPersonalHealth[0].pCovidFamilyCount == ''">
                -</td>
              <!-- <td style="width: 20%;">อื่นๆ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOther != null && dataPersonalHealth[0].pOther != ''">{{dataPersonalHealth[0].pOther}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOther == null || dataPersonalHealth[0].pOther == ''">-</td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <table class="table" *ngIf="dataPersonalHealth != null">

          <tbody>
            <tr>
              <!-- <td style="width: 20%;">โรคประจำตัว</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCongenitalDisease != null && dataPersonalHealth[0].pCongenitalDisease != ''">{{dataPersonalHealth[0].pCongenitalDisease}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCongenitalDisease == null || dataPersonalHealth[0].pCongenitalDisease == ''">-</td> -->
              <td style="width: 20%;">ผลข้างเคียง</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pSideEffect != null && dataPersonalHealth[0].pSideEffect != ''">
                {{dataPersonalHealth[0].pSideEffect}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pSideEffect == null || dataPersonalHealth[0].pSideEffect == ''">-</td>
            </tr>
            <tr>
              <!-- <td style="width: 20%;">ยาที่กินประจำ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pRegularMedication != null && dataPersonalHealth[0].pRegularMedication != ''">{{dataPersonalHealth[0].pRegularMedication}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pRegularMedication == null || dataPersonalHealth[0].pRegularMedication == ''">-</td> -->
              <td style="width: 20%;">วันที่ได้รับเชื้อ</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pDateInfection != null && dataPersonalHealth[0].pDateInfection != ''">
                {{dayInfection}} , {{dataPersonalHealth[0].pDateInfection | date:'shortDate'}} </td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pDateInfection == null || dataPersonalHealth[0].pDateInfection == ''">-
              </td>
            </tr>
            <tr>
              <!-- <td style="width: 20%;">แพ้ยาอะไร</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAllergicDrug != null && dataPersonalHealth[0].pAllergicDrug != ''">{{dataPersonalHealth[0].pAllergicDrug}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAllergicDrug == null || dataPersonalHealth[0].pAllergicDrug == ''">-</td> -->
              <td style="width: 20%;">วันที่เริ่มมีอาการ</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pOnsetDate != null && dataPersonalHealth[0].pOnsetDate != ''">
                {{dayOnsetDate}} , {{dataPersonalHealth[0].pOnsetDate | date:'shortDate'}} </td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pOnsetDate == null || dataPersonalHealth[0].pOnsetDate == ''">-</td>
            </tr>
            <tr>
              <!-- <td style="width: 20%;">อาการเริ่มต้น</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pInitSymptom != null && dataPersonalHealth[0].pInitSymptom != ''">{{dataPersonalHealth[0].pInitSymptom}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pInitSymptom == null || dataPersonalHealth[0].pInitSymptom == ''">-</td> -->
              <td style="width: 20%;">โรงพยาบาลต้นสังกัด</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAffHospital != null && dataPersonalHealth[0].pAffHospital != ''">
                {{dataPersonalHealth[0].pAffHospital}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAffHospital == null || dataPersonalHealth[0].pAffHospital == ''">-</td>
            </tr>
            <tr>
              <!-- <td style="width: 20%;">ยาที่ทานมาก่อน</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pBeforeMedicine != null && dataPersonalHealth[0].pBeforeMedicine != ''">{{dataPersonalHealth[0].pBeforeMedicine}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pBeforeMedicine == null || dataPersonalHealth[0].pBeforeMedicine == ''">-</td> -->
              <td style="width: 20%;">อายุเด็ก</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAgeChild != null && dataPersonalHealth[0].pAgeChild != ''">
                {{dataPersonalHealth[0].pAgeChild}} เดือน</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAgeChild == null || dataPersonalHealth[0].pAgeChild == ''">-</td>
            </tr>
            <tr>
              <!-- <td style="width: 20%;">อาการปัจจุบัน</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCurrentSymptiom != null && dataPersonalHealth[0].pCurrentSymptiom != ''">{{dataPersonalHealth[0].pCurrentSymptiom}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCurrentSymptiom == null || dataPersonalHealth[0].pCurrentSymptiom == ''">-</td> -->
              <td style="width: 20%;">อายุครรภ์</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAgePregnant != null && dataPersonalHealth[0].pAgePregnant != ''">
                {{dataPersonalHealth[0].pAgePregnant}} เดือน</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pAgePregnant == null || dataPersonalHealth[0].pAgePregnant == ''">-</td>
            </tr>
            <tr>
              <!-- <td style="width: 20%;">ประจำเดือน</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pMenstruation != null && dataPersonalHealth[0].pMenstruation != ''">{{dataPersonalHealth[0].pMenstruation}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pMenstruation == null || dataPersonalHealth[0].pMenstruation == ''">-</td> -->
              <td style="width: 20%;">อื่นๆ</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pOther != null && dataPersonalHealth[0].pOther != ''">
                {{dataPersonalHealth[0].pOther}}</td>
              <td style="width: 40%;"
                *ngIf="dataPersonalHealth[0].pOther == null || dataPersonalHealth[0].pOther == ''">-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <table class="table" *ngIf="dataPersonalHealth != null">
   
        <tbody>
          <tr>
            <td style="width: 20%;">โรคประจำตัว</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCongenitalDisease != null && dataPersonalHealth[0].pCongenitalDisease != ''">{{dataPersonalHealth[0].pCongenitalDisease}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCongenitalDisease == null || dataPersonalHealth[0].pCongenitalDisease == ''">-</td>
            <td style="width: 20%;">ผลข้างเคียง</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pSideEffect != null && dataPersonalHealth[0].pSideEffect != ''">{{dataPersonalHealth[0].pSideEffect}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pSideEffect == null || dataPersonalHealth[0].pSideEffect == ''">-</td>
          </tr>
          <tr>
            <td style="width: 20%;">ยาที่กินประจำ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pRegularMedication != null && dataPersonalHealth[0].pRegularMedication != ''">{{dataPersonalHealth[0].pRegularMedication}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pRegularMedication == null || dataPersonalHealth[0].pRegularMedication == ''">-</td>
            <td style="width: 20%;">วันที่ได้รับเชื้อ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pDateInfection != null && dataPersonalHealth[0].pDateInfection != ''">{{dataPersonalHealth[0].pDateInfection | date:'mediumDate'}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pDateInfection == null || dataPersonalHealth[0].pDateInfection == ''">-</td>
          </tr>
          <tr>
            <td style="width: 20%;">แพ้ยาอะไร</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAllergicDrug != null && dataPersonalHealth[0].pAllergicDrug != ''">{{dataPersonalHealth[0].pAllergicDrug}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAllergicDrug == null || dataPersonalHealth[0].pAllergicDrug == ''">-</td>
            <td style="width: 20%;">วันที่เริ่มมีอาการ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOnsetDate != null && dataPersonalHealth[0].pOnsetDate != ''">{{dataPersonalHealth[0].pOnsetDate | date:'mediumDate'}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOnsetDate == null || dataPersonalHealth[0].pOnsetDate == ''">-</td>
          </tr>
          <tr>
            <td style="width: 20%;">อาการเริ่มต้น</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pInitSymptom != null && dataPersonalHealth[0].pInitSymptom != ''">{{dataPersonalHealth[0].pInitSymptom}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pInitSymptom == null || dataPersonalHealth[0].pInitSymptom == ''">-</td>
            <td style="width: 20%;">โรงพยาบาลต้นสังกัด</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAffHospital != null && dataPersonalHealth[0].pAffHospital != ''">{{dataPersonalHealth[0].pAffHospital}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAffHospital == null || dataPersonalHealth[0].pAffHospital == ''">-</td>
          </tr>
          <tr>
            <td style="width: 20%;">ยาที่ทานมาก่อน</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pBeforeMedicine != null && dataPersonalHealth[0].pBeforeMedicine != ''">{{dataPersonalHealth[0].pBeforeMedicine}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pBeforeMedicine == null || dataPersonalHealth[0].pBeforeMedicine == ''">-</td>
            <td style="width: 20%;">อายุเด็ก</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgeChild != null && dataPersonalHealth[0].pAgeChild != ''">{{dataPersonalHealth[0].pAgeChild}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgeChild == null || dataPersonalHealth[0].pAgeChild == ''">-</td>
          </tr>
          <tr>
            <td style="width: 20%;">อาการปัจจุบัน</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCurrentSymptiom != null && dataPersonalHealth[0].pCurrentSymptiom != ''">{{dataPersonalHealth[0].pCurrentSymptiom}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pCurrentSymptiom == null || dataPersonalHealth[0].pCurrentSymptiom == ''">-</td>
            <td style="width: 20%;">อายุครรภ์</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgePregnant != null && dataPersonalHealth[0].pAgePregnant != ''">{{dataPersonalHealth[0].pAgePregnant}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pAgePregnant == null || dataPersonalHealth[0].pAgePregnant == ''">-</td>
          </tr>
          <tr>
            <td style="width: 20%;">ประจำเดือน</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pMenstruation != null && dataPersonalHealth[0].pMenstruation != ''">{{dataPersonalHealth[0].pMenstruation}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pMenstruation == null || dataPersonalHealth[0].pMenstruation == ''">-</td>
            <td style="width: 20%;">อื่นๆ</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOther != null && dataPersonalHealth[0].pOther != ''">{{dataPersonalHealth[0].pOther}}</td>
            <td style="width: 30%;" *ngIf="dataPersonalHealth[0].pOther == null || dataPersonalHealth[0].pOther == ''">-</td>
          </tr>
        </tbody>
      </table> -->
</mat-dialog-content>