import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from 'src/app/core/services/service.service';

@Component({
  selector: 'app-access-required',
  templateUrl: './access-required.component.html',
  styleUrls: ['./access-required.component.scss']
})
export class AccessRequiredComponent implements OnInit {
  page: string | null;
  hidePassword: boolean = true;
  password: string = "";
  username: string | null;
  wrongPassword: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AccessRequiredComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private serviceService: ServiceService
  ) {
    this.page = this.data;
    this.username = localStorage.getItem("currentUsername");
  }

  ngOnInit(): void {
  }

  passwordAuth() {
    var postData: any = {
      AdminUsername: this.username,
      AdminPassword: this.password,
      ActionText: this.page
    }
    this.serviceService.LoginAuthLog_(postData).then((res) => {
      if (res.status) {
        this.wrongPassword = false;
        this.dialogRef.close(res.status);
      } else {
        this.wrongPassword = true;
        this.password = "";
      }
    });
  }
}
