import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connect-api-error',
  templateUrl: './connect-api-error.component.html',
  styleUrls: ['./connect-api-error.component.scss']
})
export class ConnectApiErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  refresh(): void {
    window.location.reload();
  }

}
