<style>
    ::ng-deep .mat-form-field-prefix {
        top: 0px !important;
        /* background-color: brown; */
    }
</style>

<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <h1 style="text-align: center;">Add Operator</h1>
            </div>
            <div class="col-md-10">
                <hr>
            </div>
        </div>
    </div>
</section>



<div class="col-md-12">
    <div class="row">
        <div class="col-md-2">
            <div class="row" style="display: flex;justify-content: center;">
                <div class="image-preview" *ngIf="previewUrl">
                    <img [src]="previewUrl" style="border-radius: 50%;width: 160px;height: 160px;object-fit: cover;" />
                </div>

                <div *ngIf="noImg">
                    <img src="assets/adminlte/dist/img/nouser.svg"
                        style="border-radius: 50%;width: 160px;height: 160px;object-fit: cover;" />
                </div>
                <div style="margin-left: 3px;margin-top: auto;">
                    <button mat-mini-fab (click)="fileInput.click()">
                        <mat-icon>photo_size_select_actual</mat-icon>
                        <input #fileInput type="file" name="image" (change)="fileProgress($event)" style="display:none;"
                            accept="image/png, image/jpeg" />
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-5">
            <div class="card-body">
                <div class="row ">
                    <div class="col-md">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Enter your email</mat-label>
                            <input matInput placeholder="pat@example.com" [formControl]="operatorEmail" maxlength="120"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9.@_-]/gi, '').trim();" required>
                            <mat-error *ngIf="operatorEmail.invalid">{{getErrorMessageEmail()}}</mat-error>
                            <mat-hint *ngIf="duplicateEmailCheck" style="color: red;"> {{duplicateUsername}} </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">


                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline"
                            FloatLabelType='always'>
                            <!-- <mat-label>Username</mat-label> -->
                            <input matInput [formControl]="operatorUsername" required  placeholder="Username" maxlength="100"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9._-]/gi, '').trim();">
                            <mat-error *ngIf="operatorUsername.invalid">{{getErrorMessageUsername()}}</mat-error>
                            <span matPrefix>{{englishAbbreviation}}-</span>
                            <mat-hint *ngIf="duplicateUsernameCheck" style="color: red;"> {{duplicateUsername}} </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="hideP1 ? 'password' : 'text'" [formControl]="operatorPassword" minlength="8" maxlength="200">
                            <mat-icon matSuffix (click)="hideP1 = !hideP1">{{hideP1 ? "visibility_off" : "visibility"}}</mat-icon>
                            <mat-error *ngIf="operatorPassword.invalid">{{getErrorMessagePassword()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                      <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                          <mat-label>Password Confirm</mat-label>
                          <input matInput [type]="hideP2 ? 'password' : 'text'" [formControl]="operatorPasswordConfirm" minlength="8" maxlength="200">
                          <mat-icon matSuffix (click)="hideP2 = !hideP2">{{hideP2 ? "visibility_off" : "visibility"}}</mat-icon>
                          <mat-error *ngIf="operatorPasswordConfirm.invalid">{{getErrorMessagePasswordConfirm()}}</mat-error>
                      </mat-form-field>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>FirstName</mat-label>
                            <input matInput [formControl]="operatorFirstname" maxlength="120"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9ก-๙]/gi, '').trim();" required>
                            <mat-error *ngIf="operatorFirstname.invalid">{{getErrorMessageFirstname()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>LastName</mat-label>
                            <input matInput [formControl]="operatorLastname" maxlength="120"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9ก-๙]/gi, '').trim();" required>
                            <mat-error *ngIf="operatorLastname.invalid">{{getErrorMessageLastname()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Gender</mat-label>
                            <mat-select [formControl]="operatorGender" required>
                                <mat-option *ngFor="let gender of genders" [value]="gender.value">
                                    {{gender.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="operatorGender.invalid">{{getErrorMessageGender()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Age</mat-label>
                            <input matInput [formControl]="operatorAge" maxlength="3" oninput="this.value = this.value.replace(/[^0-9]/gi, '').trim();" required>
                            <mat-error *ngIf="operatorAge.invalid">{{getErrorMessageAge()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Birthday</mat-label>
                            <input matInput [matDatepicker]="picker" [formControl]="operatorDateofbirth" required>
                            <mat-error *ngIf="operatorDateofbirth.invalid">{{getErrorMessageDateofbirth()}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Phone</mat-label>
                            <input matInput [formControl]="operatorPhone" minlength="10" maxlength="10"
                            oninput="this.value = this.value.replace(/[^0-9]/gi, '').trim();" required>
                            <mat-error *ngIf="operatorPhone.invalid">{{getErrorMessagePhone()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Address</mat-label>
                            <textarea matInput [formControl]="operatorAddress" required></textarea>
                            <mat-error *ngIf="operatorAddress.invalid">{{getErrorMessageAddress()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                            <mat-label>Province</mat-label>
                            <mat-select [formControl]="operatorProvince" required [(ngModel)]="selectedProvince"
                                (ngModelChange)="onProvinceSelection()">
                                <mat-option *ngFor="let province of provinces" [value]="province.value">
                                    {{province.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="operatorProvince.invalid">{{getErrorMessageProvince()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                            <mat-label>District</mat-label>
                            <mat-select [formControl]="operatorDistrict" required [(ngModel)]="selectedDistrict"
                                (ngModelChange)="onDistrictSelection()">
                                <mat-option *ngFor="let district of districts" [value]="district.value">
                                    {{district.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="operatorDistrict.invalid">{{getErrorMessageDistrict()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                            <mat-label>Subdistrict</mat-label>
                            <mat-select [formControl]="operatorSubdistrict" required [(ngModel)]="selectedSubdistrict"
                                (ngModelChange)="onSubdistrictSelection()">
                                <mat-option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.value">
                                    {{subdistrict.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="operatorSubdistrict.invalid">{{getErrorMessageSubdistrict()}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                            <mat-label>Postal Code</mat-label>
                            <input matInput [formControl]="operatorPostalcode" maxlength="5" maxlength="5"
                            oninput="this.value = this.value.replace(/[^0-9]/gi, '').trim();" required>
                            <mat-error *ngIf="operatorPostalcode.invalid">{{getErrorMessagePostalcode()}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class="float-right" mat-raised-button type="submit" color="primary" (click)="onSubmit()">Add
                        Operator</button>
                </div>
            </div>
        </div>
    </div>
</div>
