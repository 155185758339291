import { OnInit, Component, NgZone } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../../core/services/service.service';
import { MatDialog } from '@angular/material/dialog';
import { LOCALE_ID, Inject } from '@angular/core';
import { DialogDetailSos } from '../../../../shared/modal/detailCovid/detail-sos.component';
import { DialogPersonalHealth } from 'src/app/shared/modal/personalHealth/personal-health.component';
import { DialogCovid19Chart } from './modal/chart-covid/chart-covid.component';
import { DialogEditVitalSign } from './modal/edit-vital-sign/edit-vital-sign.component';
import { DialogAddDispenseMedicine } from './modal/success-dispense-medicine/dialog-add-dispense-medicine';
import { DialogdialogReferUserCovid19 } from './modal/refer-covid19/dialog-refer-user-covid19';
import { formatDate } from '@angular/common';
import { io } from 'socket.io-client';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DialogdialogSuccessReferCovid19 } from './modal/success-refer-covid19/dialog-success-refer-covid19';
import { DialoghistoryReferUserCovid19 } from './modal/history-refer-covid19/dialog-history-refer-covid19';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-user-covid19',
  templateUrl: './user-covid19.component.html',
  styleUrls: ['./user-covid19.component.scss']
})
export class UserCovid19Component implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  idOperatorAdmin: any;
  commentForm!: FormGroup;
  noteForm!: FormGroup;
  dataConvertDispenseMedicine: any;
  dataConvertDispenseMedicineTH: any;
  dataConvert: any;
  selectedSymptomCovid: string = "";
  userCovidStatus: string = "";
  nameDoctor: boolean = false;
  commentDoctor: boolean = false;
  noteDoctor: boolean = false;
  getIdUser: any;
  dataUser: any;
  bmi: number = 0;
  btnFavorite!: boolean;
  dataDoctorPatient: any;
  dataCommentDoctorPatient: any;
  dataPersonalHealth: any;
  dataCovidSymptom: any = [];

  recordFirstSymptoms: any;
  recordLatestSymptoms: any;

  recordFirstSymptoms2: any;
  recordLatestSymptoms2: any;

  dispenseMedicineTH!: FormGroup;
  selectMedicineStatiaCZNote: boolean = true;
  selectMedicineStatiaNote: boolean = true;
  selectMedicine5rootsNote: boolean = true;
  selectMedicineGreenMedicineNote: boolean = true;
  selectMedicineAummahivaNote: boolean = true;
  selectMedicinePrasapoyaiNote: boolean = true;
  selectMedicineJanlelarNote: boolean = true;
  selectMedicineHomyenNote: boolean = true;
  selectMedicineHomronNote: boolean = true;
  selectMedicineFatalaijonNote: boolean = true;

  dispenseMedicine!: FormGroup;
  disabledFavi: boolean = true;
  disabledFaviFirst5days: boolean = true;
  disabledFaviNext5days: boolean = true;
  disabledDexamethasone: boolean = true;
  disabledPrednisolone: boolean = true;
  disabledOmeprazole: boolean = true;
  disabledOxygen: boolean = true;
  disabledOthers: boolean = true;
  mpFaviDayWeight: string = '';
  selectFavi: boolean = false;
  selectFirst5days: boolean = false;
  selectNext5days: boolean = false;
  selectDexamethasone: boolean = false;
  selectPrednisolone: boolean = false;
  selectOmeprazole: boolean = false;
  selectOxygen: boolean = false;
  selectOthers: boolean = false;
  radioStatus!: boolean;
  radioStatusFaviF5: boolean = false;
  radioStatusFaviN5: boolean = false;
  dateTime_: any;
  lastCovidDispense: any = [];
  lastCovidDispenseTH: any = [];

  disablebtn: boolean = false;

  infromationCovid19 =
    {
      cFeverish: false,
      cSoreThroat: false,
      cMusclePain: false,
      cRunny: false,
      cSputum: false,
      cHeadache: false,
      cNoseNoSmell: false,
      cRash: false,
      cLiquidStool: false,
      cCoughAlittle: false,
      cTongueNoTaste: false,
      cConjunctivitis: false,
      cCanNotEat: false,
      cCoughAlot: false,
      cCoughingUpBlood: false,
      cBreathingDiff: false,
      cTriedDailyActivities: false,
      cTiredSitStill: false,
      cLieDownBreath: false
    };

  selectMptStatiaCz: boolean = false;
  selectMptStatia: boolean = false;
  selectMpt5roots: boolean = false;
  selectMptGreenMedicine: boolean = false;
  selectMptAummahiva: boolean = false;
  selectMptPrasapoyai: boolean = false;
  selectMptJanlelar: boolean = false;
  selectMptHomyen: boolean = false;
  selectMptHomron: boolean = false;
  selectMptFatalaijon: boolean = false;

  dataPrescription: number = -1;

  private socket: any;
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    public formBuilder: FormBuilder,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.socket = io(GlobalConstants.socket, { transports: ['websocket', 'polling', 'flashsocket'] });
    this.getIdUser = this.activatedRoute.snapshot.paramMap.get('idUser');
    this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
    this.dateTime_ = new Date();
    this.dateTime_ = new Date(this.dateTime_.setDate(this.dateTime_.getDate() + 5));
    // console.log(dateTime_);
    this.dispenseMedicine = this.formBuilder.group({
      FavipiravirStartdate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      FavipiravirFirst5daysStart: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      FavipiravirFirst5daysEnd: this.dateTime_,
      FavipiravirNext5daysStart: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      FavipiravirNext5daysEnd: this.dateTime_,
      FavipiravirNote: '',
      DexamethasoneStartdate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      DexamethasoneMG: '',
      DexamethasoneFrequency: '',
      DexamethasoneTimes: '',
      DexamethasoneNote: '',
      PrednisoloneStartdate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      PrednisoloneMG: '',
      PrednisoloneFrequency: '',
      PrednisoloneTimes: '',
      PrednisoloneNote: '',
      OmeprazoleStartDate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      OmeprazoleNote: '',
      OmeprazoleMG: '',
      Omeprazoledays: '',
      OxygenNote: '',
      OthersNote: '',
    })
    this.dispenseMedicineTH = this.formBuilder.group({
      medicineStatiaCZNote: '',
      medicineStatiaNote: '',
      medicine5rootsNote: '',
      medicineGreenMedicineNote: '',
      medicineAummahivaNote: '',
      medicinePrasapoyaiNote: '',
      medicineJanlelarNote: '',
      medicineHomyenNote: '',
      medicineHomronNote: '',
      medicineFatalaijonNote: ''
    })
    this.commentForm = this.formBuilder.group({
      commentUserDoctor: [''],
      commentUserDetail: [''],
    })
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  async ngOnInit(): Promise<void> {
    var resPhone: any;
    var resBmi: any;
    var dataPatientCovidForDoctor: any;
    await this.ServiceService.GetUserPhone_(this.getIdUser).then(dataPhone => {
      resPhone = dataPhone;
      // console.log(resPhone);
      if(resPhone.length > 0) {
        this.ServiceService.GetPatientCovidForDoctor_(this.getIdUser).then(resPatientCovidForDoctor=> {
          console.log(dataPatientCovidForDoctor);
          dataPatientCovidForDoctor = resPatientCovidForDoctor;
          if(dataPatientCovidForDoctor.length != 0){
            if(dataPatientCovidForDoctor[0].dataOperator.operatorAdminId == this.idOperatorAdmin){

            }else{
              this._snackBar.open("บุคคลนี้ไม่ได้เป็นคนไข้ของคุณ", '',{
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              });
              setTimeout(() => {
                this.router.navigate([`/doctorPatientCovid19List`], { replaceUrl: true }).then(() => { window.location.reload(); });
              }, 5000);
            }
          }else{
            this._snackBar.open("บุคคลนี้ไม่ได้ลงอาการ covid19", '',{
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            setTimeout(() => {
              this.router.navigate([`/doctorPatientCovid19List`], { replaceUrl: true }).then(() => { window.location.reload(); });
            }, 5000);
          }
        });
        this.dataUser = resPhone[0];

        resBmi = (resPhone[0].userWeight / ((resPhone[0].userHeight / 100) * (resPhone[0].userHeight / 100)));
        this.bmi = resBmi.toString().substring(0, 5);
        this.noteForm = this.formBuilder.group({
          noteUserDoctor: this.dataUser.userNoteCovid19,
        })
      }else{
        this._snackBar.open("ไม่มีบุคคลนี้", '',{
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        setTimeout(() => {
          this.router.navigate([`/doctorPatientCovid19List`], { replaceUrl: true }).then(() => { window.location.reload(); });
        }, 3000);
      }
    })

    this.loadDataDoctorPatient(this.dataUser.userId);
    this.loadDataCommentDoctorPatient(this.dataUser.userId);

    this.loadCovidDisease(this.getIdUser);

    this.loadPersonalHealth(this.getIdUser);
    this.loadDataCovidDispense();
  }

  openDetailSosLogBody(idUser: string, btnCheck?: boolean): void {

    const dialogRef = this.dialog.open(DialogDetailSos, {
      width: '100%',
      data: { idUser: idUser, btnCheck: btnCheck }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  openDialogPersonalHealth(idUser: any) {
    const dialogRef = this.dialog.open(DialogPersonalHealth, {
      data: idUser
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  openDialogCovid19Chart(dataSos: any): void {
    const dialogRef = this.dialog.open(DialogCovid19Chart, {
      // width: '1800px',
      data: { dataSos }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  async loadDataDoctorPatient(idPatient: string) {
    await this.ServiceService.GetDataDoctorPatient_(idPatient).then(resDoctorPatient => {
      // console.log("ssssssssss ", resDoctorPatient);
      if (resDoctorPatient != '') {
        this.dataDoctorPatient = resDoctorPatient;
        if (this.dataDoctorPatient[0].docMall.dmStatus == "EndTreat") {
          this.disablebtn = true;
        }
        this.userCovidStatus = this.dataDoctorPatient[0].cDangerLevel;
        this.btnFavorite = this.dataDoctorPatient[0].dpFavorite
        this.selectedSymptomCovid = this.dataDoctorPatient[0].dpDocCovidStatus;
      }
    });
  }


  async loadDataCovidDispense() {
    await this.ServiceService.GetDataCovidDispense_(this.getIdUser, "1").then(resDispense => {
      // console.log(resDispense);

      this.lastCovidDispense = resDispense;
    });

    await this.ServiceService.GetGetMedPresTHListByUser_(this.getIdUser, "1").then(resMedPresTH => {
      // console.log(resMedPresTH);
      this.lastCovidDispenseTH = resMedPresTH;

    });


  }

  myTabFocusChange(changeEvent: MatTabChangeEvent) {

    this.dataPrescription = Number(changeEvent.tab.position);
    // console.log(this.dataPrescription);


  }

  async loadDataCommentDoctorPatient(idPatient: string) {
    var datacomment: any;
    await this.ServiceService.GetDataDoctorPatientAndDoctorTask_(idPatient).then(resDataCommentDoctorPatient => {
      datacomment = resDataCommentDoctorPatient;
      this.dataCommentDoctorPatient = datacomment.sort((a: any, b: any) => a.dptDateTime > b.dptDateTime ? -1 : 1);
      // console.log(this.dataCommentDoctorPatient);
    });
  }

  onClickFavorite(idPatient: string, docCovidStatus: string, favorite: boolean) {
    var dataUpdateDocCovidStatusAndFavorite: any;
    this.ServiceService.UpdateDocCovidStatusAndFavorite_(idPatient, docCovidStatus, favorite).then(resUpdateDocCovidStatusAndFavorite => {
      // console.log(resUpdateDocCovidStatusAndFavorite);
      dataUpdateDocCovidStatusAndFavorite = resUpdateDocCovidStatusAndFavorite;
      this.btnFavorite = favorite;
    });
  }

  onChangeSymptomCovid(event: string) {
    this.selectedSymptomCovid = event;
    var dataUpdateDocCovidStatusAndFavorite: any;
    this.ServiceService.UpdateDocCovidStatusAndFavorite_(this.dataUser.userId, event, this.btnFavorite).then(resUpdateDocCovidStatusAndFavorite => {
      // console.log(resUpdateDocCovidStatusAndFavorite);
      dataUpdateDocCovidStatusAndFavorite = resUpdateDocCovidStatusAndFavorite;
    });
  }

  openDialogEditVitalSign(iddataEdit: any, nameEdit: any, dataEdit: any, dataEdit2: any): void {
    const dialogRef = this.dialog.open(DialogEditVitalSign, {
      width: '250px',
      data: { "iddataEdit": iddataEdit, "nameEdit": nameEdit, "dataEdit1": dataEdit, "dataEdit2": dataEdit2 }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed ', result);
      if (result != null || result != undefined) {
        if (nameEdit == "T") {
          this.dataDoctorPatient[0].cTemp = result.data1;
        } else if (nameEdit == "BP") {
          this.dataDoctorPatient[0].cPressureHigh = result.data1;
          this.dataDoctorPatient[0].cPressureLow = result.data2;
        } else if (nameEdit == "P") {
          this.dataDoctorPatient[0].cHeartrate = result.data1;
        } else if (nameEdit == "SpO2 At Rest") {
          this.dataDoctorPatient[0].cSpo2 = result.data1;
        } else if (nameEdit == "SpO2 After Ex") {
          this.dataDoctorPatient[0].cSpo2AfterExercise = result.data1;
        } else if (nameEdit == "SpO2 On O2") {
          this.dataDoctorPatient[0].cSpo2Ventilator = result.data1;
        }
      }
    });
  }
  checkAlert: boolean = false;
  onCheckAlertAdminComment(event: any) {
    this.checkAlert = event.checked;

    console.log(this.checkAlert);

  }

  addCommentUserCovid19(): any {
    if (this.commentForm.value.commentUserDoctor == "") {
      this.nameDoctor = true;
    } else {
      this.nameDoctor = false;
    }

    if (this.commentForm.value.commentUserDetail == "") {
      this.commentDoctor = true;
    } else {
      this.commentDoctor = false;
    }

    if (this.nameDoctor == false && this.commentDoctor == false) {
      this.dataConvert = {
        "DptDpid": this.dataDoctorPatient[0].dpId,
        "DptDetail": this.commentForm.value.commentUserDetail,
        "DptDoctorName": this.commentForm.value.commentUserDoctor,

        "starusAlert": this.checkAlert,
        "AaType": "DoctorTask",
        // "AaSubId": this.commentForm.value.commentUserDoctor,
        "AaOperatorId": this.idOperatorAdmin,
        "AaStatus": false,
      };
      console.log(this.dataConvert);

      this.ServiceService.AddCommentDoctorPatient_(this.dataConvert).then(resAddComment => {
        // console.log(resAddComment);
        if (this.checkAlert == true) {
          this.socket.emit('dataAlertPending_Covid19', { msg: "doctor-comment", status: "unread", idOperator: this.idOperatorAdmin });
        }
        var dataComment = {
          dptDoctorName: this.commentForm.value.commentUserDoctor,
          dptDetail: this.commentForm.value.commentUserDetail,
          dptDateTime: Date.now()
        }
        this.dataCommentDoctorPatient.splice(0, 0, dataComment);
        //  this.dataCommentDoctorPatient.sort((a: any, b: any) =>a.dptDateTime > b.dptDateTime ? -1 : 1)
        this.commentForm.setValue({
          commentUserDoctor: [''],
          commentUserDetail: [''],
        })
      });

    }
  }

  addNoteUserCovid19(idUser: string) {
    if (this.noteForm.value.noteUserDoctor == "" || this.noteForm.value.noteUserDoctor == null) {
      this.noteDoctor = true;
    } else {
      this.noteDoctor = false;
    }

    if (this.noteDoctor == false) {
      if (this.noteForm.value.noteUserDoctor != this.dataUser.userNoteCovid19) {
        this.ServiceService.UpdateNoteCommentUser_(idUser, this.noteForm.value.noteUserDoctor).then(resAddComment => {
          console.log(resAddComment);
        });
      }
    }
  }

  loadPersonalHealth(idUser: string) {
    var dataH_: any;
    this.ServiceService.GetPersonalHealth_(idUser).then(resPersonalHealth => {
      // console.log("resPersonalHealth > ", resPersonalHealth);
      dataH_ = resPersonalHealth;
      // console.log("dataH_ ",dataH_);
      if (dataH_ != "") {
        this.dataPersonalHealth = dataH_[0].pCongenitalDisease.split(',');
        // console.log(this.dataPersonalHealth);
      }

    });
  }

  async loadCovidDisease(idUser: string) {
    var data_CovidSymptom: any;
    var data_CovidSymptom1: any;
    var data_Sound1: any;
    var data_Sound2: any;
    var data_Sound11: any;
    var data_Sound22: any;

    var dataResultCovidSymptomV4: any = [];

    await this.ServiceService.GetCovidSymptomV4_(idUser).then(resCovidSymptom => {
      // console.log(resCovidSymptom);
      data_CovidSymptom = resCovidSymptom;
      data_CovidSymptom1 = resCovidSymptom;

      if (data_CovidSymptom.length > 3) {
        for (let i = 0; i < 3; i++) {
          dataResultCovidSymptomV4.push({ cId: data_CovidSymptom[i].cId, dataCovid: data_CovidSymptom[i], dataSymptom: JSON.parse(data_CovidSymptom[i].cCurrentSymptoms) });
          // this.dataCovidSymptom.push({ dataCovid: data_CovidSymptom[i], dataSymptom: JSON.parse(data_CovidSymptom[i].cCurrentSymptoms) });
          if (i == 3 - 1) {
            this.dataCovidSymptom = dataResultCovidSymptomV4.sort((a: { cId: number; }, b: { cId: number; }) => a.cId < b.cId ? -1 : a.cId > b.cId ? 1 : 0)
          }
        }
      } else {
        for (let i = 0; i < data_CovidSymptom.length; i++) {
          dataResultCovidSymptomV4.push({ cId: data_CovidSymptom[i].cId, dataCovid: data_CovidSymptom[i], dataSymptom: JSON.parse(data_CovidSymptom[i].cCurrentSymptoms) });
          // this.dataCovidSymptom.push({ dataCovid: data_CovidSymptom[i], dataSymptom: JSON.parse(data_CovidSymptom[i].cCurrentSymptoms) });
          if (i == data_CovidSymptom.length - 1) {
            this.dataCovidSymptom = dataResultCovidSymptomV4.sort((a: { cId: number; }, b: { cId: number; }) => a.cId < b.cId ? -1 : a.cId > b.cId ? 1 : 0)
          }
        }
      }


      for (let i = 0; i < dataResultCovidSymptomV4.length; i++) {
        if (dataResultCovidSymptomV4[i].dataCovid.cFeverish == true) {
          this.infromationCovid19.cFeverish = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cSoreThroat == true) {
          this.infromationCovid19.cSoreThroat = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cMusclePain == true) {
          this.infromationCovid19.cMusclePain = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cRunny == true) {
          this.infromationCovid19.cRunny = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cSputum == true) {
          this.infromationCovid19.cSputum = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cHeadache == true) {
          this.infromationCovid19.cHeadache = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cNoseNoSmell == true) {
          this.infromationCovid19.cNoseNoSmell = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cRash == true) {
          this.infromationCovid19.cRash = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cLiquidStool == true) {
          this.infromationCovid19.cLiquidStool = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cCoughAlittle == true) {
          this.infromationCovid19.cCoughAlittle = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cTongueNoTaste == true) {
          this.infromationCovid19.cTongueNoTaste = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cConjunctivitis == true) {
          this.infromationCovid19.cConjunctivitis = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cCanNotEat == true) {
          this.infromationCovid19.cCanNotEat = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cCoughAlot == true) {
          this.infromationCovid19.cCoughAlot = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cCoughingUpBlood == true) {
          this.infromationCovid19.cCoughingUpBlood = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cBreathingDiff == true) {
          this.infromationCovid19.cBreathingDiff = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cTriedDailyActivities == true) {
          this.infromationCovid19.cTriedDailyActivities = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cTiredSitStill == true) {
          this.infromationCovid19.cTiredSitStill = true;
        }

        if (dataResultCovidSymptomV4[i].dataCovid.cLieDownBreath == true) {
          this.infromationCovid19.cLieDownBreath = true;
        }
      }


      for (let i = 0; i < data_CovidSymptom.length; i++) {
        if (data_CovidSymptom[i].cRecordSymptoms != '' && data_CovidSymptom[i].cRecordSymptoms != null) {
          // console.log("ID : ",data_CovidSymptom[i].cId);
          data_Sound1 = { id: data_CovidSymptom[i].cId, sound: data_CovidSymptom[i].cRecordSymptoms, dateSound: data_CovidSymptom[i].cDate }
          break;
        }
      }

      for (let i = 0; i < data_CovidSymptom.length; i++) {
        if (data_CovidSymptom[i].cRecordSymptoms2 != '' && data_CovidSymptom[i].cRecordSymptoms2 != null) {
          // console.log("ID : ",data_CovidSymptom[i].cId);
          data_Sound11 = { id: data_CovidSymptom[i].cId, sound: data_CovidSymptom[i].cRecordSymptoms2, dateSound: data_CovidSymptom[i].cDate }
          break;
        }
      }

      data_CovidSymptom1.sort((a: { cId: number; }, b: { cId: number; }) => a.cId < b.cId ? -1 : a.cId > b.cId ? 1 : 0)
      for (let i = 0; i < data_CovidSymptom1.length; i++) {
        if (data_CovidSymptom1[i].cRecordSymptoms != '' && data_CovidSymptom1[i].cRecordSymptoms != null) {
          // console.log("ID : ",data_CovidSymptom1[i].cId);
          data_Sound2 = { id: data_CovidSymptom1[i].cId, sound: data_CovidSymptom1[i].cRecordSymptoms, dateSound: data_CovidSymptom1[i].cDate }
          break;
        }
      }

      for (let i = 0; i < data_CovidSymptom1.length; i++) {
        if (data_CovidSymptom1[i].cRecordSymptoms2 != '' && data_CovidSymptom1[i].cRecordSymptoms2 != null) {
          // console.log("ID : ",data_CovidSymptom1[i].cId);
          data_Sound22 = { id: data_CovidSymptom1[i].cId, sound: data_CovidSymptom1[i].cRecordSymptoms2, dateSound: data_CovidSymptom1[i].cDate }
          break;
        }
      }
      // console.log("data_Sound1.id ", data_Sound1);
      if (data_Sound1 != undefined) {
        if (data_Sound1.id == data_Sound2.id) {
          this.recordFirstSymptoms = data_Sound1;
        } else {
          this.recordFirstSymptoms = data_Sound1;
          this.recordLatestSymptoms = data_Sound2;
        }
      }
      if (data_Sound11 != undefined) {
        if (data_Sound11.id == data_Sound22.id) {
          this.recordFirstSymptoms2 = data_Sound11;
        } else {
          this.recordFirstSymptoms2 = data_Sound11;
          this.recordLatestSymptoms2 = data_Sound22;
        }
      }



    });
  }

  onCheckFavi(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.disabledFavi = false;
      this.selectFavi = true;
      this.radioStatusFaviF5 = false;
      this.radioStatusFaviN5 = false;
      this.disabledFaviFirst5days = false;
      this.disabledFaviNext5days = false;
    } else {
      this.disabledFavi = true;
      this.selectFavi = false;
      this.disabledFaviFirst5days = false;
      this.disabledFaviNext5days = false;
      this.radioStatusFaviF5 = false;
      this.radioStatusFaviN5 = false;
    }
  }

  onCheckFaviFirst5days(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.disabledFaviFirst5days = false;
      this.disabledFaviNext5days = true;
      this.selectFirst5days = true;
      this.radioStatusFaviF5 = false;
      this.radioStatusFaviN5 = true;
    } else {
      this.disabledFaviFirst5days = false;
      this.disabledFaviNext5days = false;
      this.selectFirst5days = false;
      this.radioStatusFaviF5 = false;
      this.radioStatusFaviN5 = false;
    }
  }

  onCheckFaviNext5days(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.disabledFaviFirst5days = true;
      this.selectNext5days = true;
      this.disabledFaviNext5days = false;

      this.radioStatusFaviF5 = true;
      this.radioStatusFaviN5 = false;

    } else {
      this.disabledFaviFirst5days = false;
      this.disabledFaviNext5days = false;
      this.selectNext5days = false;
      this.radioStatusFaviF5 = false;
      this.radioStatusFaviN5 = false;
    }
  }


  onChangeDayWeight(txt: string) {
    // console.log(txt);
    this.mpFaviDayWeight = txt;
  }

  onCheckDexamethasone(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.selectDexamethasone = true;
      this.disabledDexamethasone = false;
    } else {
      this.selectDexamethasone = false;
      this.disabledDexamethasone = true;
    }
  }

  onCheckPrednisolone(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.selectPrednisolone = true;
      this.disabledPrednisolone = false;
    } else {
      this.selectPrednisolone = false;
      this.disabledPrednisolone = true;
    }
  }

  onCheckOmeprazole(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.selectOmeprazole = true;
      this.disabledOmeprazole = false;
    } else {
      this.selectOmeprazole = false;
      this.disabledOmeprazole = true;
    }
  }

  onCheckOxygen(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.selectOxygen = true;
      this.disabledOxygen = false;
    } else {
      this.selectOxygen = false;
      this.disabledOxygen = true;
    }
  }

  onCheckOthers(event: any) {
    // console.log(event.checked);
    if (event.checked != false) {
      this.selectOthers = true;
      this.disabledOthers = false;
    } else {
      this.selectOthers = false;
      this.disabledOthers = true;
    }
  }

  onSubmitDispenseMedicine() {

    let dateTime = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    if (this.dispenseMedicine.value.FavipiravirStartdate != "" && this.dispenseMedicine.value.FavipiravirStartdate != null) {
      var MpFaviStartDate_ = formatDate(new Date(this.dispenseMedicine.value.FavipiravirStartdate), 'yyyy-MM-dd', this.locale);
    } else {
      var MpFaviStartDate_ = "";
    }
    if (this.dispenseMedicine.value.FavipiravirFirst5daysStart != "" && this.dispenseMedicine.value.FavipiravirFirst5daysStart != null) {
      var MpFaviDayStart_ = formatDate(new Date(this.dispenseMedicine.value.FavipiravirFirst5daysStart), 'yyyy-MM-dd', this.locale);
    } else {
      var MpFaviDayStart_ = "";
    }
    if (this.dispenseMedicine.value.FavipiravirFirst5daysEnd != "" && this.dispenseMedicine.value.FavipiravirFirst5daysEnd != null) {
      var MpFaviDayEnd_ = formatDate(new Date(this.dispenseMedicine.value.FavipiravirFirst5daysEnd), 'yyyy-MM-dd', this.locale);
    } else {
      var MpFaviDayEnd_ = "";
    }
    if (this.dispenseMedicine.value.FavipiravirNext5daysStart != "" && this.dispenseMedicine.value.FavipiravirNext5daysStart != null) {
      var FavipiravirNext5daysStart_ = formatDate(new Date(this.dispenseMedicine.value.FavipiravirNext5daysStart), 'yyyy-MM-dd', this.locale);
    } else {
      var FavipiravirNext5daysStart_ = "";
    }
    if (this.dispenseMedicine.value.FavipiravirNext5daysEnd != "" && this.dispenseMedicine.value.FavipiravirNext5daysEnd != null) {
      var FavipiravirNext5daysEnd_ = formatDate(new Date(this.dispenseMedicine.value.FavipiravirNext5daysEnd), 'yyyy-MM-dd', this.locale);
    } else {
      var FavipiravirNext5daysEnd_ = "";
    }
    if (this.dispenseMedicine.value.DexamethasoneStartdate != "" && this.dispenseMedicine.value.DexamethasoneStartdate != null) {
      var MpDexaStartDate_ = formatDate(new Date(this.dispenseMedicine.value.DexamethasoneStartdate), 'yyyy-MM-dd', this.locale);
    } else {
      var MpDexaStartDate_ = "";
    }
    if (this.dispenseMedicine.value.PrednisoloneStartdate != "" && this.dispenseMedicine.value.PrednisoloneStartdate != null) {
      var MpPrednisoloneStartDate_ = formatDate(new Date(this.dispenseMedicine.value.PrednisoloneStartdate), 'yyyy-MM-dd', this.locale);
    } else {
      var MpPrednisoloneStartDate_ = "";
    }
    if (this.dispenseMedicine.value.OmeprazoleStartDate != "" && this.dispenseMedicine.value.OmeprazoleStartDate != null) {
      var OmeprazoleStartDate_ = formatDate(new Date(this.dispenseMedicine.value.OmeprazoleStartDate), 'yyyy-MM-dd', this.locale);
    } else {
      var OmeprazoleStartDate_ = "";
    }

    if (this.selectFavi != false) {
      var dateTimeFavi = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    } else {
      var dateTimeFavi = "";
      MpFaviStartDate_ = "";
      FavipiravirNext5daysStart_ = "";
      FavipiravirNext5daysEnd_ = "";
    }
    if (this.selectDexamethasone != false) {
      var dateTimeDexamethasone = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    } else {
      var dateTimeDexamethasone = "";
      MpDexaStartDate_ = "";
    }
    if (this.selectPrednisolone != false) {
      var dateTimePrednisolone = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    } else {
      MpPrednisoloneStartDate_ = "";
      var dateTimePrednisolone = "";
    }
    if (this.selectOmeprazole != false) {
      var dateTimeOmeprazole = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    } else {
      var dateTimeOmeprazole = "";
      OmeprazoleStartDate_ = "";
    }



    if (this.selectFirst5days == true) {
      this.dataConvertDispenseMedicine = {
        "MpFavi": this.selectFavi,
        "MpFaviPrecriptionDate": dateTimeFavi,
        "MpFaviStartDate": MpFaviStartDate_,
        "MpFaviDay": "f5d",
        "MpFaviDayWeight": this.mpFaviDayWeight,
        "MpFaviDayStart": MpFaviDayStart_,
        "MpFaviDayEnd": MpFaviDayEnd_,
        "MpFaviNote": this.dispenseMedicine.value.FavipiravirNote,
        "MpDexa": this.selectDexamethasone,
        "MpDexaPrecriptionDate": dateTimeDexamethasone,
        "MpDexaStartDate": MpDexaStartDate_,
        "MpDexaMg": this.dispenseMedicine.value.DexamethasoneMG,
        "MpDexaFrequency": this.dispenseMedicine.value.DexamethasoneFrequency,
        "MpDexaDay": this.dispenseMedicine.value.DexamethasoneTimes,
        "MpDexaNote": this.dispenseMedicine.value.DexamethasoneNote,
        "MpPrednisolone": this.selectPrednisolone,
        "MpPrednisolonePrecriptionDate": dateTimePrednisolone,
        "MpPrednisoloneStartDate": MpPrednisoloneStartDate_,
        "MpPrednisoloneMg": this.dispenseMedicine.value.PrednisoloneMG,
        "MpPrednisoloneFrequency": this.dispenseMedicine.value.PrednisoloneFrequency,
        "MpPrednisoloneDay": this.dispenseMedicine.value.PrednisoloneTimes,
        "MpPrednisoloneNote": this.dispenseMedicine.value.PrednisoloneNote,
        "MpOmeprazole": this.selectOmeprazole,
        "MpOmeprazolePrecriptionDate": dateTimeOmeprazole,
        "MpOmeprazoleStartDate": OmeprazoleStartDate_,
        "MpOmeprazoleMg": this.dispenseMedicine.value.OmeprazoleMG,
        "MpOmeprazoleDay": this.dispenseMedicine.value.Omeprazoledays,
        "MpOmeprazoleNote": this.dispenseMedicine.value.OmeprazoleNote,
        "MpOxygen": this.selectOxygen,
        "MpOxygenNote": this.dispenseMedicine.value.OxygenNote,
        "MpOthers": this.selectOthers,
        "MpOthersNote": this.dispenseMedicine.value.OthersNote,
        "MpUserId": this.getIdUser,
        "MpStatus": "request",

        //table TbAdminAlert
        "AaType": "MedPres",
        // public long? AaSubId : "",
        "AaOperatorId": this.idOperatorAdmin,
        "AaStatus": false
      }
    } else if (this.selectNext5days == true) {
      this.dataConvertDispenseMedicine = {
        "MpFavi": this.selectFavi,
        "MpFaviPrecriptionDate": dateTimeFavi,
        "MpFaviStartDate": MpFaviStartDate_,
        "MpFaviDay": "n5d",
        "MpFaviDayWeight": this.mpFaviDayWeight,
        "MpFaviDayStart": FavipiravirNext5daysStart_,
        "MpFaviDayEnd": FavipiravirNext5daysEnd_,
        "MpFaviNote": this.dispenseMedicine.value.FavipiravirNote,
        "MpDexa": this.selectDexamethasone,
        "MpDexaPrecriptionDate": dateTimeDexamethasone,
        "MpDexaStartDate": MpDexaStartDate_,
        "MpDexaMg": this.dispenseMedicine.value.DexamethasoneMG,
        "MpDexaFrequency": this.dispenseMedicine.value.DexamethasoneFrequency,
        "MpDexaDay": this.dispenseMedicine.value.DexamethasoneTimes,
        "MpDexaNote": this.dispenseMedicine.value.DexamethasoneNote,
        "MpPrednisolone": this.selectPrednisolone,
        "MpPrednisolonePrecriptionDate": dateTimePrednisolone,
        "MpPrednisoloneStartDate": MpPrednisoloneStartDate_,
        "MpPrednisoloneMg": this.dispenseMedicine.value.PrednisoloneMG,
        "MpPrednisoloneFrequency": this.dispenseMedicine.value.PrednisoloneFrequency,
        "MpPrednisoloneDay": this.dispenseMedicine.value.PrednisoloneTimes,
        "MpPrednisoloneNote": this.dispenseMedicine.value.PrednisoloneNote,
        "MpOmeprazole": this.selectOmeprazole,
        "MpOmeprazolePrecriptionDate": dateTimeOmeprazole,
        "MpOmeprazoleStartDate": OmeprazoleStartDate_,
        "MpOmeprazoleMg": this.dispenseMedicine.value.OmeprazoleMG,
        "MpOmeprazoleDay": this.dispenseMedicine.value.Omeprazoledays,
        "MpOmeprazoleNote": this.dispenseMedicine.value.OmeprazoleNote,
        "MpOxygen": this.selectOxygen,
        "MpOxygenNote": this.dispenseMedicine.value.OxygenNote,
        "MpOthers": this.selectOthers,
        "MpOthersNote": this.dispenseMedicine.value.OthersNote,
        "MpUserId": this.getIdUser,
        "MpStatus": "request",

        //table TbAdminAlert
        "AaType": "MedPres",
        // public long? AaSubId : "",
        "AaOperatorId": this.idOperatorAdmin,
        "AaStatus": false
      }
    } else {
      this.dataConvertDispenseMedicine = {
        "MpFavi": this.selectFavi,
        "MpFaviPrecriptionDate": dateTimeFavi,
        "MpFaviStartDate": MpFaviStartDate_,
        "MpFaviDay": "",
        "MpFaviDayWeight": this.mpFaviDayWeight,
        "MpFaviDayStart": FavipiravirNext5daysStart_,
        "MpFaviDayEnd": FavipiravirNext5daysEnd_,
        "MpFaviNote": this.dispenseMedicine.value.FavipiravirNote,
        "MpDexa": this.selectDexamethasone,
        "MpDexaPrecriptionDate": dateTimeDexamethasone,
        "MpDexaStartDate": MpDexaStartDate_,
        "MpDexaMg": this.dispenseMedicine.value.DexamethasoneMG,
        "MpDexaFrequency": this.dispenseMedicine.value.DexamethasoneFrequency,
        "MpDexaDay": this.dispenseMedicine.value.DexamethasoneTimes,
        "MpDexaNote": this.dispenseMedicine.value.DexamethasoneNote,
        "MpPrednisolone": this.selectPrednisolone,
        "MpPrednisolonePrecriptionDate": dateTimePrednisolone,
        "MpPrednisoloneStartDate": MpPrednisoloneStartDate_,
        "MpPrednisoloneMg": this.dispenseMedicine.value.PrednisoloneMG,
        "MpPrednisoloneFrequency": this.dispenseMedicine.value.PrednisoloneFrequency,
        "MpPrednisoloneDay": this.dispenseMedicine.value.PrednisoloneTimes,
        "MpPrednisoloneNote": this.dispenseMedicine.value.PrednisoloneNote,
        "MpOmeprazole": this.selectOmeprazole,
        "MpOmeprazolePrecriptionDate": dateTimeOmeprazole,
        "MpOmeprazoleStartDate": OmeprazoleStartDate_,
        "MpOmeprazoleMg": this.dispenseMedicine.value.OmeprazoleMG,
        "MpOmeprazoleDay": this.dispenseMedicine.value.Omeprazoledays,
        "MpOmeprazoleNote": this.dispenseMedicine.value.OmeprazoleNote,
        "MpOxygen": this.selectOxygen,
        "MpOxygenNote": this.dispenseMedicine.value.OxygenNote,
        "MpOthers": this.selectOthers,
        "MpOthersNote": this.dispenseMedicine.value.OthersNote,
        "MpUserId": this.getIdUser,
        "MpStatus": "request",

        //table TbAdminAlert
        "AaType": "MedPres",
        // public long? AaSubId : "",
        "AaOperatorId": this.idOperatorAdmin,
        "AaStatus": false
      }
    }

    if (this.selectOthers == true || this.selectOxygen == true || this.selectOmeprazole == true || this.selectPrednisolone == true || this.selectDexamethasone == true || this.selectFavi == true) {

      console.log(this.dataConvertDispenseMedicine);

      const dialogRef = this.dialog.open(DialogAddDispenseMedicine, {
        // width: '250px',
        data: { dataSave: this.dataConvertDispenseMedicine, medicineType: "Medicine" }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.socket.emit('dataAlertPending_Covid19', { msg: "doctor-dispenseMedicine", status: "unread", idOperator: this.idOperatorAdmin });
        window.location.reload();
        this.radioStatus = false;
        this.disabledOthers = true;
        this.disabledOxygen = true;
        this.disabledOmeprazole = true;
        this.disabledPrednisolone = true;
        this.disabledDexamethasone = true;
        this.disabledFaviFirst5days = true;
        this.disabledFaviNext5days = true;
        this.disabledFavi = true;
        this.selectOthers = false
        this.selectOxygen = false
        this.selectOmeprazole = false
        this.selectPrednisolone = false
        this.selectDexamethasone = false
        this.selectFavi = false
        this.dispenseMedicine = this.formBuilder.group({
          FavipiravirStartdate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
          FavipiravirFirst5daysStart: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
          FavipiravirFirst5daysEnd: this.dateTime_,
          FavipiravirNext5daysStart: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
          FavipiravirNext5daysEnd: this.dateTime_,
          FavipiravirNote: '',
          DexamethasoneStartdate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
          DexamethasoneMG: '',
          DexamethasoneFrequency: '',
          DexamethasoneTimes: '',
          DexamethasoneNote: '',
          PrednisoloneStartdate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
          PrednisoloneMG: '',
          PrednisoloneFrequency: '',
          PrednisoloneTimes: '',
          PrednisoloneNote: '',
          OmeprazoleStartDate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
          OmeprazoleNote: '',
          OmeprazoleMG: '',
          Omeprazoledays: '',
          OxygenNote: '',
          OthersNote: '',
        })
      });
    }

  }

  onCheckMptStatiaCz(event: any) {
    this.selectMptStatiaCz = event.checked;
    if (event.checked == true) {
      this.selectMedicineStatiaCZNote = false;
    } else {
      this.selectMedicineStatiaCZNote = true;
    }
  }
  onCheckMptStatia(event: any) {
    this.selectMptStatia = event.checked;
    if (event.checked == true) {
      this.selectMedicineStatiaNote = false;
    } else {
      this.selectMedicineStatiaNote = true;
    }
  }
  onCheckMpt5roots(event: any) {
    this.selectMpt5roots = event.checked;
    if (event.checked == true) {
      this.selectMedicine5rootsNote = false;
    } else {
      this.selectMedicine5rootsNote = true;
    }
  }
  onCheckMptGreenMedicine(event: any) {
    this.selectMptGreenMedicine = event.checked;
    if (event.checked == true) {
      this.selectMedicineGreenMedicineNote = false;
    } else {
      this.selectMedicineGreenMedicineNote = true;
    }
  }
  onCheckMptAummahiva(event: any) {
    this.selectMptAummahiva = event.checked;
    if (event.checked == true) {
      this.selectMedicineAummahivaNote = false;
    } else {
      this.selectMedicineAummahivaNote = true;
    }
  }
  onCheckMptPrasapoyai(event: any) {
    this.selectMptPrasapoyai = event.checked;
    if (event.checked == true) {
      this.selectMedicinePrasapoyaiNote = false;
    } else {
      this.selectMedicinePrasapoyaiNote = true;
    }
  }
  onCheckMptJanlelar(event: any) {
    this.selectMptJanlelar = event.checked;
    if (event.checked == true) {
      this.selectMedicineJanlelarNote = false;
    } else {
      this.selectMedicineJanlelarNote = true;
    }
  }
  onCheckMptHomyen(event: any) {
    this.selectMptHomyen = event.checked;
    if (event.checked == true) {
      this.selectMedicineHomyenNote = false;
    } else {
      this.selectMedicineHomyenNote = true;
    }
  }
  onCheckMptHomron(event: any) {
    this.selectMptHomron = event.checked;
    if (event.checked == true) {
      this.selectMedicineHomronNote = false;
    } else {
      this.selectMedicineHomronNote = true;
    }
  }
  onCheckMptFatalaijon(event: any) {
    this.selectMptFatalaijon = event.checked;
    if (event.checked == true) {
      this.selectMedicineFatalaijonNote = false;
    } else {
      this.selectMedicineFatalaijonNote = true;
    }
  }

  onSubmitDispenseMedicineTH() {
    if (this.selectMptStatiaCz == true || this.selectMptAummahiva == true ||
      this.selectMptStatia == true || this.selectMptPrasapoyai == true ||
      this.selectMpt5roots == true || this.selectMptJanlelar == true ||
      this.selectMptGreenMedicine == true || this.selectMptHomyen == true ||
      this.selectMptHomron == true || this.selectMptFatalaijon == true) {
      var noteStatiaCz = "";
      var noteStatia = "";
      var note5roots = "";
      var noteGreenMedicine = "";
      var noteAummahiva = "";
      var notePrasapoyai = "";
      var noteJanlelar = "";
      var noteHomyen = "";
      var noteHomron = "";
      var noteFatalaijo = "";
      if (this.selectMptStatiaCz == true) {
        noteStatiaCz = this.dispenseMedicineTH.value.medicineStatiaCZNote;
      }
      if (this.selectMptStatia == true) {
        noteStatia = this.dispenseMedicineTH.value.medicineStatiaNote;
      }
      if (this.selectMpt5roots == true) {
        note5roots = this.dispenseMedicineTH.value.medicine5rootsNote;
      }
      if (this.selectMptGreenMedicine == true) {
        noteGreenMedicine = this.dispenseMedicineTH.value.medicineGreenMedicineNote;
      }
      if (this.selectMptAummahiva == true) {
        noteAummahiva = this.dispenseMedicineTH.value.medicineAummahivaNote;
      }
      if (this.selectMptPrasapoyai == true) {
        notePrasapoyai = this.dispenseMedicineTH.value.medicinePrasapoyaiNote;
      }
      if (this.selectMptJanlelar == true) {
        noteJanlelar = this.dispenseMedicineTH.value.medicineJanlelarNote;
      }
      if (this.selectMptHomyen == true) {
        noteHomyen = this.dispenseMedicineTH.value.medicineHomyenNote;
      }
      if (this.selectMptHomron == true) {
        noteHomron = this.dispenseMedicineTH.value.medicineHomronNote;
      }
      if (this.selectMptFatalaijon == true) {
        noteFatalaijo = this.dispenseMedicineTH.value.medicineFatalaijonNote;
      }
      this.dataConvertDispenseMedicineTH = {
        "MptStatiaCz": this.selectMptStatiaCz,
        "MptStatiaCzNote": noteStatiaCz,
        "MptStatia": this.selectMptStatia,
        "MptStatiaNote": noteStatia,
        "Mpt5roots": this.selectMpt5roots,
        "Mpt5rootsNote": note5roots,
        "MptGreenMedicine": this.selectMptGreenMedicine,
        "MptGreenMedicineNote": noteGreenMedicine,
        "MptAummahiva": this.selectMptAummahiva,
        "MptAummahivaNote": noteAummahiva,
        "MptPrasapoyai": this.selectMptPrasapoyai,
        "MptPrasapoyaiNote": notePrasapoyai,
        "MptJanlelar": this.selectMptJanlelar,
        "MptJanlelarNote": noteJanlelar,
        "MptHomyen": this.selectMptHomyen,
        "MptHomyenNote": noteHomyen,
        "MptHomron": this.selectMptHomron,
        "MptHomronNote": noteHomron,
        "MptFatalaijon": this.selectMptFatalaijon,
        "MptFatalaijonNote": noteFatalaijo,
        "MptUserId": this.getIdUser,
        "MptStatus": "request",
        "MptDate": this.dispenseMedicine.value.DexamethasoneMG,

        //table TbAdminAlert
        "AaType": "MedPresTH",
        // public long? AaSubId : "",
        "AaOperatorId": this.idOperatorAdmin,
        "AaStatus": false
      }
      // console.log(this.dataConvertDispenseMedicineTH);

      const dialogRef = this.dialog.open(DialogAddDispenseMedicine, {
        // width: '250px',
        data: { dataSave: this.dataConvertDispenseMedicineTH, medicineType: "MedicineTH" }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.socket.emit('dataAlertPending_Covid19', { msg: "doctor-dispenseMedicine", status: "unread", idOperator: this.idOperatorAdmin });
        window.location.reload();
      });
    }
  }

  async openDialogReferUserCovid19(datauser: any, commentDoctor: any): Promise<void> {

    var dataRefer_: any = [];
    var dataCommentDoctor: any = [];
    var dataUserCo: any = [];
    var dataAllUserCovid19: any;
    var dataDrugUserCovid19: any;
    var dataDrugTHUserCovid19: any;
    dataCommentDoctor = { comment: commentDoctor }
    dataUserCo = { dataUser: datauser }
    if (this.lastCovidDispenseTH[0] != undefined && this.lastCovidDispenseTH[0].medPres.mptStatus == 'accept') {
      dataDrugTHUserCovid19 = { dataDrugTH: this.lastCovidDispenseTH[0].medPres }
    } else {
      dataDrugTHUserCovid19 = { dataDrugTH: "" }
    }
    await this.ServiceService.GetdataProfileUserCovidAll_(datauser.userId).then(async resAllUserCovid19 => {
      // console.log("resPersonalHealth : ", resAllUserCovid19);
      dataAllUserCovid19 = resAllUserCovid19;
    });
    await this.ServiceService.GetDrugUserCovid19ByUser_(datauser.userId).then(resDrugUserCovid19 => {
      // console.log("resDrugUserCovid19 > ",resDrugUserCovid19);

      dataDrugUserCovid19 = { dataDrug: resDrugUserCovid19 };
    });
    dataRefer_ = Object.assign(dataAllUserCovid19[0], dataCommentDoctor);
    dataRefer_ = Object.assign(dataRefer_, dataUserCo);
    dataRefer_ = Object.assign(dataRefer_, dataDrugUserCovid19);
    dataRefer_ = Object.assign(dataRefer_, dataDrugTHUserCovid19);
    // console.log("dataRefer_ ", dataRefer_);

    const dialogRef = this.dialog.open(DialogdialogReferUserCovid19, {
      width: '1000px',
      data: dataRefer_
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  async endCase(userId: string) {
    var dataAllUserCovid19: any;
    await this.ServiceService.GetdataProfileUserCovidAll_(userId).then(async resAllUserCovid19 => {
      // console.log("resPersonalHealth : ", resAllUserCovid19);
      dataAllUserCovid19 = resAllUserCovid19
      const dialogRef = this.dialog.open(DialogdialogSuccessReferCovid19, {
        width: '350px',
        disableClose: true,
        data: {
          userID: userId,
          dmID: dataAllUserCovid19[0].doctorMall.dmId,
          refer: "noRefer"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    });
  }

  openDialogHistoryReferUserCovid19(datauser: any, commentDoctor: any) {
    const dialogRef = this.dialog.open(DialoghistoryReferUserCovid19, {
      width: '1000px',
      // disableClose: true,
      data: {
        dataUser: datauser,
        dataComment: commentDoctor,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
