<h2 mat-dialog-title>Edit Admin</h2>
<form [formGroup]="editAdminForm">
    <mat-dialog-content class="mat-typography" style="width: 1000px;">
        <div class="row">
            <div class="col-md">
                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="Username " formControlName="usernameEdit"
                        oninput="this.value = this.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ''); this.value = this.value.replace(/[^A-Za-z0-9_]/gi, '');" required>
                    <mat-hint *ngIf="InvalidUsernameLength" style="color: red;"><b>This field length should be larger</b></mat-hint>
                    <mat-hint *ngIf="InvalidUsername" style="color: red;"><b>Duplicate Username</b></mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md">

                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Enter your password old</mat-label>
                    <input matInput placeholder="Password" formControlName="passwordEdit"
                        [type]="hidePassword ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint *ngIf="InvalidOldPassword" style="color: red;"><b>The old password is invalid.</b></mat-hint>
                </mat-form-field>
                
            </div>
        </div>
        <div class="row">
            <div class="col-md">

                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Enter your password new</mat-label>
                    <input matInput placeholder="Password" formControlName="passwordNew"
                    [type]="hidePasswordConfirm ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordConfirm">
                    <mat-icon>{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint *ngIf="InvalidNewAndOldPassword" style="color: red;"><b>New and old passwords don't match.</b></mat-hint>
                </mat-form-field>

            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Phone</mat-label>
                    <input matInput placeholder="Phone" formControlName="phoneEdit" oninput="this.value = this.value.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
                    this.value = this.value.replace(/[^0-9]/gi, '');" minlength="10" maxlength="10" required>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button type="submit" color="primary"  (click)="onUpdateAdmin()">Update</button>
    </mat-dialog-actions>
</form>