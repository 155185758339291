<div class="mt-3">

    <div class="row">
      <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
        <h1 style="color: #222;" style="margin: 0px;">StepCount Chart</h1>
      </div>
      <div class="col-md-4 text-right">

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Enter a date range</mat-label>

          <mat-date-range-input [formGroup]="rangeDateStartAndEnd" [rangePicker]="picker_start_end">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onChengDate()">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
          <mat-date-range-picker #picker_start_end></mat-date-range-picker>
        </mat-form-field>
      </div>

    </div>

    <div *ngIf="countChartData != 0" >

    <div class="card mt-2">
      <div class="card-body">
        <div #chartStepCount style="width: 100%; height: 600px"></div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h6 class="nospace">Average (เฉลี่ย)</h6>
            <h3 class="nospace txtindigo">{{averStepCountValue | number : '1.2-2'}} ก้าวต่อวัน</h3>
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h6 class="nospace">Max (มากสุด)</h6>
            <h3 class="nospace txtindigo">{{maxStepCountValue}} ก้าวต่อวัน</h3>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-4 mt-3">
        <div class="card">
          <div class="card-body">
            <h6 class="nospace">Min (น้อยสุด)</h6>
            <h3 class="nospace txtindigo">{{minStepCountValue}} ก้าวต่อวัน</h3>
          </div>
        </div>
      </div> -->


      <div class="col-md-12 mt-3">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-8">
                  <h6 class="nospace">StepCount 24Hr Average Chart</h6>
                  <span *ngFor="let datelist of datetimeShowList" class="badge bg-primary" style="margin-right: 2px;">{{ datelist.datetxtShow }}</span>
              </div>
              <div class="col-md-4 text-right">
                  <mat-form-field style="width: 100%;" appearance="outline">
                      <input matInput [matDatepicker]="picker" placeholder="Choose a date" [value]="setNow"
                          (dateChange)="onChangeDateTimeEvent($event, false)">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
              </div>
          </div>

            <!-- <div #chartStepCountAver24 style="width: 100%; height: 600px"></div> -->
            <div #chartStepCountAver24Line class="mt-2" style="width: 100%; height: 500px"></div>

          </div>
        </div>
      </div>


      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h4 class="nospace txtindigo"><span>การเผาผลาญพลังงาน</span></h4>
            <h3 class="nospace txtindigo"><span style="font-size: 20px; color: #F93154;"> {{ d1CalDemo | number : '1.2-2' }} แคลลอรี่</span></h3>
            <p class="nospace">ทุกการเดิน 20 ก้าว จะเบิร์นได้ 1 กิโลแคลอรี่ ทั้งก็ขึ้นอยู่กับน้ำหนักตัวของท่าน หากน้ำหนักมากก็จะเบิร์นได้มากกว่า</p>
            <p class="nospace"> ทุก ๆ 3,500 กิโลแคลอรี่ที่เบิร์นออกไป น้ำหนักจะลง 0.45 กิโลกรัม </p>
            <p class="nospace"> คนที่หนักไม่เกิน 60 กิโลกรัม เดิน 30 นาที จะเผาผลาญไปได้ประมาณ 90 กิโลแคลอรี่ </p>
            <a href="https://www.cosmenet.in.th/cosme-intrend/33294">ที่มา https://www.cosmenet.in.th/cosme-intrend/33294</a>
          </div>
        </div>
      </div>


      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h4 class="nospace txtindigo"><span>เดินเป็นระยะทางใน 1 วัน ได้ประมาณ</span></h4>
            <h3 class="nospace txtindigo"><span style="font-size: 20px; color: #F93154;">ประมาณ {{ d1WalkDemo | number : '1.2-2' }} กิโลเมตร</span></h3>
            <p class="nospace"> หญิง ความสูง x 0.413 | ชาย ความสูง x 0.415 </p>
            <p class="nospace"> สมมุติถ้าเป็นชายสูง 170 cm และเดินวันละ 10,000 ก้าวระยะทางเฉลี่ยที่ได้จะเท่ากับ </p>
            <p class="nospace"> 170 cm x 0.415 = 70.55 cm/step </p>
            <p class="nospace"> เดิน 10,000 ก้าว x 70.55 cm = 705500 cm. = 7.06 ก.ม. </p>
          </div>
        </div>
      </div>



      <div class="col-md-12 mt-3">
        <div class="card">
          <div class="card-body">
            <h6 class="nospace">StepCount 7 Days Average Chart</h6>
            <div #chartStepCountAver7day style="width: 100%; height: 400px"></div>
          </div>
        </div>
      </div>


    </div>
    </div>

  <div *ngIf="countChartData == 0" style="display: flex;justify-content: center;"> <h2>*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>


    <br>
    <br>

    </div>
