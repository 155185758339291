<mat-dialog-content class="mat-typography" *ngIf="dataConfirmViewProfile.alertMsg == 'Green --> Yellow' || dataConfirmViewProfile.alertMsg == 'Green --> Red' || dataConfirmViewProfile.alertMsg == 'Yellow --> Red' ||
  dataConfirmViewProfile.alertMsg == 'SpO2 Fail' || dataConfirmViewProfile.alertMsg == 'Feverish 5 Days' || dataConfirmViewProfile.alertMsg == 'Temp >= 37.5 --> 5 Days' ||
  dataConfirmViewProfile.daType == 'Green --> Yellow' || dataConfirmViewProfile.daType == 'Green --> Red' || dataConfirmViewProfile.daType == 'Yellow --> Red' ||
  dataConfirmViewProfile.daType == 'SpO2 Fail' || dataConfirmViewProfile.daType == 'Feverish 5 Days' || dataConfirmViewProfile.daType == 'Temp >= 37.5 --> 5 Days'">
  <h2 mat-dialog-title>You want to view profile</h2>
  <p>You want to view profile <b>{{dataConfirmViewProfile.userName}}</b></p>
  <div class="row" style="margin-left: 0px;">
    <p style="padding-right: 5px;"><b>SpO2 : </b> {{dataConfirmViewProfile.cSpo2}}</p>
    <p style="padding-right: 5px;"><b>SBP : </b> {{dataConfirmViewProfile.cPressureHigh}}</p>
    <p style="padding-right: 5px;"><b>DBP : </b> {{dataConfirmViewProfile.cPressureLow}}</p>
    <p style="padding-right: 5px;"><b>HR : </b> {{dataConfirmViewProfile.cHeartrate}}</p>
    <p style="padding-right: 5px;"><b>T : </b> {{dataConfirmViewProfile.cTemp}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-content class="mat-typography" 
*ngIf="dataConfirmViewProfile.alertMsg != 'Green --> Yellow' && dataConfirmViewProfile.alertMsg != 'Green --> Red' && dataConfirmViewProfile.alertMsg != 'Yellow --> Red' &&
dataConfirmViewProfile.alertMsg != 'SpO2 Fail' && dataConfirmViewProfile.alertMsg != 'Feverish 5 Days' && dataConfirmViewProfile.alertMsg != 'Temp >= 37.5 --> 5 Days' &&
dataConfirmViewProfile.daType != 'Green --> Yellow' && dataConfirmViewProfile.daType != 'Green --> Red' && dataConfirmViewProfile.daType != 'Yellow --> Red' &&
dataConfirmViewProfile.daType != 'SpO2 Fail' && dataConfirmViewProfile.daType != 'Feverish 5 Days' && dataConfirmViewProfile.daType != 'Temp >= 37.5 --> 5 Days'">
  <h2 mat-dialog-title>You want to view page case start</h2>
  <p>You want to view page case start <b>{{dataConfirmViewProfile.userName}} SOS</b></p>
  <h3>ขอความช่วยเหลือ <b>SOS</b></h3>
  <div class="row" style="margin-left: 0px;">
    <p style="padding-right: 5px;"><b>SpO2 : </b> {{dataConfirmViewProfile.cSpo2}}</p>
    <p style="padding-right: 5px;"><b>SBP : </b> {{dataConfirmViewProfile.cPressureHigh}}</p>
    <p style="padding-right: 5px;"><b>DBP : </b> {{dataConfirmViewProfile.cPressureLow}}</p>
    <p style="padding-right: 5px;"><b>HR : </b> {{dataConfirmViewProfile.cHeartrate}}</p>
    <p style="padding-right: 5px;"><b>T : </b> {{dataConfirmViewProfile.cTemp}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button *ngIf="dataConfirmViewProfile.alertMsg == 'Green --> Yellow' || dataConfirmViewProfile.alertMsg == 'Green --> Red' || dataConfirmViewProfile.alertMsg == 'Yellow --> Red' ||
    dataConfirmViewProfile.alertMsg == 'SpO2 Fail' || dataConfirmViewProfile.alertMsg == 'Feverish 5 Days' || dataConfirmViewProfile.alertMsg == 'Temp >= 37.5 --> 5 Days' ||
    dataConfirmViewProfile.daType == 'Green --> Yellow' || dataConfirmViewProfile.daType == 'Green --> Red' || dataConfirmViewProfile.daType == 'Yellow --> Red' ||
    dataConfirmViewProfile.daType == 'SpO2 Fail' || dataConfirmViewProfile.daType == 'Feverish 5 Days' || dataConfirmViewProfile.daType == 'Temp >= 37.5 --> 5 Days'" [mat-dialog-close]="true" (click)="onClickConfirm(dataConfirmViewProfile)"
    cdkFocusInitial>OK</button>
  <button mat-button *ngIf="dataConfirmViewProfile.alertMsg != 'Green --> Yellow' && dataConfirmViewProfile.alertMsg != 'Green --> Red' && dataConfirmViewProfile.alertMsg != 'Yellow --> Red' &&
    dataConfirmViewProfile.alertMsg != 'SpO2 Fail' && dataConfirmViewProfile.alertMsg != 'Feverish 5 Days' && dataConfirmViewProfile.alertMsg != 'Temp >= 37.5 --> 5 Days' &&
    dataConfirmViewProfile.daType != 'Green --> Yellow' && dataConfirmViewProfile.daType != 'Green --> Red' && dataConfirmViewProfile.daType != 'Yellow --> Red' &&
    dataConfirmViewProfile.daType != 'SpO2 Fail' && dataConfirmViewProfile.daType != 'Feverish 5 Days' && dataConfirmViewProfile.daType != 'Temp >= 37.5 --> 5 Days'" [mat-dialog-close]="true" (click)="startPracticeJob(dataConfirmViewProfile)"
    cdkFocusInitial>Start Case</button>
</mat-dialog-actions>