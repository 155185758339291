import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../../core/services/service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-covid-dispense-medicine-list',
  templateUrl: './covid-dispense-medicine-list.component.html',
  styleUrls: ['./covid-dispense-medicine-list.component.scss']
})
export class CovidDispenseMedicineListComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  getIdUser: any;

  resultDataCovidDispense: any;
  resultDataCovidDispenseTH: any;
  selected = '20';
  loadDataUser: boolean = true;
  loadDataUserTH: boolean = true;
  tableShow: boolean = false;
  tableShowTH: boolean = false;
  cardShow: boolean = false;
  cardShowTH: boolean = false;
  noDataInformation: boolean = false;
  noDataInformationTH: boolean = false;

  dataPrescription: number= -1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.getIdUser = this.activatedRoute.snapshot.paramMap.get('idUser');
  }

  ngOnInit(): void {
    this.loadDataCovidDispense(this.selected);
    this.loadDataCovidDispenseTH(this.selected);
  }


  onClickRefresh() {
    this.loadDataUser = true;
    this.loadDataUserTH = true;
    this.tableShow = false;
    this.tableShowTH = false;
    this.cardShow = false;
    this.cardShowTH = false;
    this.loadDataCovidDispense(this.selected);
    this.loadDataCovidDispenseTH(this.selected);
  }

  onChangeCountItem(event: string) {
    this.loadDataUser = true;
    this.loadDataUserTH = true;
    this.tableShow = false;
    this.tableShowTH = false;
    this.cardShow = false;
    this.cardShowTH = false;
    this.resultDataCovidDispense = [];
    this.resultDataCovidDispenseTH = [];
    this.selected = event;
    this.loadDataCovidDispense(this.selected);
    this.loadDataCovidDispenseTH(this.selected);
  }

  onClickShowData(type: string) {
    this.loadDataUser = true;
    this.loadDataUserTH = true;
    this.tableShow = false;
    this.tableShowTH = false;
    this.cardShow = false;
    this.cardShowTH = false;
    if (type == "card") {
      setTimeout(() => {
        this.loadDataUser = false;
        this.loadDataUserTH = false;
        this.tableShow = false;
        this.tableShowTH = false;
        this.cardShow = true;
        this.cardShowTH = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.loadDataUser = false;
        this.loadDataUserTH = false;
        this.tableShow = true;
        this.tableShowTH = true;
        this.cardShow = false;
        this.cardShowTH = false;
      }, 2000);
    }
  }

  loadDataCovidDispense(countLists: string) {
    this.loadData(countLists).then(resultDaTa => {
      this.resultDataCovidDispense = [];
      if (resultDaTa != false) {
        this.resultDataCovidDispense = resultDaTa;
        setTimeout(() => {
          this.noDataInformation = false;
          this.loadDataUser = false;
          this.tableShow = true;
          this.cardShow = false;
        }, 2000);
      } else {
        setTimeout(() => {
          this.noDataInformation = true;
          this.loadDataUser = false;
          this.tableShow = true;
          this.cardShow = false;
        }, 2000);
      }
    });
  }

  async loadData(countLists: string) {
    return new Promise<any>(async (resolve, rejects) => {
      var dataCovidDispense: any;
      await this.ServiceService.GetDataCovidDispense_(this.getIdUser, countLists).then(res => {
        dataCovidDispense = res;
        if (dataCovidDispense.length != 0) {
          resolve(dataCovidDispense);
        } else {
          resolve(false);
        }
      });
    });
  }

  myTabFocusChange(changeEvent: MatTabChangeEvent) {

    this.dataPrescription = Number(changeEvent.tab.position);
    // console.log(this.dataPrescription);
  }


  loadDataCovidDispenseTH(countLists: string) {
    this.loadDataTH(countLists).then(resultDaTa => {
      // console.log("resultDaTa ",resultDaTa);

      this.resultDataCovidDispenseTH = [];
      if (resultDaTa != false) {
        this.resultDataCovidDispenseTH = resultDaTa;
        setTimeout(() => {
          this.noDataInformationTH = false;
          this.loadDataUserTH = false;
          this.tableShowTH = true;
          this.cardShowTH = false;
        }, 2000);
      } else {
        setTimeout(() => {
          this.noDataInformationTH = true;
          this.loadDataUserTH = false;
          this.tableShowTH = true;
          this.cardShowTH = false;
        }, 2000);
      }
    });
  }

  async loadDataTH(countLists: string) {
    return new Promise<any>(async (resolve, rejects) => {
      var dataCovidDispense: any;
      await this.ServiceService.GetGetMedPresTHListByUser_(this.getIdUser, countLists).then(res => {
        dataCovidDispense = res;
        if (dataCovidDispense.length != 0) {
          resolve(dataCovidDispense);
        } else {
          resolve(false);
        }
      });
    });
  }

}
