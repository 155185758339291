import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ServiceService } from 'src/app/core/services/service.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-respiratory-rate',
  templateUrl: './respiratory-rate.component.html',
  styleUrls: ['./respiratory-rate.component.scss']
})
export class RespiratoryRateComponent implements OnInit {
  rrList: any[] = [];
  rrListOrigin: any[] = [];
  rrStart: Date = new Date(new Date().toLocaleDateString());
  rrEnd: Date = new Date(new Date().toLocaleDateString());
  rrAvg: number = 0;
  rrMax: number = 0;
  rrMin: number = 0;
  @Input() imei: string;
  @ViewChild('rrChart', { static: false }) rrChart!: ElementRef;

  constructor(
    private changeDetectRef: ChangeDetectorRef,
    private serviceService: ServiceService,
    private datePipe: DatePipe
  ) { }

  async ngOnInit() {
    await this.getRRList(this.imei);
  }

  getRRList(Imei: string) {
    this.serviceService.getCN_RespiraList_(Imei).then(res => {
      if (res.length) this.rrList = res;
      this.rrList = this.rrList.map((item: any) => ({
        ...item,
        rr_val: Number(item.rr_val),
        datetime: this.datePipe.transform(new Date(item.time_log).getTime() - (1000 * 60 * 60 * 7), "dd/MM/yyyy H:mm")
      }));
      this.rrList = this.rrList.sort((a: any, b: any) => new Date(a.time_log).getTime() - new Date(b.time_log).getTime());
      this.rrListOrigin = this.rrList;
      for (let i = 0; i < this.rrListOrigin.length; i++) {
        this.rrAvg += this.rrListOrigin[i].rr_val;
        if (i <= 0) {
          this.rrMax = this.rrListOrigin[i].rr_val;
          this.rrMin = this.rrListOrigin[i].rr_val;
        } else {
          if (this.rrListOrigin[i].rr_val > this.rrMax) this.rrMax = this.rrListOrigin[i].rr_val;
          if (this.rrListOrigin[i].rr_val < this.rrMin) this.rrMin = this.rrListOrigin[i].rr_val;
        }
      }
      this.rrAvg = this.rrAvg / this.rrListOrigin.length;
      this.filterDate(this.rrEnd);
    }, err => {
      console.log("err -> ", err);
    });
  }

  filterDate(Event: Date) {
    this.rrList = this.rrListOrigin;
    let startDate: Date = new Date(new Date(this.rrStart).getTime() + (1000 * 60 * 60 * 7));
    let endDate: Date = new Date(new Date(this.rrEnd).getTime() + (1000 * 60 * 60 * 7));
    if (startDate && endDate && new Date(startDate).getTime() != new Date(endDate).getTime()) this.rrList = this.rrList.filter((item: any) => new Date(item.time_log).getTime() >= new Date(startDate).getTime() && new Date(item.time_log).getTime() < new Date(endDate).getTime() + ((1000 * 60 * 60 * 23) + (1000 * 60 * 59) + (1000 * 59)));
    else this.rrList = this.rrList.filter((item: any) => new Date(item.time_log).getTime() >= new Date(startDate).getTime() && new Date(item.time_log).getTime() < new Date(startDate).getTime() + ((1000 * 60 * 60 * 23) + (1000 * 60 * 59) + (1000 * 59)));
    setTimeout(async () => {
      await this.generateRRChart();
    }, 1500);
  }

  generateRRChart() {
    this.changeDetectRef.detectChanges();
    am4core.useTheme(am4themes_animated);
    var chart = am4core.create(this.rrChart.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.data = this.rrList;
    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color('#243b55'));
    gradient.addColor(am4core.color('#141e30'));
    gradient.rotation = 90;
    chart.background.fill = gradient;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'datetime';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    categoryAxis.renderer.labels.template.fill = am4core.color('#ffffff');
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 35;
    valueAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    valueAxis.renderer.labels.template.fill = am4core.color('#ffffff');
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'rr_val';
    series.dataFields.categoryX = 'datetime';
    series.strokeWidth = 1.5;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('#64dbfb');
    series.tooltipText = '[bold]{datetime}:[/] RR — {rr_val}\n[bold]';
    var highRange = valueAxis.axisRanges.create();
    highRange.value = 25;
    highRange.endValue = 35;
    highRange.axisFill.fill = am4core.color('#ff5566');
    highRange.axisFill.fillOpacity = 0.2;
    highRange.grid.strokeOpacity = 0;
    var newNormalRange = valueAxis.axisRanges.create();
    newNormalRange.value = 20;
    newNormalRange.endValue = 25;
    newNormalRange.axisFill.fill = am4core.color('#64dbfb');
    newNormalRange.axisFill.fillOpacity = 0.2;
    newNormalRange.grid.strokeOpacity = 0;
    var normalRange = valueAxis.axisRanges.create();
    normalRange.value = 11;
    normalRange.endValue = 20;
    normalRange.axisFill.fill = am4core.color('#00ba00');
    normalRange.axisFill.fillOpacity = 0.2;
    normalRange.grid.strokeOpacity = 0;
    var lowRange = valueAxis.axisRanges.create();
    lowRange.value = 0;
    lowRange.endValue = 11;
    lowRange.axisFill.fill = am4core.color('#ff5566');
    lowRange.axisFill.fillOpacity = 0.2;
    lowRange.grid.strokeOpacity = 0;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }
}
