import { ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalvalDialog } from '../modal/globalval/globalval.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-body-temp',
  templateUrl: './body-temp.component.html',
  styleUrls: ['./body-temp.component.scss']
})
export class BodyTempComponent implements OnInit {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  ipServer: string = "https://api.medilifewellness.com:2443";
  TrickerServer:string = "https://connect.medilifewellness.com:3143";

  // ipServer: string = "https://localhost:44378";

  pageGetDatatype:string = "bodytemp";


  imeiList: any[] = [];

  dataChartBodyTemp: any[] = [];
  dataCalculateBodyTemp: any[] = [];
  dataChart24List: any[] = [];
  dataChart24List_new: any[] = [];

  dataChart7DayList: any[] = [];
  minBodyTempValue: any = 0;
  maxBodyTempValue: any = 0;
  averBodyTempValue: any = 0;


  filterEmtyData: any[] = [];
  datetimeShowList: any[] = [];


  dataChart24List_Day1: any[] = [];
  dataCalculateBodyTemp_Day1: any[] = [];

  tickerDataToTableList: any[] = [];


  countMin: any = 0;
  countNor: any = 0;
  countMax: any = 0;
  countvalErr: any = 0;

  gimeiCode: any = "";
  dataBodyTempAll: any = [];

  rangeDateStartAndEnd = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date())
  });

  @ViewChild('chartBodyTemp', { static: false }) chartBodyTemp!: ElementRef;
  // @ViewChild('chartBodyTempAver24', { static: false }) chartBodyTempAver24!: ElementRef;
  @ViewChild('chartBodyTempAver24Line', { static: false }) chartBodyTempAver24Line!: ElementRef;
  @ViewChild('chartBodyTempAver7day', { static: false }) chartBodyTempAver7day!: ElementRef;
  @ViewChild('chartBodyTempValPercen', { static: false }) chartBodyTempValPercen!: ElementRef;

  imei: string = "";
  value: string = "";
  loading: boolean = true;
  setNow: any = new Date();

  constructor(private httpClient: HttpClient,
    private sharedServiceService: SharedServiceService,
    @Inject(LOCALE_ID) private locale: string,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private changeDetectRef:ChangeDetectorRef,
    private route: ActivatedRoute,
    private datePipe: DatePipe
    ) {
      this.imei = this.route.snapshot.params["imei"];
      this.value = this.route.snapshot.params["value"];
  }

  ngOnInit(): void {
    this.sharedServiceService.deviceIDValue.subscribe(
      (message) => {
        //  console.log("message ID ", message);

        this.loadDataChart(message ? message : this.imei);
      }
    );
    this.loadGobalListData();
    let dateNow = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    setTimeout(async () => {
      this.loading = false;
      await this.onChangeDateTimeEvent(dateNow, true);
    }, 1500);
  }

  onChengDate() {

    var dataStart: string = "";
    var dataEnd: string = "";

    dataStart = this.rangeDateStartAndEnd.value.start;
    dataEnd = this.rangeDateStartAndEnd.value.end;

    if (this.rangeDateStartAndEnd.value.start != null && this.rangeDateStartAndEnd.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);

      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      // console.log("dataStart_ ", dataStart_, " | dataEnd_ ", dataEnd_);

      this.filterDate(dataStart_, dataEnd_);
    }

  }

  loadIMEIListFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_BodyTemp_CollectionList`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  openDialogGlovbalval(typeGlobal: any): void {
    const dialogRef = this.dialog.open(GlobalvalDialog, {
      width: '700px',
      data: { typeGlobal },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed', result);
      if ((result+"").startsWith('Update')) {
        this.loadGobalListData();
        this._snackBar.open(result, 'Close', {
          duration: 1000
        });
      }
      else{
        this._snackBar.open(result, 'Close', {
          duration: 3000
        });
      }
    });
  }

  //////////////////////////////////////////////////////////////////////////////

  g_name:any;
  g_minvalue:any = 0;
  g_maxvalue:any = 0;
  g_diffvalue:any = 0;

  loadGobalValFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getEditGobalValue?gobaltext=${this.pageGetDatatype}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  async loadGobalListData() {
    await this.loadGobalValFormAPI().then(
      (res: any) => {
        this.g_name = res['swgvName'];
        this.g_minvalue = res['swgvMinValue'];
        this.g_maxvalue = res['swgvMaxValue'];
        this.g_diffvalue = res['swgvDifferenceValue'];
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }

  setGlobalValue(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/setEditInfoValue`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: 'text',
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveGobalDataValue(minval:any,maxval:any,diffval:any){
    // console.log("save ", minval, maxval, diffval);

    var jsonData = {
      SwgvName: 'bodytemp',
      SwgvMinValue: minval,
      SwgvMaxValue: maxval,
      SwgvDifferenceValue: diffval,
    };

    this.setGlobalValue(jsonData).then(
       (res: any) => {
        this.loadGobalListData();
        // console.log(res.data);
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  //////////////////////////////////////////////////////////////////////////


  loadChartFormAPI(imeiCode: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_LogBodyTempList?imeicode=${imeiCode}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  loadAvgTemp(imeiCode: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.TrickerServer}/avg_log?sw_imei=${imeiCode}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  async loadDataChart(imeiCode: string) {
    await this.loadChartFormAPI(imeiCode).then(
      async (resq: any) => {

        // console.log("bodytemp res ",resq);
        var res = [];

        this.gimeiCode = imeiCode;

        this.dataChart24List = [];


        for (var i = 0; i < resq.length; i++) {
          if (resq[i].body_temp >= 20) {
            res.push({
              id: resq[i].id,
              imeicode: resq[i].imeicode,
              body_temp: resq[i].body_temp,
              time_log: resq[i].time_log
            });
          }
        }


        res.sort(function (a: any, b: any) {
          return + new Date(a.time_log) - + new Date(b.time_log);
        });

        this.dataBodyTempAll = await res;
        // console.log("res -------------> ", res);

        if (!this.value) {
          this.filterDate("", "");
        } else {
          var sentStartDate: any = this.datePipe.transform(this.value, "yyyy-MM-dd")?.toString();
          var addOneDate = new Date(this.value);
          addOneDate.setDate(addOneDate.getDate() + 1);
          var sentEndDate: any = this.datePipe.transform(addOneDate, "yyyy-MM-dd")?.toString();
          this.filterDate(sentStartDate, sentEndDate);
          this.rangeDateStartAndEnd.setValue({
            start: sentStartDate,
            end: sentStartDate
          });
        }


      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }

  async filterDate(dateStart: string, dateEnd: string) {
    var dataBodyTempAll_ = this.dataBodyTempAll;
    var dataBodyTemp: any;
    this.filterEmtyData = [];
    this.datetimeShowList = [];

    this.dataCalculateBodyTemp = [];
    this.dataChartBodyTemp = [];
    this.dataChart24List = [];
    this.dataChart7DayList = [];
    this.minBodyTempValue = 0;
    this.maxBodyTempValue = 0;
    this.averBodyTempValue = 0;
    this.countMin = 0;
    this.countMax = 0;
    this.countNor = 0;

    if (dateStart != "" && dateEnd != "") {
      dataBodyTemp = dataBodyTempAll_.filter((item: any) => {
        return (new Date(item.time_log).valueOf() >= new Date(dateStart).setHours(7, 0, 0, 0).valueOf()
        && new Date(item.time_log).valueOf() <= new Date(dateEnd).valueOf())
      });
    } else {
      //กรอง เฉพาะวันนี้ วันปัจจุบัน
      dataBodyTemp = dataBodyTempAll_.filter((item: any) => {
        var timelog = new Date(item.time_log).valueOf();
        var timeNow = new Date().setHours(new Date().getHours()+7);
        var timeStart =new Date().setHours(7, 0, 0, 0).valueOf();
        return (timelog >= timeStart &&
        timelog <= timeNow);
      });
    }

    for (let i = 0; i < dataBodyTempAll_.length; i++) {
      this.dataCalculateBodyTemp.push({
        date: new Date(dataBodyTempAll_[i].time_log),
        time_h: new Date(formatDate(dataBodyTempAll_[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
        time_day: new Date(formatDate(dataBodyTempAll_[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
        value1: Number(dataBodyTempAll_[i].body_temp),
      });
    }
    this.dataCalculateBodyTemp = this.dataCalculateBodyTemp.map((item: any) => ({
      ...item,
      date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
      time_h: item.time_h - 7
    }));

    for (let i = 0; i < dataBodyTemp.length; i++) {

      this.dataChartBodyTemp.push({
        // date: formatDate(
        //   dataBodyTemp[i].time_log,
        //   'yyyy/MM/dd hh:mm',
        //   'en-US'
        // ),
        date: dataBodyTemp[i].time_log.split("T")[0]+" "+dataBodyTemp[i].time_log.split("T")[1].substring(0,5),

        dateformat: new Date(formatDate(
          dataBodyTemp[i].time_log,
          'yyyy/MM/dd',
          'en-US'
        )).toLocaleString().split(" ", 1)[0],
        value1: dataBodyTemp[i].body_temp,
      });

    }

    this.dataChartBodyTemp.sort(function (a: any, b: any) {
      return + new Date(a.date) - + new Date(b.date);
    });

    var dataChartBodyTemp_: any = []
    for (let i = 0; i < dataBodyTempAll_.length; i++) {

      dataChartBodyTemp_.push({
        date: formatDate(
          dataBodyTempAll_[i].time_log,
          'yyyy/MM/dd hh:mm',
          'en-US'
        ),
        dateformat: new Date(formatDate(
          dataBodyTempAll_[i].time_log,
          'yyyy/MM/dd',
          'en-US'
        )).toLocaleString().split(" ", 1)[0],
        value1: dataBodyTempAll_[i].body_temp,
      });

    }


    // console.log("dataCalculateBodyTemp ", this.dataCalculateBodyTemp);


    for (var i = 0; i < dataChartBodyTemp_.length; i++) {
      if (dataChartBodyTemp_[i].value1 != 0 && dataChartBodyTemp_[i].value1 != NaN) {
        this.filterEmtyData.push({
          dateformat: dataChartBodyTemp_[i].dateformat
        });
      }
    }

    // Group DateTime For เลือกแบบวัน
    let group = this.filterEmtyData.reduce((r, a) => {
      r[a.dateformat] = [...r[a.dateformat] || [], a];
      return r;
    }, {});
    // console.log("Group ", group);

    const result_group = Object.keys(group).map(function (k) {
      const item = group[k];
      return {
        datetxtShow: item[0].dateformat
      }
    });

    this.datetimeShowList = result_group;
    // console.log("Group datetimeShowList ", this.datetimeShowList);




















    // console.log("this.dataCalculateBodyTemp ",this.dataCalculateBodyTemp);
    // console.log("this.dataChartBodyTemp ",this.dataChartBodyTemp);


    // ---------------------------------------- หา min max หาเฉลี่ย
    if(this.dataChartBodyTemp.length > 0){
    this.minBodyTempValue = Math.min.apply(Math, this.dataChartBodyTemp.map(function (o) { return o.value1; }));
    this.maxBodyTempValue = Math.max.apply(Math, this.dataChartBodyTemp.map(function (o) { return o.value1; }));
    var total = 0;
    for (var i = 0; i < this.dataChartBodyTemp.length; i++) {
      total +=  Number(this.dataChartBodyTemp[i].value1);
    }
    this.averBodyTempValue = total / this.dataChartBodyTemp.length;
  }

    // // หา 24 ชม. เฉลี่ย --------------------------- เอาตัวเลข time_h มา Group
    // const reduced = this.dataCalculateBodyTemp.reduce(function (m, d) {
    //   if (!m[d.time_h]) {
    //     m[d.time_h] = { ...d, count: 1 };
    //     return m;
    //   }
    //   m[d.time_h].value1 += d.value1;
    //   m[d.time_h].count += 1;
    //   return m;
    // }, {});

    // // หาค่าเฉลี่ย
    // const result = Object.keys(reduced).map(function (k) {
    //   const item = reduced[k];
    //   return {
    //     time_h: item.time_h,
    //     // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
    //     avg: item.value1 / item.count,
    //   }
    // });

    // // this.dataChart24List = result;

    // // โชว์ค่าทั้ง 24 ชั่วโมง
    // for (var i = 0; i < 24; i++) {
    //   var index = result.findIndex(e => e.time_h == i);
    //   if (index > -1) {
    //     this.dataChart24List.push({
    //       time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
    //       avg: result[index].avg,
    //       num_hr: result[index].time_h
    //     });
    //   } else {
    //     this.dataChart24List.push({
    //       time_h: ("00" + i).slice(-2) + ":00 น",
    //       avg: 0,
    //       num_hr: i
    //     });
    //   }
    // }

    await this.loadAvgTemp(this.gimeiCode).then((resAvg:any)=>{
      // console.log("loadAvgTemp",resAvg);
      if(resAvg != null){
      this.dataChart24List = resAvg.avg_Temp;

      this.dataChart24List_new = [];

      for (let index = 0; index < this.dataChart24List.length; index++) {
        // const element = this.dataChart24List[index].num_hr;
        var now = new Date();
        now.setHours(this.dataChart24List[index].num_hr);
        now.setMinutes(0);

        this.dataChart24List_new.push({
          time_h: now,
          avg: this.dataChart24List[index].avg,
        });
      }
      // console.log('this.dataChart24List', this.dataChart24List);
      // console.log('this.dataChart24List_new', this.dataChart24List_new);
    }
    });

    this.GraphAver24hLine(this.dataChart24List);


    // --------------------------------- หาผล min กี่ครั้ง , max กี่ครั้ง
    if(this.dataChartBodyTemp.length > 0){
    for (var i = 0; i < this.dataChartBodyTemp.length; i++) {
      if(this.dataChartBodyTemp[i].value1 <= 37.5 && this.dataChartBodyTemp[i].value1 >= 36){
        this.countNor++;
      }else if(this.dataChartBodyTemp[i].value1 > 37.5){
        this.countMax++;
      }else if(this.dataChartBodyTemp[i].value1 < 36){
        this.countMin++;
      }else{
        this.countvalErr++;
      }
    }
  }





    // หา 7 วันเฉลี่ย --------------------- เอาตัวเลข time_day มา Group
    const reduced_day = this.dataCalculateBodyTemp.reduce(function (m, d) {
      if (!m[d.time_day]) {
        m[d.time_day] = { ...d, count: 1 };
        return m;
      }
      m[d.time_day].value1 += d.value1;
      m[d.time_day].count += 1;
      return m;
    }, {});

    // หาค่าเฉลี่ย
    const result_day = Object.keys(reduced_day).map(function (k) {
      const item = reduced_day[k];

      var daynames = "";

      switch (item.time_day) {
        case 0:
          daynames = "วันอาทิตย์";
          break;
        case 1:
          daynames = "วันจันทร์";
          break;
        case 2:
          daynames = "วันอังคาร";
          break;
        case 3:
          daynames = "วันพุธ";
          break;
        case 4:
          daynames = "วันพฤหัส";
          break;
        case 5:
          daynames = "วันศุกร์";
          break;
        default:
          daynames = "วันเสาร์";
          break;
      }

      return {
        time_day: daynames,
        avg: item.value1 / item.count,
      }
    });

    result_day.sort(function (a: any, b: any) {
      return + new Date(a.time_day) - + new Date(b.time_day);
    });

    this.dataChart7DayList = result_day;


    // GEN กราฟ
    this.GraphBodyTempDemo();
    // this.GraphAver24h(this.dataChart24List);
    // this.GraphAver24hLine(this.dataChart24List);
    this.GraphAver7days();
    this.GraphValPercen();

  }

  loadChartbyDateFormAPI(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_DayBodyTemp?imeicode=${imeiCode}&date=${datetimes}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }


  async loadDatabyDateChart(imeiCode: string, datetimes: string) {
    await this.loadChartbyDateFormAPI(imeiCode, datetimes).then(
      (res: any) => {

        this.dataChart24List_Day1 = [];
        this.dataCalculateBodyTemp_Day1 = [];

        for (let i = 0; i < res.length; i++) {
          this.dataCalculateBodyTemp_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(res[i].time_log),

            // time_h: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
            time_day: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
            value1: Number(res[i].body_temp),
            avg: Number(res[i].body_temp),
          });
        }

        const reduced = this.dataCalculateBodyTemp_Day1.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
            time_h: item.time_h,
            avg: item.value1 / item.count,
          }
        });

        this.dataChart24List_Day1 = this.dataCalculateBodyTemp_Day1;
        this.dataChart24List_Day1 = this.dataChart24List_Day1.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: new Date(new Date(item.time_h).getTime() - (1000 * 60 * 60 * 7))
        }));

        // // โชว์ค่าทั้ง 24 ชั่วโมง
        // for (var i = 0; i < 24; i++) {
        //   var index = result.findIndex(e => e.time_h == i);
        //   if (index > -1) {
        //     this.dataChart24List_Day1.push({
        //       time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
        //       avg: result[index].avg,
        //       num_hr: result[index].time_h
        //     });
        //   } else {
        //     this.dataChart24List_Day1.push({
        //       time_h: ("00" + i).slice(-2) + ":00 น",
        //       avg: 0,
        //       num_hr: i
        //     });
        //   }
        // }

        for (let index = 0; index < this.dataChart24List_new.length; index++) {
          // this.dataChart24List_new[index].time_h.setDate(18);
          var split_date = datetimes.split('-');
          this.dataChart24List_new[index].time_h.setFullYear(
            split_date[0],
            Number(split_date[1]) - 1,
            split_date[2]
          );
        }


        // this.GraphAver24h(this.dataChart24List_Day1);
        this.GraphAver24hLine(this.dataChart24List_Day1);

      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }




  GraphBodyTempDemo() {

    this.changeDetectRef.detectChanges();
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    var chart = am4core.create(this.chartBodyTemp.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    this.dataChartBodyTemp = this.dataChartBodyTemp.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")
    }));
    chart.data = this.dataChartBodyTemp;

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 32;
    valueAxis.max = 42;
    valueAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 1;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('#64dbfb');
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';

    var axisRangetop = valueAxis.axisRanges.create();
    axisRangetop.value = 40;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#f3260e'); //00ba00
    axisRangetop.grid.strokeWidth = 2;

    var rangeareatop = valueAxis.axisRanges.create();
    rangeareatop.value = 37.5;
    rangeareatop.endValue = 40;
    rangeareatop.axisFill.fill = am4core.color('#f3260e');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    var rangeareab = valueAxis.axisRanges.create();
    rangeareab.value = 34.5;
    rangeareab.endValue = 36;
    rangeareab.axisFill.fill = am4core.color('#f3260e');
    rangeareab.axisFill.fillOpacity = 0.2;
    rangeareab.grid.strokeOpacity = 0;

    // var axisRangebottom = valueAxis.axisRanges.create();
    // axisRangebottom.value = 36;
    // axisRangebottom.grid.strokeOpacity = 1;
    // axisRangebottom.grid.stroke = am4core.color('#00ba00');
    // axisRangebottom.grid.strokeWidth = 2;

    var rangeareabottom = valueAxis.axisRanges.create();
    rangeareabottom.value = 37.5;
    rangeareabottom.endValue = 36;
    rangeareabottom.axisFill.fill = am4core.color('#00ba00');
    rangeareabottom.axisFill.fillOpacity = 0.2;
    rangeareabottom.grid.strokeOpacity = 0;

    // var labelbaselinetop = chart.createChild(am4core.Label);
    // labelbaselinetop.text = 'Bad BodyTemp (มากกว่าหรือเท่ากับ 37.5)';
    // labelbaselinetop.fontSize = 16;
    // labelbaselinetop.isMeasured = false;
    // labelbaselinetop.x = am4core.percent(75);
    // labelbaselinetop.y = 30;

    // var labelbaselinebottom = chart.createChild(am4core.Label);
    // labelbaselinebottom.text = 'Good BodyTemp (ประมาณ 36 องศา)';
    // labelbaselinebottom.fontSize = 16;
    // labelbaselinebottom.isMeasured = false;
    // labelbaselinebottom.x = am4core.percent(75);
    // labelbaselinebottom.y = 380;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }



  // GraphAver24h(mainData:any[]) {

  //   console.log("24H mainData : ",mainData);
  //   console.log("24H dataChart24List : ",this.dataChart24List);

  //   am4core.useTheme(am4themes_animated);

  //   var chart = am4core.create(this.chartBodyTempAver24.nativeElement, am4charts.XYChart);
  //   chart.exporting.menu = new am4core.ExportMenu();

  //    // Add data
  //   let newList: any = [];

  //   for (var i = 0; i < mainData.length; i++) {
  //     newList.push({
  //       time_h: mainData[i].time_h,
  //       avg: mainData[i].avg,
  //       "color": chart.colors.next()
  //     })
  //   }

  //   chart.data = newList;

  //   // Create axes
  //   let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //   categoryAxis.dataFields.category = "time_h";
  //   categoryAxis.renderer.grid.template.location = 0;
  //   categoryAxis.renderer.minGridDistance = 30;
  //   categoryAxis.renderer.labels.template.horizontalCenter = "right";
  //   categoryAxis.renderer.labels.template.verticalCenter = "middle";
  //   categoryAxis.renderer.labels.template.rotation = 270;
  //   categoryAxis.renderer.minHeight = 110;

  //   let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis.min = 30;
  //   // valueAxis.max = 42;


  //   // Create series
  //   let series = chart.series.push(new am4charts.ColumnSeries());
  //   series.sequencedInterpolation = true;
  //   series.dataFields.valueY = "avg";
  //   series.dataFields.categoryX = "time_h";
  //   series.tooltipText = "[{categoryX}: bold]{valueY.formatNumber('#.00')}[/]%";
  //   series.columns.template.strokeWidth = 0;
  //   series.columns.template.column.cornerRadiusTopLeft = 10;
  //   series.columns.template.column.cornerRadiusTopRight = 10;
  //   series.columns.template.column.fillOpacity = 0.8;
  //   series.columns.template.propertyFields.fill = "color";
  //   series.tooltipPosition = "pointer";


  //   let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
  //   dateAxis2.renderer.grid.template.location = 0;
  //   dateAxis2.renderer.minGridDistance = 40;
  //   dateAxis2.renderer.labels.template.disabled = true;
  //   dateAxis2.renderer.grid.template.disabled = true;
  //   dateAxis2.cursorTooltipEnabled = false;

  //   let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis2.renderer.opposite = true;
  //   valueAxis2.renderer.grid.template.disabled = true;
  //   valueAxis2.renderer.labels.template.disabled = true;

  //   let series2 = chart.series.push(new am4charts.LineSeries());
  //   series2.dataFields.valueY = "avg";
  //   series2.dataFields.dateX = "num_hr";
  //   series2.data = this.dataChart24List;
  //   series2.xAxis = dateAxis2;
  //   series2.yAxis = valueAxis2;
  //   series2.strokeWidth = 3;
  //   series2.tooltipText = "Average : [bold]{valueY.formatNumber('#.00')}[/]%";
  //   series2.stroke = am4core.color('red');

  //   // Add cursor
  //   chart.cursor = new am4charts.XYCursor();
  // }






  GraphAver24hLine(mainData: any[]) {

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartBodyTempAver24Line.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    let newList: any = [];

    for (var i = 0; i < mainData.length; i++) {
      newList.push({
        time_h: mainData[i].time_h,
        avg: mainData[i].avg,
        num_hr: i
      })
    }

    chart.data = newList;

    // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.dataFields.category = "time_h";
    // categoryAxis.renderer.grid.template.location = 0;
    // categoryAxis.renderer.minGridDistance = 30;
    // categoryAxis.renderer.labels.template.horizontalCenter = "right";
    // categoryAxis.renderer.labels.template.verticalCenter = "middle";
    // categoryAxis.renderer.labels.template.rotation = 270;
    // categoryAxis.renderer.minHeight = 110;
    // categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    // categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      "timeUnit": "minute",
      "count": 1
    };
    dateAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    dateAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis2.renderer.grid.template.location = 0;
    dateAxis2.renderer.minGridDistance = 40;
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.grid.template.disabled = true;
    dateAxis2.cursorTooltipEnabled = false;

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.min = 32;
    valueAxis2.max = 42;
    valueAxis2.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis2.renderer.labels.template.fill = am4core.color("#ffffff");

    // var axisRangetop = valueAxis2.axisRanges.create();
    // axisRangetop.value = 100;
    // axisRangetop.grid.strokeOpacity = 1;
    // axisRangetop.grid.stroke = am4core.color('#00ba00');
    // axisRangetop.grid.strokeWidth = 2;

    // var axisRangebottom = valueAxis2.axisRanges.create();
    // axisRangebottom.value = 90;
    // axisRangebottom.grid.strokeOpacity = 1;
    // axisRangebottom.grid.stroke = am4core.color('#f3260e');
    // axisRangebottom.grid.strokeWidth = 2;

    var rangeareatop = valueAxis2.axisRanges.create();
    rangeareatop.value = 36;
    rangeareatop.endValue = 37.5;
    rangeareatop.axisFill.fill = am4core.color('#00ba00');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    // var rangeareabottom = valueAxis2.axisRanges.create();
    // rangeareabottom.value = 90;
    // rangeareabottom.endValue = 95;
    // rangeareabottom.axisFill.fill = am4core.color('#f3260e');
    // rangeareabottom.axisFill.fillOpacity = 0.2;
    // rangeareabottom.grid.strokeOpacity = 0;



    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "avg";
    series2.dataFields.dateX = "time_h";
    series2.data = this.dataChart24List_new;
    series2.xAxis = dateAxis2;
    series2.yAxis = valueAxis2;
    series2.strokeWidth = 3;
    series2.tooltipText = "Average : [bold]{valueY.formatNumber('#.00')}[/]%";
    series2.stroke = am4core.color('#cd0506');
    series2.fill = am4core.color('#cd0506');


    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = "avg";
    series1.dataFields.dateX = "time_h";
    series1.data = newList.reverse();
    series1.xAxis = dateAxis2;
    series1.yAxis = valueAxis2;
    series1.strokeWidth = 2;
    series1.tooltipText = "ปัจจุบัน [bold]{valueY.formatNumber('#.00')}[/]%";
    series1.stroke = am4core.color('#00bce6');
    series1.fill = am4core.color('#00bce6');


    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }






  GraphAver7days() {

    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create(this.chartBodyTempAver7day.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    // Add data
    chart.data = this.dataChart7DayList;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time_day";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "avg";
    series.dataFields.categoryX = "time_day";
    series.tooltipText = "[{categoryX}: bold]{valueY.formatNumber('#.00')}[/]";
    series.columns.template.strokeWidth = 0;

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    series.fill = am4core.color("#514ab8");

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  }



  GraphValPercen() {

    var valmin = (this.countMin / this.dataChartBodyTemp.length) * 100;
    var valnor = (this.countNor / this.dataChartBodyTemp.length) * 100;
    var valmax = (this.countMax / this.dataChartBodyTemp.length) * 100;

    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create(this.chartBodyTempValPercen.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = [
      {
        "country": "อุณหภูมิปกติ 36-37.5",
        "visits": valnor.toFixed(2),
        "color": chart.colors.next()
      },
      {
      "country": "มากกว่า 37.5 องศา",
      "visits": valmax.toFixed(2),
      "color": chart.colors.next()
    },
    {
      "country": "น้อยกว่า 36 องศา",
      "visits": valmin.toFixed(2),
      "color": chart.colors.next()
    }
  ];

    //create category axis for years
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;

    //create value axis for income and expenses
    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;

    //create columns
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "country";
    series.dataFields.valueX = "visits";
    series.name = "BodyTemp";
    series.columns.template.fillOpacity = 0.5;
    series.columns.template.strokeOpacity = 0;
    series.tooltipText = "{categoryY} : {valueX.value.formatNumber('#.00')}%";
    series.columns.template.propertyFields.fill = "color";

    //add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomY";

    //add legend
    chart.legend = new am4charts.Legend();

  }


  onChangeDateTimeEvent(event: any, isFirst: boolean) {
    if (!isFirst) var datetimeStr = formatDate(event.value, 'yyyy-MM-dd', 'en-US').toString();
    else var datetimeStr = formatDate(event, 'yyyy-MM-dd', 'en-US').toString();
    this.loadDatabyDateChart(this.gimeiCode, datetimeStr);
    this.loadDataTickerLogToTable(this.gimeiCode,datetimeStr);

  }

  async loadDataTickerLogToTable(imeiCode: string,datetimes: string) {
    await this.loadTickerLog(imeiCode,datetimes).then(
      (resq: any) => {
        this.tickerDataToTableList = [];
        this.tickerDataToTableList = resq;
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }

  loadTickerLog(imeiCode: string,datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getlogTickerList?imei=${imeiCode}&type=${this.pageGetDatatype}&date=${datetimes}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }
}
