<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 style="text-align: center;">Doctor Patient </h1>
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content pl-2 pr-2">
    <div class="mat-elevation-z8 card card-solid">
        <div class="card-body pb-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-10" style="margin: auto;">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button style="margin-right: 15px;background-color: white;" value="1"
                                (change)="onChangeStatus('red')">User-Red
                            </mat-radio-button>
                            <mat-radio-button style="margin-right: 15px;background-color: white;" value="2"
                                (change)="onChangeStatus('yellow')">User-Yellow
                            </mat-radio-button>
                            <mat-radio-button style="margin-right: 15px;background-color: white;" value="3"
                                (change)="onChangeStatus('green')">User-Green
                            </mat-radio-button>
                            <mat-radio-button style="margin-right: 15px;background-color: white;" value="4"
                                (change)="onChangeStatus('all')" checked>All
                            </mat-radio-button>
                        </mat-radio-group>
                        <mat-form-field appearance="outline">
                            <mat-label>Select Show Favorite</mat-label>
                            <mat-select [(value)]="selectedFavorite"
                                (selectionChange)="onChangeFavorite(selectedFavorite)">
                                <mat-option value="noFavorite">No favorites</mat-option>
                                <mat-option value="favorite">Favorite</mat-option>
                                <mat-option value="allFavorite">Show-All</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2" style="text-align: end;">
                        <button mat-raised-button style="width: 120px;" color="primary" (click)="onClickRefresh()"><i
                                class="fas fa-redo-alt"></i> Refresh</button>
                    </div>
                </div>
            </div>
            <hr style="margin-top: 8px;">
            <div class="row mb-3">
                <div class="col-6" style="text-align: start; margin-bottom: auto;margin-top: auto;padding-left: 20px">
                    <div class="col-6" *ngIf="resultDataDoctorMall != null"
                    style="font-size: 22px;font-weight: 500;">
                    {{resultDataDoctorMall.length}} User
                </div>
                </div>
                <div class="col-6" style="text-align: end;padding-right: 18px;">
                    <button mat-icon-button style="margin-right: 5px;" (click)="onClickShowData('card')" disabled>
                        <mat-icon>apps</mat-icon>
                    </button>
                    <button mat-icon-button style="margin-right: 5px;" (click)="onClickShowData('table')">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <mat-form-field appearance="outline">
                        <mat-label>Select Show Item</mat-label>
                        <mat-select [(value)]="selected" (selectionChange)="onChangeCountItem(selected)">
                            <mat-option value="20">20-Item</mat-option>
                            <mat-option value="50">50-Item</mat-option>
                            <mat-option value="100">100-Item</mat-option>
                            <mat-option value="all">Show-All</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-body pb-0" style="padding: 0px;">
                <div class="row" *ngIf="loadDataUser == true">
                    <div class="col-12">
                        <mat-spinner
                            style="margin-left: auto;margin-right: auto;margin-top: 110px;margin-bottom: 110px;">
                        </mat-spinner>
                    </div>
                </div>
                <div class="row" *ngIf="cardShow == true">
                    <div *ngFor="let itemUser of resultDataDoctorMall;let i = index;"
                    class="fadeIn col-xl-3 col-lg-6 col-md-6 d-flex align-items-stretch flex-column "
                        style="height: 335px;">
                        <div class="card bg-light d-flex flex-fill mat-elevation-z8">
                            <div class="card-body pt-0 pb-0">
                                <div class="row mt-3 mb-1">
                                    <div class="col-7">
                                        <h2 class="lead"><b>{{itemUser.userName}}</b></h2>
                                        <p class="text-muted text-sm" style="font-size: 12px !important;"><b>Start date: </b> {{itemUser.dmRequestTime |
                                            date:'short'}}</p>
                                        <p class="text-muted text-sm" style="font-size: 12px !important;"><b>Last update: </b> {{itemUser.dmLastUpdate
                                            | date:'short'}}</p>
                                        <p class="text-muted text-sm" style="font-size: 12px !important;"><b>Doctor start date: </b> {{itemUser.dpDate
                                            | date:'short'}}</p>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                            <li class="small">
                                                <span class="fa-li">
                                                    <i class="fas fa-lg fa-building"></i>
                                                </span> <span class="line-clamp"> Address: {{itemUser.userAddress}}
                                                    {{itemUser.userSubdistrict}} {{itemUser.userDistrict}}
                                                    {{itemUser.userProvince}} {{itemUser.userPostalcode}}</span>
                                            </li>
                                            <li class="small"><span class="fa-li"><i
                                                        class="fas fa-lg fa-phone"></i></span> Phone :
                                                {{itemUser.userPhone}}</li>
                                        </ul>
                                    </div>
                                    <div class="col-5 text-center">
                                        <div
                                            *ngIf="itemUser.userPic != null && itemUser.userPic != ''; else elseImgBlock">
                                            <img src="{{imageUrl2}}/{{itemUser.userPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                alt="user-avatar" class="img-circle img-fluid"
                                                style="width: 136px;height: 136px;object-fit: cover;">
                                        </div>
                                        <ng-template #elseImgBlock>
                                            <img src="assets/adminlte/dist/img/nouser.svg" alt="user-avatar"
                                                class="img-circle img-fluid" style="width: 136px;height: 136px;">
                                        </ng-template>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12" style="font-size: 14px;">
                                        <div class="row" style="display: flex;justify-content: flex-end;">
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cSpo2 != '' && itemUser.cSpo2 != null"><b>SpO2 : </b> {{itemUser.cSpo2}} </p>
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cSpo2 == '' || itemUser.cSpo2 == null"><b>SpO2 : </b> - </p>
                                            <p style="padding-right: 0px;" *ngIf="itemUser.cPressureHigh != '' && itemUser.cSpo2 != null"><b>BP : </b> {{itemUser.cPressureHigh}}/ </p>
                                            <p style="padding-right: 0px;" *ngIf="itemUser.cPressureHigh == '' || itemUser.cSpo2 == null"><b>BP : </b> -</p>
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cPressureLow != '' && itemUser.cPressureLow != null"><b></b>{{itemUser.cPressureLow}} </p>
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cPressureLow == '' || itemUser.cPressureLow == null"><b></b>-</p>
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cHeartrate != '' && itemUser.cHeartrate != null"><b>P : </b> {{itemUser.cHeartrate}} </p>
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cHeartrate == '' || itemUser.cHeartrate == null"><b>P : </b>  -</p>
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cTemp != '' && itemUser.cTemp != null"><b>T : </b> {{itemUser.cTemp}} </p>
                                            <p style="padding-right: 5px;" *ngIf="itemUser.cTemp == '' || itemUser.cTemp == null"><b>T : </b> -</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer" style="padding: 10px;">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6" style="margin: auto;">
                                            <div class="row" style="display: flex;justify-content: flex-start;">
                                                <span style="margin-right: 8px;">Symptom Today</span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                                    *ngIf="itemUser.dpCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                                    *ngIf="itemUser.dpCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                                    *ngIf="itemUser.dpCovidStatus == 'Red'"></span>
                                                <!-- <span *ngIf="itemUser.covidRed == false && itemUser.covidYellow == false && itemUser.covidGreen == false"> -</span> -->
                                            </div>
                                        </div>
                                        <div class="col-6" style="text-align: end;">
                                            <button mat-raised-button style="width: 120px;" color="primary"
                                                routerLink="/userCovid19/{{itemUser.userId}}">
                                                <mat-icon>visibility</mat-icon> View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="tableShow == true">
                    <table *ngIf="loadDataUser == false && noDataInformation == false" class="table"
                        style="text-align: center;">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Favorite</th>
                                <th scope="col">Username</th>
                                <th scope="col" class="hide-detail-table-1374">Age</th>
                                <th scope="col" class="hide-detail-table-1255">Weight</th>
                                <th scope="col" class="hide-detail-table-1255">Height</th>
                                <th scope="col" class="hide-detail-table-1374">Gender</th>
                                <th scope="col">DOI</th>
                                <th scope="col" class="hide-detail-table-1374 hide-detail-table-1472">ATK/RT-PCR</th>
                                <th scope="col" class="hide-detail-table-1255">PersonalHealth</th>
                                <th scope="col" class="hide-detail-table-1374">Last update date</th>
                                <th scope="col">Status</th>
                                <th scope="col" class="hide-detail-table-1374">Doctor start date</th>
                                <th scope="col">Doctor Status</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let itemUser of resultDataDoctorMall;let i = index;" class="fadeIn">
                            <tr>
                                <th scope="row">{{ i+1 }}</th>
                                <td *ngIf="itemUser.dpFavorite == false">
                                    <a matTooltip="เพิ่มรายการโปรด" href="javascript:void(0)" style="color: black;"
                                        (click)="onClickFavorite(itemUser.userId,'',true)"><i class="tada far fa-star"
                                            style="font-size: 25px;"></i></a>
                                </td>
                                <td *ngIf="itemUser.dpFavorite == true">
                                    <a matTooltip="ยกเลิกรายการโปรด" href="javascript:void(0)" style="color: black;"
                                        (click)="onClickFavorite(itemUser.userId,'',false)"><i class="tada fas fa-star"
                                            style="font-size: 25px; color: #e3bb1c;"></i></a>
                                </td>
                                <td *ngIf="itemUser.userName != '' && itemUser.userName != null">{{itemUser.userName}}
                                </td>
                                <td *ngIf="itemUser.userName == '' || itemUser.userName == null">-</td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.userAge != '' && itemUser.userAge != null && itemUser.userAge >= 60"><b style="color: #ffc107;">{{itemUser.userAge}}</b></td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.userAge != '' && itemUser.userAge != null && itemUser.userAge < 60">{{itemUser.userAge}}</td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.userAge == '' || itemUser.userAge == null">-</td>
                                <td class="hide-detail-table-1255" *ngIf="itemUser.userWeight != '' && itemUser.userWeight != null && itemUser.userWeight >= 90"><b style="color: #ffc107;">{{itemUser.userWeight}}</b></td>
                                <td class="hide-detail-table-1255" *ngIf="itemUser.userWeight != '' && itemUser.userWeight != null && itemUser.userWeight < 90">{{itemUser.userWeight}}</td>
                                <td class="hide-detail-table-1255" *ngIf="itemUser.userWeight == '' || itemUser.userWeight == null">-</td>
                                <td class="hide-detail-table-1255" *ngIf="itemUser.userHeight != '' && itemUser.userHeight != null">
                                    {{itemUser.userHeight}}</td>
                                <td class="hide-detail-table-1255" *ngIf="itemUser.userHeight == '' || itemUser.userHeight == null">-</td>
                                <td class="hide-detail-table-1374"
                                    *ngIf="itemUser.userSex != '' && itemUser.userSex != null  && itemUser.userSex == 'M'">
                                    Male</td>
                                <td class="hide-detail-table-1374"
                                    *ngIf="itemUser.userSex != '' && itemUser.userSex != null  && itemUser.userSex == 'F'">
                                    FeMale</td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.userSex == '' || itemUser.userSex == null">-</td>
                                <td *ngIf="itemUser.dmRequestTime != '' && itemUser.dmRequestTime != null">
                                    {{itemUser.dmRequestTime | date:'short'}}</td>
                                <td *ngIf="itemUser.dmRequestTime == '' || itemUser.dmRequestTime == null">-</td>
                                <td  class="hide-detail-table-1374 hide-detail-table-1472" *ngIf="itemUser.pAtkRtpcr != '' && itemUser.pAtkRtpcr != null">
                                    {{itemUser.pAtkRtpcr | date:'short'}}</td>
                                    <td  class="hide-detail-table-1374 hide-detail-table-1472" *ngIf="itemUser.pAtkRtpcr == '' || itemUser.pAtkRtpcr == null">-</td>
                                <td class="hide-detail-table-1255">
                                    <div class="row" style="display: flex;justify-content: center;">
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cSpo2 != '' && itemUser.cSpo2 != null"><b>SpO2 : </b> {{itemUser.cSpo2}} </p>
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cSpo2 == '' || itemUser.cSpo2 == null"><b>SpO2 : </b> - </p>
                                        <p style="padding-right: 0px;" *ngIf="itemUser.cPressureHigh != '' && itemUser.cSpo2 != null"><b>BP : </b> {{itemUser.cPressureHigh}}/ </p>
                                        <p style="padding-right: 0px;" *ngIf="itemUser.cPressureHigh == '' || itemUser.cSpo2 == null"><b>BP : </b> -</p>
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cPressureLow != '' && itemUser.cPressureLow != null"><b></b>{{itemUser.cPressureLow}} </p>
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cPressureLow == '' || itemUser.cPressureLow == null"><b></b>-</p>
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cHeartrate != '' && itemUser.cHeartrate != null"><b>P : </b> {{itemUser.cHeartrate}} </p>
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cHeartrate == '' || itemUser.cHeartrate == null"><b>P : </b>  -</p>
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cTemp != '' && itemUser.cTemp != null"><b>T : </b> {{itemUser.cTemp}} </p>
                                        <p style="padding-right: 5px;" *ngIf="itemUser.cTemp == '' || itemUser.cTemp == null"><b>T : </b> -</p>

                                    </div>
                                </td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.dmLastUpdate != '' && itemUser.dmLastUpdate != null">
                                    {{itemUser.dmLastUpdate | date:'short'}}</td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.dmLastUpdate == '' || itemUser.dmLastUpdate == null">-</td>
                                <td>
                                    <div class="row" style="display: flex;justify-content: center;">
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                            *ngIf="itemUser.dpCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                            *ngIf="itemUser.dpCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                            *ngIf="itemUser.dpCovidStatus == 'Red'"></span>
                                    </div>
                                </td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.dpDate != '' && itemUser.dpDate != null">{{itemUser.dpDate |
                                    date:'short'}}</td>
                                <td class="hide-detail-table-1374" *ngIf="itemUser.dpDate == '' || itemUser.dpDate == null">-</td>
                                <td>
                                    <div class="row" style="display: flex;justify-content: center;">
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="onChangeSymptomCovid('Green',itemUser.userId)">Zone-Green</button>
                                            <button mat-menu-item (click)="onChangeSymptomCovid('Yellow',itemUser.userId)">Zone-Yellow</button>
                                            <button mat-menu-item (click)="onChangeSymptomCovid('Red',itemUser.userId)">Zone-Red</button>
                                        </mat-menu>
                                        <button class="tada starus-covid-green"  [matMenuTriggerFor]="menu" #menuTrigger
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                            *ngIf="itemUser.dpDocCovidStatus == 'Green'"></button>

                                        <button class="tada starus-covid-yellow" [matMenuTriggerFor]="menu" #menuTrigger
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                            *ngIf="itemUser.dpDocCovidStatus == 'Yellow'"></button>

                                        <button class="tada starus-covid-red" [matMenuTriggerFor]="menu" #menuTrigger
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                            *ngIf="itemUser.dpDocCovidStatus == 'Red'"></button>
                                        <button class="tada starus-covid-null" [matMenuTriggerFor]="menu" #menuTrigger
                                            *ngIf="itemUser.dpDocCovidStatus == '' || itemUser.dpDocCovidStatus == null"
                                            style="box-shadow: 0 0 8px 0 #c2c2c2, 0 0 5px 0 #bbbbbb;">
                                            </button>
                                    </div>
                                </td>
                                <td>
                                    <button mat-raised-button color="primary"
                                        routerLink="/userCovid19/{{itemUser.userId}}">
                                        <mat-icon>visibility</mat-icon> View
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-12" *ngIf="noDataInformation == true">
                    <div class="mb-2">
                        <div style="display: flex;">
                            <div class="container rubberBand"
                                style="color: #bdbbb7;margin-top: 100px;margin-bottom: 100px;text-align: center;">
                                <i class="fas fa-calendar-times" style="font-size: 50px;"></i>
                                <p>No Notification.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>
