<div [class]="!value ? 'mt-3' : 'container-fluid p-3'">

    <div class="col-md-12 mt-3">
        <div class="row">
            <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
                <h1 style="color: #222;" style="margin: 0px;">SpO2 Chart</h1>
            </div>
            <div class="col-md-4 text-right">

                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Enter a date range</mat-label>

                    <mat-date-range-input [formGroup]="rangeDateStartAndEnd" [rangePicker]="picker_start_end">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onChengDate()">
                      </mat-date-range-input>

                    <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker_start_end></mat-date-range-picker>
                  </mat-form-field>
            </div>

        </div>
    </div>

    <div *ngIf="dataSpO2All.length != 0">

    <div class="card mt-2">
        <div class="card-body">
            <div #chartSpo2 style="width: 100%; height: 400px;"></div>
            <div class="mt-1" #chartSpo2Coll style="width: 100%; height: 500px;"></div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace h6size">Average (เฉลี่ย)</h6>
                    <h3 class="nospace txtindigo">{{averSpo2Value | number : '1.2-2'}}%</h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">Max (มากสุด)</h6>
                    <h3 class="nospace txtindigo">{{maxSpo2Value}}%</h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">Min (น้อยสุด)</h6>
                    <h3 class="nospace txtindigo">{{minSpo2Value}}%</h3>
                </div>
            </div>
        </div>


        <div class="col-md-6 mt-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="nospace"><span style="color: red;">SpO2 ต่ำกว่าหรือเท่ากับ 79%</span></h4>
                    <h3 class="nospace txtindigo">{{ countMin }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                            {{ dataChartSpo2.length }} ครั้ง คิดเป็น {{ (countMin / dataChartSpo2.length) * 100 | number
                            : '1.2-2' }}%</span></h3>
                </div>
            </div>
        </div>


        <div class="col-md-6 mt-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="nospace"><span style="color: green;">SpO2 เท่ากับ 90-100%</span></h4>
                    <h3 class="nospace txtindigo">{{ countMax }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                            {{ dataChartSpo2.length }} ครั้ง คิดเป็น {{ (countMax / dataChartSpo2.length) * 100 | number
                            : '1.2-2' }}%</span></h3>
                </div>
            </div>
        </div>


        <div class="col-md-12 mt-3">


            <div class="card">
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-8">
                            <h6 class="nospace">SpO2 24Hr Chart</h6>
                            <span *ngFor="let datelist of datetimeShowList" class="badge bg-primary"
                                style="margin-right: 2px;">{{ datelist.datetxtShow }}</span>
                        </div>

                        <div class="col-md-4 text-right">
                            <mat-form-field style="width: 100%;" appearance="outline">
                                <input matInput [matDatepicker]="picker" placeholder="Choose a date" [value]="setNow"
                                    (dateChange)="onChangeDateTimeEvent($event, false)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="mt-2" #chartSpo2Aver24Line style="width: 100%; height: 500px;"></div>

                </div>
            </div>


            <!-- <div class="mt-3">
                <table class="table table-striped mt-1">
                  <thead>
                    <tr style="background-color: rgb(208, 242, 253);">
                      <th scope="col" width="80px">#</th>
                      <th scope="col">Ticker Time</th>
                      <th scope="col">type</th>
                      <th scope="col">value type</th>
                      <th scope="col">ค่าที่ตรวจวัดได้</th>
                      <th scope="col">Description</th>
                      <th scope="col">ส่วนต่าง (+ - ไม่เกิน {{ g_diffvalue }}) <button
                          style="margin-top: 0px; margin-bottom: 0px;" type="button" (click)="openDialogGlovbalval(pageGetDatatype)" class="btn btn-primary btn-sm">Edit Value</button> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let datelist of tickerDataToTableList index as i">
                        <th scope="row">{{i+1}}</th>
                      <td>{{ datelist.atTime }}</td>
                      <td>{{ datelist.atTickerGobalOrAvg }}</td>
                      <td style="color: green; font-weight: bold;">{{ datelist.atType }}</td>
                      <td style="color: red; font-weight: bold;">{{ datelist.atValue }}</td>
                      <td colspan="1" style="color: rgb(12, 110, 175); font-weight: bold;">{{ datelist.atLogTxtDesc }}</td>
                      <td>{{ g_diffvalue }}</td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
        </div>


        <div class="col-md-6 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">SpO2 7 วันเฉลี่ย</h6>
                    <div #chartSpo2Aver7day style="width: 100%; height: 400px"></div>
                </div>
            </div>
        </div>


        <div class="col-md-6 mt-3">
            <div class="card">
                <div class="card-body text-center">
                    <h6 class="nospace">SpO2 Average Percent Chart <span *ngIf="countvalErr > 0">{{ countvalErr
                            }}</span></h6>
                    <div #chartSpo2ValPercen style="width: 100%; height: 400px"></div>
                </div>
            </div>
        </div>


    </div>

</div>

<div *ngIf="dataSpO2All.length == 0 && !loading" style="display: flex;justify-content: center;"> <h2>*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>


    <br>
    <br>

</div>
