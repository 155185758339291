import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../../../../core/services/service.service';
import Swal from 'sweetalert2';
import { AccessRequiredComponent } from '../../access-required/access-required.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  dataLcation: LocationElement[] = [];
  dataLcationBackUp: LocationElement[] = [];
  dataLcationBackUp2: LocationElement[] = [];
  markers: any = null;
  datestartend : any;
  lat = 13.749240588023444;
  long = 100.4909659585323;
  zoom = 16;
  selected = '10';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  rangeDateStartAndEnd = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });
  gimeiCode: any = "";
  temp = [];

  SearchDateStart!: string;
  SearchDateEnd!: string;
  getIdUser: any;
  pastIndex: number = -1;
  currentIndex: number = -1;

  roleAdmin: string | null;
  showContent: boolean = false;
  @Input() imei: string;
  @Output() pageEmit = new EventEmitter<boolean>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    //  private sharedServiceService: SharedServiceService,
    public dialog: MatDialog,
    private router: Router,
     @Inject(LOCALE_ID) private locale: string
  ) {
    this.getIdUser = this.activatedRoute.snapshot.paramMap.get('idUser');
    this.roleAdmin = localStorage.getItem("currentRole");
    if (this.roleAdmin != "SuperAdmin") {
      this.accessRequired("location", this.showContent, null);
    }
  }

  ngOnInit() {
    this.getLogLocation();
    // this.sharedServiceService.deviceIDValue.subscribe(
    //   (swimei) => {
    //     // console.log("message ID ", swimei);
    //     var now = new Date();

    //     var nowStart = formatDate(now, 'yyyy-MM-dd', this.locale);
    //     var nowEnd = formatDate(now.setDate(now.getDate() + 1), 'yyyy-MM-dd', this.locale);
    //     // this.loadDataChart(swimei, nowStart, nowEnd);
    //   }
    // );
  }

  onChangeDate() {
    this.dataLcation = this.dataLcationBackUp;
    var dataStart: string = '';
    var dataEnd: string = '';

    dataStart = this.rangeDateStartAndEnd.value.start;
    dataEnd = this.rangeDateStartAndEnd.value.end;

    if (this.rangeDateStartAndEnd.value.start != null && this.rangeDateStartAndEnd.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);

      var newEnd = new Date(dataEnd);
      var newStart = new Date(dataStart);


      this.dataLcation = this.dataLcation.filter((start: any) => {
        var time = formatDate(start.time_log, 'yyyy-MM-dd', this.locale);
        if (newStart.getDate() == newEnd.getDate()) {
          if (time == dataEnd_) {
            return start;
          }

        }else{
          if (time >= dataStart_ && time <= dataEnd_) {
            return start;
          }
        }
      })
      this.dataLcationBackUp2 = this.dataLcation;
      this.dataLcation = this.dataLcation.slice(0, Number(this.selected));
    }
  }

  getLogLocation() {
    this.ServiceService.GetDeviceWatch_(this.getIdUser).then((resData) => {
      if (resData != null && resData.length > 0)
        this.ServiceService.getLogMainLogList(resData[0].deviceSerial).then(
          (res) => {
            this.dataLcation = res.slice(0, 10);
            this.dataLcationBackUp = res;
            this.dataLcationBackUp2 = res;

            this.dataLcationBackUp2 = this.dataLcationBackUp2.map((item: any) => ({
              ...item,
              time_log: new Date(new Date(item.time_log).getTime() - (1000 * 60 * 60 * 7))
            }));
            const result = res.filter((x: { checkValid: string; }) => x.checkValid == "A");
            this.dataLcation = result.slice(0, 10);
            this.dataLcationBackUp = result;
            this.dataLcationBackUp = this.dataLcationBackUp.map((item: any) => ({
              ...item,
              time_log: new Date(new Date(item.time_log).getTime() - (1000 * 60 * 60 * 7))
            }));
            this.dataLcation = this.dataLcation.map((item: any) => ({
              ...item,
              isSelected: false,
              time_log: new Date(new Date(item.time_log).getTime() - (1000 * 60 * 60 * 7))
            }));
          }
        );
    });
  }

  markerClicked(_lat: string, _long: string, date: any, index: number) {
    if (this.roleAdmin != "SuperAdmin") {
      var data: any = {
        _lat: _lat,
        _long: _long,
        date: date,
        index: index
      }
      this.accessRequired("location_" + this.imei + "_" + date.toString(), true, data);
    } else {
      this.markerConfirm(_lat, _long, date, index);
    }
  }

  markerConfirm(_lat: string, _long: string, date: any, index: number) {
    this.currentIndex = index - 1;
    this.dataLcation[this.currentIndex].isSelected = true;
    if (this.pastIndex != -1) {
      this.dataLcation[this.pastIndex].isSelected = false;
    }
    this.pastIndex = this.currentIndex;
    this.markers = null;
    this.lat = Number(_lat);
    this.long = Number(_long);
    this.zoom = 16;
    var json_data = {
      lat: _lat,
      lng: _long,
      label: date,
      title1: index,
    };
    this.markers = json_data;
  }

  onChangeCountItem(event: string) {
    // this.dataLcation = [];
    this.dataLcation = this.dataLcationBackUp2;
    if (event != 'all') {
      this.dataLcation = this.dataLcation.slice(0, Number(event));
    }
    // this.dataLcation =
    //   event == 'all'
    //     ? this.dataLcation
    //     : this.dataLcation.slice(0, Number(event));
  }

  accessRequired(Page: string, CanAccess: boolean, Data: any) {
    Swal.fire({
      text: "ระบบจะทำการเก็บประวัติการเข้าถึงข้อมูลของผู้ใช้",
      showCancelButton: true,
      confirmButtonColor: '#0033CC',
      cancelButtonColor: '#BEBEBE',
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        const dialogRef = this.dialog.open(AccessRequiredComponent, {
          data: Page,
          width: "480px",
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.showContent = true;
            if (CanAccess) {
              this.markerConfirm(Data._lat, Data._long, Data.date, Data.index);
            }
          } else {
            if (!CanAccess) {
              this.pageEmit.emit(true);
            }
          }
        });
      } else {
        if (!CanAccess) {
          this.pageEmit.emit(true);
        }
      }
    })
  }
}

export interface LocationElement {
  id: string;
  checkValid: string;
  degrees_north: string;
  latitude: string;
  degrees_east: string;
  longitude: string;
  speed: string;
  direction_angle: string;
  gsM_signal: string;
  number_satellites: string;
  working_mode: string;
  battery_level: string;
  imeicode: string;
  time_log: Date;
  isSelected: boolean;
}
