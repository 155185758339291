<h2 mat-dialog-title>Add Admin</h2>
<form [formGroup]="addAdminDistrictForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography" style="width: 1000px;">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Username</mat-label>
          <input matInput placeholder="Username " formControlName="username" maxlength="100" required
            oninput="this.value = this.value.replace(/[^A-Za-z0-9._-]/gi, '').trim();">
          <mat-hint *ngIf="InvalidUsername" style="color: red;"><b>Duplicate Username</b></mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" value="123456" maxlength="100" disabled>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Confirm your password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" value="123456" maxlength="100" disabled>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Address</mat-label>
          <textarea matInput disabled required></textarea>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Address</mat-label>
          <textarea matInput formControlName="address" required></textarea>
        </mat-form-field> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
          <mat-label>Province</mat-label>
          <mat-select disabled>
            <mat-option *ngFor="let province of provinces" [value]="province.value">
              {{province.viewValue}}
            </mat-option>
          </mat-select>
          <!-- <mat-select formControlName="province" required [(ngModel)]="selectedProvince"
            (ngModelChange)="onProvinceSelection()">
            <mat-option *ngFor="let province of provinces" [value]="province.value">
              {{province.viewValue}}
            </mat-option>
          </mat-select> -->
        </mat-form-field>
      </div>
      <div class="col-md">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
          <mat-label>District</mat-label>
          <mat-select disabled>
            <mat-option *ngFor="let district of districts" [value]="district.value">
              {{district.viewValue}}
            </mat-option>
          </mat-select>
          <!-- <mat-select formControlName="district"  [(ngModel)]="selectedDistrict" 
            (ngModelChange)="onDistrictSelection()" >
            <mat-option *ngFor="let district of districts" [value]="district.value">
              {{district.viewValue}}
            </mat-option>
          </mat-select> -->
        </mat-form-field>
      </div>
      <div class="col-md">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
          <mat-label>Subdistrict</mat-label>
          <!-- <mat-select disabled>
            <mat-option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.value">
              {{subdistrict.viewValue}}
            </mat-option>
          </mat-select> -->
          <mat-select formControlName="subdistrict"  [(ngModel)]="selectedSubdistrict"
            (ngModelChange)="onSubdistrictSelection()">
            <mat-option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.value">
              {{subdistrict.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Postalcode</mat-label>
          <input matInput placeholder="Postalcode"disabled minlength="5" maxlength="5" required>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Postalcode</mat-label>
          <input matInput placeholder="Postalcode" formControlName="postalcode" required>
        </mat-form-field> -->
      </div>
      <div class="col-md-5">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available">
            <mat-label>Administrator Role</mat-label>
            <mat-select formControlName="Administrator Role" formControlName="adminRole" required>
              <mat-option *ngFor="let roleAdmin of roleAdmins" [value]="roleAdmin.value">
                {{roleAdmin.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" style="width: -webkit-fill-available">
          <mat-label>Phone</mat-label>
          <input matInput placeholder="Phone" formControlName="phone" minlength="10" maxlength="10"
          oninput="this.value = this.value.replace(/[^0-9]/gi, '').trim();" required>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button type="submit" color="primary">Add</button>
  </mat-dialog-actions>
</form>