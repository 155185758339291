<div class="mt-3" *ngIf="deviceId != null">
    <div class="row">
        <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
            <h1 style="color: #222;" style="margin: 0px;">Sleep Chart</h1>
        </div>
        <div class="col-md-4 text-right">
            <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="selectedDateForm" [rangePicker]="picker_start_end">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="selectedDateChange()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
            <mat-date-range-picker #picker_start_end></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="theSleepData.length">
        <div class="card mt-2">
            <div class="card-body">
                <div #theSleepChart style="width: 100%; height: 600px"></div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
                <h1 style="color: #222;" style="margin: 0px;">Deep Sleep Chart</h1>
            </div>
        </div>
        <div class="card mt-2">
            <div class="card-body">
                <div #deepSleepChart style="width: 100%; height: 600px"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
                <h1 style="color: #222;" style="margin: 0px;">Light Sleep Chart</h1>
            </div>
        </div>
        <div class="card mt-2">
            <div class="card-body">
                <div #lightSleepChart style="width: 100%; height: 600px"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
                <h1 style="color: #222;" style="margin: 0px;">Good Sleep Chart</h1>
            </div>
        </div>
        <div class="card mt-2">
            <div class="card-body">
                <div #goodSleepChart style="width: 100%; height: 600px"></div>
            </div>
        </div> -->
    </div>
    <div class="mb-5" style="display: flex;justify-content: center;" *ngIf="!theSleepData.length"> <h2>*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>
</div>

<div class="mt-3" *ngIf="deviceId == null">
    <div class="row">
        <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
            <h1 style="color: #222;" style="margin: 0px;">Sleep Chart</h1>
        </div>
        <div class="col-md-4 text-right">
            <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="selectedDateForm" [rangePicker]="picker_start_end">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="selectedDateChange()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
            <mat-date-range-picker #picker_start_end></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="mb-5" style="display: flex;justify-content: center;"> <h2>*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>
</div>
