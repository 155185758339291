import { NgZone } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AuthGuardService } from './core/services/auth-guard.service';
import { ServiceService } from '../app/core/services/service.service';
import {MatSnackBar ,  MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { ConnectApiErrorComponent } from './shared/snackBar/connect-api-error/connect-api-error.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smartwatch-dashboard-admin';
  idOperatorAfmin: any;
  adminRule: any;
  showHead: boolean = true;
  router_: any;
  durationInSeconds = 5;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private route: ActivatedRoute, private router: Router, private authGuardService: AuthGuardService, private ServiceService: ServiceService,private _snackBar: MatSnackBar) {
    this.router_ = router.url;
    this.idOperatorAfmin = localStorage.getItem("currentIdAdmin");
    this.adminRule = localStorage.getItem("currentRole");
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login') {
          if (localStorage.getItem("currentUser")) {
            this.showHead = true;
            this.router.navigate([`dashboard`]);
          } else {
            this.showHead = false;
          }
        }
        if (event['url'] == '/liveMonitor' || event['url'] == '/liveMonitorWorking' || event['url'] == '/liveMonitorSuccess') {
          if (localStorage.getItem("currentUser")) {

            this.showHead = false;
          }
        }

        else {
          this.showHead = true;
          // if (this.route.snapshot.paramMap.get("idOperatorAdmin") && this.route.snapshot.paramMap.get("idCase")) {

          // }
          this.checkLogin();
        }

        if (event['url'].startsWith('/caseOP') || event['url'].startsWith('/caseProfileNew')) {
          this.showHead= false;
        }
        // if (event['url'] == '/liveMonitorWorking') {
        //   if (localStorage.getItem("currentUser")) {

        //     this.showHead = false;
        //   }
        // } else {
        //   this.showHead = true;
        //   this.checkLogin();
        // }

      }
    });
  }

  checkLogin() {
    this.ServiceService.CheckToken(localStorage.getItem("currentUser")).toPromise().then(res => {
      // console.log("ss ", res);
      if (res == null) {
        this.showHead = false;
        // alert("API Connection Error!")
        this._snackBar.openFromComponent(ConnectApiErrorComponent, {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          // duration: this.durationInSeconds * 1000,
        });
        // this.router.navigate(['/connectApiError'], { replaceUrl: true }).then();
      }
      if (res.status == true && res.idAdmin == localStorage.getItem("currentIdAdmin") && res.role == localStorage.getItem("currentRole")) {
        // this.router.navigate([`dashboard/${localStorage.getItem("currentIdAdmin")}`]);
      } else {
        // alert("AAAA")
        var resUpdateStatusOperator :any;
        this.showHead = false;
        if (this.adminRule == "Operator") {
          this.ServiceService.UpdateStatusOperator_(this.idOperatorAfmin, false).then(dataUpdateStatusOperator => {
            resUpdateStatusOperator = dataUpdateStatusOperator;
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentIdAdmin');
            localStorage.removeItem('currentRole');
            localStorage.removeItem('showAdmin');
            localStorage.removeItem('nameOpertor');
            this.router.navigate(['/login'], { replaceUrl: true }).then();
          });
        } else {
          localStorage.removeItem('currentUser');
          localStorage.removeItem('currentIdAdmin');
          localStorage.removeItem('currentRole');
          localStorage.removeItem('showAdmin');
          localStorage.removeItem('nameOpertor');
          // this.router.navigate(['/login'], { replaceUrl: true }).then();
        }
      }


    })
  }

}
