<style>
    .mat-accent {
        background-color: #ff3c23;
        color: #ffffff;
    }

</style>
<div class="col-12">
    <div class="row">
        <div class="col-6">
            <h2 mat-dialog-title>Health Information</h2>
        </div>
        <div class="col-6" style="text-align: end;" *ngIf="showbtnPSCovid19 == true">
            <button mat-raised-button style="margin-right: 6px;" color="accent"
                (click)="openDialogPersonalHealth(dataIdUser)"><i class="fas fa-notes-medical"></i> Personal
                Health</button>
            <button mat-raised-button color="accent" (click)='openDialogCovid19Chart(dataIdUser)'><i
                    class="far fa-chart-bar"></i> Health Information Covid 19</button>
        </div>
    </div>
</div>
<div class="col-md-12">
    <div class="row" *ngIf="loadData == true">
        <mat-spinner style="margin-right: auto;margin-left: auto;margin-top: 190px;margin-bottom: 190px;"></mat-spinner>
    </div>
</div>
<div class="col-md-12" *ngIf="loadData == false && dataListHistoryBody.length < 1 ">
    <div class="col-md-12">
        <div class="mb-2">
            <div style="display: flex;">
                <div class="container rubberBand"
                    style="color: #bdbbb7;margin-top: 195px;margin-bottom: 195px;text-align: center;">
                    <i class="fas fa-calendar-times" style="font-size: 50px;"></i>
                    <p>No Notification.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<mat-tab-group *ngIf="loadData == false && dataListHistoryBody.length > 0">
    <mat-tab label="Health History">
        <div class="col-12 mat-elevation-z8">
            <div class="row">
                <div class="col-12" *ngIf="showbtnPSCovid19 == false">
                    <div class="pt-2 pb-2" style="overflow: auto; height:460px;">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Pressure</th>
                                    <th scope="col">Pressure</th>
                                    <th scope="col">Heart Rate</th>
                                    <th scope="col">Temperature</th>
                                    <th scope="col">Spo2</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of dataListHistoryBody;let i = index">
                                <!-- <tr (click)="onClickDetail(item)"> -->
                                <tr>
                                    <th scope="row">{{i+1}}</th>
                                    <td>{{item.cPressureHigh}}</td>
                                    <td>{{item.cPressureLow}}</td>
                                    <td>{{item.cHeartrate}}</td>
                                    <td>{{item.cTemp}}</td>
                                    <td>{{item.cSpo2}}</td>
                                    <td>{{item.cDate | date:'medium'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-8" *ngIf="showbtnPSCovid19 == true">
                    <div class="pt-2 pb-2" style="overflow: auto; height:460px;">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Pressure </th>
                                    <th scope="col">Pressure </th>
                                    <th scope="col">Heart Rate</th>
                                    <th scope="col">Temperature</th>
                                    <th scope="col">Spo2</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of dataListHistoryBody;let i = index" (click)="onClickDetail(item)">
                                <!-- <tr (click)="onClickDetail(item)"> -->
                                <tr>
                                    <th scope="row">{{i+1}}</th>
                                    <td>{{item.cPressureHigh}}</td>
                                    <td>{{item.cPressureLow}}</td>
                                    <td>{{item.cHeartrate}}</td>
                                    <td>{{item.cTemp}}</td>
                                    <td>{{item.cSpo2}}</td>
                                    <td>{{item.cDate | date:'medium'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-4"  *ngIf="showbtnPSCovid19 == true">
                    <div class="callout callout-info mat-elevation-z8 mt-2">
                        <h2 style="text-align: center;"><i class="fas fa-info"></i> Detailed History</h2>
                        <div style="padding-left: 8px;padding-right: 8px;overflow: auto; height:355px;">
                            <p *ngIf="dataDetailHistory.cFeverish.isCheck == true" style="margin: 0px;">- มีไข้</p>
                            <p *ngIf="dataDetailHistory.cFeverish.isCheck == true" style="margin-left: 8px;"> อาการมีไข้ : 
                                <b *ngIf="dataDetailHistory.cFeverish.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cFeverish.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cFeverish.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cSoreThroat.isCheck == true" style="margin: 0px;">- เจ็บคอ</p>
                            <p *ngIf="dataDetailHistory.cSoreThroat.isCheck == true" style="margin-left: 8px;"> อาการเจ็บคอ :
                                <b *ngIf="dataDetailHistory.cSoreThroat.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cSoreThroat.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cSoreThroat.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cMusclePain.isCheck == true" style="margin: 0px;">- ปวดกล้ามเนื้อ</p>
                            <p *ngIf="dataDetailHistory.cMusclePain.isCheck == true" style="margin-left: 8px;"> อาการปวดกล้ามเนื้อ :
                                <b *ngIf="dataDetailHistory.cMusclePain.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cMusclePain.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cMusclePain.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cRunny.isCheck == true" style="margin: 0px;">- มีน้ำมูก</p>
                            <p *ngIf="dataDetailHistory.cRunny.isCheck == true" style="margin-left: 8px;"> อาการมีน้ำมูก : 
                                <b *ngIf="dataDetailHistory.cRunny.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cRunny.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cRunny.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cSputum.isCheck == true" style="margin: 0px;">- มีเสมหะ</p>
                            <p *ngIf="dataDetailHistory.cSputum.isCheck == true" style="margin-left: 8px;"> อาการมีเสมหะ : 
                                <b *ngIf="dataDetailHistory.cSputum.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cSputum.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cSputum.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cHeadache.isCheck == true" style="margin: 0px;">- ปวดหัว</p>
                            <p *ngIf="dataDetailHistory.cHeadache.isCheck == true" style="margin-left: 8px;"> อาการปวดหัว :  
                                <b *ngIf="dataDetailHistory.cHeadache.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cHeadache.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cHeadache.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cNoseNoSmell.isCheck == true" style="margin: 0px;">- จมูกไม่ได้กลิ่น</p>
                            <p *ngIf="dataDetailHistory.cNoseNoSmell.isCheck == true" style="margin-left: 8px;"> อาการจมูกไม่ได้กลิ่น :
                                <b *ngIf="dataDetailHistory.cNoseNoSmell.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cNoseNoSmell.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cNoseNoSmell.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cRash.isCheck == true" style="margin: 0px;">- มีผื่น</p>
                            <p *ngIf="dataDetailHistory.cRash.isCheck == true" style="margin-left: 8px;"> อาการมีผื่น :
                                <b *ngIf="dataDetailHistory.cRash.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cRash.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cRash.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cLiquidStool.isCheck == true" style="margin: 0px;">- ถ่ายเหลว</p>
                            <p *ngIf="dataDetailHistory.cLiquidStool.isCheck == true" style="margin-left: 8px;"> อาการถ่ายเหลว :
                                <b *ngIf="dataDetailHistory.cLiquidStool.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cLiquidStool.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cLiquidStool.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cCoughAlittle.isCheck == true" style="margin: 0px;">- ไอเล็กน้อย</p>
                            <p *ngIf="dataDetailHistory.cCoughAlittle.isCheck == true" style="margin-left: 8px;"> อาการไอเล็กน้อย :
                                <b *ngIf="dataDetailHistory.cCoughAlittle.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cCoughAlittle.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cCoughAlittle.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cTongueNoTaste.isCheck == true" style="margin: 0px;">- ลิ้นไม่รับรส</p>
                            <p *ngIf="dataDetailHistory.cTongueNoTaste.isCheck == true" style="margin-left: 8px;"> อาการลิ้นไม่รับรส :
                                <b *ngIf="dataDetailHistory.cTongueNoTaste.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cTongueNoTaste.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cTongueNoTaste.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cConjunctivitis.isCheck == true" style="margin: 0px;">- ตาแดง</p>
                            <p *ngIf="dataDetailHistory.cConjunctivitis.isCheck == true" style="margin-left: 8px;"> อาการตาแดง :
                                <b *ngIf="dataDetailHistory.cConjunctivitis.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cConjunctivitis.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cConjunctivitis.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cCanNotEat.isCheck == true" style="margin: 0px;">- ทานอาหารไม่ได้</p>
                            <p *ngIf="dataDetailHistory.cCanNotEat.isCheck == true" style="margin-left: 8px;"> อาการทานอาหารไม่ได้ :
                                <b *ngIf="dataDetailHistory.cCanNotEat.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cCanNotEat.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cCanNotEat.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cCoughAlot.isCheck == true" style="margin: 0px;">- ไอเยอะ</p>
                            <p *ngIf="dataDetailHistory.cCoughAlot.isCheck == true" style="margin-left: 8px;"> อาการไอเยอะ :
                                <b *ngIf="dataDetailHistory.cCoughAlot.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cCoughAlot.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cCoughAlot.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cCoughingUpBlood.isCheck == true" style="margin: 0px;">- ไอเป็นเลือด</p>
                            <p *ngIf="dataDetailHistory.cCoughingUpBlood.isCheck == true" style="margin-left: 8px;"> อาการไอเป็นเลือด : 
                                <b *ngIf="dataDetailHistory.cCoughingUpBlood.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cCoughingUpBlood.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cCoughingUpBlood.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cBreathingDiff.isCheck == true" style="margin: 0px;">- หายใจลำบาก แน่นหน้าอก</p>
                            <p *ngIf="dataDetailHistory.cBreathingDiff.isCheck == true" style="margin-left: 8px;"> อาการหายใจลำบาก แน่นหน้าอก :
                                <b *ngIf="dataDetailHistory.cBreathingDiff.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cBreathingDiff.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cBreathingDiff.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cTriedDailyActivities.isCheck == true" style="margin: 0px;">- เหนื่อยในขณะที่ทำกิจวัตรประจำวัน</p>
                            <p *ngIf="dataDetailHistory.cTriedDailyActivities.isCheck == true" style="margin-left: 8px;"> อาการเหนื่อยในขณะที่ทำกิจวัตรประจำวัน :
                                <b *ngIf="dataDetailHistory.cTriedDailyActivities.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cTriedDailyActivities.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cTriedDailyActivities.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cTiredSitStill.isCheck == true" style="margin: 0px;">- เหนื่อยขณะนั่งเฉยๆ</p>
                            <p *ngIf="dataDetailHistory.cTiredSitStill.isCheck == true" style="margin-left: 8px;"> อาการเหนื่อยขณะนั่งเฉยๆ :
                                <b *ngIf="dataDetailHistory.cTiredSitStill.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cTiredSitStill.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cTiredSitStill.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                            <p *ngIf="dataDetailHistory.cLieDownBreath.isCheck == true" style="margin: 0px;">- นอนราบแล้วหายใจไม่ออก</p>
                            <p *ngIf="dataDetailHistory.cLieDownBreath.isCheck == true" style="margin-left: 8px;"> อาการนอนราบแล้วหายใจไม่ออก :
                                <b *ngIf="dataDetailHistory.cLieDownBreath.currentSymptoms == 0"> ดีขึ้น</b>
                                <b *ngIf="dataDetailHistory.cLieDownBreath.currentSymptoms == 1"> เท่าเดิม</b>
                                <b *ngIf="dataDetailHistory.cLieDownBreath.currentSymptoms == 2"> แย่ลง</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab >
        <ng-template mat-tab-label>
            <span (click)="genarateGraph()">Pressure</span>
        </ng-template>
        <div *ngIf="dataChartPressure.length == 0" class="col-md-12"
            style="text-align: center;margin-top: 190px;margin-bottom: 190px;">
            <i class="far fa-calendar-times" style="font-size: 80px;color: #7b7b7b;"></i>
            <p>No Information</p>
        </div>
        <div class="col-md-12" *ngIf="dataChartPressure.length != 0">
            <div class="row" style="padding: 20px;">
                <div class="col-md-7">
                    <!-- <div class="chartjs-container"> -->
                        <!-- <canvas style="background-image: url('https://i.ibb.co/CsCd25v/testbg.png'); background-size: cover;" baseChart [datasets]="lineChartPressureData" [labels]="lineChartLabels" -->
                        <!-- <canvas baseChart [datasets]="lineChartPressureData" [labels]="lineChartLabels"
                            [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                            [chartType]="lineChartType" [plugins]="lineChartPlugins">
                        </canvas> -->
                    <!-- </div> -->
                    <div #chartPressure style="width: 100%; height: 500px" ></div>
                </div>
                <div class="col-md-5">
                    <div style="overflow: auto; height:420px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Pressure</th>
                                    <th scope="col">Pressure</th>
                                    <th scope="col">DateTime</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of dataListHistoryBody;let i = index">
                                <tr>
                                    <th scope="row">{{i+1}}</th>
                                    <td>{{item.cPressureHigh}}</td>
                                    <td>{{item.cPressureLow}}</td>
                                    <td>{{item.cDate | date:'medium'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span (click)="GraphHr()">Heart Rate</span>
        </ng-template>
        <div *ngIf="dataChartHeartRate.length == 0" class="col-md-12"
            style="text-align: center;margin-top: 190px;margin-bottom: 190px;">
            <i class="far fa-calendar-times" style="font-size: 80px;color: #7b7b7b;"></i>
            <p>No Information</p>
        </div>
        <div class="col-md-12" *ngIf="dataChartHeartRate.length != 0">
            <div class="row" style="padding: 20px;">
                <div class="col-md-7">
                    <div #chartHR style="width: 100%; height: 500px" ></div>
                    <!-- <div class="chartjs-container">
                        <canvas baseChart [datasets]="lineChartHeartRateData" [labels]="lineChartLabels"
                            [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                            [chartType]="lineChartType" [plugins]="lineChartPlugins">
                        </canvas>
                    </div> -->
                </div>
                <div class="col-md-5">
                    <div style="overflow: auto; height:420px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Value</th>
                                    <th scope="col">DateTime</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of dataListHistoryBody;let i = index">
                                <tr>
                                    <th scope="row">{{i+1}}</th>
                                    <td>{{item.cHeartrate}}</td>
                                    <td>{{item.cDate | date:'medium'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span (click)="GraphTemp()">Temperature</span>
        </ng-template>
        <div *ngIf="dataChartTemperature.length == 0" class="col-md-12"
            style="text-align: center;margin-top: 190px;margin-bottom: 190px;">
            <i class="far fa-calendar-times" style="font-size: 80px;color: #7b7b7b;"></i>
            <p>No Information</p>
        </div>
        <div class="col-md-12" *ngIf="dataChartTemperature.length != 0">
            <div class="row" style="padding: 20px;">
                <div class="col-md-7">
                    <div #chartTemp style="width: 100%; height: 500px" ></div>
                    <!-- <div class="chartjs-container">
                        <canvas baseChart [datasets]="lineChartTemperatureData" [labels]="lineChartLabels"
                            [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                            [chartType]="lineChartType" [plugins]="lineChartPlugins">
                        </canvas>
                    </div> -->
                </div>
                <div class="col-md-5">
                    <div style="overflow: auto; height:420px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Value</th>
                                    <th scope="col">DateTime</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of dataListHistoryBody;let i = index">
                                <tr>
                                    <th scope="row">{{i+1}}</th>
                                    <td>{{item.cTemp}}</td>
                                    <td>{{item.cDate | date:'medium'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab >
        <ng-template mat-tab-label>
            <span (click)="GraphSpo2()">Spo2</span>
        </ng-template>
        <div *ngIf="dataChartSpo2.length == 0" class="col-md-12"
            style="text-align: center;margin-top: 190px;margin-bottom: 190px;">
            <i class="far fa-calendar-times" style="font-size: 80px;color: #7b7b7b;"></i>
            <p>No Information</p>
        </div>
        <div class="col-md-12" *ngIf="dataChartSpo2.length != 0">
            <div class="row" style="padding: 20px;">
                <div class="col-md-7">
                    <div #chartSpo2 style="width: 100%; height: 500px" ></div>
                    <!-- <div class="chartjs-container">
                        <canvas baseChart [datasets]="lineChartSpo2Data" [labels]="lineChartLabels"
                            [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                            [chartType]="lineChartType" [plugins]="lineChartPlugins">
                        </canvas>
                    </div> -->
                </div>
                <div class="col-md-5">
                    <div style="overflow: auto; height:420px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Value</th>
                                    <th scope="col">DateTime</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of dataListHistoryBody;let i = index">
                                <tr>
                                    <th scope="row">{{i+1}}</th>
                                    <td>{{item.cSpo2}}</td>
                                    <td>{{item.cDate | date:'medium'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>