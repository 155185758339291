import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../../../../core/services/service.service';
import { DialogConfirmMallUser } from './modal/confirm-mall-user-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-doctor-mall',
  templateUrl: './doctor-mall.component.html',
  styleUrls: ['./doctor-mall.component.scss']
})
export class DoctorMallComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  resultDataDoctorMall: any;
  selected = '20';
  status: string = "all"
  loadDataUser: boolean = true;
  tableShow: boolean = false;
  cardShow: boolean = false;
  noDataInformation: boolean = false;
  idOperatorAdmin: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private ServiceService: ServiceService
  ) {
    this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
   }

  ngOnInit(): void {
    this.loadDataDoctorMall("Request", this.status, this.selected);
  }

  onClickRefresh() {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;
    this.loadDataDoctorMall("Request", this.status, this.selected);
  }

  onChangeCountItem(event: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;

    this.resultDataDoctorMall = [];
    // console.log(event);
    this.selected = event;
    this.loadDataDoctorMall("Request", this.status, this.selected);
  }

  onChangeStatus(status: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;

    this.resultDataDoctorMall = [];
    if (status == "red") {
      this.status = "Red";
    } else if (status == "yellow") {
      this.status = "Yellow";
    } else if (status == "green") {
      this.status = "Green";
    } else {
      this.status = "all";
    }
    this.loadDataDoctorMall("Request", this.status, this.selected);
  }

  onClickShowData(type: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;
    if (type == "card") {
      setTimeout(() => {
        this.loadDataUser = false;
        this.tableShow = false;
        this.cardShow = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.loadDataUser = false;
        this.tableShow = true;
        this.cardShow = false;
      }, 2000);
    }
  }

  loadDataDoctorMall(status: string, codivStatus: string, countLists: string) {
    this.loadData(status, codivStatus, countLists).then(resultDaTa => {
      this.resultDataDoctorMall = [];
      // console.log("resDoctorMall ", resultDaTa);
      if (resultDaTa != false) {
        this.resultDataDoctorMall = resultDaTa;
        setTimeout(() => {
          this.noDataInformation = false;
          this.loadDataUser = false;
          this.tableShow = true;
          this.cardShow = false;
        }, 2000);
      } else {
        setTimeout(() => {
          this.noDataInformation = true;
          this.loadDataUser = false;
          this.tableShow = true;
          this.cardShow = false;
        }, 2000);
      }
    });
  }

  async loadData(status: string, codivStatus: string, countLists: string) {
    return new Promise<any>(async (resolve, rejects) => {
      var dataDoctorMall_: any;
      await this.ServiceService.GetDataDoctorMallList_(status, codivStatus, countLists,this.idOperatorAdmin).then(resDoctorMall => {
        // console.log("resDoctorMall >> ",resDoctorMall);

        dataDoctorMall_ = resDoctorMall;
        if (dataDoctorMall_.length != 0) {
          resolve(dataDoctorMall_);
        } else {
          resolve(false);
        }
      });

    });
  }

  openDialogConfirm(data: any,index:any): void {
    const dialogRef = this.dialog.open(DialogConfirmMallUser, {
      data: data, disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "Failed") {
        this.loadDataUser = true;
        this.tableShow = false;
        this.cardShow = false;

        this.resultDataDoctorMall = [];
        this.loadDataDoctorMall("Request", this.status, this.selected);
      }else if (result == "Cancel") {

      }else{
        this.resultDataDoctorMall.splice(index, 1);
      }
    });
  }

}

