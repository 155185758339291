import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { ServiceService } from '../../../../../../core/services/service.service';
import { DialogdialogSuccessReferCovid19 } from '../success-refer-covid19/dialog-success-refer-covid19';

@Component({
    selector: 'dialog-refer-user-covid19',
    templateUrl: './dialog-refer-user-covid19.html',
})

export class DialogdialogReferUserCovid19 {

    showRefer: boolean = false;
    dataReferUserCovid19: any;
    dataPersonalHealth: any;

    mpFavi_: boolean = false;
    mpDexa_: boolean = false;
    mpPrednisolone_: boolean = false;
    mpOmeprazole_: boolean = false;
    mpOxygen_: boolean = false;
    mpOthers_: boolean = false;

    dataFavi_: any;
    dataDexa_: any;
    dataPrednisolone_: any;
    dataOmeprazole_: any;
    dataOxygen_: any;
    dataOthers_: any;

    dateTimeFavi_: string = "";
    dateTimeFaviDay_: string = "";
    dateTimeFavi_start: string = "";
    dateTimeFavi_end: string = "";
    dateTimeDexa_: string = "";
    dateTimePrednisolone_: string = "";
    dateTimeOmeprazole_: string = "";
    dateOxygen_: string = "";
    dateOthers_: string = "";

    referUserForm!: FormGroup;
    dataConvert: any;
    dataConvertSave: any;

    gender: string = "-";

    dataNameDrugTH: string = "";

    requiredReferHospital1: boolean = false;
    requiredReferHospitalAffiliation: boolean = false;
    requiredReferRightToTreatment: boolean = false;
    requiredReferPhoneAdmin: boolean = false;
    requiredReferHospital2: boolean = false;
    requiredReferDoctorname: boolean = false;
    requiredReferIdDoctor: boolean = false;

    selectAsymptomatic: string = "";
    selectUpperRespiratoryInfection: string = "";
    selectMildPneumonia: string = "";
    selectSeverePneumonia: string = "";



    constructor(
        public dialog: MatDialog,
        private ServiceService: ServiceService,
        @Inject(LOCALE_ID) private locale: string,
        fb: FormBuilder,
        public dialogRef: MatDialogRef<DialogdialogReferUserCovid19>,
        public formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.referUserForm = this.formBuilder.group({
            referHospital1: [''],
            referHospitalAffiliation: [''],
            referRightToTreatment: [''],
            referPhoneAdmin: [''],
            referHospital2: [''],
            refernoteDoctor: [''],
            referHospitalDate: new Date(),
            referDoctorname: [''],
            referIdDoctor: [''],
            referDate: new Date()
        });
        // console.log(">>>>>>>>>>>>>>>>>>> ", data);
        this.loadDataRefer(data);
    }

    loadDataRefer(datarefer: any) {
        this.dataReferUserCovid19 = datarefer;
        if (this.dataReferUserCovid19.personalHealth != null) {
            this.dataPersonalHealth = this.dataReferUserCovid19.personalHealth.pCongenitalDisease.split(',');

        } else {
            this.dataPersonalHealth = "";
        }

        if (this.dataReferUserCovid19.dataUser.userSex == "M") {
            this.gender = "ชาย";
        } else {
            this.gender = "หญิง";
        }
        var index = datarefer.dataDrug.length - 1;
        // console.log(">>>>>>>>> ",datarefer.dataDrug);
        
        if (datarefer.dataDrug != "") {
            if (datarefer.dataDrug[index].mpFavi == true) {
                // console.log("mpFavi");
                this.mpFavi_ = true;
                this.dateTimeFavi_ = datarefer.dataDrug[index].mpFaviStartDate;

                this.dateTimeFavi_start = datarefer.dataDrug[index].mpFaviDayStart;
                this.dateTimeFavi_end = datarefer.dataDrug[index].mpFaviDayEnd;

                if (datarefer.dataDrug[index].mpFaviDay = "f5d") {
                    this.dateTimeFaviDay_ = "First 5 days";
                } else {
                    this.dateTimeFaviDay_ = "Next 5 days";
                }

                this.dataFavi_ = `${this.dateTimeFaviDay_} start : ${formatDate(new Date(this.dateTimeFavi_start), 'yyyy-MM-dd', this.locale)} end : ${formatDate(new Date(this.dateTimeFavi_end), 'yyyy-MM-dd', this.locale)}`;
            }
            if (datarefer.dataDrug[index].mpDexa == true) {
                // console.log("mpDexa");
                this.mpDexa_ = true;
                this.dateTimeDexa_ = datarefer.dataDrug[index].mpDexaStartDate;

                this.dataDexa_ = datarefer.dataDrug[index].mpDexaStartDate
            }
            if (datarefer.dataDrug[index].mpPrednisolone == true) {
                // console.log("mpPrednisolone");
                this.mpPrednisolone_ = true;
                this.dateTimePrednisolone_ = datarefer.dataDrug[index].mpPrednisoloneStartDate;

                this.dataPrednisolone_ = datarefer.dataDrug[index].mpPrednisoloneStartDate;
            }
            if (datarefer.dataDrug[index].mpOmeprazole == true) {
                // console.log("mpOmeprazole");
                this.mpOmeprazole_ = true;
                this.dateTimeOmeprazole_ = datarefer.dataDrug[index].mpOmeprazoleStartDate;

                this.dataOmeprazole_ = datarefer.dataDrug[index].mpOmeprazoleStartDate;
            }
            if (datarefer.dataDrug[index].mpOxygen == true) {
                // console.log("mpOxygen");
                this.mpOxygen_ = true;
                this.dateOxygen_ = datarefer.dataDrug[index].mpOxygenNote;

                this.dataOxygen_ = datarefer.dataDrug[index].mpOxygenNote;
            }
            if (datarefer.dataDrug[index].mpOthers == true) {
                // console.log("mpOthers");
                this.mpOthers_ = true;
                this.dateOthers_ = datarefer.dataDrug[index].mpOthersNote;

                this.dataOthers_ = datarefer.dataDrug[index].mpOthersNote;
            }
        }else{
            this.dataFavi_ = "";
            this.dataDexa_ = "";
            this.dataPrednisolone_ = "";
            this.dataOmeprazole_ = "";
            this.dataOxygen_ = "";
            this.dataOthers_ = "";
        }

        var nameDrugTH = "";
        if (this.dataReferUserCovid19.dataDrugTH.mptStatiaCz == true) {
            nameDrugTH = nameDrugTH + "STATIA C Z ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptStatia == true) {
            nameDrugTH = nameDrugTH + "STATIA ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mpt5roots == true) {
            nameDrugTH = nameDrugTH + "5 ราก ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptGreenMedicine == true) {
            nameDrugTH = nameDrugTH + "ยาเขียว ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptAummahiva == true) {
            nameDrugTH = nameDrugTH + "ยาอํามฤควาที ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptPrasapoyai == true) {
            nameDrugTH = nameDrugTH + "ยาประสะเปราะใหญ่ ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptJanlelar == true) {
            nameDrugTH = nameDrugTH + "ยาจันทน์ลีลา ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptHomyen == true) {
            nameDrugTH = nameDrugTH + "ยาหอมเย็น ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptHomron == true) {
            nameDrugTH = nameDrugTH + "ยาหอมร้อน ,"
        }
        if (this.dataReferUserCovid19.dataDrugTH.mptFatalaijon == true) {
            nameDrugTH = nameDrugTH + "ฟ้าทลายโจร ,"
        }
        this.dataNameDrugTH = nameDrugTH.substring(0, nameDrugTH.length - 1)
        // console.log(this.dataNameDrugTH);


    }

    onCheckAsymptomatic(event: any) {
        if (event.checked == true) {
            this.selectAsymptomatic = "Asymptomatic";
        } else {
            this.selectAsymptomatic = "";
        }
    }

    onCheckUpperRespiratoryInfection(event: any) {
        if (event.checked == true) {
            this.selectUpperRespiratoryInfection = "Upper respiratory infection";
        } else {
            this.selectUpperRespiratoryInfection = "";
        }
    }

    onCheckMildPneumonia(event: any) {
        if (event.checked == true) {
            this.selectMildPneumonia = "Mild Pneumonia";
        } else {
            this.selectMildPneumonia = "";
        }
    }

    onCheckSeverePneumonia(event: any) {
        if (event.checked == true) {
            this.selectSeverePneumonia = "Severe Pneumonia";
        } else {
            this.selectSeverePneumonia = "";
        }
    }

    confirmPDF(event: boolean) {
        if (this.referUserForm.value.referHospital1 != "") {
            this.requiredReferHospital1 = false;
        } else {
            this.requiredReferHospital1 = true;
        }

        if (this.referUserForm.value.referHospitalAffiliation != "") {
            this.requiredReferHospitalAffiliation = false;
        } else {
            this.requiredReferHospitalAffiliation = true;
        }

        if (this.referUserForm.value.referRightToTreatment != "") {
            this.requiredReferRightToTreatment = false;
        } else {
            this.requiredReferRightToTreatment = true;
        }

        if (this.referUserForm.value.referPhoneAdmin != "") {
            this.requiredReferPhoneAdmin = false;
        } else {
            this.requiredReferPhoneAdmin = true;
        }

        if (this.referUserForm.value.referHospital2 != "") {
            this.requiredReferHospital2 = false;
        } else {
            this.requiredReferHospital2 = true;
        }

        if (this.referUserForm.value.referDoctorname != "") {
            this.requiredReferDoctorname = false;
        } else {
            this.requiredReferDoctorname = true;
        }

        if (this.referUserForm.value.referIdDoctor != "") {
            this.requiredReferIdDoctor = false;
        } else {
            this.requiredReferIdDoctor = true;
        }

        if (this.referUserForm.value.referIdDoctor != "" && this.referUserForm.value.referDoctorname != "" && this.referUserForm.value.referHospital2 != "" &&
            this.referUserForm.value.referPhoneAdmin != "" && this.referUserForm.value.referRightToTreatment != "" && this.referUserForm.value.referHospitalAffiliation != "" &&
            this.referUserForm.value.referHospital1 != "") {
            this.showRefer = event;
            var personalHealth_ = ""
            var pRegularMedication_ = ""
            if (this.dataReferUserCovid19.personalHealth == "" || this.dataReferUserCovid19.personalHealth == null) {
                personalHealth_ = "";
                pRegularMedication_ = "";
            }else{
                personalHealth_ = this.dataReferUserCovid19.personalHealth.pAtkRtpcr; 
                pRegularMedication_ = this.dataReferUserCovid19.personalHealth.pRegularMedication; 
            }

            var dataConvert_ = {
                "DOI": this.dataReferUserCovid19.doctorMall.dmRequestTime,
                "DateATK": personalHealth_,
                "HomeIsolate": this.dataReferUserCovid19.doctorMall.dmRequestTime,
                "RiskFactor": this.dataPersonalHealth,
                "UserName": this.dataReferUserCovid19.dataUser.userName,
                "UserDate": this.dataReferUserCovid19.dataUser.userDate,
                "Usersex": this.gender,
                "UserAge": this.dataReferUserCovid19.dataUser.userAge,
                "UserAdress": `${this.dataReferUserCovid19.dataUser.userAddress} ${this.dataReferUserCovid19.dataUser.userSubdistrict}, ${this.dataReferUserCovid19.dataUser.userDistrict}, ${this.dataReferUserCovid19.dataUser.userProvince}, ${this.dataReferUserCovid19.dataUser.userPostalcode}`,
                "UserphoneRelative": `${this.dataReferUserCovid19.dataUser.reletiveNumber1}, ${this.dataReferUserCovid19.dataUser.reletiveNumber2}`,
                "Vitalsign_T": this.dataReferUserCovid19.covidSymptom.cTemp,
                "Vitalsign_P": this.dataReferUserCovid19.covidSymptom.cHeartrate,
                "Vitalsign_BP": `${this.dataReferUserCovid19.covidSymptom.cPressureHigh}/${this.dataReferUserCovid19.covidSymptom.cPressureLow}`,
                "Vitalsign_SpO2AtRest": this.dataReferUserCovid19.covidSymptom.cSpo2,
                "Vitalsign_SpO2AfterEx": this.dataReferUserCovid19.covidSymptom.cSpo2AfterExercise,
                "Vitalsign_SpO2OnO2": this.dataReferUserCovid19.covidSymptom.cSpo2Ventilator,

                "SelectYourtoppings2": `${this.selectAsymptomatic} ${this.selectUpperRespiratoryInfection} ${this.selectMildPneumonia} ${this.selectSeverePneumonia}`,

                "MedicineReceivedFavipiravir": this.dataFavi_,
                "MedicineReceivedDexamethasone": this.dataDexa_,
                "MedicineReceivedPrednisolone": this.dataPrednisolone_,
                "MedicineReceivedOmeprazole": this.dataOmeprazole_,
                "MedicineReceivedOxygen": this.dataOxygen_,
                "MedicineReceivedOthers": this.dataOthers_,

                "RegularMedication": pRegularMedication_,
                "MedicalComments": this.dataReferUserCovid19.comment,

                "ReferHospital1": this.referUserForm.value.referHospital1,
                "ReferHospitalAffiliation": this.referUserForm.value.referHospitalAffiliation,
                "ReferRightToTreatment": this.referUserForm.value.referRightToTreatment,
                "ReferPhoneAdmin": this.referUserForm.value.referPhoneAdmin,
                "ReferHospital2": this.referUserForm.value.referHospital2,
                "RefernoteDoctor": this.referUserForm.value.refernoteDoctor,
                "ReferDoctorname": this.referUserForm.value.referDoctorname,
                "ReferIdDoctor": this.referUserForm.value.referIdDoctor,
                "ReferHospitalDate": this.referUserForm.value.referHospitalDate,
                "ReferDate": this.referUserForm.value.referDate
            };

            this.dataConvert = dataConvert_;
            // console.log(this.dataConvert);

        } else {
        }

    }


    makePDF() {
        var vitalsignNew = "";
        var vitalsignNew_ = "";

        var dateDexamethasone_: string = "";
        var datePrednisolone_: string = "";
        var dateOmeprazole_: string = "";
        var dateDOI_: string = "";
        var dateDateATK_: string = "";
        var dateHomeIsolate_: string = "";
        var dateHospitalDate_: string = "";
        var dateDate_: string = "";
        if (this.dataConvert.Vitalsign_T.length != 0) {
            vitalsignNew = vitalsignNew + " T " + this.dataConvert.Vitalsign_T + ", ";
        }
        if (this.dataConvert.Vitalsign_P.length != 0) {
            vitalsignNew = vitalsignNew + " P " + this.dataConvert.Vitalsign_P + ", ";
        }
        if (this.dataConvert.Vitalsign_BP.length != 0) {
            vitalsignNew = vitalsignNew + " BP " + this.dataConvert.Vitalsign_BP + ", ";
        }
        if (this.dataConvert.Vitalsign_SpO2AtRest.length != 0) {
            vitalsignNew = vitalsignNew + " SpO2 At Rest " + this.dataConvert.Vitalsign_SpO2AtRest + ", ";
        }
        if (this.dataConvert.Vitalsign_SpO2AfterEx.length != 0) {
            vitalsignNew = vitalsignNew + " SpO2 After Ex " + this.dataConvert.Vitalsign_SpO2AfterEx + ", ";
        }
        if (this.dataConvert.Vitalsign_SpO2OnO2.length != 0) {
            vitalsignNew = vitalsignNew + " SpO2 On O2 " + this.dataConvert.Vitalsign_SpO2OnO2 + ", ";
        }
        if (this.dataConvert.Vitalsign_T.length != 0 || this.dataConvert.Vitalsign_P.length || this.dataConvert.Vitalsign_BP.length || this.dataConvert.Vitalsign_SpO2AtRest.length != 0
            || this.dataConvert.Vitalsign_SpO2AfterEx.length != 0 || this.dataConvert.Vitalsign_SpO2OnO2.length != 0) {
            vitalsignNew_ = vitalsignNew.substring(0, vitalsignNew.length - 2)
            // console.log("vitalsignNew_>>>>>> ",vitalsignNew_ );
        }
        
        if (this.dataConvert.MedicineReceivedDexamethasone != "" && this.dataConvert.MedicineReceivedDexamethasone != null) {

            dateDexamethasone_ = formatDate(new Date(this.dataConvert.MedicineReceivedDexamethasone), 'yyyy-MM-dd', this.locale);
            // dateDexamethasone_ = "";
        }
        if (this.dataConvert.MedicineReceivedPrednisolone != "" && this.dataConvert.MedicineReceivedPrednisolone != null) {
            datePrednisolone_ = formatDate(new Date(this.dataConvert.MedicineReceivedPrednisolone), 'yyyy-MM-dd', this.locale);
            // datePrednisolone_ = "";
        }
        if (this.dataConvert.MedicineReceivedOmeprazole != "" && this.dataConvert.MedicineReceivedOmeprazole != null) {
            dateOmeprazole_ = formatDate(new Date(this.dataConvert.MedicineReceivedOmeprazole), 'yyyy-MM-dd', this.locale);
            // dateOmeprazole_ = "";
        }
        if (this.dataConvert.DOI != "" && this.dataConvert.DOI != null) {
            dateDOI_ = formatDate(new Date(this.dataConvert.DOI), 'yyyy-MM-dd', this.locale);
            // dateDOI_ = "";
        }

        if (this.dataConvert.DateATK != "" && this.dataConvert.DateATK != null) {
            dateDateATK_ = formatDate(new Date(this.dataConvert.DateATK), 'yyyy-MM-dd', this.locale);
            // dateDateATK_ = "";
        }
        if (this.dataConvert.HomeIsolate != "" && this.dataConvert.HomeIsolate != null) {
            dateHomeIsolate_ = formatDate(new Date(this.dataConvert.HomeIsolate), 'yyyy-MM-dd', this.locale);
            // dateHomeIsolate_ = "";
        }
        if (this.dataConvert.ReferHospitalDate != "" && this.dataConvert.ReferHospitalDate != null) {
            dateHospitalDate_ = formatDate(new Date(this.dataConvert.ReferHospitalDate), 'yyyy-MM-dd', this.locale);
            // dateHospitalDate_ = "";
        }
 
        if (this.dataConvert.ReferDate != "" && this.dataConvert.ReferDate != null) {
            
            dateDate_ = formatDate(new Date(this.dataConvert.ReferDate), 'yyyy-MM-dd', this.locale);
            // dateDate_ = "";
        }
        // console.log("this.dataConvert.RiskFactor ",this.dataConvert.RiskFactor);
        var RiskFactor_ = "";
        if(this.dataConvert.RiskFactor == ""){
            RiskFactor_ = ""
        }else{
            RiskFactor_ = this.dataConvert.RiskFactor.join(", ");
        }
        
        this.dataConvertSave = {
            ReferUserId: this.dataReferUserCovid19.dataUser.userId,
            ReferDoi: dateDOI_,
            ReferAtk: dateDateATK_,
            ReferHomeIsolate: dateHomeIsolate_,
            ReferRiskFactor: RiskFactor_,
            ReferVitalSign: vitalsignNew_,
            ReferToppings: this.dataConvert.SelectYourtoppings2,
            ReferHospital1: this.dataConvert.ReferHospital1,
            ReferHospitalAffiliated: this.dataConvert.ReferHospitalAffiliation,
            ReferRightTreatment: this.dataConvert.ReferRightToTreatment,
            ReferPhoneAdmin: this.dataConvert.ReferPhoneAdmin,

            ReferMedicineReceivedFavipiravir: this.dataConvert.MedicineReceivedFavipiravir,
            ReferMedicineReceivedDexamethasone: this.dataConvert.MedicineReceivedDexamethasone,
            ReferMedicineReceivedPrednisolone: datePrednisolone_,
            ReferMedicineReceivedOmeprazole: dateOmeprazole_,
            ReferMedicineReceivedOxygen: this.dataConvert.MedicineReceivedOxygen,
            ReferMedicineReceivedOthers: this.dataConvert.MedicineReceivedOthers,

            ReferMedicineThai: this.dataNameDrugTH,

            ReferRegularMedicationFromPatient: this.dataConvert.RegularMedication,
            ReferComment: this.dataConvert.RefernoteDoctor,
            ReferHospital2: this.dataConvert.ReferHospital2,
            ReferHospitalDate: dateHospitalDate_,
            ReferNameDoctor: this.dataConvert.ReferDoctorname,
            ReferDoctorId: this.dataConvert.ReferIdDoctor,
            ReferDate: dateDate_
        }
        // consoleee.log("aaaaa ", this.dataConvertSave);
        this.ServiceService.AddReferPatientCovid19_(this.dataConvertSave).then(resPrint => {
            // console.log("resPrint : ", resPrint);
            const dialogRef = this.dialog.open(DialogdialogSuccessReferCovid19, {
                width: '350px',
                disableClose: true,
                data: {
                    userID: this.dataReferUserCovid19.dataUser.userId,
                    dmID: this.dataReferUserCovid19.doctorMall.dmId,
                    refer: "refer"
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
                this.dialogRef.close();
            });

            // window.open("https://localhost:44316/api/CovidSymptomV2/ViewReferUsercovid19?userId="+this.dataReferUserCovid19.dataUser.userId);

        });
    }

}


