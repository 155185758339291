import { Component, OnInit, LOCALE_ID, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';

export interface DialogEditProvince {
    idAdmin: string;
}

@Component({
    selector: 'editAdmin-modal',
    templateUrl: './editAdmin-modal.html',
})
export class EditAdminDialog {

    editAdminForm!: FormGroup;
    dataAdmin: any;
    dataConvert!: any;
    dataConvertEditAdminProvince: any[] = [];
    hidePassword = true;
    hidePasswordConfirm = true;

    InvalidUsernameLength = false;
    InvalidUsername = false;
    InvalidConfirmPassword = false;
    InvalidOldPassword = false;
    InvalidNewAndOldPassword = false;
    constructor(
        public dialogRef: MatDialogRef<EditAdminDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditProvince,
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        private router: Router,
        private ngZone: NgZone,
        private ServiceService: ServiceService
    ) {
        // console.log("data************* ", data);

        this.editAdminForm = this.formBuilder.group({
            usernameEdit: '',
            passwordEdit: '',
            passwordNew: '',
            phoneEdit: ''
        })
        var dataAdmin: any;
        this.ServiceService.GetAdminProvince_(data.idAdmin).then(resDataAdmin => {
            dataAdmin = resDataAdmin;
            var parseJson = JSON.parse(dataAdmin);
            // console.log(parseJson);
            this.dataAdmin = parseJson[0];

            this.editAdminForm.setValue({
                usernameEdit: parseJson[0].admin_Username,
                passwordEdit: '',
                passwordNew: '',
                phoneEdit: parseJson[0].admin_Phone
            })

        })
    }

    onUpdateAdmin() {
        var idAdminProvince: any;

        this.dataConvert = {
            AdminID: this.dataAdmin.admin_Id,
            AdminUsername: this.editAdminForm.value.usernameEdit,
            AdminPassword: this.editAdminForm.value.passwordEdit,
            AdminPasswordNew: this.editAdminForm.value.passwordNew,
            AdminPhone: this.editAdminForm.value.phoneEdit
        };

        if(this.editAdminForm.value.usernameEdit.length > 2){
            this.InvalidUsername = false;
            // if (this.editAdminForm.value.passwordEdit == this.editAdminForm.value.passwordNew) {
                // this.InvalidConfirmPassword = false;
                this.InvalidNewAndOldPassword = false;
                this.InvalidOldPassword = false;

                this.ServiceService.EditAdmin_(this.dataConvert).then((dataidAdminProvince) => {
                    idAdminProvince = dataidAdminProvince;
                    // console.log("Data added successfully --> ", idAdminProvince);
                    // if(idAdminProvince != "Duplicate Username"){
                    //     this.dataConvertEditAdminProvince = [
                    //         {
                    //             admin_Id: idAdminProvince,
                    //             admin_Username: this.editAdminForm.value.usernameEdit,
                    //         }
                    //     ]
                    //     this.dialogRef.close(this.dataConvertEditAdminProvince);
                    // }else{
                    //     this.InvalidUsername = true;
                    // }

                    switch(idAdminProvince) { 
                        case "Duplicate Username": { 
                            this.InvalidUsername = true;
                           break; 
                        } 
                        case "New and old passwords don't match.": { 
                           this.InvalidNewAndOldPassword = true;
                           break; 
                        } 
                        case "The old password is invalid.": { 
                            this.InvalidOldPassword = true;
                           break; 
                        } 
                        case "Fail Process Update Admin": { 
                           //statements; 
                           break; 
                        } 
                        default: { 
                            this.dataConvertEditAdminProvince = [
                                {
                                    admin_Id: idAdminProvince,
                                    admin_Username: this.editAdminForm.value.usernameEdit,
                                }
                            ]
                            this.dialogRef.close(this.dataConvertEditAdminProvince);
                           break; 
                        } 
                     } 
                }, (err) => {
                    console.log(" : : ", err);
                })
            // } else {
            //     this.InvalidConfirmPassword = true;
            // }
        }else{
            this.InvalidUsernameLength = true;
        }

    }
}