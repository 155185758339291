import { Component, OnInit, LOCALE_ID, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';

export interface DialogEditDistrict {
    idAdminDistrict: string;
    usernameDistrict: string;
    passwordDistrict: string;
    phoneDistrict: string;
}

@Component({
    selector: 'editAdminDistrict-modal',
    templateUrl: './editAdminDistrict-modal.html',
})
export class DialogEditAdminDistrictDialog {

    editAdminDistrictForm!: FormGroup;

    dataConvert!: any;
    dataConvertEditAdminDistrict: any[] = [];

    InvalidUsernameLength = false;
    InvalidUsername = false;
    hidePassword = true;
    hideConfirmPassword = true;
    InvalidConfirmPassword = false;
    InvalidOldPassword = false;
    InvalidNewAndOldPassword = false;
    constructor(
        public dialogRef: MatDialogRef<DialogEditAdminDistrictDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditDistrict,
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        private router: Router,
        private ngZone: NgZone,
        private ServiceService: ServiceService
    ) {

        this.editAdminDistrictForm = this.formBuilder.group({
            usernameEditDistrict: '',
            passwordEditDistrict: '',
            passwordNew: '',
            phoneEditDistrict: ''
        })

        this.editAdminDistrictForm.setValue({
            usernameEditDistrict: this.data.usernameDistrict,
            passwordEditDistrict: "",
            passwordNew: "",
            phoneEditDistrict: this.data.phoneDistrict
        })
    }

    onUpdateAdminDistrict() {
        var idAdminEditDistrict:any;
        this.dataConvert = {
            AdminID: this.data.idAdminDistrict,
            AdminUsername: this.editAdminDistrictForm.value.usernameEditDistrict,
            AdminPassword: this.editAdminDistrictForm.value.passwordEditDistrict,
            AdminPasswordNew: this.editAdminDistrictForm.value.passwordNew,
            AdminPhone: this.editAdminDistrictForm.value.phoneEditDistrict
        };
        if(this.editAdminDistrictForm.value.usernameEditDistrict.length > 2){
            this.InvalidUsername = false;
            // if (this.editAdminDistrictForm.value.passwordEditDistrict == this.editAdminDistrictForm.value.confirmPasswordEditDistrict) {
            //     this.InvalidConfirmPassword = false;
                this.InvalidOldPassword = false;
                this.InvalidNewAndOldPassword = false;
                this.ServiceService.EditAdmin_(this.dataConvert).then((dataidAdminEditDistrict) => {
                    idAdminEditDistrict = dataidAdminEditDistrict;
                    // console.log("Data added successfully --> ", idAdminEditDistrict);
                    // if(idAdminEditDistrict != "Duplicate Username"){
                    //     this.dataConvertEditAdminDistrict = [
                    //         {
                    //             admin_Id: idAdminEditDistrict,
                    //             admin_Username: this.editAdminDistrictForm.value.usernameEditDistrict,
                    //             admin_Password: this.editAdminDistrictForm.value.passwordEditDistrict,
                    //             admin_Phone: this.editAdminDistrictForm.value.phoneEditDistrict
                    //         }
                    //     ]
                    //     this.dialogRef.close(this.dataConvertEditAdminDistrict);
                    // }else{
                    //     this.InvalidUsername = true;
                    // }

                    switch(idAdminEditDistrict) { 
                        case "Duplicate Username": { 
                            this.InvalidUsername = true;
                           break; 
                        } 
                        case "New and old passwords don't match.": { 
                           this.InvalidNewAndOldPassword = true;
                           break; 
                        } 
                        case "The old password is invalid.": { 
                            this.InvalidOldPassword = true;
                           break; 
                        } 
                        case "Fail Process Update Admin": { 
                           //statements; 
                           break; 
                        } 
                        default: { 
                            this.dataConvertEditAdminDistrict = [
                                {
                                    admin_Id: idAdminEditDistrict,
                                    admin_Username: this.editAdminDistrictForm.value.usernameEditDistrict,
                                    admin_Password: this.editAdminDistrictForm.value.passwordEditDistrict,
                                    admin_Phone: this.editAdminDistrictForm.value.phoneEditDistrict
                                }
                            ]
                            this.dialogRef.close(this.dataConvertEditAdminDistrict);
                           break; 
                        } 
                     } 

                }, (err) => {
                    console.log(" : : ", err);
                })
            // } else {
            //     this.InvalidConfirmPassword = true;
            // }
        }else{
            this.InvalidUsernameLength = true;
        }
        
    }
}