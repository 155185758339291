import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AlertsComponent } from './admin/pages/alerts/alerts.component';
import { UsersProfileComponent } from './admin/pages/users-profile/users-profile.component';
import { OperatorsListComponent } from './admin/pages/operators-list/operators-list.component';
import { OperatorsProfileComponent } from './admin/pages/operators-profile/operators-profile.component';
import { LoginComponent } from './admin/pages/login/login.component';
import { AdminDistrictComponent } from './admin/pages/admin-district/admin-district.component';
import { AdminSubDistrictComponent } from './admin/pages/admin-sub-district/admin-sub-district.component';
import { CaseProfileComponent } from './admin/pages/case-profile/case-profile.component';
import { UserCovid19ListComponent } from './admin/pages/user-covid19-list/user-covid19-list.component';
import { DoctorNotificationComponent } from './admin/pages/covid/doctor-notification/doctor-notification.component';
import { DoctorMallComponent } from './admin/pages/covid/doctor-mall/doctor-mall.component';
import { DoctorPatientComponent } from './admin/pages/covid/doctor-patient/doctor-patient.component';
import { DoctorDashboardComponent } from './admin/pages/covid/doctor-dashboard/doctor-dashboard.component';
import { UserCovid19Component } from './admin/pages/covid/user-covid19/user-covid19.component';
import { CovidAdminAlertComponent } from './admin/pages/covid/covid-admin-alert/covid-admin-alert.component';
import { CovidDispenseMedicineListComponent } from './admin/pages/covid/covid-dispense-medicine-list/covid-dispense-medicine-list.component';
import { TreatedPatientsComponent } from './admin/pages/covid/treated-patients/treated-patients.component';

import { AuthGuardService } from './core/services/auth-guard.service';
import { CaseProfileAppComponent } from './admin/pages/case-profile/case-profile-app/case-profile-app.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: "",
    canActivate: [AuthGuardService],
    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "",
    canActivate: [AuthGuardService],
    loadChildren: () => import("./operator/operator.module").then((m) => m.OperatorModule),
  },
  {
    path: "",
    canActivate: [AuthGuardService],
    loadChildren: () => import("./admin-filter/admin-filter.module").then((m) => m.AdminFilterModule),
  },
  { path: 'adminDistrict-list/:nameProvince/:provinceId/:idAdminProvince', component: AdminDistrictComponent, canActivate: [AuthGuardService] },
  { path: 'adminSubistrict-list/:nameProvince/:provinceId/:nameDistrict/:idDistrict/:parrent', component: AdminSubDistrictComponent, canActivate: [AuthGuardService] },
  { path: 'operator-list/:nameProvince/:nameDistrict/:nameSubDistrict/:idSubDistrict/:parrent', component: OperatorsListComponent, canActivate: [AuthGuardService] },
  { path: 'operator/:nameProvince/:adminRole/:adminId/:parrent', component: OperatorsListComponent, canActivate: [AuthGuardService] },
  { path: 'detailOperator/:idOperatorAdmin', component: OperatorsProfileComponent, canActivate: [AuthGuardService] },
  { path: 'profile/:typeDevice/:idUser', component: UsersProfileComponent, canActivate: [AuthGuardService] },
  { path: 'alerts/:idOperatorAdmin', component: AlertsComponent, canActivate: [AuthGuardService] },
  { path: 'caseProfile/:idOperatorAdmin/:idCase', component: CaseProfileComponent, canActivate: [AuthGuardService] },
  { path: 'caseOP/:idOperatorAdmin/:idCase/:jwtToken', component: CaseProfileAppComponent },
  { path: 'caseProfileNew/:idOperatorAdmin/:idCase', component: CaseProfileComponent, canActivate: [AuthGuardService] },
  { path: 'userCovid19List', component: UserCovid19ListComponent, canActivate: [AuthGuardService] },
  { path: 'doctorNotificationCovid19List', component: DoctorNotificationComponent, canActivate: [AuthGuardService] },
  { path: 'doctorMallCovid19List', component: DoctorMallComponent, canActivate: [AuthGuardService] },
  { path: 'doctorPatientCovid19List', component: DoctorPatientComponent, canActivate: [AuthGuardService] },
  { path: 'doctorDashboard', component: DoctorDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'userCovid19/:idUser', component: UserCovid19Component, canActivate: [AuthGuardService] },
  { path: 'covidAdminAlert', component: CovidAdminAlertComponent, canActivate: [AuthGuardService] },
  { path: 'covidDispenseMedicineList/:idUser', component: CovidDispenseMedicineListComponent, canActivate: [AuthGuardService] },
  { path: 'treatedPatientsList', component: TreatedPatientsComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent},
  { path: '**',pathMatch: 'full', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
