import { Component, OnInit, NgZone } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  Event as NavigationEvent,
} from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { ChartDataSets, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DialogSuccessJob } from '../../pages/case-profile/models/succcess-job/success-job.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogDetailSos } from '../../../shared/modal/detailCovid/detail-sos.component';
import { MapsAPILoader } from '@agm/core';
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-case-profile',
  templateUrl: './case-profile.component.html',
  styleUrls: ['./case-profile.component.scss'],
})
export class CaseProfileComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  dataDrugAllergyUser: any;
  checkDrugAllergyUser: boolean = false;
  idCase: any;
  idOperator: any;
  theUsername: any;
  commentForm: FormGroup;
  dataConvert: any;
  dataUpdateStatus: any;
  dataComments: Array<any> = [];
  dataNo!: string;
  logSos: any = [];
  dataSOS: any = [];
  buttonDisabledEndJob!: boolean;
  checkLog!: boolean;
  dataDetailAdmin: any;
  pressure: any;
  spo2: any;
  getSymptomsId: string = '';
  reqData: any = [];
  allowPdf: boolean = false;

  commentJob: string = '';
  field1: string = '';
  field2: string = '';
  field3: string = '';
  field4: string = '';
  field5: string = '';

  private geoCoder!: google.maps.Geocoder;
  private socket: any;

  dataConnectWatch: any = [];

  constructor(
    private mapsAPILoader: MapsAPILoader,
    // private socket_: Socket,
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService,
    private router: Router,
    public formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {

        if (
          this.router.url.startsWith('/caseOP') ||
          this.router.url.startsWith('/caseProfileNew')
        ) {
          // this.showHead= false;
        } else {
          // Connect Socket with server URL
          this.socket = io(GlobalConstants.socket, {
            autoConnect: true,
            reconnection: true, // Enable reconnection
            reconnectionAttempts: Infinity, // Limit the number of reconnection attempts
            reconnectionDelay: 1000,
            transports: ['websocket', 'polling', 'flashsocket'],
          });
        }


    // this.socket = io('http://192.168.1.43:2022/',{  transports: ['websocket', 'polling', 'flashsocket'] });
    // this.socket = io('http://183.88.230.105:2022/',{  transports: ['websocket', 'polling', 'flashsocket'] });
    this.idCase = this.activatedRoute.snapshot.paramMap.get('idCase');
    this.idOperator =
      this.activatedRoute.snapshot.paramMap.get('idOperatorAdmin');
    this.commentForm = this.formBuilder.group({
      commentJob: [''],
      field1: [''],
      field2: [''],
      field3: [''],
      field4: [''],
      field5: [''],
    });
    var username: any = localStorage.getItem('currentUsername');
    this.theUsername = btoa(username);
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.buttonDisabledEndJob = true;
    this.GetJobTaskOperators(this.idCase);
    this.loadDataSOS(this.idOperator, this.idCase);
    this.loadDataOperators(this.idOperator);
    this.getRequestPdf();
  }

  lat!: number;
  long!: number;
  zoom = 13;
  markers: any = [];
  dataChartHeartRate: any[] = [];
  lineChartData: ChartDataSets[] = [
    { data: this.dataChartHeartRate, label: 'Heart Rate' },
  ];

  lineChartLabels: Label[] = [];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#4B94C0',
      backgroundColor: '#4B94C0',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType: ChartType = 'line';

  GetJobTaskOperators(idC: string) {
    this.ServiceService.GetJobTaskOperator_(idC).then((resJobTaskOperator) => {
      if (resJobTaskOperator.length > 0) {
        this.buttonDisabledEndJob = false;
        this.dataComments = resJobTaskOperator;
      } else {
        this.buttonDisabledEndJob = true;
      }
    });
  }

  addCommentJob(): any {
    this.dataConvert = {
      OjobId: this.idCase,
      OjobTaskDetail: this.commentForm.value.commentJob,
      ojobTaskField1: this.commentForm.value.field1,
      ojobTaskField2: this.commentForm.value.field2,
      ojobTaskField3: this.commentForm.value.field3,
      ojobTaskField4: this.commentForm.value.field4,
      ojobTaskField5: this.commentForm.value.field5,
    };

    var dateTime: any;
    // if (this.commentForm.value.commentJob != '') {
    // }
    this.ServiceService.AddCommentJobOperator_(this.dataConvert).then(
      (resTime) => {
        dateTime = resTime;
        this.commentForm.setValue({
          commentJob: '',
          field1: '',
          field2: '',
          field3: '',
          field4: '',
          field5: '',
        });
        // console.log("dateTime--------------> ", dateTime);
        var data = {
          OjobId: this.dataConvert.OjobId,
          ojobTaskDetail: this.dataConvert.OjobTaskDetail,
          ojobTaskDate: dateTime,
          ojobTaskField1: this.dataConvert.ojobTaskField1,
          ojobTaskField2: this.dataConvert.ojobTaskField2,
          ojobTaskField3: this.dataConvert.ojobTaskField3,
          ojobTaskField4: this.dataConvert.ojobTaskField4,
          ojobTaskField5: this.dataConvert.ojobTaskField5,
        };

        this.dataComments.push(data);
        this.buttonDisabledEndJob = false;
      }
    );
  }

  updateStatus() {
    var resDataOperator: any;
    this.ServiceService.GetIdOperatorByIdOperatorAdmin_(this.idOperator).then(
      (dataDataOperator) => {
        resDataOperator = dataDataOperator;
        // console.log("resDataOperator ***** ", resDataOperator);

        this.dataUpdateStatus = {
          OjobId: this.idCase,
          OperatorId: resDataOperator[0].operatorId,
          ObjectId: '',
          OjobDate: '',
          OjobStatus: 'end',
          OjobHeartRate: '',
          OjobTemp: '',
          OjobBattery: '',
        };
        console.log(this.dataUpdateStatus);
        this.ServiceService.updateStatusSosAlert_(this.dataUpdateStatus).then(
          (res) => {

                if (
                  this.router.url.startsWith('/caseOP') ||
                  this.router.url.startsWith('/caseProfileNew')
                ) {
                  // this.showHead= false;
                  this.ServiceService.updateCaseMobile_(this.dataUpdateStatus).then(
                    (res) => {
                  const dialogRef = this.dialog.open(DialogSuccessJob);
                  dialogRef.afterClosed().subscribe((result) => {

                  });
                });
                } else {
                  this.socket.emit('dataMessageJobWork', {
                    id: this.idCase,
                    status: 'end',
                    portal: localStorage.getItem('showAdmin'),
                  });
                  this.socket.emit('dataMessageJob', 'sos');
                  const dialogRef = this.dialog.open(DialogSuccessJob);
                  dialogRef.afterClosed().subscribe((result) => {
                    this.router.navigate([`/alerts/${this.idOperator}`], {
                      replaceUrl: true,
                    });
                  });

                }


          }
        );
      }
    );
  }

  forceEndJob() {
    let testData: any = {
      OjobId: this.idCase,
      OjobTaskDetail: "test",
      ojobTaskField1: "test",
      ojobTaskField2: "test",
      ojobTaskField3: "test",
      ojobTaskField4: "ไม่เรียกรถฉุกเฉิน",
      ojobTaskField5: "test"
    };
    this.ServiceService.AddCommentJobOperator_(testData);
    this.updateStatus();
  }

  loadDataSOS(idOperator: string, idCase: string) {
    this.getLogSos(idOperator, idCase).then(async (result) => {
      // console.log("result--------- ",result[0]);

      this.dataSOS = result[0];
      // console.log('---+ ', this.dataSOS);

      if (result[0].dataDevice != undefined) {
        this.getPressure(result[0].dataDevice.deviceSerial);
        this.getSpo2(result[0].dataDevice.deviceSerial);
      }
      await this.ServiceService.GetDeviceWatch_(
        this.dataSOS.dataDevice
          ? this.dataSOS.dataDevice.deviceId
          : this.dataSOS.dataUserPhone.userId
      ).then(
        (res) => {
          if (res.length) {
            this.dataSOS = { ...this.dataSOS, emergency: res[0] };
          }
        },
        (err) => {
          console.log('err -> ', err);
        }
      );
      // console.log("result1----> ", this.dataSOS);
      this.lat = Number(result[0].logSos.lat);
      this.long = Number(result[0].logSos.lng);
      this.markers.push({
        lat: result[0].logSos.lat,
        lng: result[0].logSos.lng,
        label: this.dataSOS.logSos.sos_type,
        title1: '' + 1,
      });
      if (result[0].dataUserPhone != undefined) {
        this.loadDrugAllergyUser(result[0].dataUserPhone.userId);
      }
      this.checkApproved();
    });
  }
  getPressure(idC: any) {
    this.ServiceService.GetPressureList_(idC).then((value) => {
      this.pressure = value;
    });
  }
  getSpo2(idC: any) {
    this.ServiceService.GetSpo2List_(idC).then((value) => {
      var result: any = value;
      this.spo2 = result.spo2_val;
    });
  }

  loadDataOperators(idOperatorAdmin: string) {
    var resAdminGetOperator: any;
    this.ServiceService.GetAdminGetOperator_(idOperatorAdmin).then(
      (dataAdminGetOperator) => {
        resAdminGetOperator = dataAdminGetOperator;
        // console.log("toda !!!!!! res >> ", resAdminGetOperator);
        this.dataDetailAdmin = resAdminGetOperator[0];
      }
    );
  }

  loadPhoneConnectWatch(idUser: string) {
    this.ServiceService.GetPhoneConnectWatch_(idUser).then(
      async (dataWatchs) => {
        // console.log("dataWatchs ",dataWatchs);
        this.dataConnectWatch = dataWatchs;
        for (let i = 0; i < this.dataConnectWatch.length; i++) {
          await this.ServiceService.GetDeviceWatch_(
            this.dataConnectWatch[i].deviceId
          ).then(
            (res) => {
              if (res.length) {
                this.dataConnectWatch[i] = {
                  ...this.dataConnectWatch[i],
                  emergency: res[0],
                };
              }
            },
            (err) => {
              console.log('err -> ', err);
            }
          );
        }
      }
    );
  }

  openPersonalHealth(deviceImei: string) {
    window.open(
      `https://personal.medilifewellness.com/auth?deviceImei=${deviceImei}&sysUserId=${this.theUsername}&chart=true`,
      '_blank'
    );
  }

  getLogSos(idOperator: string, idCase: string) {
    var resIdJob: any;
    var resLogSos: any;
    var resDevices: any;
    var resUserPhone: any;
    var resHeartRateList: any;
    var resLocation: string = '-';
    return new Promise<any>(async (resolve, rejects) => {
      // console.log("idCase : ", idCase);

      await this.ServiceService.GetJobOperatorByJobId_(idCase).then(
        async (dataIdJob) => {
          resIdJob = dataIdJob;
          this.getSymptomsId =
            resIdJob[0].ojobImei.split(':').length <= 1 &&
            resIdJob[0].ojobImei.length <= 13
              ? resIdJob[0].ojobImei
              : '';
          // console.log("resIdJob[0].ojobImei ",this.getSymptomsId);

          // console.log("resIdJob : ", resIdJob[0]);
          var wordsjectId = resIdJob[0].objectId.split(',');
          // console.log("wordsjectId[0] ",wordsjectId[0]);

          await this.ServiceService.GetLogSos_(wordsjectId[0]).then(
            async (dataLogSos) => {
              resLogSos = dataLogSos;
              // console.log("This", resLogSos);

              await this.getAddress(
                Number(resLogSos[0].lat),
                Number(resLogSos[0].lng)
              ).then(
                async (dataLocation) => {
                  resLocation = dataLocation;
                  this.updateAddressSOS(resIdJob[0].ojobId, resLocation).then(
                    (resdataUpdate) => {
                      // console.log("resdataUpdate > ",resdataUpdate);
                    }
                  );
                },
                (error) => {
                  console.log('error ', error);
                }
              );

              await this.ServiceService.GetDeviceBySerial_(
                resLogSos[0].watch_imeicode
              ).then(async (dataDevices) => {
                resDevices = dataDevices;
                // console.log('resDevices : ', resDevices);

                if (resDevices[0] == undefined) {
                  await this.ServiceService.GetUserPhone_(
                    resLogSos[0].watch_imeicode
                  ).then((dataUserPhone) => {
                    resUserPhone = dataUserPhone;
                    // console.log("resDevicesPhone>>> ",resUserPhone[0]);

                    this.logSos.push({
                      logSos: resLogSos[0],
                      dataUserPhone: resUserPhone[0],
                      dataJob: resIdJob[0],
                      location: resLocation,
                    });
                    this.checkLog = false;
                    this.loadPhoneConnectWatch(resUserPhone[0].userId);
                    resolve(this.logSos);
                  });
                } else {
                  await this.ServiceService.GetLogHeartRateList_(
                    resLogSos[0].watch_imeicode
                  ).then((dataHeartRateList) => {
                    resHeartRateList = dataHeartRateList;
                    // console.log('---- ', resHeartRateList);

                    // console.log("resHeartRateList : ", resHeartRateList[0]);
                    if (resHeartRateList != undefined) {
                      //table
                      var har: any = [];
                      if (resHeartRateList.length <= 2) {
                        for (let j = 0; j < resHeartRateList.length; j++) {
                          har = har.concat(resHeartRateList[j].heart_rate);
                        }
                      } else {
                        for (let j = 0; j < 2; j++) {
                          har = har.concat(resHeartRateList[j].heart_rate);
                        }
                      }
                      // console.log("har : ", har.length);
                      har.sort(function (a: any, b: any) {
                        return +new Date(a.time) - +new Date(b.time);
                      });
                      //chart
                      if (resHeartRateList.length < 10) {
                        for (let j = 0; j < har.length; j++) {
                          this.dataChartHeartRate.push(har[j].value);

                          this.lineChartLabels.push(
                            har[j].time.split('T')[1].substring(0, 5)
                          );
                        }
                      } else {
                        for (let j = 0; j < har.length; j++) {
                          this.dataChartHeartRate.push(har[j].value);

                          this.lineChartLabels.push(
                            har[j].time.split('T')[1].substring(0, 5)
                          );
                        }
                      }
                      this.ServiceService.GetDeviceUserPhoneAccountByDeviceUserId_(
                        resDevices[0].deviceUserId
                      ).then((resAccountDevice) => {
                        // console.log('resAccountDevice ', resAccountDevice);
                        if (resAccountDevice != null) {
                          this.logSos.push({
                            accountPhoneByDevice: resAccountDevice,
                            logSos: resLogSos[0],
                            dataDevice: resDevices[0],
                            dataJob: resIdJob[0],
                            location: resLocation,
                          });
                        } else {
                          this.logSos.push({
                            accountPhoneByDevice: null,
                            logSos: resLogSos[0],
                            dataDevice: resDevices[0],
                            dataJob: resIdJob[0],
                            location: resLocation,
                          });
                        }
                        this.checkLog = true;
                        resolve(this.logSos);
                      });
                    } else {
                      this.logSos.push({
                        logSos: resLogSos[0],
                        dataUserPhone: resHeartRateList[0],
                        dataJob: resIdJob[0],
                        location: resLocation,
                      });
                      this.checkLog = false;
                      resolve(this.logSos);
                    }
                  });
                }
              });
            }
          );
        }
      );
    });
  }

  updateAddressSOS(idJob: any, dataLocation: any) {
    return new Promise<any>(async (resolve, rejects) => {
      // console.log("SOS : ", dataSOS);//OJob_ID
      // console.log("resSensor *** ", dataSensor);
      await this.ServiceService.UpdateAddressJob(
        Number(idJob),
        dataLocation
      ).then(
        (res) => {
          // console.log(res);
          resolve(res);
        },
        (error) => {
          console.log(error.status + ' ' + error.statusText);
        }
      );
    });
  }

  getAddress(latitude: number, longitude: number) {
    return new Promise<any>((resolve, rejects) => {
      this.geoCoder.geocode(
        { location: { lat: latitude, lng: longitude } },
        (results: any, status: any) => {
          // console.log("results ---> ",results);
          // console.log("results ---> ",results[3].formatted_address);
          if (status === 'OK') {
            // console.log("-------------results --------------> ", status);
            resolve(results[1].formatted_address);
          } else {
            // console.log("ell-------------results --------------> ", status);
            rejects(status);
          }
        }
      );
    });
  }

  openDetailSosLogBody(idUser: string): void {
    const dialogRef = this.dialog.open(DialogDetailSos, {
      width: '100%',
      data: { idUser: idUser },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  async loadDrugAllergyUser(idUser: string) {
    await this.ServiceService.GetPersonalHealth_(idUser).then(
      (res) => {
        // console.log("this.dataDrugAllergyUser : ",this.dataDrugAllergyUser);

        if (
          this.dataDrugAllergyUser != undefined &&
          this.dataDrugAllergyUser.length > 0
        ) {
          this.checkDrugAllergyUser = true;
        }
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  openRequestPdf() {
    Swal.fire({
      title: '<strong>Request PDF?</strong>',
      text: 'Are you sure for request PDF?',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#7c8184',
      confirmButtonText: 'Send a Request',
      cancelButtonText: 'Cancel',
      allowOutsideClick: true,
      width: 500,
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.ServiceService.AddRequestPDF_(this.dataSOS.dataJob.ojobId).then(
          (res) => {
            if (res.status) {
              this.reqData = res.data.dataApprovedPdf;
              this.socket.emit('requestPDF', {
                data: res.data,
                status: 'request',
              });
            }
          },
          (error) => {
            console.log(error.status + ' ' + error.statusText);
          }
        );
        this.requestingWindow();
      }
    });
  }

  cancelRequest() {
    var updateReq: any = {
      approvedId: this.reqData.approvedId,
      adminId: '',
      jobId: this.reqData.ojobId,
      approvedStatus: 'cancleByOpertor',
    };
    this.ServiceService.UpdateRequestPDF_(updateReq).then(
      (res) => {
        if (res.status) {
          this.socket.emit('requestPDF', {
            status: 'cancleByOpertor',
          });
          Swal.fire({
            title: 'Canceled!',
            icon: 'success',
            html: 'A request PDF was canceled.',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: true,
            allowOutsideClick: true,
            confirmButtonText: 'OK',
          });
        }
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  getRequestPdf() {
    this.socket.on('dataRequestPDF', (data: any) => {
      if (data.data.status == 'confirm') {
        this.allowPdf = true;
        Swal.fire({
          title: 'Accepted!',
          icon: 'success',
          html: "Your request has been accepted by admin.<br>You can download by click 'Export PDF' button.",
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: true,
          allowOutsideClick: true,
          confirmButtonText: 'OK',
        });
      } else if (data.data.status == 'cancleByAdmin') {
        this.allowPdf = false;
        Swal.fire({
          title: 'Declined!',
          icon: 'error',
          html: 'Your request has been declined by admin.',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: true,
          allowOutsideClick: true,
          confirmButtonText: 'OK',
        });
      } else if (data.data.status == 'cancleByOpertor') {
        this.allowPdf = false;
        Swal.fire({
          title: 'Canceled!',
          icon: 'success',
          html: 'You canceled a request.',
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: true,
          allowOutsideClick: true,
          confirmButtonText: 'OK',
        });
      }
    });
  }

  requestingWindow() {
    Swal.fire({
      title: 'Requesting...',
      html: "<div class='spinner-border text-info'></div><br><br>Please wait an accept from admin.",
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: 'Cancel a Request',
      confirmButtonColor: '#d9534f',
      allowOutsideClick: false,
      width: 500,
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.cancelRequest();
      }
    });
  }

  checkApproved() {
    this.ServiceService.CheckApprovedPdfByOpertor_(
      this.dataSOS.dataJob.ojobId
    ).then(
      (res) => {
        if (res.status) {
          if (res.data.approvedStatus == 'request') {
            this.reqData = res.data;
            this.requestingWindow();
          }
        }
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  oneTimePdf() {
    setTimeout(() => {
      this.allowPdf = false;
    }, 2500);
  }
}
