import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../../../core/services/service.service';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'log-sos.component',
  templateUrl: './log-sos.component.html',
})
export class DialogLogSosHistory implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  latitude!: number;
  longitude!: number;
  zoom!: number;
  address!: string;
  dataPeopleSeeHelps: any;
  dataSos_: any;
  logSos: any;
  hideContentUserInfomation: boolean = false;
  private geoCoder!: google.maps.Geocoder;

  constructor(
    private router: Router,
    private ServiceService: ServiceService,
    private mapsAPILoader: MapsAPILoader,
    public dialogRef: MatDialogRef<DialogLogSosHistory>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // console.log("data ",data);

      this.dataSos_ =  data.dataSos;
    }

    ngOnInit() {
      this.loadLogsos(this.dataSos_);
      this.GetGetUserInfomation(this.dataSos_);
  }

  loadLogsos(dataSos: any) {
    this.getDataSos(dataSos).then(data => {
      // console.log("data ", data);
      this.logSos = data;
      this.latitude = Number(data.dataSOS[0].lat);
      this.longitude = Number(data.dataSOS[0].lng);
      this.zoom = 15;
      // this.mapsAPILoader.load().then(() => {
      //   this.geoCoder = new google.maps.Geocoder;
      //   this.getAddress(data.dataSOS[0].lat, data.dataSOS[0].lng);
      // });
    });
  }

  getDataSos(dataSos: any) {
    return new Promise<any>(async (resolve, rejects) => {

      var objectID = dataSos.objectId.split(',');

      let logsos = this.ServiceService.GetLogSos_(objectID[0]).then();
      let loadOperator = this.ServiceService.GetIdOperatorAdminByIdOperator_(dataSos.operatorId).then();
      let loadJobTaskOperator = this.ServiceService.GetJobTaskOperator_(dataSos.ojobId).then();

      Promise.all([logsos, loadOperator, loadJobTaskOperator]).then(results => {

        resolve({ "dataJob": dataSos, "dataSOS": results[0], "dataOperator": results[1], "dataJobTaskOperator": results[2], "sosCount": objectID.length });

      });
    });
  }

  async GetGetUserInfomation(data: any) {
    // console.log("data ----> ",data);
    var objectID = data.objectId.split(',');
    var dataLogSos: any;
    await this.ServiceService.GetLogSos_(objectID[0]).then(async resLogSos => {
        dataLogSos = resLogSos;
        var dataUserID = {
            userID: dataLogSos[0].user_accept
        }
        this.ServiceService.GetGetUserInfomation_(dataUserID).then(resUserInfomation => {

            // console.log(">>>>>>>>>> ",resUserInfomation);
            this.dataPeopleSeeHelps = resUserInfomation;
            if(this.dataPeopleSeeHelps.length > 0){
                this.hideContentUserInfomation = true;
            }
        });
    });
}

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ', status);
      }

    });
  }

  onclickToProfileOperator(idAdminOperator:string){
    this.router.navigate([`detailOperator/${idAdminOperator}`]);
    this.dialogRef.close();
  }

}
