<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 style="text-align: center;">Summary-{{nameOperator}}</h1>
                <hr>
            </div>
        </div>
    </div>
</section>

<mat-spinner *ngIf="loadDataPage == false" style="margin-left: auto;margin-right: auto;margin-top:280px"></mat-spinner>

<section class="container-fluid" *ngIf="loadDataPage == true">

        <div class="row">
            <div class="col-xl-2 col-lg-3 col-sm-6">
                <mat-card class="small-box bg-info mat-elevation-z8 pulse" style="cursor: pointer;padding-bottom: 0px;" routerLink="/doctorPatientCovid19List"> 
                    <div class="inner" style="padding-left: 0px;padding-right: 0px;">
                        <h1 style="color: aliceblue;font-size: 50px;">{{userGreen + userRed + userYellow}}</h1>

                        <p class="f-box-menu">Total User</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                        <!-- <i class="ion ion-bag"></i> -->
                    </div>
                    <!-- //<a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                </mat-card>
            </div>
            <div class="col-xl-2 col-lg-3 col-sm-6">
                <mat-card class="small-box bg-success mat-elevation-z8 pulse" style="cursor: pointer;max-height: 132px;padding-bottom: 0px;" routerLink="/doctorPatientCovid19List">
                    <div class="inner" style="padding-left: 0px;padding-right: 0px;">
                        <h1 style="color: aliceblue;font-size: 50px;">{{userGreen}}</h1>

                        <p class="f-box-menu">User Green</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-user"></i>
                    </div>
                    <!-- //<a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                </mat-card>
            </div>
            <div class="col-xl-2 col-lg-3 col-sm-6">
                <mat-card class="small-box bg-warning mat-elevation-z8 pulse" style="cursor: pointer;max-height: 132px;padding-bottom: 0px;" routerLink="/doctorPatientCovid19List">
                    <div class="inner" style="padding-left: 0px;padding-right: 0px;">
                        <h1 style="color: aliceblue;font-size: 50px;">{{userYellow}}</h1>
                        
                        <p class="f-box-menu" style="color: aliceblue;">User Yellow</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-user"></i>
                    </div>
                    <!-- //<a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                </mat-card>
            </div>
            <div class="col-xl-2 col-lg-3 col-sm-6">
                <mat-card class="small-box bg-danger mat-elevation-z8 pulse" style="cursor: pointer;max-height: 132px;padding-bottom: 0px;" routerLink="/doctorPatientCovid19List">
                    <div class="inner" style="padding-left: 0px;padding-right: 0px;">
                        <h1 style="color: aliceblue;font-size: 50px;">{{userRed}}</h1>

                        <p class="f-box-menu">User Red</p>
                    </div>
                    <div class="icon">
                        <!-- <i class="ion ion-pie-graph"></i> -->
                        <i class="fas fa-user"></i>
                    </div>
                    <!-- //<a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                </mat-card>
            </div>
            <div class="col-xl-2 col-lg-6 col-sm-6">
                <mat-card class="small-box bg-danger mat-elevation-z8 pulse" style="cursor: pointer;max-height: 132px;padding-bottom: 0px;" routerLink="/covidAdminAlert">
                    <div class="inner" style="padding-left: 0px;padding-right: 0px;">
                        <h1 style="color: aliceblue;font-size: 50px;">{{resultDataLogCodiv19.length}}</h1>

                        <p class="f-box-menu" style="color: aliceblue;">Alert Pending</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-user-md"></i>
                    </div>
                    <!-- //<a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                </mat-card>
            </div>
            <div class="col-xl-2 col-lg-6 col-sm-6">
                <mat-card class="small-box bg-danger mat-elevation-z8 pulse" style="cursor: pointer;max-height: 132px;padding-bottom: 0px;" routerLink="/doctorNotificationCovid19List">
                    <div class="inner" style="padding-left: 0px;padding-right: 0px;">
                        <!-- <h3>53<sup style="font-size: 20px">%</sup></h3> -->
                        <h1 style="color: aliceblue;font-size: 50px;">{{resultDataLogAlertUserCodiv19.length}}</h1>

                        <p class="f-box-menu" style="color: aliceblue; ">Doc Alert</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-user-nurse"></i>
                    </div>
                    <!-- //<a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                </mat-card>
            </div>
        </div>

</section>

<section class="content" *ngIf="loadDataPage == true">
    <div class="container-fluid">
        <div class="row">

            <div class="mb-3 col-xl-5 col-lg-6">
                <mat-card class="mat-elevation-z8">
                    <div class="card-header" style="padding: 0px;">
                        <h3 class="card-title">People by age</h3>
                    </div>
                    <div class="chartjs-size-monitor">
                        <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                        </div>
                        <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                        </div>
                    </div>
                    <div class="chart-wrapper">
                        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                            [chartType]="barChartType" [colors]="barChartColors">
                        </canvas>
                    </div>
                </mat-card>
            </div>

            <div class="mb-3 col-xl-4 col-lg-6">
                <mat-card class="mat-elevation-z8">
                    <div class="card-header" style="padding: 0px;">
                        <h3 class="card-title">Total Watch</h3>
                    </div>
                    <div class="chartjs-size-monitor">
                        <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                        </div>
                        <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                        </div>
                    </div>
                    <div class="chart-wrapper">
                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                            [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend"
                            [colors]="pieChartColors">
                        </canvas>
                    </div>
                    <div style="padding-top: 6px;">
                        <p style="color: #6c757d;padding: 0px;margin: 0px;">Users-Green : <b>{{userGreen}} people</b></p>
                        <p style="color: #6c757d;padding: 0px;margin: 0px;">Users-Yellow : <b>{{userYellow}} people</b></p>
                        <p style="color: #6c757d;padding: 0px;margin: 0px;">Users-Red : <b>{{userRed}} people</b></p>
                    </div>
                </mat-card>
            </div>

            <div class="mb-3 col-xl-3 col-lg-12">
                <mat-card class="mat-elevation-z8">
                    <div class="card-header" style="padding: 0px;">
                        <h3 class="card-title">Total customers</h3>
                    </div>
                    <div class="chartjs-size-monitor">
                        <div class="chartjs-size-monitor-expand">
                            <div class=""></div>
                        </div>
                        <div class="chartjs-size-monitor-shrink">
                            <div class=""></div>
                        </div>
                    </div>
                    <div class="chart-wrapper">
                        <canvas baseChart [data]="pieChartDataSex" [labels]="pieChartLabelsSex" [chartType]="pieChartTypeSex"
                            [options]="pieChartOptionsSex" [plugins]="pieChartPluginsSex" [legend]="pieChartLegendSex"
                            [colors]="pieChartColorsSex">
                        </canvas>
                    </div>
                    <div class="row mt-1" style="display:flex;justify-content: space-evenly;">
                        <p style="color: #6c757d;padding: 0px;margin: 0px;">Male : <b>{{sexMale}} people</b></p>
                        <p style="color: #6c757d;padding: 0px;margin: 0px;">FeMale : <b>{{sexFeMale}} people</b></p>
                    </div>
                </mat-card>
                <mat-card class="mat-elevation-z8 mt-2">
                    <div class="card-header" style="padding: 0px;">
                        <h3 style="padding-bottom: 0px;margin-bottom:2px">Top age</h3>
                    </div>
                    <div class="card-body" style="padding: 0px;">
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="text-success " style="font-size: 24px;margin-bottom: 0px;margin-top: 16px;">
                                <span class="text-muted mr-2"><b>{{topAge[0]}}</b></span>
                                <span class="right badge " style="color: #6c757d;"> {{maxValue}} people</span>
                            </p>
                        </div>
                    </div>
                </mat-card>
            </div>

        </div>
    </div>
</section>