<div class="container-fluid mt-3">
  <div class="d-flex justify-content-between">
    <h1 class="mt-2">Respiratory Rate</h1>
    <mat-form-field appearance="outline" style="width: 500px;">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker_start_end">
        <input matStartDate [(ngModel)]="rrStart" placeholder="Start date">
        <input matEndDate [(ngModel)]="rrEnd" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
      <mat-date-range-picker #picker_start_end>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply (click)="filterDate(rrEnd)">Apply</button>
        </mat-datepicker-actions>
      </mat-date-range-picker>
    </mat-form-field>
  </div>
  <hr class="mt-0 mb-4">
  <ng-container *ngIf="this.rrList.length">
    <div #rrChart class="w-100" style="height: 650px;"></div>
    <div class="row mt-4 mb-4">
      <div class="col-4">
        <mat-card class="p-4">
          <h2>Average (เฉลี่ย)</h2>
          <h1 class="txtindigo">RR : {{rrAvg | number: '1.0-0'}}</h1>
        </mat-card>
      </div>
      <div class="col-4">
        <mat-card class="p-4">
          <h2>Max (มากสุด)</h2>
          <h1 class="txtindigo">RR : {{rrMax | number}}</h1>
        </mat-card>
      </div>
      <div class="col-4">
        <mat-card class="p-4">
          <h2>Min (น้อยสุด)</h2>
          <h1 class="txtindigo">RR : {{rrMin | number}}</h1>
        </mat-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!this.rrList.length">
    <h2 class="text-center mt-5 mb-5">*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2>
  </ng-container>
</div>
