import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'globalval.component',
  templateUrl: './globalval.component.html',
})
export class GlobalvalDialog implements OnInit {
  typeOfGlobalval:any;

  ipServer: string = "https://api.medilifewellness.com:2443";

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public dialogRef: MatDialogRef<GlobalvalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
       console.log("data ",data);

      this.typeOfGlobalval =  data.typeGlobal;
    }

    ngOnInit() {
        this.loadGobalListData();
  }

  g_name: any;
  g_minvalue: any = 0;
  g_maxvalue: any = 0;
  g_diffvalue: any = 0;

  loadGobalValFormAPI() {
      console.log("loadGobalValFormAPI",this.typeOfGlobalval);

    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getEditGobalValue?gobaltext=${this.typeOfGlobalval}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadGobalListData() {
    await this.loadGobalValFormAPI().then(
      (res: any) => {
          console.log("loadGobalListData",res);

        this.g_name = res['swgvName'];
        this.g_minvalue = res['swgvMinValue'];
        this.g_maxvalue = res['swgvMaxValue'];
        this.g_diffvalue = res['swgvDifferenceValue'];
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  setGlobalValue(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/setEditInfoValue`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: 'text',
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveGobalDataValue(minval: any, maxval: any, diffval: any) {
    console.log('save ', minval, maxval, diffval);

    var jsonData = {
      SwgvName: this.typeOfGlobalval,
      SwgvMinValue: minval,
      SwgvMaxValue: maxval,
      SwgvDifferenceValue: diffval,
    };

    this.setGlobalValue(jsonData).then(
      (res: any) => {
        this.loadGobalListData();
        console.log(res.data);
        this.dialogRef.close('Updated !');
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
        this.dialogRef.close('Error :'+error.statusText);

      }
    );
  }


  //////////////////////////////////////////////////////////

}
