<h2 mat-dialog-title>Edit Admin SubDistrict</h2>
<form [formGroup]="editAdminSubDistrictForm">
    <mat-dialog-content class="mat-typography" style="width: 1000px;">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="Username " formControlName="usernameEditSubDistrict">
                    <mat-hint *ngIf="InvalidUsernameLength" style="color: red;"><b>This field length should be larger</b></mat-hint>
                    <mat-hint *ngIf="InvalidUsername" style="color: red;"><b>Duplicate Username</b></mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Enter your password old</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="passwordEditSubDistrict">
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint *ngIf="InvalidOldPassword" style="color: red;"><b>The old password is invalid.</b></mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Enter your password new</mat-label>
                    <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="passwordNew">
                    <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideConfirmPassword">
                        <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint *ngIf="InvalidNewAndOldPassword" style="color: red;"><b>New and old passwords don't match.</b></mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field appearance="outline" style="width: -webkit-fill-available">
                    <mat-label>Phone</mat-label>
                    <input matInput placeholder="Phone" formControlName="phoneEditSubDistrict" >
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button type="submit" color="primary" (click)="onUpdateAdminSubDistrict()">Update</button>
    </mat-dialog-actions>
</form>