import { Component, OnInit, LOCALE_ID, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { GlobalConstants } from 'src/app/common/global-constants';

interface Gender {
    value: string;
    viewValue: string;
}
interface Province {
    value: string;
    viewValue: string;
}

interface District {
    value: string;
    viewValue: string;
}

interface Subdistricts {
    value: string;
    viewValue: string;
}

export interface DialogEditData {
    idOperatorAdmin: string;
}

@Component({
    selector: 'edit-operator-profile-modal',
    templateUrl: './edit-operator-profile-modal.html',
})
export class DialogEditProfileOperator {
    imageUrl1: string = GlobalConstants.imageUrl1;
    imageUrl2: string = GlobalConstants.imageUrl2;
    fileData!: File;
    previewUrl: any = null;
    fileUploadProgress!: string;
    imgProfileOperator: any;

    dataProvince: any = 0;
    dataDistrict: any = 0;
    dataSubdistricts: any = 0;

    provinces: Province[] = [];
    districts: District[] = [];
    subdistricts: Subdistricts[] = [];

    selectedProvince!: number;
    selectedDistrict!: number;
    selectedSubdistrict!: number;

    nameProvince!: string;
    nameDistricts!: string;
    nameSubdistricts!: string;

    editOperatorSubDistrictForm!: FormGroup;
    dataConvert!: any;
    dataConvertEditOperator: any[] = [];

    idOperator!: string;
    idAdminSubDistrict!: string;
    idAdminRole!: string;
    idAdminParrent!: string;
    idAdminNamerole!: string;
    idAdminProvinceID!: string;
    idAdminDistrictID!: string;
    idAdminSubDistrictID!: string;

    idProvince!: number;
    idDistrict!: number;
    idSubdistrict!: number;

    passOld: any;
    noImg: boolean = true;

    englishAbbreviation!: string;

    constructor(
        public dialogRef: MatDialogRef<DialogEditProfileOperator>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditData,
        @Inject(LOCALE_ID) private locale: string,
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        private router: Router,
        private ngZone: NgZone,
        private ServiceService: ServiceService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.editOperatorSubDistrictForm = this.formBuilder.group({
            operatorEmail: '',
            operatorUsername: '',
            operatorPassword: '',
            operatorConfirmPassword: '',
            operatorFirstname: '',
            operatorLastname: '',
            operatorGender: '',
            operatorAge: '',
            operatorDateofbirth: '',
            operatorPhone: '',
            operatorAddress: '',
            operatorProvince: '',
            operatorDistrict: '',
            operatorSubdistrict: '',
            operatorPostalcode: ''
        });
        // console.log(">>>>ss ",this.data.idOperatorAdmin);
        var provinceID:any;
        var districtID:any;
        var subDistrictID:any;
        var resAdminGetOperator:any;
        this.ServiceService.GetAdminGetOperator_(this.data.idOperatorAdmin).then(async dataAdminGetOperator => {
            resAdminGetOperator = dataAdminGetOperator;
            // console.log("res[0].Operator_Gender ", res);
            this.imgProfileOperator = resAdminGetOperator[0].operatorPic;
            this.passOld = resAdminGetOperator[0].operatorPassword;
            this.idOperator = resAdminGetOperator[0].operatorId;
            await this.ServiceService.GetProvinceByName_(resAdminGetOperator[0].operatorProvinceId).then(async dataProvinceID => {
                provinceID = dataProvinceID;
                this.idProvince = provinceID[0].provinceId;
                this.nameProvince = provinceID[0].provinceName;
                await this.ServiceService.GetDistrictByName_(resAdminGetOperator[0].operatorDistrictId).then(async datadistrictID => {
                    districtID = datadistrictID;
                    // console.log("districtID : ",districtID);
                    // console.log("districtID : ",districtID[0].amphurId);
                    // console.log("districtID : ",districtID[0].amphurName);

                    this.idDistrict = districtID[0].amphurId;
                    this.nameDistricts = districtID[0].amphurName;
                    await this.ServiceService.GetSubdistrictByName_(resAdminGetOperator[0].operatorSubdistrictId).then(dataSubDistrictID => {
                        subDistrictID = dataSubDistrictID;
                        // console.log("subDistrictID : ",subDistrictID);

                        this.idSubdistrict = subDistrictID[0].districtId;
                        this.nameSubdistricts = subDistrictID[0].districtName;
                        // console.log(" res[0].idSubdistrict ", this.idSubdistrict);
                        var ans_username = resAdminGetOperator[0].operatorUsername.split('-');
                        // console.log(ans_username[0]);
                        this.englishAbbreviation = ans_username[0];

                        this.editOperatorSubDistrictForm.setValue({
                            operatorEmail: resAdminGetOperator[0].operatorEmail,
                            operatorUsername: ans_username[1],
                            operatorPassword: "",
                            operatorConfirmPassword: "",
                            operatorFirstname: resAdminGetOperator[0].operatorFirstname,
                            operatorLastname: resAdminGetOperator[0].operatorLastname,

                            operatorGender: resAdminGetOperator[0].operatorGender,

                            operatorAge: resAdminGetOperator[0].operatorAge,
                            // operatorDateofbirth: new Date(2017, 0, 1),
                            operatorDateofbirth: new Date(formatDate(resAdminGetOperator[0].operatorDateofbirth, 'yyyy,d,M', this.locale)),
                            operatorPhone: resAdminGetOperator[0].operatorPhone,
                            operatorAddress: resAdminGetOperator[0].operatorAddress,

                            operatorProvince: this.idProvince,
                            operatorDistrict: this.idDistrict,
                            operatorSubdistrict: this.idSubdistrict,

                            operatorPostalcode: resAdminGetOperator[0].operatorPostalcode
                        })
                    })
                })
            })
        });

        this.loadSelectDialogProvince();
        // this.loadOperatorSubDistrict(this.data.idSubDistrict, this.data.parrent)
    }

    genders: Gender[] = [
        { value: 'M', viewValue: 'Male' },
        { value: 'FM', viewValue: 'FeMale' }
    ];

    onUpdate(): void {
        const formData = new FormData();
        formData.append('FormFiles', this.fileData);
        var img :any;
        this.ServiceService.UploadIMGProfile_(formData).then((resImg) => {
            img = resImg;
            var checkData = false;
            if (this.editOperatorSubDistrictForm.value.operatorPassword == "" && this.editOperatorSubDistrictForm.value.operatorConfirmPassword == "") {
                checkData = true;
                this.dataConvert = {
                    OperatorId: this.idOperator,
                    OperatorAdminId: this.idAdminSubDistrict,
                    OperatorEmail: this.editOperatorSubDistrictForm.value.operatorEmail,
                    OperatorUsername: `${this.englishAbbreviation}-${this.editOperatorSubDistrictForm.value.operatorUsername}`,
                    OperatorPassword: "passOld",
                    OperatorFirstname: this.editOperatorSubDistrictForm.value.operatorFirstname,
                    OperatorLastname: this.editOperatorSubDistrictForm.value.operatorLastname,
                    operatorPic: img,
                    OperatorGender: this.editOperatorSubDistrictForm.value.operatorGender,
                    OperatorAge: this.editOperatorSubDistrictForm.value.operatorAge,
                    OperatorDateofbirth: formatDate(this.editOperatorSubDistrictForm.value.operatorDateofbirth, 'MM/dd/yyyy', this.locale),
                    OperatorAddress: this.editOperatorSubDistrictForm.value.operatorAddress,
                    OperatorProvinceId: this.idProvince,
                    OperatorProvince: this.nameProvince,
                    OperatorDistrictId: this.idDistrict,
                    OperatorDistrict: this.nameDistricts,
                    OperatorSubdistrictId: this.idSubdistrict,
                    OperatorSubdistrict: this.nameSubdistricts,
                    OperatorPostalcode: this.editOperatorSubDistrictForm.value.operatorPostalcode,
                    OperatorPhone: this.editOperatorSubDistrictForm.value.operatorPhone,

                    OperatorRole: this.idAdminRole,
                    OperatorParrent: this.idAdminParrent,
                    OperatorNamerole: this.idAdminNamerole,
                    OperatorProvinceId_Admin: this.idAdminProvinceID,
                    OperatorDistrictId_Admin: this.idAdminDistrictID,
                    OperatorSubDistrictId_Admin: this.idAdminSubDistrictID

                };
            } else if (this.editOperatorSubDistrictForm.value.operatorPassword == this.editOperatorSubDistrictForm.value.operatorConfirmPassword) {
                checkData = true;
                this.dataConvert = {
                    OperatorId: this.idOperator,
                    OperatorAdminId: this.idAdminSubDistrict,
                    OperatorEmail: this.editOperatorSubDistrictForm.value.operatorEmail,
                    OperatorUsername: `${this.englishAbbreviation}-${this.editOperatorSubDistrictForm.value.operatorUsername}`,
                    OperatorPassword: this.editOperatorSubDistrictForm.value.operatorPassword,
                    OperatorFirstname: this.editOperatorSubDistrictForm.value.operatorFirstname,
                    OperatorLastname: this.editOperatorSubDistrictForm.value.operatorLastname,
                    OperatorPic: img,
                    OperatorGender: this.editOperatorSubDistrictForm.value.operatorGender,
                    OperatorAge: this.editOperatorSubDistrictForm.value.operatorAge,
                    OperatorDateofbirth: formatDate(this.editOperatorSubDistrictForm.value.operatorDateofbirth, 'MM/dd/yyyy', this.locale),
                    OperatorAddress: this.editOperatorSubDistrictForm.value.operatorAddress,
                    OperatorProvinceId: this.idProvince,
                    OperatorProvince: this.nameProvince,
                    OperatorDistrictId: this.idDistrict,
                    OperatorDistrict: this.nameDistricts,
                    OperatorSubdistrictId: this.idSubdistrict,
                    OperatorSubdistrict: this.nameSubdistricts,
                    OperatorPostalcode: this.editOperatorSubDistrictForm.value.operatorPostalcode,
                    OperatorPhone: this.editOperatorSubDistrictForm.value.operatorPhone,

                    OperatorRole: this.idAdminRole,
                    OperatorParrent: this.idAdminParrent,
                    OperatorNamerole: this.idAdminNamerole,
                    OperatorProvinceId_Admin: this.idAdminProvinceID,
                    OperatorDistrictId_Admin: this.idAdminDistrictID,
                    OperatorSubDistrictId_Admin: this.idAdminSubDistrictID

                };
            } else {
                alert("Incorrect password")
                checkData = false;
            }
            if (checkData) {
                var idOperator:any;
                this.ServiceService.EditOperator_(this.dataConvert).then((dataIdOperator) => {
                    idOperator = dataIdOperator;
                    this.dataConvertEditOperator = [
                        {
                            Operator_ID: idOperator,
                            Operator_AdminID: this.idAdminSubDistrict,
                            Operator_Email: this.editOperatorSubDistrictForm.value.operatorEmail,
                            Operator_Username: this.editOperatorSubDistrictForm.value.operatorUsername,
                            Operator_Password: this.editOperatorSubDistrictForm.value.operatorPassword,
                            Operator_Firstname: this.editOperatorSubDistrictForm.value.operatorFirstname,
                            Operator_Lastname: this.editOperatorSubDistrictForm.value.operatorLastname,
                            Operator_Pic: img,
                            Operator_Gender: this.editOperatorSubDistrictForm.value.operatorGender,
                            Operator_Age: this.editOperatorSubDistrictForm.value.operatorAge,
                            Operator_Dateofbirth: formatDate(this.editOperatorSubDistrictForm.value.operatorDateofbirth, 'MM/dd/yyyy', this.locale),
                            Operator_Address: this.editOperatorSubDistrictForm.value.operatorAddress,
                            Operator_Province: this.nameProvince,
                            Operator_District: this.nameDistricts,
                            Operator_Subdistrict: this.nameSubdistricts,
                            Operator_Postalcode: this.editOperatorSubDistrictForm.value.operatorPostalcode,
                            Operator_Phone: this.editOperatorSubDistrictForm.value.operatorPhone,

                            // Operator_Role: this.idAdminRole,
                            // Operator_Parrent: this.idAdminParrent,
                            // Operator_Namerole: this.idAdminNamerole,
                            // Operator_ProvinceID: this.idAdminProvinceID,
                            // Operator_DistrictID: this.idAdminDistrictID,
                            // Operator_SubDistrictID: this.idAdminSubDistrictID
                        }
                    ]

                    this.dialogRef.close(this.dataConvertEditOperator);
                }, (err) => {
                    console.log(" : : ", err);
                });
            }

        }, (err) => {
            console.log(" : : ", err);
        });
    }

    loadOperatorSubDistrict(id: string, parrent: string) {
        var resAdminOperatorSubDistricts : any;
        this.ServiceService.GetAdminOperatorSubDistricts_(parrent, id, "0", false).then(dataAdminOperatorSubDistricts => {
            resAdminOperatorSubDistricts = dataAdminOperatorSubDistricts;
            // console.log("res >> ", res);
            this.idAdminSubDistrict = resAdminOperatorSubDistricts[0].admin_Id;
            this.idAdminRole = "Operator";
            this.idAdminParrent = resAdminOperatorSubDistricts[0].admin_Parrent;
            this.idAdminNamerole = resAdminOperatorSubDistricts[0].admin_Namerole;
            this.idAdminProvinceID = resAdminOperatorSubDistricts[0].admin_ProvinceId;
            this.idAdminDistrictID = resAdminOperatorSubDistricts[0].admin_DistrictId;
            this.idAdminSubDistrictID = resAdminOperatorSubDistricts[0].admin_SubDistrictId;
        });
    }

    loadSelectDialogProvince() {
        var resProvinceSelect :any;
        this.ServiceService.GetProvinceSelect_().then(dataProvinceSelect => {
            resProvinceSelect = dataProvinceSelect;
            this.dataProvince = resProvinceSelect;
            for (let i = 0; i < this.dataProvince.length; i++) {
                this.provinces.push({ value: this.dataProvince[i].provinceId, viewValue: this.dataProvince[i].provinceName })
            }
        });
    }

    loadSelectDistrict(selectedProvince: number) {
        if (selectedProvince != undefined) {
            this.districts = [];
            this.subdistricts = [];
            var resDistrict:any;
            this.ServiceService.GetDistrict_(selectedProvince).then(dataDistrict => {
                resDistrict = dataDistrict;
                this.dataDistrict = resDistrict;
                for (let i = 0; i < this.dataDistrict.length; i++) {

                    this.districts.push({ value: this.dataDistrict[i].amphurId, viewValue: this.dataDistrict[i].amphurName })
                }
            });
        }
    }

    loadSelectSubdistrict(selectedDistrict: number) {
        if (selectedDistrict != undefined) {
            this.subdistricts = [];
            var resSubdistrict:any;
            this.ServiceService.GetSubdistrict_(selectedDistrict).then(dataSubdistrict => {
                resSubdistrict = dataSubdistrict;
                this.dataSubdistricts = resSubdistrict;
                for (let i = 0; i < this.dataSubdistricts.length; i++) {
                    this.subdistricts.push({ value: this.dataSubdistricts[i].districtId, viewValue: this.dataSubdistricts[i].districtName })
                }
            });
        }
    }

    onProvinceSelection() {
        this.loadSelectDistrict(this.selectedProvince);

        for (let i = 0; i < this.dataProvince.length; i++) {
            if (this.selectedProvince == this.dataProvince[i].provinceId) {
                this.nameProvince = this.dataProvince[i].provinceName;
                this.idProvince = this.dataProvince[i].provinceId;
                // console.log(this.nameProvince);
            }
        }
    }

    onDistrictSelection() {
        this.loadSelectSubdistrict(this.selectedDistrict);

        for (let i = 0; i < this.dataDistrict.length; i++) {

            if (this.selectedDistrict == this.dataDistrict[i].amphurId) {

                this.nameDistricts = this.dataDistrict[i].amphurName;
                this.idDistrict = this.dataDistrict[i].amphurId;
                // console.log(this.nameDistricts);
            }
        }
    }

    onSubdistrictSelection() {
        for (let i = 0; i < this.dataSubdistricts.length; i++) {
            if (this.selectedSubdistrict == this.dataSubdistricts[i].districtId) {
                // console.log(this.dataSubdistricts[i]);
                this.nameSubdistricts = this.dataSubdistricts[i].districtName;
                this.idSubdistrict = this.dataSubdistricts[i].districtId;
                // console.log(this.nameSubdistricts);
            }
        }
    }


    fileProgress(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
        this.preview();
    }

    preview() {
        this.noImg = false;
        // Show preview
        var mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
            this.previewUrl = reader.result;
        }
    }

}
