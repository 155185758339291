import { ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { formatDate, DatePipe } from '@angular/common';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-count',
  templateUrl: './step-count.component.html',
  styleUrls: ['./step-count.component.scss']
})
export class StepCountComponent implements OnInit {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  ipServer: string = "https://api.medilifewellness.com:2443";
  // ipServer: string = "https://localhost:44378";

  imeiList: any[] = [];

  dataChartStepCount: any[] = [];
  dataCalculateStepCount: any[] = [];
  dataChart24List: any[] = [];
  dataChart7DayList: any[] = [];
  // minStepCountValue: any = 0;
  maxStepCountValue: any = 0;
  averStepCountValue: any = 0;


  dataChart24List_Day1: any[] = [];
  dataCalculateStepCount_Day1: any[] = [];

  filterEmtyData: any[] = [];
  datetimeShowList: any[] = [];

  gimeiCode: any = "";
  dataStepCountAll: any = [];

  rangeDateStartAndEnd = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date())
  });

  @ViewChild('chartStepCount', { static: false }) chartStepCount!: ElementRef;
  // @ViewChild('chartStepCountAver24', { static: false }) chartStepCountAver24!: ElementRef;
  @ViewChild('chartStepCountAver24Line', { static: false }) chartStepCountAver24Line!: ElementRef;
  @ViewChild('chartStepCountAver7day', { static: false }) chartStepCountAver7day!: ElementRef;
  countChartData = 1;
  setNow: any = new Date();

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private changeDetectRef:ChangeDetectorRef, private sharedServiceService: SharedServiceService, @Inject(LOCALE_ID) private locale: string) {

  }

  ngOnInit(): void {
    this.sharedServiceService.deviceIDValue.subscribe(
      (message) => {
        // console.log("message ID ", message);
        this.loadDataChart(message);
        let dateNow = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        setTimeout(async () => {
          await this.onChangeDateTimeEvent(dateNow, true);
        }, 1500);
      }
    );
  }

  onChengDate() {

    var dataStart: string = "";
    var dataEnd: string = "";

    dataStart = this.rangeDateStartAndEnd.value.start;
    dataEnd = this.rangeDateStartAndEnd.value.end;

    if (this.rangeDateStartAndEnd.value.start != null && this.rangeDateStartAndEnd.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);

      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      console.log("dataStart_ ", dataStart_, " | dataEnd_ ", dataEnd_);

      this.filterDate(dataStart_, dataEnd_);
    }

  }

  loadIMEIListFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_StepCount_CollectionList`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  loadChartFormAPI(imeiCode: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_LogStepCountList?imeicode=${imeiCode}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }


  async loadDataChart(imeiCode: string) {
    await this.loadChartFormAPI(imeiCode).then(
      async (res: any) => {

         console.log("StepCount res ", res);
        this.countChartData = res.length;


        this.gimeiCode = imeiCode;

        res.sort(function (a: any, b: any) {
          return + new Date(a.time_log) - + new Date(b.time_log);
        });

        this.dataStepCountAll = await res;

        this.filterDate("", "");

      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }

  filterDate(dateStart: string, dateEnd: string) {
    var dataStepCountAll_ = this.dataStepCountAll;
    var dataStepCount: any;

    this.filterEmtyData = [];
    this.datetimeShowList = [];

    this.dataCalculateStepCount = [];
    this.dataChartStepCount = [];
    this.dataChart24List = [];
    this.dataChart7DayList = [];
    // this.minStepCountValue = 0;
    this.maxStepCountValue = 0;
    this.averStepCountValue = 0;

    if (dateStart != "" && dateEnd != "") {
      dataStepCount = dataStepCountAll_.filter((item: any) => {
        return (new Date(item.time_log).valueOf() >= new Date(dateStart).setHours(7, 0, 0, 0).valueOf()
        && new Date(item.time_log).valueOf() <= new Date(dateEnd).valueOf());
      });
    } else {
      //กรอง เฉพาะวันนี้ วันปัจจุบัน
      dataStepCount = dataStepCountAll_.filter((item: any) => {
        var timelog = new Date(item.time_log).valueOf();
        var timeNow = new Date().setHours(new Date().getHours()+7);
        var timeStart =new Date().setHours(7, 0, 0, 0).valueOf();
        return (timelog >= timeStart &&
          timelog <= timeNow);
      });
    }

    for (let i = 0; i < dataStepCountAll_.length; i++) {
      this.dataCalculateStepCount.push({
        date: new Date(dataStepCountAll_[i].time_log),
        time_h: new Date(formatDate(dataStepCountAll_[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
        time_day: new Date(formatDate(dataStepCountAll_[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
        value1: Number(dataStepCountAll_[i].count_step),
      });
    }
    this.dataCalculateStepCount = this.dataCalculateStepCount.map((item: any) => ({
      ...item,
      date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
      time_h: item.time_h - 7
    }));

    for (let i = 0; i < dataStepCount.length; i++) {
      this.dataChartStepCount.push({
        // date: formatDate(
        //   dataStepCount[i].time_log,
        //   'yyyy/MM/dd hh:mm',
        //   'en-US'
        // ),
        date: dataStepCount[i].time_log.split("T")[0]+" "+dataStepCount[i].time_log.split("T")[1].substring(0,5),

        dateformat: new Date(formatDate(
          dataStepCount[i].time_log,
          'yyyy/MM/dd',
          'en-US'
        )).toLocaleString().split(" ", 1)[0],
        value1: Number(dataStepCount[i].count_step),
      });
    }

    var dataChartStepCount_: any = []
    for (let i = 0; i < dataStepCountAll_.length; i++) {
      dataChartStepCount_.push({
        date: formatDate(
          dataStepCountAll_[i].time_log,
          'yyyy/MM/dd hh:mm',
          'en-US'
        ),
        dateformat: new Date(formatDate(
          dataStepCountAll_[i].time_log,
          'yyyy/MM/dd',
          'en-US'
        )).toLocaleString().split(" ", 1)[0],
        value1: Number(dataStepCountAll_[i].count_step),
      });
    }

    for (var i = 0; i < dataChartStepCount_.length; i++) {
      if (dataChartStepCount_[i].value1 != 0 && dataChartStepCount_[i].value1 != NaN) {
        this.filterEmtyData.push({
          dateformat: dataChartStepCount_[i].dateformat
        });
      }
    }

    // Group DateTime For เลือกแบบวัน
    let group = this.filterEmtyData.reduce((r, a) => {
      r[a.dateformat] = [...r[a.dateformat] || [], a];
      return r;
    }, {});

    // console.log("Group ", group);

    const result_group = Object.keys(group).map(function (k) {
      const item = group[k];
      return {
        datetxtShow: item[0].dateformat
      }
    });

    this.datetimeShowList = result_group;
    // console.log("Group datetimeShowList ", this.datetimeShowList);





















    // ---------------------------------------- หา min max หาเฉลี่ย
    // this.minStepCountValue = Math.min.apply(Math, this.dataChartStepCount.map(function (o) { return o.value1; }));
    if(this.dataChartStepCount.length > 0){
    this.maxStepCountValue = Math.max.apply(Math, this.dataChartStepCount.map(function (o) { return o.value1; }));
    var total = 0;
    for (var i = 0; i < this.dataChartStepCount.length; i++) {
      total += this.dataChartStepCount[i].value1;
    }
    this.averStepCountValue = total / this.dataChartStepCount.length;
  }


    // หา 24 ชม. เฉลี่ย --------------------------- เอาตัวเลข time_h มา Group
    const reduced = this.dataCalculateStepCount.reduce(function (m, d) {
      if (!m[d.time_h]) {
        m[d.time_h] = { ...d, count: 1 };
        return m;
      }
      m[d.time_h].value1 += d.value1;
      m[d.time_h].count += 1;
      return m;
    }, {});

    // หาค่าเฉลี่ย
    const result = Object.keys(reduced).map(function (k) {
      const item = reduced[k];
      return {
        time_h: item.time_h,
        // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
        avg: item.value1 / item.count,
      }
    });

    // this.dataChart24List = result;

    // โชว์ค่าทั้ง 24 ชั่วโมง
    for (var i = 0; i < 24; i++) {
      var index = result.findIndex(e => e.time_h == i);
      if (index > -1) {
        this.dataChart24List.push({
          time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
          avg: result[index].avg,
          num_hr: result[index].time_h
        });
      } else {
        this.dataChart24List.push({
          time_h: ("00" + i).slice(-2) + ":00 น",
          avg: 0,
          num_hr: i
        });
      }
    }



    // หา 7 วันเฉลี่ย --------------------- เอาตัวเลข time_day มา Group
    const reduced_day = this.dataCalculateStepCount.reduce(function (m, d) {
      if (!m[d.time_day]) {
        m[d.time_day] = { ...d, count: 1 };
        return m;
      }
      m[d.time_day].value1 += d.value1;
      m[d.time_day].count += 1;
      return m;
    }, {});

    // หาค่าเฉลี่ย
    const result_day = Object.keys(reduced_day).map(function (k) {
      const item = reduced_day[k];

      var daynames = "";

      switch (item.time_day) {
        case 0:
          daynames = "วันอาทิตย์";
          break;
        case 1:
          daynames = "วันจันทร์";
          break;
        case 2:
          daynames = "วันอังคาร";
          break;
        case 3:
          daynames = "วันพุธ";
          break;
        case 4:
          daynames = "วันพฤหัส";
          break;
        case 5:
          daynames = "วันศุกร์";
          break;
        default:
          daynames = "วันเสาร์";
          break;
      }

      return {
        time_day: daynames,
        avg: item.value1 / item.count,
      }
    });

    this.dataChart7DayList = result_day;


    // GEN กราฟ
    this.GraphStepCountDemo();
    // this.GraphAver24h(this.dataChart24List);
    this.GraphAver24hLine(this.dataChart24List);
    this.GraphAver7days();
  }

  loadChartbyDateFormAPI(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_DayStepCount?imeicode=${imeiCode}&date=${datetimes}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }


  d1CalDemo: any = 0;
  d1WalkDemo: any = 0;

  async loadDatabyDateChart(imeiCode: string, datetimes: string) {
    await this.loadChartbyDateFormAPI(imeiCode, datetimes).then(
      (res: any) => {

        this.d1CalDemo = 0;
        this.d1WalkDemo = 0;

        this.dataChart24List_Day1 = [];
        this.dataCalculateStepCount_Day1 = [];

        for (let i = 0; i < res.length; i++) {
          this.dataCalculateStepCount_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
            time_day: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
            value1: Number(res[i].count_step),
          });
        }
        this.dataCalculateStepCount_Day1 = this.dataCalculateStepCount_Day1.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: item.time_h - 7
        }));

        const reduced = this.dataCalculateStepCount_Day1.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            // time_h: ("00" + item.time_h).slice(-2) + ":00 น",
            time_h: item.time_h,
            avg: item.value1 / item.count,
          }
        });


        // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex(e => e.time_h == i);
          if (index > -1) {
            this.dataChart24List_Day1.push({
              time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
              avg: result[index].avg,
              num_hr: result[index].time_h
            });
          } else {
            this.dataChart24List_Day1.push({
              time_h: ("00" + i).slice(-2) + ":00 น",
              avg: 0,
              num_hr: i
            });
          }
        }


        var sumday1avg = 0;
        sumday1avg = Math.max.apply(Math, this.dataChart24List_Day1.map(function (o) { return o.avg; }));
        this.d1CalDemo = sumday1avg / 20;
        this.d1WalkDemo = ((170 * 0.415) * sumday1avg) / 100000;



        // this.GraphAver24h(this.dataChart24List_Day1);
        this.GraphAver24hLine(this.dataChart24List_Day1);
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }




  GraphStepCountDemo() {
    this.changeDetectRef.detectChanges();
    am4core.useTheme(am4themes_animated);
    // Create chart instance
    var chart = am4core.create(this.chartStepCount.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    this.dataChartStepCount = this.dataChartStepCount.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")
    }));
    chart.data = this.dataChartStepCount;

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    // Create series
    // var series = chart.series.push(new am4charts.LineSeries());
    // series.dataFields.valueY = 'value1';
    // series.dataFields.categoryX = 'date';
    // series.strokeWidth = 1;
    // series.minBulletDistance = 10;
    // series.stroke = am4core.color('red');
    // series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';


    var series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "value1";
    series.dataFields.categoryX = "date";
    series.tooltipText = "[bold]{date}:[/] {value1}\n[bold]";
    // series.columns.template.strokeWidth = 0;
    // series.columns.template.column.cornerRadiusTopLeft = 10;
    // series.columns.template.column.cornerRadiusTopRight = 10;
    // series.columns.template.column.fillOpacity = 0.8;
    // series.columns.template.propertyFields.fill = "color";
    // series.tooltipPosition = "pointer";



    var axisRangetop = valueAxis.axisRanges.create();
    axisRangetop.value = 10000;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#00ba00');
    axisRangetop.grid.strokeWidth = 2;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }



  // GraphAver24h(mainData: any[]) {

  //   console.log("24H mainData : ", mainData);
  //   console.log("24H dataChart24List : ", this.dataChart24List);

  //   am4core.useTheme(am4themes_animated);

  //   var chart = am4core.create(this.chartStepCountAver24.nativeElement, am4charts.XYChart);
  //   chart.exporting.menu = new am4core.ExportMenu();

  //   // Add data
  //   let newList: any = [];

  //   for (var i = 0; i < mainData.length; i++) {
  //     newList.push({
  //       time_h: mainData[i].time_h,
  //       avg: mainData[i].avg,
  //       "color": chart.colors.next()
  //     })
  //   }

  //   chart.data = newList;

  //   // Create axes
  //   let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //   categoryAxis.dataFields.category = "time_h";
  //   categoryAxis.renderer.grid.template.location = 0;
  //   categoryAxis.renderer.minGridDistance = 30;
  //   categoryAxis.renderer.labels.template.horizontalCenter = "right";
  //   categoryAxis.renderer.labels.template.verticalCenter = "middle";
  //   categoryAxis.renderer.labels.template.rotation = 270;
  //   categoryAxis.renderer.minHeight = 110;

  //   let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis.min = 0;

  //   // Create series
  //   let series = chart.series.push(new am4charts.ColumnSeries());
  //   series.sequencedInterpolation = true;
  //   series.dataFields.valueY = "avg";
  //   series.dataFields.categoryX = "time_h";
  //   series.tooltipText = "[{categoryX}: bold]{valueY.formatNumber('#,###.##')}[/] ก้าว";
  //   series.columns.template.strokeWidth = 0;
  //   series.columns.template.column.cornerRadiusTopLeft = 10;
  //   series.columns.template.column.cornerRadiusTopRight = 10;
  //   series.columns.template.column.fillOpacity = 0.8;
  //   series.columns.template.propertyFields.fill = "color";
  //   series.tooltipPosition = "pointer";


  //   let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
  //   dateAxis2.renderer.grid.template.location = 0;
  //   dateAxis2.renderer.minGridDistance = 40;
  //   dateAxis2.renderer.labels.template.disabled = true;
  //   dateAxis2.renderer.grid.template.disabled = true;
  //   dateAxis2.cursorTooltipEnabled = false;

  //   let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis2.renderer.opposite = true;
  //   valueAxis2.renderer.grid.template.disabled = true;
  //   valueAxis2.renderer.labels.template.disabled = true;

  //   let series2 = chart.series.push(new am4charts.LineSeries());
  //   series2.dataFields.valueY = "avg";
  //   series2.dataFields.dateX = "num_hr";
  //   series2.data = this.dataChart24List;
  //   series2.xAxis = dateAxis2;
  //   series2.yAxis = valueAxis2;
  //   series2.strokeWidth = 3;
  //   series2.tooltipText = "เฉลี่ยจากทั้งหมด : [bold]{valueY.formatNumber('#.00')}[/] ก้าว";
  //   series2.stroke = am4core.color('red');

  //   // Add cursor
  //   chart.cursor = new am4charts.XYCursor();
  // }



  GraphAver24hLine(mainData: any[]) {

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartStepCountAver24Line.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    let newList: any = [];

    for (var i = 0; i < mainData.length; i++) {
      newList.push({
        time_h: mainData[i].time_h,
        avg: mainData[i].avg,
        num_hr: i
      })
    }

    chart.data = newList;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time_h";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis2.renderer.grid.template.location = 0;
    dateAxis2.renderer.minGridDistance = 40;
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.grid.template.disabled = true;
    dateAxis2.cursorTooltipEnabled = false;

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.min = 0;
    valueAxis2.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis2.renderer.labels.template.fill = am4core.color("#ffffff");

    var axisRangetop = valueAxis2.axisRanges.create();
    axisRangetop.value = 10000;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#00ba00');
    axisRangetop.grid.strokeWidth = 2;

    // var axisRangebottom = valueAxis2.axisRanges.create();
    // axisRangebottom.value = 90;
    // axisRangebottom.grid.strokeOpacity = 1;
    // axisRangebottom.grid.stroke = am4core.color('#f3260e');
    // axisRangebottom.grid.strokeWidth = 2;

    // var rangeareatop = valueAxis2.axisRanges.create();
    // rangeareatop.value = 35.4;
    // rangeareatop.endValue = 37.4;
    // rangeareatop.axisFill.fill = am4core.color('#00ba00');
    // rangeareatop.axisFill.fillOpacity = 0.2;
    // rangeareatop.grid.strokeOpacity = 0;

    // var rangeareabottom = valueAxis2.axisRanges.create();
    // rangeareabottom.value = 90;
    // rangeareabottom.endValue = 95;
    // rangeareabottom.axisFill.fill = am4core.color('#f3260e');
    // rangeareabottom.axisFill.fillOpacity = 0.2;
    // rangeareabottom.grid.strokeOpacity = 0;


    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "avg";
    series2.dataFields.dateX = "num_hr";
    series2.data = this.dataChart24List;
    series2.xAxis = dateAxis2;
    series2.yAxis = valueAxis2;
    series2.strokeWidth = 3;
    series2.tooltipText = "Average : [bold]{valueY.formatNumber('#.00')}[/] ก้าว";
    series2.stroke = am4core.color('#cd0506');
    series2.fill = am4core.color('#cd0506');


    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = "avg";
    series1.dataFields.dateX = "num_hr";
    series1.data = newList;
    series1.xAxis = dateAxis2;
    series1.yAxis = valueAxis2;
    series1.strokeWidth = 2;
    series1.tooltipText = "ปัจจุบัน [bold]{valueY.formatNumber('#.00')}[/] ก้าว";
    series1.stroke = am4core.color('#00bce6');
    series1.fill = am4core.color('#00bce6');

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }




  GraphAver7days() {

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartStepCountAver7day.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = this.dataChart7DayList;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time_day";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "avg";
    series.dataFields.categoryX = "time_day";
    series.tooltipText = "เฉลี่ย [{categoryX}: bold]{valueY.formatNumber('#.00')}[/] ก้าว";
    series.columns.template.strokeWidth = 0;

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    series.fill = am4core.color("#514ab8");

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  }


  onChangeDateTimeEvent(event: any, isFirst: boolean) {
    if (!isFirst) var datetimeStr = formatDate(event.value, 'yyyy-MM-dd', 'en-US').toString();
    else var datetimeStr = formatDate(event, 'yyyy-MM-dd', 'en-US').toString();
    this.loadDatabyDateChart(this.gimeiCode, datetimeStr);
  }

}
