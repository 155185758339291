<div *ngIf="showContent || roleAdmin == 'SuperAdmin'">
  <div class="row">
    <div style="margin-top: 18px;display: flex;justify-content: center;">
      <div class="col-md-6">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Enter a date range</mat-label>

          <mat-date-range-input [formGroup]="rangeDateStartAndEnd" [rangePicker]="picker_start_end">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onChangeDate()">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
          <mat-date-range-picker #picker_start_end></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field appearance="outline" style="width: 167px;">
          <mat-label>Select Show Item</mat-label>
          <mat-select [(value)]="selected"
            (selectionChange)="onChangeCountItem(selected)">
            <mat-option value="10">10-Item</mat-option>
            <mat-option value="20">20-Item</mat-option>
            <mat-option value="50">50-Item</mat-option>
            <mat-option value="100">100-Item</mat-option>
            <mat-option value="all">Show-All</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="dataLcation.length> 0 || dataLcationBackUp.length >0">
    <div class="col-4">
      <div class="scOverflow">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hoverBtn" [class.onClickFocus]="item.isSelected" *ngFor="let item of dataLcation index as
              i"(click)="markerClicked(item.latitude,item.longitude,item.time_log,i+1);">
              <th scope="row">{{i+1}}</th>
              <td>{{item.time_log | date:'medium' }}</td>
              <td>
                <span class="text-success text-center" *ngIf="item.latitude != '0' && item.longitude != '0'">Success</span>
                <span class="text-danger text-center" *ngIf="item.latitude == '0' && item.longitude == '0'">Unsuccess</span>
              </td>
              <!-- <td>{{item.time_log | timeago }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-8">
      <div class="sticky" *ngIf="markers != null">
        <a href="http://maps.google.com/maps?q=loc:{{markers.lat}},{{markers.lng}}" style="width: -webkit-fill-available;" target="_blank" class="btn btn-success"><img  src="https://www.beemortar.com/wp-content/uploads/2021/04/kisspng-responsive-web-design-social-media-google-maps-com-map-icon-5abfaf382c12a9.8640671315225116721805.png" width="25px" height="25px"> Google Map</a>
        <agm-map [latitude]='lat' [longitude]='long' [zoom]='zoom'>
          <agm-marker [latitude]="markers.lat"
            [longitude]="markers.lng"
            [label]="markers.title1">
            <agm-info-window #infoWindow>
              {{ markers.label | timeago }} <br>
              {{ markers.label | date:'short' }}
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
      <div *ngIf="markers == null" style="display: flex;justify-content:
        center;margin-top: 300px;"> <h1><mat-icon> map</mat-icon></h1> </div>
    </div>
  </div>
  <div class="row" *ngIf="dataLcation.length <= 0">

    <div class="col-12"> <h2 class="mt-5 mb-5" style="
        display: flex;
        justify-content: center;
        ">***
        ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>

  </div>
</div>
