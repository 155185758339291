import { Component, Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ServiceService } from '../../../../../core/services/service.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'caa-detail-modal.component',
  templateUrl: './caa-detail-modal.component.html',
})
export class CaaDetailModal {
  drugDateForm!: FormGroup;

  doctorTaskDetail: any;
  medPresDetail: any;
  medPresTHDetail: any;
  AlertType: any;
  AlertID: any;
  subID: any;
  userName: any;

  selectedStatusDispenseMedicine: string = 'waiting';

  selectMptStatiaCz: boolean = false;
  selectMptStatia: boolean = false;
  selectMpt5roots: boolean = false;
  selectMptGreenMedicine: boolean = false;
  selectMptAummahiva: boolean = false;
  selectMptPrasapoyai: boolean = false;
  selectMptJanlelar: boolean = false;
  selectMptHomyen: boolean = false;
  selectMptHomron: boolean = false;
  selectMptFatalaijon: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private ServiceService: ServiceService,
    private router: Router,
    public dialogRef: MatDialogRef<CaaDetailModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.drugDateForm = this.formBuilder.group({
      nameStartdate: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      nameFavipiravirFirst5daysStart: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      nameFavipiravirFirst5daysEnd: formatDate(new Date().setDate(new Date().getDate() + 5), 'yyyy-MM-dd', this.locale),
      namePickerNext5daysStart: formatDate(new Date(), 'yyyy-MM-dd', this.locale),
      namePickerNext5daysEnd: formatDate(new Date().setDate(new Date().getDate() + 5), 'yyyy-MM-dd', this.locale),
    });
    this.AlertType = data['Type'];
    this.subID = data['ID'];
    this.userName = data['UserName'];
    this.AlertID = data['AlertID'];

    if (this.AlertType == 'DoctorTask') {
      this.loadDoctorTaskByID(this.subID);
    } else if (this.AlertType == 'MedPresTH') {
      this.loadMedicalMedPresTHByID(this.subID);
    } else {
      this.loadMedicalPrescribtionByID(this.subID);
    }

  }

  loadDoctorTaskByID(DPT_ID: any) {
    this.ServiceService.GetDoctorPatientTask(DPT_ID).then((data) => {
      // console.log(data);
      this.doctorTaskDetail = data;
    });
  }

  loadMedicalPrescribtionByID(MP_ID: any) {
    this.ServiceService.GetMedicalPrescribtionByID(MP_ID).then((data) => {
      // console.log(data);
      this.medPresDetail = data;
    });
  }
  
  async loadMedicalMedPresTHByID(MP_ID: any) {
    await this.ServiceService.GetMedicalMedPresTHByID(MP_ID).then((data) => {
      this.medPresTHDetail = data;
      // console.log(this.medPresTHDetail.medPres);

      if (this.medPresTHDetail.medPres.mptStatiaCz == true) {
        this.selectMptStatiaCz = true;
      }
      if (this.medPresTHDetail.medPres.mptStatia == true) {
        this.selectMptStatia = true;
      }
      if (this.medPresTHDetail.medPres.mpt5roots == true) {
        this.selectMpt5roots = true;
      }
      if (this.medPresTHDetail.medPres.mptGreenMedicine == true) {
        this.selectMptGreenMedicine = true;
      }
      if (this.medPresTHDetail.medPres.mptAummahiva == true) {
        this.selectMptAummahiva = true;
      }
      if (this.medPresTHDetail.medPres.mptPrasapoyai == true) {
        this.selectMptPrasapoyai = true;
      }
      if (this.medPresTHDetail.medPres.mptJanlelar == true) {
        this.selectMptJanlelar = true;
      }
      if (this.medPresTHDetail.medPres.mptHomyen == true) {
        this.selectMptHomyen = true;
      }
      if (this.medPresTHDetail.medPres.mptHomron == true) {
        this.selectMptHomron = true;
      }
      if (this.medPresTHDetail.medPres.mptFatalaijon == true) {
        this.selectMptFatalaijon = true;
      }
    });
  }

  AcceptDoctorPatientTask(alertID: any, status: any): void {
    this.ServiceService.changeDoctorPatientStatus(alertID, status).then(
      (data) => {
        this.dialogRef.close({ status: true, type: "comment" });
      }
    );
  }

  
  onChangeStatusDispenseMedicine(event: string) {
    this.selectedStatusDispenseMedicine = event;
  }

  AcceptMedTHPres(alertID: any, status: any): void {
    var status_ = status;
    if (this.selectedStatusDispenseMedicine != 'accept') {
      status_ = false;
    }
    var jsonDate: any;
    jsonDate = {
      MedPresType: "MedPresTH",
      FavipiravirStartdate: "",
      FavipiravirFirst5daysStart: "",
      FavipiravirFirst5daysEnd: "",
      FavipiravirNext5daysStart: "",
      FavipiravirNext5daysEnd: "",
      DexamethasoneStartdate: "",
      PrednisoloneStartdate: "",
      OmeprazoleStartDate: "",
      StatusDispenseMedicine: this.selectedStatusDispenseMedicine
    }
    var jsonStr = JSON.stringify(jsonDate);
    this.ServiceService.changeMedPresStatus(alertID, status_, jsonStr).then(
      (data) => {
        if (this.selectedStatusDispenseMedicine == "accept") {
          this.dialogRef.close({ status: true, type: "dispenseMedicine" });
        } else {
          this.dialogRef.close({ status: false, type: "dispenseMedicine" });
        }
      }
    );
  }

  AcceptMedPres(alertID: any, status: any): void {
    var status_ = status;

    if (this.selectedStatusDispenseMedicine != 'accept') {
      status_ = false;
    }

    var jsonDate: any;
    jsonDate = {
      MedPresType: "MedPres",
      FavipiravirStartdate: "",
      FavipiravirFirst5daysStart: "",
      FavipiravirFirst5daysEnd: "",
      FavipiravirNext5daysStart: "",
      FavipiravirNext5daysEnd: "",
      DexamethasoneStartdate: "",
      PrednisoloneStartdate: "",
      OmeprazoleStartDate: "",
      StatusDispenseMedicine: this.selectedStatusDispenseMedicine
    }
    if (this.medPresDetail.medPres.mpFavi == true) {
      if (this.medPresDetail.medPres.mpFaviDay == 'f5d') {
        jsonDate.FavipiravirFirst5daysStart = this.drugDateForm.value.nameFavipiravirFirst5daysStart;
        jsonDate.FavipiravirFirst5daysEnd = this.drugDateForm.value.nameFavipiravirFirst5daysEnd;
      } else {
        jsonDate.FavipiravirNext5daysStart = this.drugDateForm.value.namePickerNext5daysStart;
        jsonDate.FavipiravirNext5daysEnd = this.drugDateForm.value.namePickerNext5daysEnd;
      }
    }

    if (this.medPresDetail.medPres.mpFavi == true) {
      jsonDate.FavipiravirStartdate = this.drugDateForm.value.nameStartdate;
    }

    if (this.medPresDetail.medPres.mpDexa == true) {
      jsonDate.DexamethasoneStartdate = this.drugDateForm.value.nameStartdate;
    }

    if (this.medPresDetail.medPres.mpPrednisolone == true) {
      jsonDate.PrednisoloneStartdate = this.drugDateForm.value.nameStartdate;
    }

    if (this.medPresDetail.medPres.mpOmeprazole == true) {
      jsonDate.OmeprazoleStartDate = this.drugDateForm.value.nameStartdate;
    }


    var jsonStr = JSON.stringify(jsonDate);
    // console.log("jsonStr : ", jsonDate);

    this.ServiceService.changeMedPresStatus(alertID, status_, jsonStr).then(
      (data) => {
        if (this.selectedStatusDispenseMedicine == "accept") {
          this.dialogRef.close({ status: true, type: "dispenseMedicine" });
        } else {
          this.dialogRef.close({ status: false, type: "dispenseMedicine" });
        }
      }
    );
  }

  onSelectedStartDate() {
    var newDateStart = formatDate(this.drugDateForm.value.nameStartdate, 'yyyy-MM-dd', this.locale);
    if (this.medPresDetail.medPres.mpFaviDay == 'f5d') {
      this.drugDateForm = this.formBuilder.group({
        nameStartdate: newDateStart,
        nameFavipiravirFirst5daysStart: newDateStart,
        nameFavipiravirFirst5daysEnd: formatDate(new Date(newDateStart).setDate(new Date(newDateStart).getDate() + 5), 'yyyy-MM-dd', this.locale),
        // namePickerNext5daysStart: dataSelect.toISOString(),
        // namePickerNext5daysEnd: new Date(dataSelect.setDate(dataSelect.getDate() + 5)).toISOString(),
      });
    } else {
      this.drugDateForm = this.formBuilder.group({
        nameStartdate: newDateStart,
        // nameFavipiravirFirst5daysStart: dataSelect.toISOString(),
        // nameFavipiravirFirst5daysEnd: new Date(dataSelect.setDate(dataSelect.getDate() + 5)).toISOString(),
        namePickerNext5daysStart: newDateStart,
        namePickerNext5daysEnd: formatDate(new Date(newDateStart).setDate(new Date(newDateStart).getDate() + 5), 'yyyy-MM-dd', this.locale),
      });
    }

    this.medPresDetail.medPres.mpFaviPrecriptionDate = newDateStart;
    this.medPresDetail.medPres.mpDexaPrecriptionDate = newDateStart;
    this.medPresDetail.medPres.mpPrednisolonePrecriptionDate = newDateStart;
    this.medPresDetail.medPres.mpOmeprazolePrecriptionDate = newDateStart;

  }

  onSelectedFirst5daysStart() {
    var newDateStart = formatDate(this.drugDateForm.value.nameFavipiravirFirst5daysStart, 'yyyy-MM-dd', this.locale);
    this.drugDateForm = this.formBuilder.group({
      nameStartdate: this.drugDateForm.value.nameStartdate,
      nameFavipiravirFirst5daysStart: newDateStart,
      nameFavipiravirFirst5daysEnd: formatDate(new Date(newDateStart).setDate(new Date(newDateStart).getDate() + 5), 'yyyy-MM-dd', this.locale),
      namePickerNext5daysStart: this.drugDateForm.value.namePickerNext5daysStart,
      namePickerNext5daysEnd: this.drugDateForm.value.namePickerNext5daysEnd,
    });
  }

  onSelectedNext5daysStart() {
    var newDateStart = formatDate(this.drugDateForm.value.namePickerNext5daysStart, 'yyyy-MM-dd', this.locale);
    this.drugDateForm = this.formBuilder.group({
      nameStartdate: this.drugDateForm.value.nameStartdate,
      nameFavipiravirFirst5daysStart: this.drugDateForm.value.nameFavipiravirFirst5daysStart,
      nameFavipiravirFirst5daysEnd: this.drugDateForm.value.nameFavipiravirFirst5daysEnd,
      namePickerNext5daysStart: newDateStart,
      namePickerNext5daysEnd: formatDate(new Date(newDateStart).setDate(new Date(newDateStart).getDate() + 5), 'yyyy-MM-dd', this.locale),
    });
  }

}
