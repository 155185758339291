import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DialogEditAdminSubDistrictDialog } from './edit-admin-sub-district.component';
import * as dataProvinceEN from '../../../shared/interface/myProvince.json';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-admin-sub-district',
  templateUrl: './admin-sub-district.component.html',
  styleUrls: ['./admin-sub-district.component.scss']
})
export class AdminSubDistrictComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  roleAdmin: any;
  getNameProvince: any;
  getProvinceId: any;
  getNameDistrict: any;
  getIdDistrict: any;
  getParrent: any;
  dataAdminDistrictName!: string;
  dataAdminSubDistricts: any = [];
  dataProvinceNameTHEN: any = (dataProvinceEN as any).default;
  loadData: boolean = true;
  loadDataSuccess: boolean = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private ServiceService: ServiceService
  ) {
    this.getNameProvince = this.activatedRoute.snapshot.paramMap.get('nameProvince');
    this.getProvinceId = this.activatedRoute.snapshot.paramMap.get('provinceId');
    this.getNameDistrict = this.activatedRoute.snapshot.paramMap.get('nameDistrict');
    this.getIdDistrict = this.activatedRoute.snapshot.paramMap.get('idDistrict');
    this.getParrent = this.activatedRoute.snapshot.paramMap.get('parrent');

    this.roleAdmin = localStorage.getItem("currentRole");

    if(this.roleAdmin == "SuperAdmin" || this.roleAdmin == "AdminProvince" || this.roleAdmin == "AdminDistrict"){
      this.loadAdminSubDistricts(this.getProvinceId, this.getIdDistrict);
      this.loadAdminHeaderDistricts("AdminDistrict", this.getIdDistrict);
    }else{
      this.router.navigate([`/dashboard`], { replaceUrl: true }).then(() => { window.location.reload(); });
    }
  }

  openDialogAddAdminSubDistrict() {
    var dataAdminGetOperators: any = [];
    var dataOperator: any = [];
    const dialogRef = this.dialog.open(DialogAddAdminSubDistrict, {
      data: { id: this.getIdDistrict, parrent: this.getParrent }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result != undefined && result != "") {
        await this.ServiceService.GetListOperatorInAdmin_(result[0].admin_Id).then(async resAdminGetOperators => {
          dataAdminGetOperators = resAdminGetOperators;
          var cuntJob: number = 0;
          if (dataAdminGetOperators.length != 0) {
            for (let j = 0; j < dataAdminGetOperators.length; j++) {
              var resAdminProvince: any;
              await this.ServiceService.GetAdminProvince_(result[0].admin_Id).then(async dataAdminProvince => {
                resAdminProvince = dataAdminProvince;
                var parseJsonAdminProvince = JSON.parse(resAdminProvince);
                for (let l = 0; l < this.dataProvinceNameTHEN.length; l++) {
                  if (this.dataProvinceNameTHEN[l].idProvince == parseJsonAdminProvince[0].admin_ProvinceId) {
                    await this.ServiceService.GetJobToday_("AdminSubdistricts", this.dataProvinceNameTHEN[l].englishAbbreviation).then(resJobOperator => {
                      var dataJobOperator: any = [];
                      dataJobOperator = resJobOperator;

                      cuntJob = cuntJob + dataJobOperator.length;

                    });
                    break;
                  }
                  if (j == dataAdminGetOperators.length - 1) {
                    dataOperator.push({ dataAdmin: result[0], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
                  }
                }
              });
            }
          } else {
            dataOperator.push({ dataAdmin: result[0], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
          }
        });
        this.dataAdminSubDistricts.push(dataOperator[0]);
      }
    });
  }

  ngOnInit(): void {
  }

  openDialogEditAdminSubDistrict(idAdminSubDistrict: string, username: string, password: string, phone: string): void {
    const dialogRef = this.dialog.open(DialogEditAdminSubDistrictDialog, {
      data: { idAdminSubDistrict: idAdminSubDistrict, usernameSubDistrict: username, passwordSubDistrict: password, phoneSubDistrict: phone }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed > ? : ', result);
      if (result != undefined) {
        for (let i = 0; i < this.dataAdminSubDistricts.length; i++) {
          if (result[0].admin_Id == this.dataAdminSubDistricts[i].dataAdmin.admin_Id) {
            this.dataAdminSubDistricts[i].dataAdmin.admin_Username = result[0].admin_Username;
            this.dataAdminSubDistricts[i].dataAdmin.admin_Password = result[0].admin_Password;
            this.dataAdminSubDistricts[i].dataAdmin.admin_Phone = result[0].admin_Phone
          }
        }
      }

    });
  }

  loadAdminSubDistricts(AdminProvinceID: string, AdminDistrictID: string) {
    var resAdminSubDistrictAndSubDistrict:any;
    var dataAdminGetOperators: any = [];
    var dataOperator: any = [];
    this.ServiceService.GetAdminSubDistricts_(AdminProvinceID, AdminDistrictID).then(async dataAdminSubDistrictAndSubDistrict => {
      resAdminSubDistrictAndSubDistrict = dataAdminSubDistrictAndSubDistrict;
      // console.log("Data >>> ", resAdminSubDistrictAndSubDistrict);
      // this.dataAdminSubDistricts = resAdminSubDistrictAndSubDistrict;
      if (resAdminSubDistrictAndSubDistrict.length != 0) {
      for (let i = 0; i < resAdminSubDistrictAndSubDistrict.length; i++) {
        await this.ServiceService.GetListOperatorInAdmin_(resAdminSubDistrictAndSubDistrict[i].admin_Id).then(async resAdminGetOperators => {
          dataAdminGetOperators = resAdminGetOperators;
          // console.log(dataAdminGetOperators);
          var cuntJob: number = 0;
          if (dataAdminGetOperators.length != 0) {
            for (let j = 0; j < dataAdminGetOperators.length; j++) {

              var resAdminProvince: any;

              await this.ServiceService.GetAdminProvince_(resAdminSubDistrictAndSubDistrict[i].admin_Id).then(async dataAdminProvince => {
                resAdminProvince = dataAdminProvince;
                var parseJsonAdminProvince = JSON.parse(resAdminProvince);

                for (let l = 0; l < this.dataProvinceNameTHEN.length; l++) {
                  if (this.dataProvinceNameTHEN[l].idProvince == parseJsonAdminProvince[0].admin_ProvinceId) {
                    await this.ServiceService.GetJobToday_("AdminSubdistricts", this.dataProvinceNameTHEN[l].englishAbbreviation).then(resJobOperator => {
                      var dataJobOperator: any = [];
                      dataJobOperator = resJobOperator;
                      // console.log(dataJobOperator.length);

                      cuntJob = cuntJob + dataJobOperator.length;

                    });
                    break;
                  }
                  if (j == dataAdminGetOperators.length - 1) {
                    dataOperator.push({ dataAdmin: resAdminSubDistrictAndSubDistrict[i], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
                  }
                }
              });
            }
          } else {
            dataOperator.push({ dataAdmin: resAdminSubDistrictAndSubDistrict[i], dataOparator: { dataOparatorInfo: resAdminGetOperators, countJobOperator: cuntJob } });
          }
        });
        if (i == resAdminSubDistrictAndSubDistrict.length - 1) {
          this.dataAdminSubDistricts = dataOperator;
          setInterval(() => {
            this.loadDataSuccess = true;
            this.loadData = false;
          }, 2000);
        }
      }
    }else{
      setInterval(() => {
        this.loadDataSuccess = true;
        this.loadData = false;
      }, 2000);
    }
    }, (err) => {
      console.log(" : : ", err);
    });
  }

  loadAdminHeaderDistricts(AdminDistrict: string, AdminDistrictID: string) {
    var resAdminDistrictAndSubDistrict:any;
    this.ServiceService.GetAdminDistrictAndSubDistrict_(AdminDistrict, AdminDistrictID).then(dataAdminDistrictAndSubDistrict => {
      resAdminDistrictAndSubDistrict = dataAdminDistrictAndSubDistrict;
      var parseJson = JSON.parse(resAdminDistrictAndSubDistrict);
      // console.log("Header Data >>> ", parseJson);
      this.dataAdminDistrictName = parseJson[0].Admin_Username;
    }, (err) => {
      console.log(" : : ", err);
    });
  }

  deleteAdmin(idProvince: string, idParent: string, index: any) {
    var resRemoveAdmins :any;
    if (window.confirm("Do you want Remove Admin? ")) {
      this.ServiceService.removeAdmins_(idProvince, idParent).then((dataRemoveAdmins) => {
        resRemoveAdmins = dataRemoveAdmins;
        // console.log("remon ----> ", resRemoveAdmins);

        this.dataAdminSubDistricts.splice(index, 1);

      })
    }
  }

}

interface Province {
  value: string;
  viewValue: string;
}
interface District {
  value: string;
  viewValue: string;
}
interface Subdistrict {
  value: string;
  viewValue: string;
}
interface RoleAdmin {
  value: string;
  viewValue: string;
}
export interface DialogData {
  id: string;
  parrent: string;
}

@Component({
  selector: 'addAdminSubDistrict-modal',
  templateUrl: './addAdminSubDistrict-modal.html',
})
export class DialogAddAdminSubDistrict {
  hide = true;
  addAdminDistrictForm!: FormGroup;

  InvalidUsername = false;

  dataProvince: any = 0;
  dataSubdistricts: any = 0;

  provinces: Province[] = [];
  districts: District[] = [];
  subdistricts: Subdistrict[] = [];
  roleAdmins: RoleAdmin[] = [
    { value: 'AdminSubdistricts', viewValue: 'Admin Subdistricts' }
  ];

  selectedProvince!: number;
  selectedDistrict!: number;
  selectedSubdistrict!: number;

  nameProvince!: string;
  // nameDistricts!: string;
  idProvince!: string;
  idDistrict!: string;
  idSubDistrict!: string;
  districtID!: string;
  nameSubdistricts!: string;
  dataConvert!: any;

  dataConvertAddAdmin: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogAddAdminSubDistrict>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private ServiceService: ServiceService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.loadDataDistrict(this.data.id, this.data.parrent);
    this.addAdminDistrictForm = this.formBuilder.group({
      username: '',
      // password: '',
      // confirmPassword: '',
      // address: '',
      // province: '',
      // district: '',
      subdistrict: '',
      // postalcode: '',
      adminRole: '',
      phone: ''
    })
  }

  onSubmit(): any {
    if (this.addAdminDistrictForm.value.username != "" &&
      // this.addAdminDistrictForm.value.address != "" &&
      this.nameSubdistricts != "undefined" &&
      this.addAdminDistrictForm.value.postalcode != "" &&
      this.addAdminDistrictForm.value.adminRole != "" &&
      this.addAdminDistrictForm.value.phone != ""
    ) {
      this.dataConvert = {
        AdminUsername: this.addAdminDistrictForm.value.username,
        AdminPassword: "123456",
        AdminProvinceID: this.idProvince,
        AdminDistrictID: this.idDistrict,
        AdminSubDistrictID: this.idSubDistrict,
        AdminNamerole: this.nameSubdistricts,
        AdminParrent: `${this.data.parrent}${this.districtID}_`,
        AdminRole: this.addAdminDistrictForm.value.adminRole,
        AdminPhone: this.addAdminDistrictForm.value.phone
      };
      // console.log(this.dataConvert);


      this.ServiceService.AddAdmin(this.dataConvert).subscribe((idAdmin) => {
        // console.log("Data added successfully", idAdmin);
        if(idAdmin != "Duplicate Account"){
          this.dataConvertAddAdmin = [
            {
              admin_Id: idAdmin,
              admin_Username: this.addAdminDistrictForm.value.username,
              admin_Password: "123456",
              admin_ProvinceId: this.idProvince,
              admin_DistrictId: this.idDistrict,
              admin_SubDistrictId: this.idSubDistrict,
              admin_Namerole: this.nameSubdistricts,
              admin_Parrent: `${this.data.parrent}${this.districtID}_`,
              admin_Role: this.addAdminDistrictForm.value.adminRole,
              admin_Phone: this.addAdminDistrictForm.value.phone
            }
          ]
          this.dialogRef.close(this.dataConvertAddAdmin);
        }else{
          this.InvalidUsername = true;
        }
      }, (err) => {
        console.log(" : : ", err);
      })
    }
  }

  loadDataDistrict(id: string, parrent: string) {
    var resAdminHeaderSubDistricts:any;
    this.ServiceService.GetAdminHeaderDistricts_(parrent, id).then(dataAdminHeaderSubDistricts => {
      resAdminHeaderSubDistricts = dataAdminHeaderSubDistricts;
      var parseJson = JSON.parse(resAdminHeaderSubDistricts);
      // console.log("res >> ", parseJson);
      // console.log("res00 ---------->> ", res[0].Admin_ProvinceID);
      this.districtID = parseJson[0].admin_Id;

      this.loadSubdistrict(parseJson[0].admin_DistrictId);
    });
  }


  loadSubdistrict(selectedDistrict: number) {
    // console.log("admin_DistrictId---------> ",selectedDistrict);

    this.subdistricts = [];
    var resSubdistrict:any;
    this.ServiceService.GetSubdistrict_(selectedDistrict).then(dataSubdistrict => {
      resSubdistrict = dataSubdistrict;
      // var parseJson = JSON.parse(res);
      // console.log("----------------- >> ", res);
      this.dataSubdistricts = resSubdistrict;
      for (let i = 0; i < this.dataSubdistricts.length; i++) {
        this.subdistricts.push({ value: this.dataSubdistricts[i].districtCode, viewValue: this.dataSubdistricts[i].districtName })
      }
    });
  }


  onSubdistrictSelection() {
    for (let i = 0; i < this.dataSubdistricts.length; i++) {
      if (this.selectedSubdistrict == this.dataSubdistricts[i].districtCode) {
        this.nameSubdistricts = this.dataSubdistricts[i].districtName;
        this.idProvince = this.dataSubdistricts[i].provinceId;
        this.idDistrict = this.dataSubdistricts[i].amphurId;
        this.idSubDistrict = this.dataSubdistricts[i].districtId;
      }
    }
  }

}
