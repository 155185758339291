import { Injectable } from '@angular/core';

@Injectable()
export class GlobalConstants {

    public dataSos: any =[];
    public static imageUrl1: string = "https://api.medilifewellness.com:1243";
    public static imageUrl2: string = "https://api.medilifewellness.com:2443";
    public static imageUrl3: string = "https://api.medilifewellness.com:2477";
    public static socket: string = "https://connect.medilifewellness.com:2422";

    // public static socket: string = "https://uat-socket.medilifewellness.com";
}
