<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 style="text-align: center;"> Dispense Medicine </h1>
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content pl-2 pr-2">
    <div class="mat-elevation-z8 card card-solid">
        <div class="card-body pb-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-10" style="margin: auto;"></div>
                    <div class="col-2" style="text-align: end;">
                        <button mat-raised-button style="width: 120px;" color="primary" (click)="onClickRefresh()"><i
                                class="fas fa-redo-alt"></i> Refresh</button>
                    </div>
                </div>
            </div>
            <hr style="margin-top: 8px;">
            <div class="row mb-3">
                <div class="col-6">
                    <div *ngIf="resultDataCovidDispense != null && resultDataCovidDispense != ''"
                        style="text-align: start; margin-bottom: auto;margin-top: auto;padding-left: 20px;font-size: 22px;font-weight: 500;">
                        <img *ngIf="resultDataCovidDispense[0].userPic != null"
                            src="{{imageUrl2}}/{{resultDataCovidDispense[0].userPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'" width="30px"
                            height="30px" style="object-fit: cover; border-radius: 50%;">
                        <img *ngIf="resultDataCovidDispense[0].userPic == null"
                            src="assets/adminlte/dist/img/nouser.svg" width="30px" height="30px"
                            style="object-fit: cover; border-radius: 50%;">
                        <span> {{ resultDataCovidDispense[0].userName }} </span>
                        <span *ngIf="dataPrescription == -1" style="color: #3f51b5;"> {{resultDataCovidDispense.length}}
                            Records </span>
                        <span *ngIf="dataPrescription == 1" style="color: #3f51b5;">
                            {{resultDataCovidDispenseTH.length}} Records </span>
                    </div>
                </div>
                <div class="col-6" style="text-align: end;padding-right: 18px;">

                    <!-- <button mat-icon-button style="margin-right: 5px;" (click)="onClickShowData('card')">
                        <mat-icon>apps</mat-icon>
                    </button>
                    <button mat-icon-button style="margin-right: 5px;" (click)="onClickShowData('table')">
                        <mat-icon>menu</mat-icon>
                    </button> -->

                    <mat-form-field appearance="outline">
                        <mat-label>Select Show Item</mat-label>
                        <mat-select [(value)]="selected" (selectionChange)="onChangeCountItem(selected)">
                            <mat-option value="20">20-Item</mat-option>
                            <mat-option value="50">50-Item</mat-option>
                            <mat-option value="100">100-Item</mat-option>
                            <mat-option value="2000">Show-Alll</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-body pb-0" style="padding: 0px;">

                <div class="row" *ngIf="loadDataUser == true">
                    <div class="col-12">
                        <mat-spinner
                            style="margin-left: auto;margin-right: auto;margin-top: 110px;margin-bottom: 110px;">
                        </mat-spinner>
                    </div>
                </div>


                <!-- <div class="row" *ngIf="cardShow == true">
                    <div *ngFor="let itemUser of resultDataCovidDispense;let i = index;"
                        class="fadeIn col-12 col-sm-6 col-md-3 d-flex align-items-stretch flex-column ">
                        <div class="card bg-light d-flex flex-fill mat-elevation-z8">
                            <div class="card-body pt-0 pb-0">
                                <div class="row mt-3 mb-1">
                                    <div class="col-7">
                                        <h2 class="lead"><b>{{itemUser.userName}}</b></h2>
                                        <p class="text-muted text-sm"><b>Start date: </b> {{itemUser.dmRequestTime |
                                            date:'short'}}</p>
                                        <p class="text-muted text-sm"><b>Last update: </b> {{itemUser.dmLastUpdate
                                            | date:'short'}}</p>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                            <li class="small">
                                                <span class="fa-li">
                                                    <i class="fas fa-lg fa-building"></i>
                                                </span> <span class="line-clamp"> Address: {{itemUser.userAddress}}
                                                    {{itemUser.userSubdistrict}} {{itemUser.userDistrict}}
                                                    {{itemUser.userProvince}} {{itemUser.userPostalcode}}</span>
                                            </li>
                                            <li class="small"><span class="fa-li"><i
                                                        class="fas fa-lg fa-phone"></i></span> Phone :
                                                {{itemUser.userPhone}}</li>
                                        </ul>
                                    </div>
                                    <div class="col-5 text-center">
                                        <div
                                            *ngIf="itemUser.userPic != null && itemUser.userPic != ''; else elseImgBlock">
                                            <img src="{{imageUrl2}}/{{itemUser.userPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                alt="user-avatar" class="img-circle img-fluid"
                                                style="width: 136px;height: 136px;object-fit: cover;">
                                        </div>
                                        <ng-template #elseImgBlock>
                                            <img src="assets/adminlte/dist/img/nouser.svg" alt="user-avatar"
                                                class="img-circle img-fluid" style="width: 136px;height: 136px;">
                                        </ng-template>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12" style="font-size: 18px;">
                                        <div class="row"
                                            style="display: flex;justify-content: flex-end;margin-right: 15px;">
                                            <mat-icon style="font-size: 20px;"> show_chart</mat-icon> <b>-</b>
                                            <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                                    class="fas fa-heartbeat"></i></mat-icon> <b>-</b>
                                            <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                                    class="fas fa-temperature-low"></i></mat-icon> <b>-</b>
                                            <mat-icon style="font-size: 20px;padding-left: 8px;">bubble_chart</mat-icon>
                                            <b>-</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer" style="padding: 10px;">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6" style="margin: auto;">
                                            <div class="row" style="display: flex;justify-content: flex-start;">
                                                <span style="margin-right: 8px;">Symptom Today</span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                                    *ngIf="itemUser.dmCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                                    *ngIf="itemUser.dmCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                                    *ngIf="itemUser.dmCovidStatus == 'Red'"></span>
                                            </div>
                                        </div>
                                        <div class="col-6" style="text-align: end;">
                                            <button mat-raised-button style="width: 120px;" color="accent" (click)="openDialogConfirm(itemUser,i)"><i  class="fas fa-mouse-pointer"></i> Select</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->


                <mat-tab-group mat-align-tabs="start" (focusChange)="myTabFocusChange($event)" *ngIf="loadDataUser == false">
                    <mat-tab label="Medicine">
                        <div class="withscroll" *ngIf="tableShow == true" >
                            <table class="table" style="text-align: left;">
                                <thead>
                                    <tr>
                                        <th scope="col" width="9%">#</th>
                                        <th scope="col" width="12%">Rx</th>
                                        <th scope="col" width="15%">Favi</th>
                                        <th scope="col" width="15%">Dexa</th>
                                        <th scope="col" width="15%">Prednisolone</th>
                                        <th scope="col" width="15%">Omeprazole</th>
                                        <th scope="col" width="12%">Oxygen</th>
                                        <th scope="col" width="12%">Others</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let itemUser of resultDataCovidDispense;let i = index;" class="fadeIn">
                                    <tr>
                                        <th scope="row" class="text-left">
                                            ครั้งที่ {{ (resultDataCovidDispense.length) -i }}
                                            <p class="nospace mt-1">
                                                <span *ngIf="itemUser.medPres.mpStatus == 'request'"
                                                    style="padding: 3px; border:1px solid #00aeff; border-radius: 5px; background-color: #e4f0f7; color: #222; font-weight: 500;">กำลังส่งคำขอ</span>
                                                <span *ngIf="itemUser.medPres.mpStatus == 'waiting'"
                                                    style="padding: 3px; border:1px solid #FFA900; border-radius: 5px; background-color: #fffaf0; color: #222; font-weight: 500;">กำลังจัดเตรียมยา</span>
                                                <span *ngIf="itemUser.medPres.mpStatus == 'accept'"
                                                    style="padding: 3px; border:1px solid #00B74A; border-radius: 5px; background-color: #effff5; color: #222; font-weight: 500;">จ่ายยาแล้ว</span>
                                            </p>
                                        </th>
                                        <td>
                                            <span *ngIf="itemUser.medPres.mpFavi == true"
                                                style="background-color: #1266F1;" class="badge span16">Favi</span>
                                            <span *ngIf="itemUser.medPres.mpDexa == true"
                                                style="background-color: #B23CFD;" class="badge span16">Dexa</span>
                                            <span *ngIf="itemUser.medPres.mpPrednisolone == true"
                                                style="background-color: #00B74A;"
                                                class="badge span16">Prednisolone</span>
                                            <span *ngIf="itemUser.medPres.mpOmeprazole == true"
                                                style="background-color: #FF3D00;"
                                                class="badge span16">Omeprazole</span>
                                            <span *ngIf="itemUser.medPres.mpOxygen == true"
                                                style="background-color: #39C0ED;" class="badge span16">Oxygen</span>
                                            <span *ngIf="itemUser.medPres.mpOthers == true"
                                                style="background-color: #212121;" class="badge span16">Others</span>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mpFavi != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mpFavi == true">
                                            <div style="border: 1px solid #1266F1; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #e0ecff; color: #1266F1;"
                                                    class="nospace pfstyle">Precription Date</p>
                                                <p class="nospace mt-1 ml-1"
                                                    *ngIf="itemUser.medPres.mpFaviPrecriptionDate != null">
                                                    {{itemUser.medPres.mpFaviPrecriptionDate | date:'short'}}</p>
                                                <p *ngIf="itemUser.medPres.mpFaviPrecriptionDate == null"
                                                    class="nospace mt-1 ml-1">-</p>

                                                <p style="background-color: #e0ecff; color: #1266F1;"
                                                    class="nospace mt-2 pfstyle">Start Date (Ship)</p>
                                                <p *ngIf="itemUser.medPres.mpFaviStartDate != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpFaviStartDate |
                                                    date:'short'}}</p>
                                                <p *ngIf="itemUser.medPres.mpFaviStartDate == null"
                                                    class="nospace mt-1 ml-1">- </p>

                                                <div *ngIf="itemUser.medPres.mpFaviDay != null">
                                                    <div *ngIf="itemUser.medPres.mpFaviDay == 'f5d'">
                                                        <p style="background-color: #e0ecff; color: #1266F1;"
                                                            class="nospace mt-2 pfstyle">First 5 Day</p>
                                                        <div *ngIf="itemUser.medPres.mpFaviDayWeight != null">
                                                            <p *ngIf="itemUser.medPres.mpFaviDayWeight == 'W<90'"
                                                                class="nospace mt-1 ml-1">Less Weight 90 kg.</p>
                                                            <p *ngIf="itemUser.medPres.mpFaviDayWeight == 'W>=90'"
                                                                class="nospace mt-1 ml-1">Over Weight 90 kg.</p>
                                                        </div>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayWeight == null"
                                                            class="nospace mt-1 ml-1"> - </p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayStart != null"
                                                            class="nospace mt-1 ml-1"><b>Start</b>
                                                            {{itemUser.medPres.mpFaviDayStart | date:'short'}}</p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayStart == null"
                                                            class="nospace mt-1 ml-1"><b>Start</b> - </p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayEnd != null"
                                                            class="nospace mt-1 ml-1"><b>End</b>
                                                            {{itemUser.medPres.mpFaviDayEnd | date:'short'}}</p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayEnd == null"
                                                            class="nospace mt-1 ml-1"><b>End</b> - </p>
                                                    </div>
                                                    <div *ngIf="itemUser.medPres.mpFaviDay == 'n5d'">
                                                        <p style="background-color: #e0ecff; color: #1266F1;"
                                                            class="nospace mt-2 pfstyle">Next 5 Day</p>
                                                        <div *ngIf="itemUser.medPres.mpFaviDayWeight != null">
                                                            <p *ngIf="itemUser.medPres.mpFaviDayWeight == 'W<90'"
                                                                class="nospace mt-1 ml-1">Less Weight 90 kg.</p>
                                                            <p *ngIf="itemUser.medPres.mpFaviDayWeight == 'W>=90'"
                                                                class="nospace mt-1 ml-1">Over Weight 90 kg.</p>
                                                        </div>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayWeight == null"
                                                            class="nospace mt-1 ml-1"> - </p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayStart != null"
                                                            class="nospace mt-1 ml-1"><b>Start</b>
                                                            {{itemUser.medPres.mpFaviDayStart | date:'short'}}</p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayStart == null"
                                                            class="nospace mt-1 ml-1"><b>Start</b> - </p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayEnd != null"
                                                            class="nospace mt-1 ml-1"><b>End</b>
                                                            {{itemUser.medPres.mpFaviDayEnd | date:'short'}}</p>
                                                        <p *ngIf="itemUser.medPres.mpFaviDayEnd == null"
                                                            class="nospace mt-1 ml-1"><b>End</b> - </p>
                                                    </div>
                                                </div>

                                                <p style="background-color: #e0ecff; color: #1266F1;"
                                                    class="nospace mt-2 pfstyle">Favi Note</p>
                                                <p *ngIf="itemUser.medPres.mpFaviNote != ''" class="nospace mt-1 ml-1">
                                                    {{itemUser.medPres.mpFaviNote}} </p>
                                                <p *ngIf="itemUser.medPres.mpFaviNote == ''" class="nospace mt-1 ml-1">
                                                    - </p>

                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mpDexa != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mpDexa == true">
                                            <div style="border: 1px solid #B23CFD; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #f7e9ff; color: #B23CFD;"
                                                    class="nospace pfstyle">Precription Date</p>
                                                <p class="nospace mt-1 ml-1"
                                                    *ngIf="itemUser.medPres.mpDexaPrecriptionDate != null">
                                                    {{itemUser.medPres.mpDexaPrecriptionDate | date:'short'}}</p>
                                                <p *ngIf="itemUser.medPres.mpDexaPrecriptionDate == null"
                                                    class="nospace mt-1 ml-1">-</p>

                                                <p style="background-color: #f7e9ff; color: #B23CFD;"
                                                    class="nospace mt-2 pfstyle">Start Date (Ship)</p>
                                                <p *ngIf="itemUser.medPres.mpDexaStartDate != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpDexaStartDate |
                                                    date:'short'}}</p>
                                                <p *ngIf="itemUser.medPres.mpDexaStartDate == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                                <p style="background-color: #f7e9ff; color: #B23CFD;"
                                                    class="nospace mt-2 pfstyle">Quantity</p>
                                                <p *ngIf="itemUser.medPres.mpDexaMg != null" class="nospace mt-1 ml-1">
                                                    {{itemUser.medPres.mpDexaMg}} mg</p>
                                                <p *ngIf="itemUser.medPres.mpDexaMg == null" class="nospace mt-1 ml-1">
                                                    - mg</p>

                                                <p style="background-color: #f7e9ff; color: #B23CFD;"
                                                    class="nospace mt-2 pfstyle">Frequency</p>
                                                <p *ngIf="itemUser.medPres.mpDexaFrequency != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpDexaFrequency}}
                                                    times</p>
                                                <p *ngIf="itemUser.medPres.mpDexaFrequency == null"
                                                    class="nospace mt-1 ml-1"> - times</p>

                                                <p style="background-color: #f7e9ff; color: #B23CFD;"
                                                    class="nospace mt-2 pfstyle">Days</p>
                                                <p *ngIf="itemUser.medPres.mpDexaDay != null" class="nospace mt-1 ml-1">
                                                    {{itemUser.medPres.mpDexaDay}} Days</p>
                                                <p *ngIf="itemUser.medPres.mpDexaDay == null" class="nospace mt-1 ml-1">
                                                    - Days</p>

                                                <p style="background-color: #f7e9ff; color: #B23CFD;"
                                                    class="nospace mt-2 pfstyle">Dexa Note</p>
                                                <p *ngIf="itemUser.medPres.mpDexaNote != ''" class="nospace mt-1 ml-1">
                                                    {{itemUser.medPres.mpDexaNote}} </p>
                                                <p *ngIf="itemUser.medPres.mpDexaNote == ''" class="nospace mt-1 ml-1">
                                                    - </p>

                                            </div>
                                        </td>



                                        <td *ngIf="itemUser.medPres.mpPrednisolone != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mpPrednisolone == true">
                                            <div style="border: 1px solid #00B74A; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #f0fff6; color: #00B74A;"
                                                    class="nospace pfstyle">Precription Date</p>
                                                <p class="nospace mt-1 ml-1"
                                                    *ngIf="itemUser.medPres.mpPrednisolonePrecriptionDate != null">
                                                    {{itemUser.medPres.mpPrednisolonePrecriptionDate | date:'short'}}
                                                </p>
                                                <p *ngIf="itemUser.medPres.mpPrednisolonePrecriptionDate == null"
                                                    class="nospace mt-1 ml-1">-</p>

                                                <p style="background-color: #f0fff6; color: #00B74A;"
                                                    class="nospace mt-2 pfstyle">Start Date (Ship)</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneStartDate != null"
                                                    class="nospace mt-1 ml-1">
                                                    {{itemUser.medPres.mpPrednisoloneStartDate | date:'short'}}</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneStartDate == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                                <p style="background-color: #f0fff6; color: #00B74A;"
                                                    class="nospace mt-2 pfstyle">Quantity</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneMg != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpPrednisoloneMg}} mg
                                                </p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneMg == null"
                                                    class="nospace mt-1 ml-1"> - mg</p>

                                                <p style="background-color: #f0fff6; color: #00B74A;"
                                                    class="nospace mt-2 pfstyle">Frequency</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneFrequency != null"
                                                    class="nospace mt-1 ml-1">
                                                    {{itemUser.medPres.mpPrednisoloneFrequency}} times</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneFrequency == null"
                                                    class="nospace mt-1 ml-1"> - times</p>

                                                <p style="background-color: #f0fff6; color: #00B74A;"
                                                    class="nospace mt-2 pfstyle">Days</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneDay != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpPrednisoloneDay}}
                                                    Days</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneDay == null"
                                                    class="nospace mt-1 ml-1"> - Days</p>

                                                <p style="background-color: #f0fff6; color: #00B74A;"
                                                    class="nospace mt-2 pfstyle">Prednisolone Note</p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpPrednisoloneNote}}
                                                </p>
                                                <p *ngIf="itemUser.medPres.mpPrednisoloneNote == ''"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>



                                        <td *ngIf="itemUser.medPres.mpOmeprazole != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mpOmeprazole == true">
                                            <div style="border: 1px solid #FF3D00; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #fffaf1; color: #FF3D00;"
                                                    class="nospace pfstyle">Precription Date</p>
                                                <p class="nospace mt-1 ml-1"
                                                    *ngIf="itemUser.medPres.mpOmeprazolePrecriptionDate != null">
                                                    {{itemUser.medPres.mpOmeprazolePrecriptionDate | date:'short'}}</p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazolePrecriptionDate == null"
                                                    class="nospace mt-1 ml-1">-</p>

                                                <p style="background-color: #fffaf1; color: #FF3D00;"
                                                    class="nospace mt-2 pfstyle">Start Date (Ship)</p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleStartDate != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpOmeprazoleStartDate
                                                    | date:'short'}}</p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleStartDate == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                                <p style="background-color: #fffaf1; color: #FF3D00;"
                                                    class="nospace mt-2 pfstyle">Quantity</p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleMg != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpOmeprazoleMg}} mg
                                                </p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleMg == null"
                                                    class="nospace mt-1 ml-1"> - mg</p>

                                                <p style="background-color: #fffaf1; color: #FF3D00;"
                                                    class="nospace mt-2 pfstyle">Days</p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleDay != null"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpOmeprazoleDay}} Days
                                                </p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleDay == null"
                                                    class="nospace mt-1 ml-1"> - Days</p>

                                                <p style="background-color: #fffaf1; color: #FF3D00;"
                                                    class="nospace mt-2 pfstyle">Omeprazole Note</p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpOmeprazoleNote}}
                                                </p>
                                                <p *ngIf="itemUser.medPres.mpOmeprazoleNote == ''"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>



                                        <td *ngIf="itemUser.medPres.mpOxygen != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mpOxygen == true">
                                            <div style="border: 1px solid #39C0ED; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #effbff; color: #39C0ED;"
                                                    class="nospace pfstyle">Oxygen Note</p>
                                                <p *ngIf="itemUser.medPres.mpOxygenNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpOxygenNote}} </p>
                                                <p *ngIf="itemUser.medPres.mpOxygenNote == ''"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mpOthers != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mpOthers == true">
                                            <div style="border: 1px solid #212121; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #f0f0f0; color: #212121;"
                                                    class="nospace pfstyle">Others Note</p>
                                                <p *ngIf="itemUser.medPres.mpOthersNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpOthersNote}} </p>
                                                <p *ngIf="itemUser.medPres.mpOthersNote == ''"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-md-12" *ngIf="noDataInformation == true">
                                <div class="mb-2">
                                    <div style="display: flex;">
                                        <div class="container rubberBand"
                                            style="color: #bdbbb7;margin-top: 100px;margin-bottom: 100px;text-align: center;">
                                            <i class="fas fa-calendar-times" style="font-size: 50px;"></i>
                                            <p>No Notification.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Medicine Thai">
                        <div class="row" *ngIf="tableShowTH == true">
                            <table class="table" style="text-align: left;">
                                <thead>
                                    <tr>
                                        <th scope="col" width="9%">#</th>
                                        <th scope="col" width="10%">Rx</th>
                                        <th scope="col">STATIA C Z</th>
                                        <th scope="col">STATIA</th>
                                        <th scope="col">5 ราก</th>
                                        <th scope="col">ยาเขียว</th>
                                        <th scope="col">ยาอํามฤควาที</th>
                                        <th scope="col">ยาประสะเปราะใหญ่</th>
                                        <th scope="col">ยาจันทน์ลีลา</th>
                                        <th scope="col">ยาหอมเย็น</th>
                                        <th scope="col">ยาหอมร้อน</th>
                                        <th scope="col">ฟ้าทลายโจร</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let itemUser of resultDataCovidDispenseTH;let i = index;" class="fadeIn">
                                    <tr>
                                        <th scope="row" class="text-left">
                                            ครั้งที่ {{ (resultDataCovidDispenseTH.length) -i }}
                                            <p class="nospace mt-1">
                                                <span *ngIf="itemUser.medPres.mptStatus == 'request'"
                                                    style="padding: 3px; border:1px solid #00aeff; border-radius: 5px; background-color: #e4f0f7; color: #222; font-weight: 500;">กำลังส่งคำขอ</span>
                                                <span *ngIf="itemUser.medPres.mptStatus == 'waiting'"
                                                    style="padding: 3px; border:1px solid #FFA900; border-radius: 5px; background-color: #fffaf0; color: #222; font-weight: 500;">กำลังจัดเตรียมยา</span>
                                                <span *ngIf="itemUser.medPres.mptStatus == 'accept'"
                                                    style="padding: 3px; border:1px solid #00B74A; border-radius: 5px; background-color: #effff5; color: #222; font-weight: 500;">จ่ายยาแล้ว</span>
                                            </p>
                                        </th>
                                        <td>
                                            <span *ngIf="itemUser.medPres.mptStatiaCz == true"
                                                style="background-color: #006400;" class="badge span16">STATIA C
                                                Z</span>
                                            <span *ngIf="itemUser.medPres.mptStatia == true"
                                                style="background-color: #DAA520;" class="badge span16">STATIA</span>
                                            <span *ngIf="itemUser.medPres.mpt5roots == true"
                                                style="background-color: #6B8E23;" class="badge span16">5 ราก</span>
                                            <span *ngIf="itemUser.medPres.mptGreenMedicine == true"
                                                style="background-color: #D2691E;" class="badge span16">ยาเขียว</span>
                                            <span *ngIf="itemUser.medPres.mptAummahiva == true"
                                                style="background-color: #FA8072;"
                                                class="badge span16">ยาอํามฤควาที</span>
                                            <span *ngIf="itemUser.medPres.mptPrasapoyai == true"
                                                style="background-color: #D2B48C;"
                                                class="badge span16">ยาประสะเปราะใหญ่</span>
                                            <span *ngIf="itemUser.medPres.mptJanlelar == true"
                                                style="background-color: #DA70D6;"
                                                class="badge span16">ยาจันทน์ลีลา</span>
                                            <span *ngIf="itemUser.medPres.mptHomyen == true"
                                                style="background-color: #357ec2;" class="badge span16">ยาหอมเย็น</span>
                                            <span *ngIf="itemUser.medPres.mptHomron == true"
                                                style="background-color: #556B2F;" class="badge span16">ยาหอมร้อน</span>
                                            <span *ngIf="itemUser.medPres.mptFatalaijon == true"
                                                style="background-color: #a0358e;"
                                                class="badge span16">ฟ้าทลายโจร</span>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mptStatiaCz != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptStatiaCz == true" style="width:100px">
                                            <div style="border: 1px solid #006400; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #e2f1e2; color: #006400;"
                                                    class="nospace pfstyle">STATIA C Z</p>
                                                <p *ngIf="itemUser.medPres.mptStatiaCz != ''" class="nospace mt-1 ml-1">
                                                    บำรุงตับบำรุงระบบภูมิคุ้มกันร่างกายและ ขับเชื้อโรคออกจากร่างกาย </p>

                                                <p style="background-color: #e2f1e2; color: #006400;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptStatiaCzNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptStatiaCzNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptStatiaCzNote == '' || itemUser.medPres.mptStatiaCzNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>

                                        <td *ngIf="itemUser.medPres.mptStatia != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptStatia == true">
                                            <div style="border: 1px solid #DAA520; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #fdf5df; color: #DAA520;"
                                                    class="nospace pfstyle">STATIA </p>
                                                <p *ngIf="itemUser.medPres.mptStatia != ''" class="nospace mt-1 ml-1">
                                                    ขับเสมหะออกจากปอด เสริมภูมิคุ้มกันบรรเทาอาการไอปวดเมื่อยร่างกาย</p>

                                                    <p style="background-color: #fdf5df; color: #DAA520;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptStatiaNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptStatiaNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptStatiaNote == '' || itemUser.medPres.mptStatiaNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mpt5roots != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mpt5roots == true">
                                            <div style="border: 1px solid #6B8E23; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #f7fdec; color: #6B8E23;"
                                                    class="nospace pfstyle">5 ราก</p>
                                                <p *ngIf="itemUser.medPres.mpt5roots != ''" class="nospace mt-1 ml-1">
                                                    กระทงเวลาที่เกาะตามอวัยวะ ลดไข้ต้านการอักเสบ </p>

                                                    <p style="background-color: #f7fdec; color: #6B8E23;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mpt5rootsNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mpt5rootsNote}} </p>
                                                <p *ngIf="itemUser.medPres.mpt5rootsNote == '' || itemUser.medPres.mpt5rootsNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mptGreenMedicine != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptGreenMedicine == true">
                                            <div style="border: 1px solid #D2691E; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #fff4eb; color: #D2691E;"
                                                    class="nospace pfstyle">ยาเขียว</p>
                                                <p *ngIf="itemUser.medPres.mptGreenMedicine != ''"
                                                    class="nospace mt-1 ml-1">
                                                    บรรเทาอาการไข้ร้อนในกระหายน้ำ(ช่วยกระทุ้งไวรัสและขับออกแบบอ่อนๆ)
                                                </p>

                                                <p style="background-color: #fff4eb; color: #D2691E;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptGreenMedicineNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptGreenMedicineNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptGreenMedicineNote == '' || itemUser.medPres.mptGreenMedicineNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>


                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mptAummahiva != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptAummahiva == true">
                                            <div style="border: 1px solid #FA8072; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #ffefee; color: #FA8072;"
                                                    class="nospace pfstyle">ยาอํามฤควาที</p>
                                                <p *ngIf="itemUser.medPres.mptAummahiva != ''"
                                                    class="nospace mt-1 ml-1"> ขับเสมหะบรรเทาอาการไอ </p>

                                                    <p style="background-color: #ffefee; color: #FA8072;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptAummahivaNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptAummahivaNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptAummahivaNote == '' || itemUser.medPres.mptAummahivaNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>

                                        <td *ngIf="itemUser.medPres.mptPrasapoyai != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptPrasapoyai == true">
                                            <div style="border: 1px solid #D2B48C; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #fff8f0; color: #D2B48C;"
                                                    class="nospace pfstyle">ยาประสะเปราะใหญ่</p>
                                                <p *ngIf="itemUser.medPres.mptPrasapoyai != ''"
                                                    class="nospace mt-1 ml-1"> ลดอาการจามน้ำมูกไหล </p>

                                                    <p style="background-color: #fff8f0; color: #D2B48C;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptPrasapoyaiNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptPrasapoyaiNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptPrasapoyaiNote == '' || itemUser.medPres.mptPrasapoyaiNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>

                                        <td *ngIf="itemUser.medPres.mptJanlelar != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptJanlelar == true">
                                            <div style="border: 1px solid #DA70D6; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #fff0fe; color: #DA70D6;"
                                                    class="nospace pfstyle">ยาจันทน์ลีลา</p>
                                                <p *ngIf="itemUser.medPres.mptJanlelar != ''" class="nospace mt-1 ml-1">
                                                    แก้ไข้ตัวร้อน </p>

                                                    <p style="background-color: #fff0fe; color: #DA70D6;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptJanlelarNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptJanlelarNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptJanlelarNote == '' || itemUser.medPres.mptJanlelarNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mptHomyen != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptHomyen == true">
                                            <div style="border: 1px solid #357ec2; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #eaf5ff; color: #357ec2;"
                                                    class="nospace pfstyle">ยาหอมเย็น</p>
                                                <p *ngIf="itemUser.medPres.mptHomyen != ''" class="nospace mt-1 ml-1">
                                                    บำรุงหัวใจ </p>

                                                    <p style="background-color: #eaf5ff; color: #357ec2;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptHomyenNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptHomyenNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptHomyenNote == '' || itemUser.medPres.mptHomyenNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mptHomron != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptHomron == true">
                                            <div style="border: 1px solid #556B2F; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #f9fff0; color: #556B2F;"
                                                    class="nospace pfstyle">ยาหอมร้อน</p>
                                                <p *ngIf="itemUser.medPres.mptHomron != ''" class="nospace mt-1 ml-1">
                                                    กระตุ้นประสาท </p>

                                                    <p style="background-color: #f9fff0; color: #556B2F;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptHomronNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptHomronNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptHomronNote == '' || itemUser.medPres.mptHomronNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>


                                        <td *ngIf="itemUser.medPres.mptFatalaijon != true"> - </td>
                                        <td *ngIf="itemUser.medPres.mptFatalaijon == true">
                                            <div style="border: 1px solid #a0358e; padding: 3px; border-radius: 5px;">

                                                <p style="background-color: #fce4f8; color: #a0358e;"
                                                    class="nospace pfstyle">ฟ้าทลายโจร</p>
                                                <p *ngIf="itemUser.medPres.mptFatalaijon != ''"
                                                    class="nospace mt-1 ml-1"> เจ็บคอ </p>

                                                    <p style="background-color: #fce4f8; color: #a0358e;"
                                                    class="nospace pfstyle">รายละเอียด</p>
                                                <p *ngIf="itemUser.medPres.mptFatalaijonNote != ''"
                                                    class="nospace mt-1 ml-1"> {{itemUser.medPres.mptFatalaijonNote}} </p>
                                                <p *ngIf="itemUser.medPres.mptFatalaijonNote == '' || itemUser.medPres.mptFatalaijonNote == null"
                                                    class="nospace mt-1 ml-1"> - </p>

                                            </div>
                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-md-12" *ngIf="noDataInformationTH == true">
                                <div class="mb-2">
                                    <div style="display: flex;">
                                        <div class="container rubberBand"
                                            style="color: #bdbbb7;margin-top: 100px;margin-bottom: 100px;text-align: center;">
                                            <i class="fas fa-calendar-times" style="font-size: 50px;"></i>
                                            <p>No Notification.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>

















            </div>
        </div>
    </div>

</section>
