<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-1">
                <h1 style="text-align: center;">Covid</h1>
            </div>
            <div class="col">
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content pl-2 pr-2">
    <div class="mat-elevation-z8 card card-solid">
        <div class="card-body pb-0">
            <div class="col-12">
                <div class="row">
                    <section class="example-section">
                        <span class="example-list-section">
                            <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
                                [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                {{task.name}}
                            </mat-checkbox>
                        </span>
                        <span class="example-list-section">
                            <div class="col-md-12 ">
                                <div class="row">
                                    <!-- <div class="col-6 " *ngFor="let subtask of task.subtasks; let i = index;">
                                    <mat-checkbox *ngIf="i < 10"[(ngModel)]="subtask.completed" [color]="subtask.color"
                                        style="padding-left: 15px;" (ngModelChange)="updateAllComplete(subtask.value)">
                                        {{subtask.name}}
                                    </mat-checkbox>
                                </div> -->
                                    <div class="col-2" style="max-width: 30%;" *ngFor="let subtask of task.subtasks ">
                                        <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                                            style="padding-left: 15px;"
                                            (ngModelChange)="updateAllComplete(subtask.value)">
                                            <span>{{subtask.name}}</span>

                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-8 mt-2 mb-2" style="display: contents;">
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Pressure Start</mat-label>
                                                <input matInput placeholder="Pressure Start"
                                                    (keyup)="onChangeSearchPressureStart($event)"
                                                    [(ngModel)]="pressureStart">
                                                <mat-icon matSuffix> show_chart</mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Pressure End</mat-label>
                                                <input matInput placeholder="Pressure End"
                                                    (keyup)="onChangeSearchPressureEnd($event)"
                                                    [(ngModel)]="pressureEnd">
                                                <mat-icon matSuffix> show_chart</mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Heartrate Start</mat-label>
                                                <input matInput placeholder="Heartrate Start"
                                                    (keyup)="onChangeSearchHeartrateStart($event)"
                                                    [(ngModel)]="HeartrateStart">
                                                <mat-icon matSuffix><i class="fas fa-heartbeat"></i></mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Heartrate End</mat-label>
                                                <input matInput placeholder="Heartrate End"
                                                    (keyup)="onChangeSearchHeartrateEnd($event)"
                                                    [(ngModel)]="HeartrateEnd">
                                                <mat-icon matSuffix><i class="fas fa-heartbeat"></i></mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                        </div>
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Temperature Start</mat-label>
                                                <input matInput placeholder="Temperature Start"
                                                    (keyup)="onChangeSearchTemperatureStart($event)"
                                                    [(ngModel)]="TemperatureStart">
                                                <mat-icon matSuffix><i class="fas fa-temperature-low"></i></mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Temperature End</mat-label>
                                                <input matInput placeholder="Temperature End"
                                                    (keyup)="onChangeSearchTemperatureEnd($event)"
                                                    [(ngModel)]="TemperatureEnd">
                                                <mat-icon matSuffix><i class="fas fa-temperature-low"></i></mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Spo2 Start</mat-label>
                                                <input matInput placeholder="Spo2 Start"
                                                    (keyup)="onChangeSearchSpo2Start($event)" [(ngModel)]="Spo2Start">
                                                <mat-icon matSuffix>bubble_chart</mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pl-2 pr-2">
                                                <mat-label>Spo2 End</mat-label>
                                                <input matInput placeholder="Spo2 End"
                                                    (keyup)="onChangeSearchSpo2End($event)" [(ngModel)]="Spo2End">
                                                <mat-icon matSuffix>bubble_chart</mat-icon>
                                                <!-- <mat-hint>Hint</mat-hint> -->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-4" style="display: flex;margin-top: auto;margin-bottom: auto;">
                                        <div>
                                            <button mat-raised-button style="width: 120px;" color="primary"
                                                (click)="onClickClear()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </section>
                </div>
            </div>
            <hr style="margin-top: 0px;">
            <div class="row mb-3">
                <div class="col-6"
                    style="text-align: start; margin-bottom: auto;margin-top: auto;padding-left: 20px;font-size: 22px;font-weight: 500;">
                    {{resultDataSerach.length}} User
                </div>
                <div class="col-6" style="text-align: end;padding-right: 18px;">
                    <button mat-icon-button style="margin-right: 5px;" (click)="onClickShowData('card')">
                        <mat-icon>apps</mat-icon>
                    </button>
                    <button mat-icon-button style="margin-right: 5px;" (click)="onClickShowData('table')">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <mat-form-field appearance="outline">
                        <mat-label>Select Show Item</mat-label>
                        <mat-select [(value)]="selected" (selectionChange)="onChangeCountItem(selected)">
                            <mat-option value="20">20-Item</mat-option>
                            <mat-option value="50">50-Item</mat-option>
                            <mat-option value="100">100-Item</mat-option>
                            <mat-option value="all">Show-Alll</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-body pb-0" style="padding: 0px;">
                <div class="row" *ngIf="loadDataUser == true">
                    <div class="col-12">
                        <mat-spinner style="margin-left: auto;margin-right: auto;margin-top: 110px;margin-bottom: 110px;"></mat-spinner>
                    </div>
                </div>
                <div class="row" *ngIf="cardShow == true">
                    <div *ngFor="let itemUser of resultDataSerach"
                        class="fadeIn col-12 col-sm-6 col-md-3 d-flex align-items-stretch flex-column ">
                        <div class="card bg-light d-flex flex-fill mat-elevation-z8">
                            <div class="card-body pt-0">
                                <div class="row mt-3 mb-1">
                                    <div class="col-7">
                                        <h2 class="lead"><b>{{itemUser.dataUser.userName}}</b></h2>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                            <li class="small">
                                                <span class="fa-li">
                                                    <i class="fas fa-lg fa-building"></i>
                                                </span> <span class="line-clamp"> Address: {{itemUser.dataUser.userAddress}}
                                                    {{itemUser.dataUser.userSubdistrict}} {{itemUser.dataUser.userDistrict}}
                                                    {{itemUser.dataUser.userProvince}} {{itemUser.dataUser.userPostalcode}}
                                                    {{itemUser.dataUser.userName}}</span>
                                            </li>
                                            <li class="small"><span class="fa-li"><i
                                                        class="fas fa-lg fa-phone"></i></span> Phone :
                                                {{itemUser.userPhone}}</li>
                                        </ul>
                                    </div>
                                    <div class="col-5 text-center">
                                        <div *ngIf="itemUser.dataUser.userPic != null && itemUser.dataUser.userPic != ''; else elseImgBlock">
                                            <img src="{{imageUrl2}}/{{itemUser.dataUser.userPic}}" alt="user-avatar" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                class="img-circle img-fluid" style="width: 136px;height: 136px;object-fit: cover;">
                                        </div>
                                        <ng-template #elseImgBlock>
                                            <img src="assets/adminlte/dist/img/nouser.svg" alt="user-avatar"
                                                class="img-circle img-fluid" style="width: 136px;height: 136px;">
                                        </ng-template>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12" style="font-size: 18px;">
                                        <div class="row"  *ngIf="itemUser.dataCovid != undefined">
                                            <mat-icon style="font-size: 20px;"> show_chart</mat-icon> <b>{{itemUser.dataCovid.cPressureHigh}}</b>
                                            <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                                    class="fas fa-heartbeat"></i></mat-icon> <b>{{itemUser.dataCovid.cHeartrate}}</b>
                                            <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                                    class="fas fa-temperature-low"></i></mat-icon> <b>{{itemUser.dataCovid.cTemp}}</b>
                                            <mat-icon style="font-size: 20px;padding-left: 8px;">bubble_chart</mat-icon>
                                            <b>{{itemUser.dataCovid.cSpo2}}</b>
                                        </div>
                                        <div class="row"  *ngIf="itemUser.dataCovid == undefined">
                                            <mat-icon style="font-size: 20px;"> show_chart</mat-icon> <b>-</b>
                                            <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                                    class="fas fa-heartbeat"></i></mat-icon> <b>-</b>
                                            <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                                    class="fas fa-temperature-low"></i></mat-icon> <b>-</b>
                                            <mat-icon style="font-size: 20px;padding-left: 8px;">bubble_chart</mat-icon>
                                            <b>-</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer" style="padding: 10px;">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6" style="margin: auto;">
                                            <div class="row" style="display: flex;justify-content: flex-start;">
                                                <span style="margin-right: 8px;">Symptom Today</span>
                                                <!-- <span class="starus-covid-green" *ngIf="itemUser.covidGreen == false"></span> -->
                                                <span class="starus-covid-green" style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;" *ngIf="itemUser.covidGreen == true"></span>
                                                <!-- <span class="starus-covid-yellow" *ngIf="itemUser.covidYellow == false"></span> -->
                                                <span class="starus-covid-yellow" style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;" *ngIf="itemUser.covidYellow == true"></span>
                                                <!-- <span class="starus-covid-red" *ngIf="itemUser.covidRed == false"></span> -->
                                                <span class="starus-covid-red" style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;" *ngIf="itemUser.covidRed == true"></span>
                                                <span *ngIf="itemUser.covidRed == false && itemUser.covidYellow == false && itemUser.covidGreen == false"> -</span>
                                            </div>
                                        </div>
                                        <div class="col-6" style="text-align: end;">
                                            <button mat-raised-button style="width: 120px;" color="primary" routerLink="/profile/phone/{{itemUser.dataUser.userId}}"><i
                                                    class="fas fa-user"></i> View Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="tableShow == true">
                    <table *ngIf="loadDataUser == false" class="table table table-hover" style="text-align: center;">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th scope="col">Age</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Height</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Date of birth</th>
                            <th scope="col">Phone</th>
                            <th scope="col">PersonalHealth</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody  *ngFor="let itemUser of resultDataSerach;let i = index;" class="fadeIn">
                          <tr routerLink="/profile/phone/{{itemUser.dataUser.userId}}">
                            <th scope="row">{{ i+1 }}</th>
                            <td *ngIf="itemUser.dataUser.userName != '' && itemUser.dataUser.userName != null">{{itemUser.dataUser.userName}}</td>
                            <td *ngIf="itemUser.dataUser.userName == '' || itemUser.dataUser.userName == null">-</td>
                            <td *ngIf="itemUser.dataUser.userAge != '' && itemUser.dataUser.userAge != null">{{itemUser.dataUser.userAge}}</td>
                            <td *ngIf="itemUser.dataUser.userAge == '' || itemUser.dataUser.userAge == null">-</td>
                            <td *ngIf="itemUser.dataUser.userWeight != '' && itemUser.dataUser.userWeight != null">{{itemUser.dataUser.userWeight}}</td>
                            <td *ngIf="itemUser.dataUser.userWeight == '' || itemUser.dataUser.userWeight == null">-</td>
                            <td *ngIf="itemUser.dataUser.userHeight != '' && itemUser.dataUser.userHeight != null">{{itemUser.dataUser.userHeight}}</td>
                            <td *ngIf="itemUser.dataUser.userHeight == '' || itemUser.dataUser.userHeight == null">-</td>
                            <td *ngIf="itemUser.dataUser.userSex != '' && itemUser.dataUser.userSex != null  && itemUser.dataUser.userSex == 'M'">Male</td>
                            <td *ngIf="itemUser.dataUser.userSex != '' && itemUser.dataUser.userSex != null  && itemUser.dataUser.userSex == 'F'">FeMale</td>
                            <td *ngIf="itemUser.dataUser.userSex == '' || itemUser.dataUser.userSex == null">-</td>
                            <td *ngIf="itemUser.dataUser.userDate != '' && itemUser.dataUser.userDate != null">{{itemUser.dataUser.userDate | date:'mediumDate'}}</td>
                            <td *ngIf="itemUser.dataUser.userDate == '' || itemUser.dataUser.userDate == null">-</td>
                            <td *ngIf="itemUser.dataUser.userPhone != '' && itemUser.dataUser.userPhone != null">{{itemUser.dataUser.userPhone}}</td>
                            <td *ngIf="itemUser.dataUser.userPhone == '' || itemUser.dataUser.userPhone == null">-</td>
                            <td>
                                <div class="row" style="display: flex;justify-content: center;" *ngIf="itemUser.dataCovid != undefined">
                                    <mat-icon style="font-size: 20px;"> show_chart</mat-icon> <b>{{itemUser.dataCovid.cPressureHigh}}</b>
                                    <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                            class="fas fa-heartbeat"></i></mat-icon> <b>{{itemUser.dataCovid.cHeartrate}}</b>
                                    <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                            class="fas fa-temperature-low"></i></mat-icon> <b>{{itemUser.dataCovid.cTemp}}</b>
                                    <mat-icon style="font-size: 20px;padding-left: 8px;">bubble_chart</mat-icon>
                                    <b>{{itemUser.dataCovid.cSpo2}}</b>
                                </div>
                                <div class="row" style="display: flex;justify-content: center;" *ngIf="itemUser.dataCovid == undefined">
                                    <mat-icon style="font-size: 20px;"> show_chart</mat-icon> <b>-</b>
                                    <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                            class="fas fa-heartbeat"></i></mat-icon> <b>-</b>
                                    <mat-icon style="font-size: 15px;padding-left: 8px;"><i
                                            class="fas fa-temperature-low"></i></mat-icon> <b>-</b>
                                    <mat-icon style="font-size: 20px;padding-left: 8px;">bubble_chart</mat-icon>
                                    <b>-</b>
                                </div>
                            </td>
                            <td>
                                <div class="row" style="display: flex;justify-content: center;">
                                    <span class="starus-covid-green" *ngIf="itemUser.covidGreen == false"></span>
                                    <span class="starus-covid-green" style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;" *ngIf="itemUser.covidGreen == true"></span>
                                    <span class="starus-covid-yellow" *ngIf="itemUser.covidYellow == false"></span>
                                    <span class="starus-covid-yellow" style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;" *ngIf="itemUser.covidYellow == true"></span>
                                    <span class="starus-covid-red" *ngIf="itemUser.covidRed == false"></span>
                                    <span class="starus-covid-red" style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;" *ngIf="itemUser.covidRed == true"></span>
                                </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div>

</section>
