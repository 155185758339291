import { Component, OnInit } from '@angular/core';
import { io } from 'socket.io-client';
import { ServiceService } from '../../../../core/services/service.service';
import { DialogConfirmSeeUser } from './modal/confirm-mall-user-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-doctor-notification',
  templateUrl: './doctor-notification.component.html',
  styleUrls: ['./doctor-notification.component.scss']
})
export class DoctorNotificationComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  private socket: any;
  loadDataCodiv19Alert: boolean = true;
  // noDataAlert: boolean = false;
  roleAdmin: any;
  idOperatorAdmin: any;
  resultDataLogCodiv19: any = [];
  dataOperator: any;

  dataSOS: any = [];
  logSos: any = [];
  dataSos_: any;

  constructor(
    private ServiceService: ServiceService,
    public dialog: MatDialog
  ) {
    this.socket = io(GlobalConstants.socket, { transports: ['websocket', 'polling', 'flashsocket'] });
    this.roleAdmin = localStorage.getItem("currentRole");
    this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
  }

  ngOnInit(): void {
    if (this.roleAdmin == "Operator") {
      // console.log("Sssssssss");

      this.waitAlertCovid19();
      this.loadDataLogCovid19(this.idOperatorAdmin);
      this.getdataOperator(this.idOperatorAdmin);

    }
  }

  onClickOpenProfileUserCovid19(dataCodiv: any) {
    const dialogRef = this.dialog.open(DialogConfirmSeeUser, {
      width: '550px',
      data: dataCodiv
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  waitAlertCovid19() {
    this.socket.on('alert_covidsym', async (dataAlertCovid19: any) => {
      // console.log("alert : ", dataAlertCovid19);

      if (this.roleAdmin == "Operator" && this.dataOperator[0].operatorId == dataAlertCovid19.daOperatorId) {
        // this.noDataAlert = false;

        var dataDoctorPatient: any;
        await this.ServiceService.GetDataDoctorPatient_(dataAlertCovid19.daUserId).then(resDoctorPatient => {
          dataDoctorPatient = resDoctorPatient;
          // console.log("dataDoctorPatient :: ",dataDoctorPatient);

          //ค้นหา คนเดิมที่เคย alert
          // var dataCodiv19 = this.resultDataLogCodiv19.find((d: { dpPatientId: any; }) => d.dpPatientId == dataAlertCovid19.daUserId);
          // console.log("dataCodiv19 : ", dataCodiv19);
          var itemSy_: any = [];
          var itemSos_: any = [];
          for (let i = 0; i < this.resultDataLogCodiv19.length; i++) {
            if (this.resultDataLogCodiv19[i].daType == "Green --> Yellow" ||
            this.resultDataLogCodiv19[i].daType == "Green --> Red" ||
            this.resultDataLogCodiv19[i].daType == "Yellow --> Red" ||
            this.resultDataLogCodiv19[i].daType == "SpO2 Fail" ||
            this.resultDataLogCodiv19[i].daType == "Feverish 5 Days" ||
            this.resultDataLogCodiv19[i].daType == "Temp >= 37.5 --> 5 Days" ||
            this.resultDataLogCodiv19[i].alertMsg == "Green --> Yellow" ||
            this.resultDataLogCodiv19[i].alertMsg == "Green --> Red" ||
            this.resultDataLogCodiv19[i].alertMsg == "Yellow --> Red" ||
            this.resultDataLogCodiv19[i].alertMsg == "SpO2 Fail" ||
            this.resultDataLogCodiv19[i].alertMsg == "Feverish 5 Days" ||
            this.resultDataLogCodiv19[i].alertMsg == "Temp >= 37.5 --> 5 Days") {
              itemSy_.push(this.resultDataLogCodiv19[i]);
            } else {
              itemSos_.push(this.resultDataLogCodiv19[i]);
            }
          }
          console.log("itemSy_ ", itemSy_);
          console.log("itemSos_ ", itemSos_);

          if (dataAlertCovid19.alertMsg == "Green --> Yellow" ||
          dataAlertCovid19.alertMsg == "Green --> Red" ||
          dataAlertCovid19.alertMsg == "Yellow --> Red" ||
          dataAlertCovid19.alertMsg == "SpO2 Fail" ||
          dataAlertCovid19.alertMsg == "Feverish 5 Days" ||
          dataAlertCovid19.alertMsg == "Temp >= 37.5 --> 5 Days") {
            var dataCodiv19Symptom = itemSy_.find((d: { dpPatientId: any; }) => d.dpPatientId == dataAlertCovid19.daUserId);
            // console.log("dataCodiv19Symptom----> ", dataCodiv19Symptom);
            if (dataCodiv19Symptom != undefined) {
              //ลบalertอันเดิมออก
              this.resultDataLogCodiv19 = this.resultDataLogCodiv19.filter((x: { daId: number; }) => {
                return x.daId != dataCodiv19Symptom.daId;
              })
              //เพิ่ม alert อันใหม่ไป
              var objSymptom = Object.assign(dataDoctorPatient[0], dataAlertCovid19);
              // console.log("this.resultDataLogCodiv19 : ",this.resultDataLogCodiv19);

              this.resultDataLogCodiv19.push(objSymptom);
              // console.log("this.resultDataLogCodiv19 New : ",this.resultDataLogCodiv19);
              this.updateStatusSeeAlert(dataCodiv19Symptom.daId);

            } else {
              if (dataDoctorPatient.length != 0) {
                var objSymptom = Object.assign(dataDoctorPatient[0], dataAlertCovid19);

                this.resultDataLogCodiv19.push(objSymptom);
              } else {
                //todo
                window.location.reload();
              }
            }
          } else {
            var dataCodiv19Sos = itemSos_.find((d: { dpPatientId: any; }) => d.dpPatientId == dataAlertCovid19.daUserId);
            // console.log("dataCodiv19Sos----> ", dataCodiv19Sos);
            if (dataCodiv19Sos != undefined) {
              //ลบalertอันเดิมออก
              this.resultDataLogCodiv19 = this.resultDataLogCodiv19.filter((x: { daId: number; }) => {
                return x.daId != dataCodiv19Sos.daId;
              })
              //เพิ่ม alert อันใหม่ไป
              var objSos = Object.assign(dataDoctorPatient[0], dataAlertCovid19);
              // console.log("this.resultDataLogCodiv19 : ",this.resultDataLogCodiv19);

              this.resultDataLogCodiv19.push(objSos);
              // console.log("this.resultDataLogCodiv19 New : ",this.resultDataLogCodiv19);
              this.updateStatusSeeAlert(dataCodiv19Sos.daId);

            } else {
              if (dataDoctorPatient.length != 0) {
                var objSos = Object.assign(dataDoctorPatient[0], dataAlertCovid19);

                this.resultDataLogCodiv19.push(objSos);
              } else {
                //todo
                window.location.reload();
              }
            }
          }


          // if (dataCodiv19 != undefined) {
          //   if (dataCodiv19.daType == dataAlertCovid19.alertMsg) {
          //     //ลบalertอันเดิมออก
          //     // this.resultDataLogCodiv19 = this.resultDataLogCodiv19.filter((x: { daId: number; }) => {
          //     //   return x.daId != dataCodiv19.daId;
          //     // })
          //     // //เพิ่ม alert อันใหม่ไป
          //     // var obj = Object.assign(dataDoctorPatient[0], dataAlertCovid19);
          //     // this.resultDataLogCodiv19.push(obj);
          //     // this.updateStatusSeeAlert(dataCodiv19.daId);
          //   } else {

          //   }
          // } else {
          //   if (dataDoctorPatient.length != 0) {
          //     var obj = Object.assign(dataDoctorPatient[0], dataAlertCovid19);
          //     this.resultDataLogCodiv19.push(obj);
          //   } else {
          //     //todo
          //     window.location.reload();
          //   }
          // }

        });

      }

    });
  }

  loadDataLogCovid19(idOperatorAdmin: any) {
    this.dataLogCodiv19(idOperatorAdmin).then(resLogCodiv19 => {
      // console.log("resLogCodiv19 new : ", resLogCodiv19);

      if (resLogCodiv19 != false) {
        var valueDataCovid = resLogCodiv19.sort((a: { daId: number; }, b: { daId: number; }) => a.daId > b.daId ? 1 : a.daId < b.daId ? -1 : 0);
        this.resultDataLogCodiv19 = valueDataCovid;
        setInterval(() => {
          this.loadDataCodiv19Alert = false;
          // this.noDataAlert = true;
        }, 2000);
      } else {
        this.resultDataLogCodiv19 = [];
        setInterval(() => {
          this.loadDataCodiv19Alert = false;
          // this.noDataAlert = true;
        }, 2000);
      }

    });
  }

  dataLogCodiv19(idOperatorAdmin: any) {
    // console.log(idOperatorAdmin);

    var dataLogCovid19: any;
    var userAlert: string = "";
    var dataAlert: any;
    var dataNewUserSymptom: any = [];
    return new Promise<any>(async (resolve, rejects) => {

      await this.ServiceService.GetLogCovid19Alert_(idOperatorAdmin).then(resLogCovid19 => {
        dataLogCovid19 = resLogCovid19;
        // console.log("dataLogCovid19 : ", dataLogCovid19);
        var itemSy: any = [];
        var itemSpO2Fail: any = [];
        var itemFeverish5Days: any = [];
        var itemTemp37dot5_5Days: any = [];
        var itemSos: any = [];

        var newUserSy: any = [];
        var newUserSpO2Fail: any = [];
        var newUserFeverish5Days: any = [];
        var newUserTemp37dot5_5Days: any = [];
        var newUserSos: any = [];

        for (let i = 0; i < dataLogCovid19.length; i++) {
          if (dataLogCovid19[i].daType == "Yellow --> Red" || dataLogCovid19[i].daType == "Green --> Red" || dataLogCovid19[i].daType == "Green --> Yellow") {
            itemSy.push(dataLogCovid19[i]);
          } else if (dataLogCovid19[i].daType == "SpO2 Fail") {
            itemSpO2Fail.push(dataLogCovid19[i]);
          } else if (dataLogCovid19[i].daType == "Feverish 5 Days") {
            itemFeverish5Days.push(dataLogCovid19[i]);
          } else if (dataLogCovid19[i].daType == "Temp >= 37.5 --> 5 Days") {
            itemTemp37dot5_5Days.push(dataLogCovid19[i]);
          } else{
            itemSos.push(dataLogCovid19[i]);
          }
        }
        // console.log("itemSos ",itemSos);
        // console.log("itemSy ",itemSy);


        var resultSy = this.groupBy(itemSy, (pet: { daUserId: any; }) => pet.daUserId);
        var resultSpO2Fail = this.groupBy(itemSpO2Fail, (pet: { daUserId: any; }) => pet.daUserId);
        var resultFeverish5Days = this.groupBy(itemFeverish5Days, (pet: { daUserId: any; }) => pet.daUserId);
        var resultTemp37dot5_5Days = this.groupBy(itemTemp37dot5_5Days, (pet: { daUserId: any; }) => pet.daUserId);
        var resultSos = this.groupBy(itemSos, (pet: { daUserId: any; }) => pet.daUserId);
        // console.log("resultSos ",resultSos);
        // console.log("resultSy ",resultSy);

        resultSy.forEach(element => {
          newUserSy.push(element[element.length - 1]);
        });
        resultSpO2Fail.forEach(element => {
          newUserSpO2Fail.push(element[element.length - 1]);
        });
        resultFeverish5Days.forEach(element => {
          newUserFeverish5Days.push(element[element.length - 1]);
        });
        resultTemp37dot5_5Days.forEach(element => {
          newUserTemp37dot5_5Days.push(element[element.length - 1]);
        });
        resultSos.forEach(element => {
          newUserSos.push(element[element.length - 1]);
        });
        // console.log("newUserSos : ",newUserSos);

        dataNewUserSymptom = newUserSy.concat(newUserSpO2Fail,newUserFeverish5Days,newUserTemp37dot5_5Days,newUserSos);
        // console.log("newUserSymptom : ", newUserSymptom);
        // console.log("newUserSos : ", newUserSos);
        // console.log("dataNewUserSymptom : ", dataNewUserSymptom);

        // const grouped = this.groupBy(dataLogCovid19, (pet: { daUserId: any; }) => pet.daUserId);
        // grouped.forEach(element => {
        //   dataNewUserSymptom.push(element[element.length - 1]);
        //   // dataNewUserSymptom.push(element);
        // });

        // var grList = Array.from(grouped.entries());

        // for (let i = 0; i < grList.length; i++) {
        //   // dataNewUserSymptom.push(grList[i][1][grList[i][1].length-1]);
        //   dataNewUserSymptom.push(grList[i][1]);
        // }
        // console.log("dataNewUserSymptom : ", dataNewUserSymptom);


        if (dataNewUserSymptom.length != 0) {
          resolve(dataNewUserSymptom);
        } else {
          resolve(false);
        }
      });
    });
  };

  getdataOperator(idOperatorAdmin: string) {
    this.ServiceService.GetIdOperatorByIdOperatorAdmin_(idOperatorAdmin).then(resDataOpertor => {
      // console.log("resDataOpertor : ",resDataOpertor);
      this.dataOperator = resDataOpertor;
    });
  }

  groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  updateStatusSeeAlert(daId: any) {
    this.ServiceService.UpdateDocCovidStatusSeeUSer_(daId, true).then(resUpdateStatus => {
      console.log(resUpdateStatus);
    });
  }

}
