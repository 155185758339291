<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <h1 style="text-align: center;">Profile Operator</h1>
            </div>
            <div class="col-md-10">
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content ml-3 mr-3 mb-3 mat-elevation-z8 ">
    <div class="card">
        <mat-progress-bar mode="query" *ngIf="loadData"></mat-progress-bar>
        <div class="card-body" *ngIf="loadDataSuccess">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-8 order-2 order-md-1" *ngIf="dataDetailAdmin != null">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="info-box bg-danger mat-elevation-z8">
                                <span class="info-box-icon"><i class="fas fa-exclamation-triangle"></i></span>

                                <div class="info-box-content">
                                    <span class="info-box-text">SOS Job</span>
                                    <span class="info-box-number" *ngIf="CountSosOperator != null"
                                        style="font-size: 19px;">{{CountSosOperator.length}} Case</span>


                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="info-box bg-warning mat-elevation-z8">
                                <span class="info-box-icon"><i class="fas fa-user-clock"></i></span>

                                <div class="info-box-content">
                                    <span class="info-box-text">Working Job</span>
                                    <span class="info-box-number" *ngIf="CountWorkingJobOperator != null"
                                        style="font-size: 19px;">{{CountWorkingJobOperator.length}} Case</span>


                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="info-box bg-success mat-elevation-z8">
                                <span class="info-box-icon"><i class="fas fa-thumbs-up"></i></span>

                                <div class="info-box-content">
                                    <span class="info-box-text">Success Job</span>
                                    <span class="info-box-number" *ngIf="dataHistoryJobs != null"
                                        style="font-size: 19px;">{{opCountTwo}} Case</span>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- <mat-tab-group> -->
                                <!-- <mat-tab label="Patient">
                                    <div class="row mt-2"
                                        style="margin: auto; display: flex;justify-content: space-between;">
                                        <div *ngIf="resultDataDoctorMall != null"
                                            style="text-align: start; margin-bottom: auto;margin-top: auto;padding-left: 20px;font-size: 22px;font-weight: 500;">
                                            {{resultDataDoctorMall.length}} User
                                        </div>
                                        <mat-radio-group aria-label="Select an option"
                                            style="margin-top: auto;margin-bottom: auto;margin-left: 15px;">
                                            <mat-radio-button style="margin-right: 15px;background-color: white;"
                                                value="1" (change)="onChangeStatus('red')">User-Red
                                            </mat-radio-button>
                                            <mat-radio-button style="margin-right: 15px;background-color: white;"
                                                value="2" (change)="onChangeStatus('yellow')">User-Yellow
                                            </mat-radio-button>
                                            <mat-radio-button style="margin-right: 15px;background-color: white;"
                                                value="3" (change)="onChangeStatus('green')">User-Green
                                            </mat-radio-button>
                                            <mat-radio-button style="margin-right: 15px;background-color: white;"
                                                value="4" (change)="onChangeStatus('all')" checked>All
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Select Show Favorite</mat-label>
                                            <mat-select [(value)]="selectedFavorite"
                                                (selectionChange)="onChangeFavorite(selectedFavorite)">
                                                <mat-option value="noFavorite">No favorites</mat-option>
                                                <mat-option value="favorite">Favorite</mat-option>
                                                <mat-option value="allFavorite">Show-All</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="loadDataUser == true">
                                        <div class="col-12">
                                            <mat-spinner
                                                style="margin-left: auto;margin-right: auto;margin-top: 110px;margin-bottom: 110px;">
                                            </mat-spinner>
                                        </div>
                                    </div>
                                    <table *ngIf="loadDataUser == false " class="table" style="text-align: center;">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Favorite</th>
                                                <th scope="col">Username</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Start date</th>
                                                <th scope="col">Last update date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Doctor start date</th>
                                                <th scope="col">Doctor Status</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let itemUser of resultDataDoctorMall;let i = index;"
                                            class="fadeIn">
                                            <tr>
                                                <th scope="row">{{ i+1 }}</th>
                                                <td *ngIf="itemUser.dpFavorite == '' || itemUser.dpFavorite == null"><i
                                                        class="far fa-star" style="font-size: 20px;"></i></td>
                                                <td *ngIf="itemUser.dpFavorite != '' && itemUser.dpFavorite != null"><i
                                                        class="fas fa-star" style="font-size: 20px;"></i></td>
                                                <td *ngIf="itemUser.userName != '' && itemUser.userName != null">
                                                    {{itemUser.userName}}</td>
                                                <td *ngIf="itemUser.userName == '' || itemUser.userName == null">-</td>
                                                <td *ngIf="itemUser.userPhone != '' && itemUser.userPhone != null">
                                                    {{itemUser.userPhone}}</td>
                                                <td *ngIf="itemUser.userPhone == '' || itemUser.userPhone == null">-
                                                </td>
                                                <td
                                                    *ngIf="itemUser.dmRequestTime != '' && itemUser.dmRequestTime != null">
                                                    {{itemUser.dmRequestTime | date:'short'}}</td>
                                                <td
                                                    *ngIf="itemUser.dmRequestTime == '' || itemUser.dmRequestTime == null">
                                                    -</td>
                                                <td
                                                    *ngIf="itemUser.dmLastUpdate != '' && itemUser.dmLastUpdate != null">
                                                    {{itemUser.dmLastUpdate | date:'short'}}</td>
                                                <td
                                                    *ngIf="itemUser.dmLastUpdate == '' || itemUser.dmLastUpdate == null">
                                                    -</td>
                                                <td>
                                                    <div class="row" style="display: flex;justify-content: center;">
                                                        <span class="starus-covid-green"
                                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                                            *ngIf="itemUser.dmCovidStatus == 'Green'"></span>

                                                        <span class="starus-covid-yellow"
                                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                                            *ngIf="itemUser.dmCovidStatus == 'Yellow'"></span>

                                                        <span class="starus-covid-red"
                                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                                            *ngIf="itemUser.dmCovidStatus == 'Red'"></span>
                                                    </div>
                                                </td>
                                                <td *ngIf="itemUser.dpDate != '' && itemUser.dpDate != null">
                                                    {{itemUser.dpDate | date:'short'}}</td>
                                                <td *ngIf="itemUser.dpDate == '' || itemUser.dpDate == null">-</td>
                                                <td>
                                                    <div class="row" style="display: flex;justify-content: center;">
                                                        <span class="starus-covid-green"
                                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;"
                                                            *ngIf="itemUser.dpDocCovidStatus == 'Green'"></span>

                                                        <span class="starus-covid-yellow"
                                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;"
                                                            *ngIf="itemUser.dpDocCovidStatus == 'Yellow'"></span>

                                                        <span class="starus-covid-red"
                                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;"
                                                            *ngIf="itemUser.dpDocCovidStatus == 'Red'"></span>
                                                        <span
                                                            *ngIf="itemUser.dpDocCovidStatus == '' || itemUser.dpDocCovidStatus == null">
                                                            -</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button mat-raised-button style="width: 120px;" color="primary"
                                                        routerLink="/userCovid19/{{itemUser.userId}}">
                                                        <mat-icon>visibility</mat-icon> View
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </mat-tab> -->
                                <!-- <mat-tab label="Recent Activity"> -->
                                    <!-- <h3>Recent Activity</h3>
                                    <hr> -->
                                    <div class="d-flex flex-row-reverse">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Select Show Item</mat-label>
                                            <mat-select [(value)]="selectCountPage" (selectionChange)="onChangeCountPage(selectCountPage)">
                                                <mat-option value="20">20-Item</mat-option>
                                                <mat-option value="50">50-Item</mat-option>
                                                <mat-option value="100">100-Item</mat-option>
                                                <mat-option value="all">Show-All</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                          <mat-label>Search by Name</mat-label>
                                          <input matInput placeholder="Search by Name..." [(ngModel)]="searchValue" (ngModelChange)="filterName(searchValue)">
                                          <mat-icon matSuffix>search</mat-icon>
                                        </mat-form-field>
                                      </div>
                                      <ng-container *ngIf="!noData">
                                          <div class="post mt-3" *ngFor="let dataHistoryJob of dataHistoryJobs">
                                              <p style="margin-bottom: 5px;margin-top: -3px;">
                                                  <b>ID-Case </b>: {{dataHistoryJob.dataEndJob.ojobId}}
                                              </p>
                                              <div class="row">
                                                  <div class="col-md-10 user-block">
                                                      <div
                                                          *ngIf="dataHistoryJob.dataEndJob.userPic != null &&  dataHistoryJob.dataEndJob.userPic != ''; else elseImg">
                                                          <img src="{{imageUrl2}}/{{dataHistoryJob.dataEndJob.userPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                              class="img-circle elevation-2" alt="img-profile"
                                                              style="width: 45px;height: 45px;object-fit: cover;">
                                                      </div>
                                                      <ng-template #elseImg>
                                                          <img src="assets/adminlte/dist/img/nouser.svg"
                                                              class="img-circle elevation-2" alt="img-profile"
                                                              style="width: 45px;height: 45px;object-fit: cover;">
                                                      </ng-template>
                                                      <span class="username"> {{dataHistoryJob.dataEndJob.userName}}
                                                      </span>
                                                      <span class="description mt-1">
                                                          <span class="badge badge-danger" style="font-size: 12px;">
                                                              <i class="fas fa-exclamation-triangle"></i>
                                                              {{
                                                                  dataHistoryJob.typeSos[0].sos_type == 'sos' ? "Watch SOS" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'covid' ? "Symptom Covid19" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'sos-phone' ? "Phone SOS" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'temp-fail' ? "Temp Fail" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'heart-fail' ? "Heartrate Fail" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'fall-detect' ? "Fall Detect" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'heartrate-detect' ? "Heartrate Detect" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'spo2-detect' ? "Spo2 Detect" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'bodytemp-detect' ? "Bodytemp Detect" :
                                                                  dataHistoryJob.typeSos[0].sos_type == 'bloodpressure-detect' ? "Bloodpressure Detect" : "no type alert"
                                                              }} &nbsp;-
                                                              {{dataHistoryJob.dataEndJob.ojobDate|
                                                              date :'short'}}
                                                          </span>
                                                      </span>
                                                  </div>
                                                  <div class="col-md-2" style="text-align: end;">
                                                      <button mat-mini-fab color="primary"
                                                          routerLink="/caseProfileHistory/{{idOperatorAdmin}}/{{dataHistoryJob.dataEndJob.ojobId}}">
                                                          <mat-icon>remove_red_eye</mat-icon>
                                                      </button>

                                                  </div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-md-12"
                                                      *ngFor="let dataHistoryCommentJob of dataHistoryJob.dataEndJob.dataComment">
                                                      <div >
                                                          <span class="badge badge-warning" style="font-size: 12px;">
                                                              <i class="far fa-clock"></i>
                                                              {{dataHistoryCommentJob.ojobTaskDate | date :'short'}}
                                                          </span>
                                                          <p style="margin: 5px;cursor: pointer;" *ngIf="dataHistoryCommentJob.ojobTaskId != showNote" (click)="openNote(dataHistoryCommentJob.ojobTaskId)"><b>Note : Read more.</b>
                                                          </p>
                                                          <p style="margin: 5px;cursor: pointer;" *ngIf="dataHistoryCommentJob.ojobTaskId == showNote" (click)="openNote(00)"><b>Note : Hide reading.</b>
                                                          </p>
                                                          <p style="margin: 10px;" *ngIf="dataHistoryCommentJob.ojobTaskId == showNote">
                                                               <b>ระบุเบอร์ที่โทรกลับไป:</b><br>
                                                              &nbsp;&nbsp;&nbsp;-{{dataHistoryCommentJob.ojobTaskField1}}<br>
                                                              <b>ระบุเบอร์โทรที่สามารถติดต่อได้:</b><br>
                                                              &nbsp;&nbsp;&nbsp;-{{dataHistoryCommentJob.ojobTaskField2}}<br>
                                                              <b>ระบุสาเหตุการเรียก SOS และอาการที่ได้รับแจ้งอย่างละเอียด:</b><br>
                                                              &nbsp;&nbsp;&nbsp;-{{dataHistoryCommentJob.ojobTaskField3}}<br>
                                                              <b>เรียกรถฉุกเฉินหรือไม่:</b><br>
                                                              &nbsp;&nbsp;&nbsp;-{{dataHistoryCommentJob.ojobTaskField4}}<br>
                                                              <b>พิมพ์รายละเอียดสรุปการทำงานที่เกิดขึ้น:</b><br>
                                                              &nbsp;&nbsp;&nbsp;-{{dataHistoryCommentJob.ojobTaskField5}}<br>
                                                              <b>อื่นๆ:</b><br>
                                                              &nbsp;&nbsp;&nbsp;-{{dataHistoryCommentJob.ojobTaskDetail}}
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="noData">
                                        <p class="text-center fs-4 fst-italic">[ No Data. ]</p>
                                      </ng-container>
                                <!-- </mat-tab> -->
                            <!-- </mat-tab-group> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                    <!-- assets/adminlte/dist/img/smart-watch.svg -->
                    <div class="card card-widget widget-user mat-elevation-z8 sticky" *ngIf="dataDetailAdmin != null">
                        <div class="widget-user-header text-white"
                            style="background: url('assets/adminlte/dist/img/sos-1520x800.png') ">
                            <h3 class="widget-user-username text-right">{{dataDetailAdmin[0].operatorFirstname}}
                                {{dataDetailAdmin[0].operatorLastname}}</h3>
                            <h5 class="widget-user-desc text-right">Operator : {{dataDetailAdmin[0].adminNamerole}}</h5>
                        </div>
                        <div class="widget-user-image" *ngIf="dataDetailAdmin[0].operatorPic != null">
                            <img src="{{imageUrl1}}/{{dataDetailAdmin[0].operatorPic}}" alt="" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                class="mat-elevation-z8 img-circle" style="width: 90px;height: 90px;object-fit: cover;">
                        </div>
                        <div class="widget-user-image" *ngIf="dataDetailAdmin[0].operatorPic == null">
                            <img src="assets/adminlte/dist/img/nouser.svg" alt=""
                                class="mat-elevation-z8 img-circle img-fluid">
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-sm-6 border-right">
                                    <div class="description-block">
                                        <h5 class="description-header" *ngIf="dataHistoryJobs != null">
                                            {{opCountTwo}}</h5>
                                        <span class="description-text">ToTal Case</span>
                                    </div>
                                </div>
                                <div class="col-sm-6 ">
                                    <div class="description-block">
                                        <h5 class="description-header" *ngIf="CountTodayJob != null">
                                            {{CountTodayJob.length}}</h5>
                                        <span class="description-text">ToDay Case</span>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <h3 class="mt-3 text-primary"><i class="far fa-bookmark"></i> Operator :
                                {{dataDetailAdmin[0].adminNamerole}}</h3>
                            <!-- <p class="text-muted">Raw denim you probably haven't heard of them jean shorts Austin.
                                Nesciunt tofu
                                stumptown aliqua butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,
                                qui irure
                                terr.</p> -->
                            <!-- <br> -->
                            <div class="text-muted">
                                <p class="text-sm"><i class="far fa-user"></i> Username :
                                    <b>{{dataDetailAdmin[0].operatorUsername}}</b>
                                </p>
                                <p class="text-sm"><i class="far fa-envelope"></i> Email :
                                    <b>{{dataDetailAdmin[0].operatorEmail}}</b>
                                </p>
                                <p class="text-sm"><i class="fas fa-signature"></i> Name :
                                    <b>{{dataDetailAdmin[0].operatorFirstname}}
                                        {{dataDetailAdmin[0].operatorLastname}}</b>
                                </p>
                                <p class="text-sm"><i class="fas fa-birthday-cake"></i> Date of birth :
                                    <b>{{dataDetailAdmin[0].operatorDateofbirth}}</b>
                                </p>
                                <p class="text-sm"><i class="far fa-calendar-alt"></i> Age :
                                    <b>{{dataDetailAdmin[0].operatorAge}}</b>
                                </p>
                                <p class="text-sm"><i class="fas fa-venus-mars"></i> Gender :
                                    <b *ngIf="dataDetailAdmin[0].operatorGender == 'M'">Male</b>
                                    <b *ngIf="dataDetailAdmin[0].operatorGender == 'FM'">FeMale</b>
                                </p>
                                <p class="text-sm"><i class="far fa-address-card"></i> Address :
                                    <b>{{dataDetailAdmin[0].operatorAddress}},
                                        ต.{{dataDetailAdmin[0].operatorSubdistrict}}
                                        อ.{{dataDetailAdmin[0].operatorDistrict}}
                                        จ.{{dataDetailAdmin[0].operatorProvince}}
                                        {{dataDetailAdmin[0].operatorPostalcode}}</b>
                                </p>
                                <p class="text-sm"><i class="fas fa-phone"></i> Phone Number :
                                    <b>{{dataDetailAdmin[0].operatorPhone}}</b>
                                </p>
                            </div>

                            <!-- <h5 class="mt-5 text-muted">Project files</h5>
                            <ul class="list-unstyled">
                                <li>
                                    <a href="" class="btn-link text-secondary"><i class="far fa-fw fa-file-word"></i>
                                        Functional-requirements.docx</a>
                                </li>
                                <li>
                                    <a href="" class="btn-link text-secondary"><i class="far fa-fw fa-file-pdf"></i>
                                        UAT.pdf</a>
                                </li>
                                <li>
                                    <a href="" class="btn-link text-secondary"><i class="far fa-fw fa-envelope"></i>
                                        Email-from-flatbal.mln</a>
                                </li>
                                <li>
                                    <a href="" class="btn-link text-secondary"><i class="far fa-fw fa-image "></i>
                                        Logo.png</a>
                                </li>
                                <li>
                                    <a href="" class="btn-link text-secondary"><i class="far fa-fw fa-file-word"></i>
                                        Contract-10_12_2014.docx</a>
                                </li>
                            </ul> -->
                            <div class="text-center mt-5 mb-3">
                                <button class="mr-2" mat-raised-button color="accent" *ngIf="roleAdmin != 'AdminViewOnly'"
                                    (click)="openDialogEditOperator()">Edit Profile</button>
                                <!-- <button mat-raised-button color="primary">Report contact</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
