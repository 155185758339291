import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { ServiceService } from 'src/app/core/services/service.service';

@Component({
  selector: 'app-case-profile-app',
  templateUrl: './case-profile-app.component.html',
  styleUrls: ['./case-profile-app.component.scss']
})
export class CaseProfileAppComponent implements OnInit {
  keywords: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ServiceService: ServiceService
  ) { }

  async ngOnInit() {
    await this.getAdminRole();
    let jwtDecode: any = this.getDecodedAccessToken(this.route.snapshot.paramMap.get("jwtToken") ?? "");
    if (this.route.snapshot.paramMap.get("idOperatorAdmin") && this.route.snapshot.paramMap.get("idCase") && this.route.snapshot.paramMap.get("jwtToken") && jwtDecode) {
      let operatorID: string | null = this.route.snapshot.paramMap.get("idOperatorAdmin");
      let caseID: string | null = this.route.snapshot.paramMap.get("idCase");
      let jwtToken: string | null = this.route.snapshot.paramMap.get("jwtToken");
      localStorage.setItem('currentUser', jwtToken ?? "");
      localStorage.setItem('currentIdAdmin', operatorID ?? "");
      localStorage.setItem('currentRole', jwtDecode.adminRole);
      localStorage.setItem('currentUsername', jwtDecode.sub);
      localStorage.setItem('showAdmin', this.keywords.find((item: any) => item.typeKeyword == jwtDecode.sub.split("-")[0]).typeAdmin);
      this.router.navigateByUrl(`/caseProfileNew/${operatorID}/${caseID}`).then(() => { window.location.reload() });
    } else this.router.navigateByUrl(`/login`).then(() => { window.location.reload() });
  }

  async getAdminRole() {
    await this.ServiceService.GetAdminRole_().then(res => {
      if (res.length) this.keywords = res;
    }, err => {
      console.log("err -> ", err);
    });
  }

  getDecodedAccessToken(token: string) {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }
}
