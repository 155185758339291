<div style="width: 500px;">
    <mat-spinner *ngIf="loadDataUpdateDoctor == true"
        style="margin-top: 50px;margin-bottom: 50px;margin-left: auto;margin-right: auto;"></mat-spinner>
    <div *ngIf="checkConfirm == true">
        <h1 mat-dialog-title>Confirm selected</h1>
        <div mat-dialog-content>You want to choose {{dataUserCovid.userName}}</div>
        <mat-dialog-actions align="end">
            <button mat-button (click)="onClickCancel()" >Cancel</button>
            <button mat-button cdkFocusInitial (click)="onClickConfirm()">Confirm</button>
        </mat-dialog-actions>
    </div>
    <div *ngIf="updateSuccess == true">
        <h1 mat-dialog-title>Update status</h1>
        <div mat-dialog-content>Select Success</div>
        <mat-dialog-actions align="end">
            <button mat-button cdkFocusInitial (click)="onClickOK('Success')">OK</button>
        </mat-dialog-actions>
    </div>
    <div *ngIf="updateFailed == true">
        <h1 mat-dialog-title>Update status</h1>
        <div mat-dialog-content>Select Failed </div>
        <mat-dialog-actions align="end">
            <button mat-button cdkFocusInitial (click)="onClickOK('Failed')">OK</button>
        </mat-dialog-actions>
    </div>
</div>