import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ServiceService } from '../../../core/services/service.service';
import { LOCALE_ID, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  value: number;
  subtasks?: Task[];
}

@Component({
  selector: 'app-user-covid19-list',
  templateUrl: './user-covid19-list.component.html',
  styleUrls: ['./user-covid19-list.component.scss']
})
export class UserCovid19ListComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  selected = '20';
  dataSerach: any;
  roleAdmin: any;
  typeName: any;
  resultDataSerach: any = [];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  tableShow: boolean = false;
  cardShow: boolean = true;
  loadDataUser: boolean = true;

  constructor(
    private router: Router,
    private ServiceService: ServiceService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.roleAdmin = localStorage.getItem("currentRole");

    if(this.roleAdmin == "Operator"){
      this.router.navigate([`/dashboard`], { replaceUrl: true }).then(() => { window.location.reload(); });
    }

  }

  async ngOnInit(): Promise<void> {

    if (this.roleAdmin != "AdminSubdistricts" && this.roleAdmin != "AdminDistrict" && this.roleAdmin != "AdminProvince") {
      if (this.roleAdmin != "SuperAdmin") {
        var dataRole: any
        await this.ServiceService.GetAdminRole_().then(resRole => {
          dataRole = resRole;
          var adminRole = this.roleAdmin;
          for (let i = 0; i < dataRole.length; i++) {
            if (dataRole[i].typeAdmin == adminRole) {
              this.typeName = dataRole[i].typeName;
            }
          }
        });
      } else {
        var dataRole: any
        await this.ServiceService.GetAdminRole_().then(resRole => {
          dataRole = resRole;
          var adminRole = this.roleAdmin;
          for (let i = 0; i < dataRole.length; i++) {
            if (dataRole[i].typeAdmin == localStorage.getItem("showAdmin")) {
              // console.log(">>>>> ",dataRole[i]);

              this.typeName = dataRole[i].typeName;
            }
          }
        });
      }
    } else {
      //todo
      console.log(this.roleAdmin)
    }
    this.dataSerach = {
      "SearchCountList": "20",
      "SearchTypeUser": this.typeName,
      "SearchPressureHighStart": "",
      "SearchPressureHighEnd": "",
      "SearchPressureLowStart": "",
      "SearchPressureLowEnd": "",
      "SearchHeartrateStart": "",
      "SearchHeartrateEnd": "",

      "SearchSpo2AfterExercise": "",
      "SearchSpo2Ventilator": "",

      "SearchTempStart": "",
      "SearchTempEnd": "",
      "SearchSpo2Start": "",
      "SearchSpo2End": "",
      "SearchFeverish": false,
      "SearchCoughing_up_blood": false,
      "SearchSore_throat": false,
      "SearchLie_down_breath": false,
      "SearchMuscle_pain": false,
      "SearchRunny": false,
      "SearchSputum": false,
      "SearchBreathing_diff": false,
      "SearchTired_sit_still": false,
      "SearchHeadache": false,
      "SearchLiquid_stool": false,
      "SearchNose_no_smell": false,
      "SearchRash": false,
      "SearchTried_daily_activities": false,
      "SearchTongue_no_taste": false,

      "SearchCoughAlittle": false,
      "SearchCoughAlot": false,
      "SearchCanNotEat": false
    };

    this.loadDataSearch(this.dataSerach);

  }

  onClickShowData(type: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;
    if (type == "card") {
      setTimeout(() => {
        this.loadDataUser = false;
        this.tableShow = false;
        this.cardShow = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.loadDataUser = false;
        this.tableShow = true;
        this.cardShow = false;
      }, 2000);
    }
  }

  onChangeCountItem(event: string) {
    this.resultDataSerach = [];
    // console.log(event);
    this.dataSerach.SearchCountList = event;
    this.loadDataSearch(this.dataSerach);
  }

  loadDataSearch(data_: any) {
    this.loadDataUser = true;
    this.loadData(data_).then(data => {
      this.resultDataSerach = [];
      // console.log("-------> ", data);
      if (data != "0") {
        setTimeout(() => {
          this.loadDataUser = false;
          this.resultDataSerach = data;
        }, 2000);

      } else {
        setTimeout(() => {
          this.loadDataUser = false;
        }, 2000);
      }

    });
  }

  onChangeSearchPressureStart(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchPressureHighStart = inputValue;
    if (this.dataSerach.SearchPressureHighStart.length != 0 && this.dataSerach.SearchPressureHighEnd.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchPressureHighStart.length == 0 && this.dataSerach.SearchPressureHighEnd.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  onChangeSearchPressureEnd(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchPressureHighEnd = inputValue;
    if (this.dataSerach.SearchPressureHighStart.length != 0 && this.dataSerach.SearchPressureHighEnd.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchPressureHighStart.length == 0 && this.dataSerach.SearchPressureHighEnd.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  onChangeSearchHeartrateStart(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchHeartrateStart = inputValue;
    if (this.dataSerach.SearchHeartrateStart.length != 0 && this.dataSerach.SearchHeartrateEnd.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchHeartrateStart.length == 0 && this.dataSerach.SearchHeartrateEnd.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  onChangeSearchHeartrateEnd(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchHeartrateEnd = inputValue;
    if (this.dataSerach.SearchHeartrateStart.length != 0 && this.dataSerach.SearchHeartrateEnd.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchHeartrateStart.length == 0 && this.dataSerach.SearchHeartrateEnd.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  onChangeSearchTemperatureStart(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchTempStart = inputValue;
    if (this.dataSerach.SearchTempStart.length != 0 && this.dataSerach.SearchTempEnd.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchTempStart.length == 0 && this.dataSerach.SearchTempEnd.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  onChangeSearchTemperatureEnd(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchTempEnd = inputValue;
    if (this.dataSerach.SearchTempStart.length != 0 && this.dataSerach.SearchTempEnd.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchTempStart.length == 0 && this.dataSerach.SearchTempEnd.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  onChangeSearchSpo2Start(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchSpo2Start = inputValue;
    if (this.dataSerach.SearchSpo2Start.length != 0 && this.dataSerach.SearchSpo2End.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchSpo2Start.length == 0 && this.dataSerach.SearchSpo2End.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  onChangeSearchSpo2End(event: any) {
    var inputValue = event.target.value;
    // console.log(inputValue);
    this.dataSerach.SearchSpo2End = inputValue;
    if (this.dataSerach.SearchSpo2Start.length != 0 && this.dataSerach.SearchSpo2End.length != 0) {
      this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    } else if (this.dataSerach.SearchSpo2Start.length == 0 && this.dataSerach.SearchSpo2End.length == 0) {
      // this.resultDataSerach = [];
      this.loadDataSearch(this.dataSerach);
    }
  }

  task: Task = {
    name: 'ทั้งหมด',
    completed: false,
    color: 'primary',
    value: 0,
    subtasks: [
      { name: 'มีไข้', completed: false, color: 'primary', value: 1 },
      { name: 'เจ็บคอ', completed: false, color: 'primary', value: 2 },
      { name: 'ปวดกล้ามเนื้อ', completed: false, color: 'primary', value: 3 },
      { name: 'มีน้ำมูก', completed: false, color: 'primary', value: 4 },
      { name: 'มีเสมหะ', completed: false, color: 'primary', value: 5 },
      { name: 'ปวดหัว', completed: false, color: 'primary', value: 6 },
      { name: 'จมูกไม่ได้กลิ่น', completed: false, color: 'primary', value: 7 },
      { name: 'มีผื่น', completed: false, color: 'primary', value: 8 },
      { name: 'ถ่ายเหลว', completed: false, color: 'primary', value: 9 },

      { name: 'ไอมาก', completed: false, color: 'primary', value: 10 },
      { name: 'ไอน้อย', completed: false, color: 'primary', value: 11 },
      { name: 'กินไม่ได้', completed: false, color: 'primary', value: 12 },
      { name: 'ไอเป็นเลือด', completed: false, color: 'primary', value: 13 },
      { name: 'หายใจลำบาก', completed: false, color: 'primary', value: 14 },
      { name: 'เหนื่อยขณะนั่งเฉยๆ', completed: false, color: 'primary', value: 15 },
      { name: 'ลิ้นไม่รับรส', completed: false, color: 'primary', value: 16 },

      { name: 'นอนราบแล้วหายใจไม่ออก', completed: false, color: 'primary', value: 17 },
      { name: 'เหนื่อยในขณะที่ทำกิจวัตรประจำวัน', completed: false, color: 'primary', value: 18 }



      // { name: 'ไม่มีอาการ', completed: false, color: 'primary', value: 1 },
      // { name: 'วัดอุณหภูมิได้ตั้งแต่ 37.5 องศาเซลเซียสขึ้นไป', completed: false, color: 'primary', value: 2 },
      // { name: 'ไอ มีน้ำมูก เจ็บคอ', completed: false, color: 'primary', value: 3 },
      // { name: 'ไม่ได้กลิ่น ลิ้นไม่รับรส ตาแดง ผื่น', completed: false, color: 'primary', value: 4 },
      // { name: 'ถ่ายเหลว', completed: false, color: 'primary', value: 5 },
      // { name: 'ไม่มีอาการหายใจเร็ว', completed: false, color: 'primary', value: 6 },
      // { name: 'ไม่มีอาการหายใจเหนื่อย', completed: false, color: 'primary', value: 7 },
      // { name: 'ไม่มีอาการหายใจลำบาก', completed: false, color: 'primary', value: 8 },
      // { name: 'ไม่มีปอดอักเสบ', completed: false, color: 'primary', value: 9 },
      // { name: 'ไม่มีปัจจัยเสี่ยงต่อการเป็นโรครุนแรงหรือโรคร่วมสำคัญ', completed: false, color: 'primary', value: 10 },

      // { name: 'ปัจจัยเสี่ยงต่อการเป็นโรครุนแรงหรือโรคร่วมสำคัญ ข้อใดข้อหนึ่ง', completed: false, color: 'primary', value: 11 },
      // { name: 'แน่นหน้าอก', completed: false, color: 'primary', value: 12 },
      // { name: 'หายใจไม่สะดวกขณะทำกิจกรรม', completed: false, color: 'primary', value: 13 },
      // { name: 'หายใจเร็ว หายใจเหนื่อย หรือหายใจลำบากเวลาไอแล้วเหนื่อย', completed: false, color: 'primary', value: 14 },
      // { name: 'อ่อนเพลีย เวียนศีรษะ', completed: false, color: 'primary', value: 15 },
      // { name: 'ปอดอักเสบ', completed: false, color: 'primary', value: 16 },
      // { name: 'ถ่ายเหลวมากกว่า 3 ครั้งต่อวันร่วมกับอาการหน้ามืด วิงเวียน', completed: false, color: 'primary', value: 17 },

      // { name: 'หอบเหนื่อย พูดไม่เป็นประโยคขณะสนทนา', completed: false, color: 'primary', value: 18 },
      // { name: 'แน่นหน้าอกตลอดเวลาหายใจแล้วเจ็บหน้าอก', completed: false, color: 'primary', value: 19 },
      // { name: 'ซึม เรียกไม่รู้สึกตัวหรือตอบสนองช้า', completed: false, color: 'primary', value: 20 },
      // // { name: 'ปอดบวมที่มี hypoxic (risting O2 saturation < 96%) หรือมีภาวะลดลงของออกซิเจน SpO2 >= 3 % <br> ของค่าที่วัดได้ครั้งแรกขณะออกแรง (exerciscinduced - hypoxemia) หรือภาพรังสีทรวงอกมี progression ของ pulmonary infitrates', completed: false, color: 'primary', value: 2 },
      // { name: 'ปอดบวมที่มี hypoxic (risting O2 saturation < 96%) หรือมีภาวะลดลงของออกซิเจน SpO2 >= 3 % ', completed: false, color: 'primary', value: 21 },
    ]
  };

  allComplete: boolean = false;

  updateAllComplete(check: number) {
    this.dataSerach.SearchFeverish = this.task.subtasks![0].completed;
    this.dataSerach.SearchCoughing_up_blood = this.task.subtasks![1].completed;
    this.dataSerach.SearchSore_throat = this.task.subtasks![2].completed;
    this.dataSerach.SearchLie_down_breath = this.task.subtasks![3].completed;
    this.dataSerach.SearchMuscle_pain = this.task.subtasks![4].completed;
    this.dataSerach.SearchRunny = this.task.subtasks![5].completed;
    this.dataSerach.SearchSputum = this.task.subtasks![6].completed;
    this.dataSerach.SearchBreathing_diff = this.task.subtasks![7].completed;
    this.dataSerach.SearchTired_sit_still = this.task.subtasks![8].completed;

    this.dataSerach.SearchCoughAlot = this.task.subtasks![9].completed;
    this.dataSerach.SearchCoughAlittle = this.task.subtasks![10].completed;
    this.dataSerach.SearchCanNotEat = this.task.subtasks![11].completed;

    this.dataSerach.SearchHeadache = this.task.subtasks![12].completed;
    this.dataSerach.SearchLiquid_stool = this.task.subtasks![13].completed;
    this.dataSerach.SearchNose_no_smell = this.task.subtasks![14].completed;
    this.dataSerach.SearchRash = this.task.subtasks![15].completed;
    this.dataSerach.SearchTried_daily_activities = this.task.subtasks![16].completed;
    this.dataSerach.SearchTongue_no_taste = this.task.subtasks![17].completed;
    this.loadDataSearch(this.dataSerach);

    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.dataSerach.SearchFeverish = completed;
    this.dataSerach.SearchCoughing_up_blood = completed;
    this.dataSerach.SearchSore_throat = completed;
    this.dataSerach.SearchLie_down_breath = completed;
    this.dataSerach.SearchMuscle_pain = completed;
    this.dataSerach.SearchRunny = completed;
    this.dataSerach.SearchSputum = completed;
    this.dataSerach.SearchBreathing_diff = completed;
    this.dataSerach.SearchTired_sit_still = completed;
    this.dataSerach.SearchCoughAlittle = completed;
    this.dataSerach.SearchCoughAlot = completed;
    this.dataSerach.SearchCanNotEat = completed;
    this.dataSerach.SearchHeadache = completed;
    this.dataSerach.SearchLiquid_stool = completed;
    this.dataSerach.SearchNose_no_smell = completed;
    this.dataSerach.SearchRash = completed;
    this.dataSerach.SearchTried_daily_activities = completed;
    this.dataSerach.SearchTongue_no_taste = completed;
    this.loadDataSearch(this.dataSerach);
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => t.completed = completed);
  }
  pressureStart!: string;
  pressureEnd!: string;
  HeartrateStart!: string;
  HeartrateEnd!: string;
  TemperatureStart!: string;
  TemperatureEnd!: string;
  Spo2Start!: string;
  Spo2End!: string;
  SearchNameIDPhone!: string;
  SearchDateStart!: string;
  SearchDateEnd!: string;
  selectedValue!: null;
  onClickClear() {
    this.pressureStart = '';
    this.pressureEnd = '';
    this.HeartrateStart = '';
    this.HeartrateEnd = '';
    this.TemperatureStart = '';
    this.TemperatureEnd = '';
    this.Spo2Start = '';
    this.Spo2End = '';
    this.SearchNameIDPhone = '';
    this.SearchDateStart = '';
    this.SearchDateEnd = '';
    this.selectedValue = null;

    this.dataSerach = {
      "SearchCountList": "20",
      "SearchTypeUser": this.typeName,
      "SearchPressureHighStart": "",
      "SearchPressureHighEnd": "",
      "SearchPressureLowStart": "",
      "SearchPressureLowEnd": "",
      "SearchHeartrateStart": "",
      "SearchHeartrateEnd": "",

      "SearchSpo2AfterExercise": "",
      "SearchSpo2Ventilator": "",

      "SearchTempStart": "",
      "SearchTempEnd": "",
      "SearchSpo2Start": "",
      "SearchSpo2End": "",
      "SearchFeverish": false,
      "SearchCoughing_up_blood": false,
      "SearchSore_throat": false,
      "SearchLie_down_breath": false,
      "SearchMuscle_pain": false,
      "SearchRunny": false,
      "SearchSputum": false,
      "SearchBreathing_diff": false,
      "SearchTired_sit_still": false,
      "SearchHeadache": false,
      "SearchLiquid_stool": false,
      "SearchNose_no_smell": false,
      "SearchRash": false,
      "SearchTried_daily_activities": false,
      "SearchTongue_no_taste": false,

      "SearchCoughAlittle": false,
      "SearchCoughAlot": false,
      "SearchCanNotEat": false
    };
    this.setAll(false);
    this.loadDataSearch(this.dataSerach);
  }

  async loadData(data_: any) {
    return new Promise<any>(async (resolve, rejects) => {
      var dataSearch: any;
      var result: any = [];
      var dataCovid: any;
      var covidRed = false;
      var covidYellow = false;
      var covidGreen = false;
      var checkCovidRed = false;
      var checkCovidYellow = false;
      var checkCovidGreen = false;

      var dataToday = "";

      await this.ServiceService.GetListUserCovid19_(data_).then(async resDataSearch => {
        dataSearch = resDataSearch;
        // console.log(dataSearch);
        if (dataSearch.length != 0) {

          for (let i = 0; i < dataSearch.length; i++) {
            await this.ServiceService.GetCovidSymptomV4_(dataSearch[i].userId).then(resCovidSymptom => {
              // console.log(i, " : ", resCovidSymptom);
              dataCovid = resCovidSymptom;
              covidGreen = false;
              covidYellow = false;
              covidRed = false;

              if (dataCovid.length != 0) {
                for (let j = 0; j < dataCovid.length; j++) {
                  var currentDate = new Date(dataCovid[j].cDate);
                  var today = new Date();
                  var day_ = Number(today.getDate()) - Number(currentDate.getDate());
                  if (day_ == 0 && dataToday == "") {
                    if (dataCovid[j].cFeverish == true || dataCovid[j].cSoreThroat == true || dataCovid[j].cMusclePain == true || dataCovid[j].cRunny == true || dataCovid[j].cSputum == true ||
                      dataCovid[j].cNoseNoSmell == true || dataCovid[j].cRash == true || dataCovid[j].cHeadache == true || dataCovid[j].cLiquidStool == true ||
                      dataCovid[j].cSpo2 > 96) {
                      checkCovidGreen = true;
                    }
                    if (dataCovid[j].cCoughingUpBlood == true || dataCovid[j].cBreathingDiff == true || dataCovid[j].cTiredSitStill == true || dataCovid[j].cTongueNoTaste == true ||
                      dataCovid[j].cSpo2 <= 96) {
                      checkCovidYellow = true;
                    }
                    if (dataCovid[j].cLieDownBreath == true || dataCovid[j].cTriedDailyActivities == true || dataCovid[j].cSpo2 <= 94) {
                      checkCovidRed = true;
                    }

                    if (checkCovidRed == true) {
                      covidRed = true;
                      covidYellow = false;
                      covidGreen = false;

                    } else if (checkCovidYellow == true) {
                      covidRed = false;
                      covidYellow = true;
                      covidGreen = false;
                    } else if (checkCovidGreen == true) {
                      covidRed = false;
                      covidYellow = false;
                      covidGreen = true;
                    }
                    result.push({ "dataUser": dataSearch[i], "dataCovid": dataCovid[0], "covidRed": covidRed, "covidYellow": covidYellow, "covidGreen": covidGreen });
                    dataToday = "0";
                    break;
                  } else {
                    result.push({ "dataUser": dataSearch[i], "dataCovid": dataCovid[0], "covidRed": covidRed, "covidYellow": covidYellow, "covidGreen": covidGreen });
                    break;
                  }
                }

              } else {
                result.push({ "dataUser": dataSearch[i], "dataCovid": dataCovid[0], "covidRed": covidRed, "covidYellow": covidYellow, "covidGreen": covidGreen });
              }
            });

            if (i == dataSearch.length - 1) {
              resolve(result);
            }

          }

        }
        else {
          resolve("0");
        }
      });
    });
  }
}
