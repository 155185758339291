<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 style="text-align: center;">Doctor Alert</h1>
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content pl-2 pr-2">
    <div class="mat-elevation-z8 card card-solid">
        <div class="card-body">
            <div class="container">
                <div class="row justify-content-around">
                    <div class="col-12">
                        <h3> <i class="fas fa-bullhorn"></i> Notification Symptom</h3>
                    </div>
                </div>
            </div>
            <mat-spinner *ngIf="loadDataCodiv19Alert == true"
                style="margin-top: 160px;margin-bottom: 160px;margin-left: auto;margin-right: auto;"></mat-spinner>

            <!-- <div *ngIf="noDataAlert == true " class="col-12" style="margin-top: 160px;margin-bottom: 160px;margin-left: auto;margin-right: auto;">
                <p style="text-align: center;">No data Alert</p>
            </div> -->

            <div *ngIf="loadDataCodiv19Alert == false">
                <div *ngFor="let itemDataLogCodiv19 of resultDataLogCodiv19">
                    <div class="container">
                        <div *ngIf="itemDataLogCodiv19.daType != 'Green --> Yellow' &&
                            itemDataLogCodiv19.daType != 'Green --> Red' &&
                            itemDataLogCodiv19.daType != 'Yellow --> Red' &&
                            itemDataLogCodiv19.alertMsg != 'Green --> Yellow' &&
                            itemDataLogCodiv19.alertMsg != 'Green --> Red' &&
                            itemDataLogCodiv19.alertMsg != 'Yellow --> Red' &&
                            itemDataLogCodiv19.alertMsg != 'SpO2 Fail' &&
                            itemDataLogCodiv19.daType != 'SpO2 Fail' &&
                            itemDataLogCodiv19.alertMsg != 'Feverish 5 Days' &&
                            itemDataLogCodiv19.daType != 'Feverish 5 Days'  &&
                            itemDataLogCodiv19.alertMsg != 'Temp >= 37.5 --> 5 Days' &&
                            itemDataLogCodiv19.daType != 'Temp >= 37.5 --> 5 Days'"
                            (click)="onClickOpenProfileUserCovid19(itemDataLogCodiv19)"
                            class="pointer hoverBtn pulse callout callout-danger mat-elevation-z8"
                            style="margin-bottom: 8px;">
                            <div class="row">
                                <div class="col-6">
                                    <div class="direct-chat-infos clearfix" style="margin: auto;">
                                        <div
                                            *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                            <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}"
                                                onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                alt="User Image">
                                        </div>
                                        <ng-template #elseBlock>
                                            <img src="assets/adminlte/dist/img/nouser.svg"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;" alt="User Image">
                                        </ng-template>
                                        <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                        <h3 style="margin: 0;padding: 0px;">ขอความช่วยเหลือ <b>SOS</b></h3>
                                        <div class="row">
                                            <p style="padding-right: 5px;"><b>SpO2 : </b>
                                                {{itemDataLogCodiv19.cSpo2}}
                                            </p>
                                            <p style="padding-right: 5px;"><b>SBP : </b>
                                                {{itemDataLogCodiv19.cPressureHigh}}</p>
                                            <p style="padding-right: 5px;"><b>DBP : </b>
                                                {{itemDataLogCodiv19.cPressureLow}}</p>
                                            <p style="padding-right: 5px;"><b>P : </b>
                                                {{itemDataLogCodiv19.cHeartrate}}</p>
                                            <p style="padding-right: 5px;"><b>T : </b> {{itemDataLogCodiv19.cTemp}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">

                                    <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.daDateTime
                                        |
                                        date:'short'}}</span>
                                    <div class="row" style="display: flex;justify-content: flex-end;margin-top: 55px;">
                                        <span>Status before : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745; margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Red'"></span>
                                        <!-- </div>
                                <div class="row" style="margin-left: 0px;margin-top: 5px;"> -->
                                        <span style="margin-left: 5px;">Status current : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Red'"></span>

                                        <span style="margin-left: 5px;">Status doctor : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Red'"></span>
                                        <span class="starus-covid-null"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus != 'Red' && itemDataLogCodiv19.dpDocCovidStatus != 'Yellow' && itemDataLogCodiv19.dpDocCovidStatus != 'Green'"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="itemDataLogCodiv19.alertMsg == 'SpO2 Fail' || itemDataLogCodiv19.daType == 'SpO2 Fail'"
                            (click)="onClickOpenProfileUserCovid19(itemDataLogCodiv19)"
                            class="pointer hoverBtn pulse callout callout-danger mat-elevation-z8"
                            style="margin-bottom: 8px;">
                            <div class="row">
                                <div class="col-6">
                                    <div class="direct-chat-infos clearfix" style="margin: auto;">
                                        <div
                                            *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                            <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}"
                                                onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                alt="User Image">
                                        </div>
                                        <ng-template #elseBlock>
                                            <img src="assets/adminlte/dist/img/nouser.svg"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;" alt="User Image">
                                        </ng-template>
                                        <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                        <h3 style="margin: 0;padding: 0px;"><b>อาการ</b> :
                                            <span>SpO2 Fail <b style="color:#e81426">{{itemDataLogCodiv19.cSpo2}}</b></span>
                                        </h3>
                                        <div class="row">
                                            <p style="padding-right: 5px;"><b>SpO2 : </b> {{itemDataLogCodiv19.cSpo2}}
                                            </p>
                                            <p style="padding-right: 5px;"><b>SBP : </b>
                                                {{itemDataLogCodiv19.cPressureHigh}}</p>
                                            <p style="padding-right: 5px;"><b>DBP : </b>
                                                {{itemDataLogCodiv19.cPressureLow}}</p>
                                            <p style="padding-right: 5px;"><b>P : </b>
                                                {{itemDataLogCodiv19.cHeartrate}}</p>
                                            <p style="padding-right: 5px;"><b>T : </b> {{itemDataLogCodiv19.cTemp}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">

                                    <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.daDateTime |
                                        date:'short'}}</span>
                                    <div class="row" style="display: flex;justify-content: flex-end;margin-top: 55px;">
                                        <span>Status before : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745; margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Red'"></span>
                                        <!-- </div>
                                <div class="row" style="margin-left: 0px;margin-top: 5px;"> -->
                                        <span style="margin-left: 5px;">Status current : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Red'"></span>

                                        <span style="margin-left: 5px;">Status doctor : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Red'"></span>
                                        <span class="starus-covid-null"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus != 'Red' && itemDataLogCodiv19.dpDocCovidStatus != 'Yellow' && itemDataLogCodiv19.dpDocCovidStatus != 'Green'"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="itemDataLogCodiv19.alertMsg == 'Feverish 5 Days' || itemDataLogCodiv19.daType == 'Feverish 5 Days'"
                            (click)="onClickOpenProfileUserCovid19(itemDataLogCodiv19)"
                            class="pointer hoverBtn pulse callout callout-danger mat-elevation-z8"
                            style="margin-bottom: 8px;">
                            <div class="row">
                                <div class="col-6">
                                    <div class="direct-chat-infos clearfix" style="margin: auto;">
                                        <div
                                            *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                            <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}"
                                                onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                alt="User Image">
                                        </div>
                                        <ng-template #elseBlock>
                                            <img src="assets/adminlte/dist/img/nouser.svg"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;" alt="User Image">
                                        </ng-template>
                                        <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                        <h3 style="margin: 0;padding: 0px;"><b>อาการ</b> :
                                            <span>มีไข้ติดกัน 5 วัน </span>
                                            <span *ngIf="itemDataLogCodiv19.cSoreThroat == true">เจ็บคอ </span>
                                        </h3>
                                        <div class="row">
                                            <p style="padding-right: 5px;"><b>SpO2 : </b> {{itemDataLogCodiv19.cSpo2}}
                                            </p>
                                            <p style="padding-right: 5px;"><b>SBP : </b>
                                                {{itemDataLogCodiv19.cPressureHigh}}</p>
                                            <p style="padding-right: 5px;"><b>DBP : </b>
                                                {{itemDataLogCodiv19.cPressureLow}}</p>
                                            <p style="padding-right: 5px;"><b>P : </b>
                                                {{itemDataLogCodiv19.cHeartrate}}</p>
                                            <p style="padding-right: 5px;"><b>T : </b> {{itemDataLogCodiv19.cTemp}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">

                                    <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.daDateTime |
                                        date:'short'}}</span>
                                    <div class="row" style="display: flex;justify-content: flex-end;margin-top: 55px;">
                                        <span>Status before : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745; margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Red'"></span>
                                        <!-- </div>
                            <div class="row" style="margin-left: 0px;margin-top: 5px;"> -->
                                        <span style="margin-left: 5px;">Status current : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Red'"></span>

                                        <span style="margin-left: 5px;">Status doctor : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Red'"></span>
                                        <span class="starus-covid-null"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus != 'Red' && itemDataLogCodiv19.dpDocCovidStatus != 'Yellow' && itemDataLogCodiv19.dpDocCovidStatus != 'Green'"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="itemDataLogCodiv19.alertMsg == 'Temp >= 37.5 --> 5 Days' || itemDataLogCodiv19.daType == 'Temp >= 37.5 --> 5 Days'"
                            (click)="onClickOpenProfileUserCovid19(itemDataLogCodiv19)"
                            class="pointer hoverBtn pulse callout callout-danger mat-elevation-z8"
                            style="margin-bottom: 8px;">
                            <div class="row">
                                <div class="col-6">
                                    <div class="direct-chat-infos clearfix" style="margin: auto;">
                                        <div
                                            *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                            <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}"
                                                onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                alt="User Image">
                                        </div>
                                        <ng-template #elseBlock>
                                            <img src="assets/adminlte/dist/img/nouser.svg"
                                                class="float-left img-circle elevation-2"
                                                style="width: 70px; height: 70px; margin-right: 10px;" alt="User Image">
                                        </ng-template>
                                        <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                        <h3 style="margin: 0;padding: 0px;"><b>อาการ</b> :
                                            <span>อุณหภูมิ >= 37.5 ติดต่อกัน 5 วัน</span>
                                            <span *ngIf="itemDataLogCodiv19.cSoreThroat == true">เจ็บคอ </span>
                                        </h3>
                                        <div class="row">
                                            <p style="padding-right: 5px;"><b>SpO2 : </b> {{itemDataLogCodiv19.cSpo2}}
                                            </p>
                                            <p style="padding-right: 5px;"><b>SBP : </b>
                                                {{itemDataLogCodiv19.cPressureHigh}}</p>
                                            <p style="padding-right: 5px;"><b>DBP : </b>
                                                {{itemDataLogCodiv19.cPressureLow}}</p>
                                            <p style="padding-right: 5px;"><b>P : </b>
                                                {{itemDataLogCodiv19.cHeartrate}}</p>
                                            <p style="padding-right: 5px;"><b>T : </b> {{itemDataLogCodiv19.cTemp}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">

                                    <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.daDateTime |
                                        date:'short'}}</span>
                                    <div class="row" style="display: flex;justify-content: flex-end;margin-top: 55px;">
                                        <span>Status before : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745; margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 9px;"
                                            *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Red'"></span>
                                        <!-- </div>
                            <div class="row" style="margin-left: 0px;margin-top: 5px;"> -->
                                        <span style="margin-left: 5px;">Status current : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Red'"></span>

                                        <span style="margin-left: 5px;">Status doctor : </span>
                                        <span class="starus-covid-green"
                                            style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Green'"></span>

                                        <span class="starus-covid-yellow"
                                            style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Yellow'"></span>

                                        <span class="starus-covid-red"
                                            style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Red'"></span>
                                        <span class="starus-covid-null"
                                            *ngIf="itemDataLogCodiv19.dpDocCovidStatus != 'Red' && itemDataLogCodiv19.dpDocCovidStatus != 'Yellow' && itemDataLogCodiv19.dpDocCovidStatus != 'Green'"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="itemDataLogCodiv19.daType == 'Green --> Yellow' || itemDataLogCodiv19.daType == 'Green --> Red' || itemDataLogCodiv19.daType == 'Yellow --> Red' || itemDataLogCodiv19.alertMsg == 'Green --> Yellow' || itemDataLogCodiv19.alertMsg == 'Green --> Red' || itemDataLogCodiv19.alertMsg == 'Yellow --> Red'">

                            <div *ngIf="itemDataLogCodiv19.cDangerLevel == 'Red' || itemDataLogCodiv19.alertMsg == 'Green --> Red' || itemDataLogCodiv19.alertMsg == 'Yellow --> Red'"
                                (click)="onClickOpenProfileUserCovid19(itemDataLogCodiv19)"
                                class="pointer hoverBtn pulse callout callout-danger mat-elevation-z8"
                                style="margin-bottom: 8px;">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="direct-chat-infos clearfix" style="margin: auto;">
                                            <div
                                                *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                                <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}"
                                                    onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                    alt="User Image">
                                            </div>
                                            <ng-template #elseBlock>
                                                <img src="assets/adminlte/dist/img/nouser.svg"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;"
                                                    alt="User Image">
                                            </ng-template>
                                            <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                            <h3 style="margin: 0;padding: 0px;"><b>อาการ</b> :
                                                <span *ngIf="itemDataLogCodiv19.cFeverish == true">มีไข้ </span>
                                                <span *ngIf="itemDataLogCodiv19.cSoreThroat == true">เจ็บคอ </span>
                                                <span *ngIf="itemDataLogCodiv19.cMusclePain == true">ปวดกล้ามเนื้อ
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cRunny == true">มีน้ำมูก </span>
                                                <span *ngIf="itemDataLogCodiv19.cSputum == true">มีเสมหะ </span>
                                                <span *ngIf="itemDataLogCodiv19.cHeadache == true">ปวดหัว </span>
                                                <span *ngIf="itemDataLogCodiv19.cNoseNoSmell == true">จมูกไม่ได้กลิ่น
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cRash == true">มีผื่น </span>
                                                <span *ngIf="itemDataLogCodiv19.cLiquidStool == true">ถ่ายเหลว
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cCoughAlittle == true">ไอเล็กน้อย
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cTongueNoTaste == true">ลิ้นไม่รับรส
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cConjunctivitis == true">ตาแดง </span>

                                                <span *ngIf="itemDataLogCodiv19.cCanNotEat == true">ทานอาหารไม่ได้
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cCoughAlot == true">ไอเยอะ
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cCoughingUpBlood == true">ไอเป็นเลือด
                                                </span>
                                                <span *ngIf="itemDataLogCodiv19.cBreathingDiff == true">หายใจลำบาก
                                                    แน่นหน้าอก
                                                </span>
                                                <span
                                                    *ngIf="itemDataLogCodiv19.cTriedDailyActivities == true">เหนื่อยในขณะที่ทำกิจวัตรประจำวัน
                                                </span>
                                                <span
                                                    *ngIf="itemDataLogCodiv19.cLieDownBreath == true">นอนราบแล้วหายใจไม่ออก
                                                </span>
                                                <span
                                                    *ngIf="itemDataLogCodiv19.cTiredSitStill == true">เหนื่อยขณะนั่งเฉยๆ
                                                </span>
                                            </h3>
                                            <div class="row">
                                                <p style="padding-right: 5px;"><b>SpO2 : </b>
                                                    {{itemDataLogCodiv19.cSpo2}}
                                                </p>
                                                <p style="padding-right: 5px;"><b>SBP : </b>
                                                    {{itemDataLogCodiv19.cPressureHigh}}</p>
                                                <p style="padding-right: 5px;"><b>DBP : </b>
                                                    {{itemDataLogCodiv19.cPressureLow}}</p>
                                                <p style="padding-right: 5px;"><b>P : </b>
                                                    {{itemDataLogCodiv19.cHeartrate}}</p>
                                                <p style="padding-right: 5px;"><b>T : </b> {{itemDataLogCodiv19.cTemp}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">

                                        <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.daDateTime
                                            |
                                            date:'short'}}</span>
                                        <div class="row"
                                            style="display: flex;justify-content: flex-end;margin-top: 55px;">
                                            <span>Status before : </span>
                                            <span class="starus-covid-green"
                                                style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745; margin-left: 9px;"
                                                *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Green'"></span>

                                            <span class="starus-covid-yellow"
                                                style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 9px;"
                                                *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Yellow'"></span>

                                            <span class="starus-covid-red"
                                                style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 9px;"
                                                *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Red'"></span>
                                            <!-- </div>
                                    <div class="row" style="margin-left: 0px;margin-top: 5px;"> -->
                                            <span style="margin-left: 5px;">Status current : </span>
                                            <span class="starus-covid-green"
                                                style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                                *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Green'"></span>

                                            <span class="starus-covid-yellow"
                                                style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                                *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Yellow'"></span>

                                            <span class="starus-covid-red"
                                                style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                                *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Red'"></span>

                                            <span style="margin-left: 5px;">Status doctor : </span>
                                            <span class="starus-covid-green"
                                                style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                                *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Green'"></span>

                                            <span class="starus-covid-yellow"
                                                style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                                *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Yellow'"></span>

                                            <span class="starus-covid-red"
                                                style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                                *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Red'"></span>
                                            <span class="starus-covid-null"
                                                *ngIf="itemDataLogCodiv19.dpDocCovidStatus != 'Red' && itemDataLogCodiv19.dpDocCovidStatus != 'Yellow' && itemDataLogCodiv19.dpDocCovidStatus != 'Green'"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="itemDataLogCodiv19.cDangerLevel == 'Yellow' || itemDataLogCodiv19.alertMsg == 'Green --> Yellow'"
                                (click)="onClickOpenProfileUserCovid19(itemDataLogCodiv19)"
                                class="pointer hoverBtn pulse callout callout-warning mat-elevation-z8"
                                style="margin-bottom: 8px;">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="direct-chat-infos clearfix" style="margin: auto;">
                                                <div
                                                    *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                                    <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}"
                                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                        class="float-left img-circle elevation-2"
                                                        style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                        alt="User Image">
                                                </div>
                                                <ng-template #elseBlock>
                                                    <img src="assets/adminlte/dist/img/nouser.svg"
                                                        class="float-left img-circle elevation-2"
                                                        style="width: 70px; height: 70px; margin-right: 10px;"
                                                        alt="User Image">
                                                </ng-template>
                                                <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                                <h3 style="margin: 0;padding: 0px;"><b>อาการ</b> :
                                                    <span *ngIf="itemDataLogCodiv19.cFeverish == true">มีไข้ </span>
                                                    <span *ngIf="itemDataLogCodiv19.cSoreThroat == true">เจ็บคอ </span>
                                                    <span *ngIf="itemDataLogCodiv19.cMusclePain == true">ปวดกล้ามเนื้อ
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cRunny == true">มีน้ำมูก </span>
                                                    <span *ngIf="itemDataLogCodiv19.cSputum == true">มีเสมหะ </span>
                                                    <span *ngIf="itemDataLogCodiv19.cHeadache == true">ปวดหัว </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cNoseNoSmell == true">จมูกไม่ได้กลิ่น
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cRash == true">มีผื่น </span>
                                                    <span *ngIf="itemDataLogCodiv19.cLiquidStool == true">ถ่ายเหลว
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cCoughAlittle == true">ไอเล็กน้อย
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cTongueNoTaste == true">ลิ้นไม่รับรส
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cConjunctivitis == true">ตาแดง
                                                    </span>

                                                    <span *ngIf="itemDataLogCodiv19.cCanNotEat == true">ทานอาหารไม่ได้
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cCoughAlot == true">ไอเยอะ
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cCoughingUpBlood == true">ไอเป็นเลือด
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cBreathingDiff == true">หายใจลำบาก
                                                        แน่นหน้าอก
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cTriedDailyActivities == true">เหนื่อยในขณะที่ทำกิจวัตรประจำวัน
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cLieDownBreath == true">นอนราบแล้วหายใจไม่ออก
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cTiredSitStill == true">เหนื่อยขณะนั่งเฉยๆ
                                                    </span>
                                                </h3>
                                                <div class="row">
                                                    <p style="padding-right: 5px;"><b>SpO2 : </b>
                                                        {{itemDataLogCodiv19.cSpo2}}
                                                    </p>
                                                    <p style="padding-right: 5px;"><b>SBP : </b>
                                                        {{itemDataLogCodiv19.cPressureHigh}}</p>
                                                    <p style="padding-right: 5px;"><b>DBP : </b>
                                                        {{itemDataLogCodiv19.cPressureLow}}</p>
                                                    <p style="padding-right: 5px;"><b>P : </b>
                                                        {{itemDataLogCodiv19.cHeartrate}}</p>
                                                    <p style="padding-right: 5px;"><b>T : </b>
                                                        {{itemDataLogCodiv19.cTemp}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">

                                            <span
                                                class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.daDateTime
                                                |
                                                date:'short'}}</span>
                                            <div class="row"
                                                style="display: flex;justify-content: flex-end;margin-top: 55px;">
                                                <span>Status before : </span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745; margin-left: 9px;"
                                                    *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 9px;"
                                                    *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 9px;"
                                                    *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Red'"></span>
                                                <!-- </div>
                                    <div class="row" style="margin-left: 0px;margin-top: 5px;"> -->
                                                <span style="margin-left: 5px;">Status current : </span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Red'"></span>

                                                <span style="margin-left: 5px;">Status doctor : </span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Red'"></span>
                                                <span class="starus-covid-null"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus != 'Red' && itemDataLogCodiv19.dpDocCovidStatus != 'Yellow' && itemDataLogCodiv19.dpDocCovidStatus != 'Green'"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="itemDataLogCodiv19.cDangerLevel == 'Green' || itemDataLogCodiv19.alertMsg == 'Symptom'"
                                (click)="onClickOpenProfileUserCovid19(itemDataLogCodiv19)"
                                class="pointer hoverBtn pulse callout callout-success mat-elevation-z8"
                                style="margin-bottom: 8px;">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="direct-chat-infos clearfix" style="margin: auto;">
                                                <div
                                                    *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                                    <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}"
                                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                        class="float-left img-circle elevation-2"
                                                        style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                        alt="User Image">
                                                </div>
                                                <ng-template #elseBlock>
                                                    <img src="assets/adminlte/dist/img/nouser.svg"
                                                        class="float-left img-circle elevation-2"
                                                        style="width: 70px; height: 70px; margin-right: 10px;"
                                                        alt="User Image">
                                                </ng-template>
                                                <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                                <h3 style="margin: 0;padding: 0px;"><b>อาการ</b> :
                                                    <span *ngIf="itemDataLogCodiv19.cFeverish == true">มีไข้ </span>
                                                    <span *ngIf="itemDataLogCodiv19.cSoreThroat == true">เจ็บคอ </span>
                                                    <span *ngIf="itemDataLogCodiv19.cMusclePain == true">ปวดกล้ามเนื้อ
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cRunny == true">มีน้ำมูก </span>
                                                    <span *ngIf="itemDataLogCodiv19.cSputum == true">มีเสมหะ </span>
                                                    <span *ngIf="itemDataLogCodiv19.cHeadache == true">ปวดหัว </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cNoseNoSmell == true">จมูกไม่ได้กลิ่น
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cRash == true">มีผื่น </span>
                                                    <span *ngIf="itemDataLogCodiv19.cLiquidStool == true">ถ่ายเหลว
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cCoughAlittle == true">ไอเล็กน้อย
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cTongueNoTaste == true">ลิ้นไม่รับรส
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cConjunctivitis == true">ตาแดง
                                                    </span>

                                                    <span *ngIf="itemDataLogCodiv19.cCanNotEat == true">ทานอาหารไม่ได้
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cCoughAlot == true">ไอเยอะ
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cCoughingUpBlood == true">ไอเป็นเลือด
                                                    </span>
                                                    <span *ngIf="itemDataLogCodiv19.cBreathingDiff == true">หายใจลำบาก
                                                        แน่นหน้าอก
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cTriedDailyActivities == true">เหนื่อยในขณะที่ทำกิจวัตรประจำวัน
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cLieDownBreath == true">นอนราบแล้วหายใจไม่ออก
                                                    </span>
                                                    <span
                                                        *ngIf="itemDataLogCodiv19.cTiredSitStill == true">เหนื่อยขณะนั่งเฉยๆ
                                                    </span>
                                                </h3>
                                                <div class="row">
                                                    <p style="padding-right: 5px;"><b>SpO2 : </b>
                                                        {{itemDataLogCodiv19.cSpo2}}
                                                    </p>
                                                    <p style="padding-right: 5px;"><b>SBP : </b>
                                                        {{itemDataLogCodiv19.cPressureHigh}}</p>
                                                    <p style="padding-right: 5px;"><b>DBP : </b>
                                                        {{itemDataLogCodiv19.cPressureLow}}</p>
                                                    <p style="padding-right: 5px;"><b>P : </b>
                                                        {{itemDataLogCodiv19.cHeartrate}}</p>
                                                    <p style="padding-right: 5px;"><b>T : </b>
                                                        {{itemDataLogCodiv19.cTemp}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">

                                            <span
                                                class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.daDateTime
                                                |
                                                date:'short'}}</span>
                                            <div class="row"
                                                style="display: flex;justify-content: flex-end;margin-top: 55px;">
                                                <span>Status before : </span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745; margin-left: 9px;"
                                                    *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 9px;"
                                                    *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 9px;"
                                                    *ngIf="itemDataLogCodiv19.dpOldCovidStatus == 'Red'"></span>
                                                <!-- </div>
                                    <div class="row" style="margin-left: 0px;margin-top: 5px;"> -->
                                                <span style="margin-left: 5px;">Status current : </span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpCovidStatus == 'Red'"></span>

                                                <span style="margin-left: 5px;">Status doctor : </span>
                                                <span class="starus-covid-green"
                                                    style="background-color: #2aa745;box-shadow: 0 0 8px 0 #2aa745, 0 0 5px 0 #2aa745;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Green'"></span>

                                                <span class="starus-covid-yellow"
                                                    style="background-color: #ffc107;box-shadow: 0 0 8px 0 #ffc107, 0 0 5px 0 #ffc107;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Yellow'"></span>

                                                <span class="starus-covid-red"
                                                    style="background-color: #e81426;box-shadow: 0 0 8px 0 #e81426, 0 0 5px 0 #e81426;margin-left: 5px;"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus == 'Red'"></span>
                                                <span class="starus-covid-null"
                                                    *ngIf="itemDataLogCodiv19.dpDocCovidStatus != 'Red' && itemDataLogCodiv19.dpDocCovidStatus != 'Yellow' && itemDataLogCodiv19.dpDocCovidStatus != 'Green'"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
