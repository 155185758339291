<div class="content" *ngIf="showRefer == false">
    <div class="row" style="display: flex;justify-content: space-between;">
        <h3>Refer ใบส่งผู้ป่วย Covid19</h3>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="referUserForm" style="display: contents;">
            <table class="table table-bordered border-secondary" width="100%">

                <tr>
                    <td colspan="8" align="center"><b>แบบฟอร์ม Refer ผู้ป่วย Covid19 จากระบบ SaveThaiFightCovid19 เข้า
                            รพ
                            <mat-form-field appearance="outline">
                                <mat-label>Please enter information</mat-label>
                                <input matInput placeholder="Placeholder" formControlName="referHospital1">
                                <mat-hint *ngIf="requiredReferHospital1 == true" style="color: red">Please enter information</mat-hint>
                            </mat-form-field>
                        </b></td>
                </tr>

                <tr>
                    <td colspan="4" width="50%"><b>DOI</b> : {{dataReferUserCovid19.doctorMall.dmRequestTime | date:'shortDate'}}</td>
                    <td colspan="4"><b>ชื่อ</b> {{dataReferUserCovid19.dataUser.userName}} &nbsp;&nbsp; <b>วันเกิด</b> :
                        {{dataReferUserCovid19.dataUser.userDate | date:'shortDate'}} &nbsp;&nbsp;
                        <b>เพศ</b> : ชาย
                    </td>
                </tr>

                <tr>
                    <td colspan="4" width="50%" *ngIf="dataReferUserCovid19.personalHealth != '' && dataReferUserCovid19.personalHealth != null"><b>date PCR/ATK</b> : {{dataReferUserCovid19.personalHealth.pAtkRtpcr | date:'short'}}</td>
                    <td colspan="4" width="50%" *ngIf="dataReferUserCovid19.personalHealth == '' || dataReferUserCovid19.personalHealth == null"><b>date PCR/ATK</b> : -</td>
                    <td colspan="4"><b>อายุ</b> : {{dataReferUserCovid19.dataUser.userAge}} ปี &nbsp;&nbsp;
                        <b>โรงพยาบาลต้นสังกัด</b> : <mat-form-field appearance="outline">
                            <mat-label>Please enter information</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="referHospitalAffiliation">
                            <mat-hint *ngIf="requiredReferHospitalAffiliation == true" style="color: red">Please enter information</mat-hint>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td colspan="4" width="50%"><b>วันเข้า Home Isolate</b> :
                        {{dataReferUserCovid19.doctorMall.dmRequestTime | date:'shortDate'}}</td>
                    <td colspan="4">
                        <p><b>ที่อยู่</b> : {{dataReferUserCovid19.dataUser.userAddress}}
                            {{dataReferUserCovid19.dataUser.userSubdistrict}},
                            {{dataReferUserCovid19.dataUser.userDistrict}},
                            {{dataReferUserCovid19.dataUser.userProvince}},
                            {{dataReferUserCovid19.dataUser.userPostalcode}}
                        </p>
                        <p><b>สิทธิการรักษา : <mat-form-field appearance="outline">
                                    <mat-label>Please enter information</mat-label>
                                    <input matInput placeholder="Placeholder" formControlName="referRightToTreatment">
                                    <mat-hint *ngIf="requiredReferRightToTreatment == true" style="color: red">Please enter information</mat-hint>
                                </mat-form-field></b></p>

                    </td>
                </tr>

                <tr>
                    <td rowspan="2" colspan="4">
                        <div class="row">
                            <div style="margin-top: auto;margin-bottom: auto; padding-left: 8px">
                                <span class="tag tag-danger"><b>Risk Factor :
                                    </b></span>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคเบาหวาน'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>DM
                                        </b></span>
                                </div>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคความดัน'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>HT
                                        </b></span>
                                </div>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคหัวใจ'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>CVD
                                        </b></span>
                                </div>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคไต'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>CKD
                                        </b></span>
                                </div>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคตับ'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>CLD
                                        </b></span>
                                </div>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคปอด'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>COPD
                                        </b></span>
                                </div>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคอ้วน'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>DLP
                                        </b></span>
                                </div>
                            </div>
                            <div *ngFor="let item of dataPersonalHealth">
                                <div class="ml-2" *ngIf="item == 'โรคเส้นเลือดสมอง'">
                                    <span class="tag tag-danger" style="font-size: 16px;"><b>CVA
                                        </b></span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="4"><b>เบอร์ติดต่อผู้ป่วย / ญาติ</b> :
                        {{dataReferUserCovid19.dataUser.reletiveNumber1}},
                        {{dataReferUserCovid19.dataUser.reletiveNumber2}}</td>
                </tr>

                <tr>
                    <td colspan="4"><b>เบอร์ติดต่อ Admin / ประสาน</b> : <mat-form-field appearance="outline">
                            <mat-label>Please enter information</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="referPhoneAdmin">
                            <mat-hint *ngIf="requiredReferPhoneAdmin == true" style="color: red">Please enter information</mat-hint>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td colspan="8"><b>Vital sign</b> : <b>T</b> {{dataReferUserCovid19.covidSymptom.cTemp}}
                        &nbsp;&nbsp;&nbsp; <b>P</b> {{dataReferUserCovid19.covidSymptom.cHeartrate}} &nbsp;&nbsp;&nbsp;
                        <b>BP</b>
                        {{dataReferUserCovid19.covidSymptom.cPressureHigh}}/{{dataReferUserCovid19.covidSymptom.cPressureLow}}
                        &nbsp;&nbsp;&nbsp; <b>SpO2 At Rest</b> {{dataReferUserCovid19.covidSymptom.cSpo2}}
                        &nbsp;&nbsp;&nbsp; <b>SpO2 After Ex</b> {{dataReferUserCovid19.covidSymptom.cSpo2AfterExercise}}
                        &nbsp;&nbsp;&nbsp; <b>SpO2 On O2</b> {{dataReferUserCovid19.covidSymptom.cSpo2Ventilator}}
                    </td>
                </tr>

                <tr>
                    <td colspan="8">
                        <div style="display: flex">
                            <h4 style="margin: 0px;padding: 0px">Select your toppings:</h4>&nbsp;&nbsp;&nbsp;
                            <mat-checkbox class="example-margin" (change)="onCheckAsymptomatic($event)">Asymptomatic</mat-checkbox>&nbsp;&nbsp;&nbsp;
                            <mat-checkbox class="example-margin" (change)="onCheckUpperRespiratoryInfection($event)">Upper respiratory infection</mat-checkbox>
                            &nbsp;&nbsp;&nbsp;
                            <mat-checkbox class="example-margin" (change)="onCheckMildPneumonia($event)">Mild Pneumonia</mat-checkbox>&nbsp;&nbsp;&nbsp;
                            <mat-checkbox class="example-margin" (change)="onCheckSeverePneumonia($event)">Severe Pneumonia</mat-checkbox>
                        </div>
                </tr>

                <tr>
                    <td style="vertical-align: top;" colspan="4">

                        <table width="100%">
                            <tr>
                                <td><b>ยาที่ได้รับ</b></td>
                            </tr>
                            <tr *ngIf="mpFavi_ == true">
                                <td><b>Favipiravir</b> {{dateTimeFaviDay_}} <b>start</b> : {{dateTimeFavi_start |
                                    date:'shortDate'}}, <b>end</b> : {{dateTimeFavi_end | date:'shortDate'}}</td>
                            </tr>
                            <tr *ngIf="mpDexa_ == true">
                                <td><b>Dexamethasone</b> {{dateTimeDexa_| date:'shortDate'}}</td>
                            </tr>
                            <tr *ngIf="mpPrednisolone_ == true">
                                <td><b>Prednisolone</b> {{dateTimePrednisolone_| date:'shortDate'}}</td>
                            </tr>
                            <tr *ngIf="mpOmeprazole_ == true">
                                <td><b>Omeprazole </b> {{dateTimeOmeprazole_| date:'shortDate'}}</td>
                            </tr>
                            <tr *ngIf="mpOxygen_ == true">
                                <td><b>Oxygen</b> : {{dateOxygen_}}</td>
                            </tr>
                            <tr *ngIf="mpOthers_ == true">
                                <td><b>Others</b> : {{dateOthers_}}</td>
                            </tr>
                            <tr *ngIf="dataNameDrugTH.length != 0">
                                <td><b>ยาแผนไทย</b> : {{dataNameDrugTH}} </td>
                            </tr>
                        </table>

                        <hr>

                        <table width="100%">
                            <tr>
                                <td><b>ยาประจำ จากผู้ป่วยลงไว้ </b></td>
                            </tr>
                            <tr>
                                <td *ngIf="dataReferUserCovid19.personalHealth != '' && dataReferUserCovid19.personalHealth != null"> - {{dataReferUserCovid19.personalHealth.pRegularMedication}}</td>
                                <td *ngIf="dataReferUserCovid19.personalHealth == '' || dataReferUserCovid19.personalHealth == null"> - </td>
                            </tr>
                        </table>

                    </td>
                    <td style="vertical-align: top;" colspan="4">

                        <table width="100%">
                            <tr>
                                <td><b>Medical Comments / การรักษาที่ดำเนินการแล้ว</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="card-comment" *ngFor="let itemComment of dataReferUserCovid19.comment">
                                        <div class="comment-text" style="margin: 0px;">
                                            <span class="username">
                                                Doctor's name : <b>{{itemComment.dptDoctorName}}</b>
                                                <span class="text-muted float-right">{{itemComment.dptDateTime |
                                                    date:'medium'}}</span>
                                            </span><!-- /.username -->

                                            <p><b>Note : </b> {{itemComment.dptDetail}}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>


                <tr>
                    <td rowspan="4" colspan="4">
                        <b>Comment</b><br>
                        <mat-form-field class="example-full-width" appearance="outline"
                            style="width: -webkit-fill-available;">
                            <mat-label>Add Comment!</mat-label>
                            <textarea matInput placeholder="note" rows="6" formControlName="refernoteDoctor"></textarea>
                            <!-- <mat-hint *ngIf="noteDoctor == true" style="color: #d00d0d;">Please enter a
                            Note!
                        </mat-hint> -->
                        </mat-form-field>
                    </td>
                    <td colspan="4">Refer to
                        <mat-form-field appearance="outline">
                            <mat-label>Please enter information</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="referHospital2">
                            <mat-hint *ngIf="requiredReferHospital2 == true" style="color: red">Please enter information</mat-hint>
                        </mat-form-field> hospital on
                        <mat-form-field appearance="outline">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="pickerHospitalDate" formControlName="referHospitalDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerHospitalDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerHospitalDate></mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td colspan="4">ลงชื่อแพทย์ Refer <mat-form-field appearance="outline">
                            <mat-label>Please enter information</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="referDoctorname">
                            <mat-hint *ngIf="requiredReferDoctorname == true" style="color: red">Please enter information</mat-hint>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td colspan="4">รหัส <mat-form-field appearance="outline">
                            <mat-label>Please enter information</mat-label>
                            <input matInput placeholder="Placeholder" formControlName="referIdDoctor">
                            <mat-hint *ngIf="requiredReferIdDoctor == true" style="color: red">Please enter information</mat-hint>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td colspan="4">วันที่ <mat-form-field appearance="outline">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="pickerDate" formControlName="referDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDate></mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>


            </table>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]=true>Close</button>
        <button mat-raised-button color="primary" (click)="confirmPDF(true)">Save changes</button>
    </mat-dialog-actions>
</div>



<div class="container" *ngIf="showRefer == true">
    <div class="row" style="display: flex;justify-content: space-between;">
        <h3>Refer ใบส่งผู้ป่วย Covid19</h3>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="mat-typography">
        <table class="table table-bordered border-secondary" id="pdfTable" #pdfTable width="100%">

            <tr>
                <td colspan="8" align="center"><b>แบบฟอร์ม Refer ผู้ป่วย Covid19 จากระบบ SaveThaiFightCovid19 เข้า รพ {{dataConvert.ReferHospital1}} </b></td>
            </tr>

            <tr>
                <td colspan="4" width="50%"><b>DOI</b> : {{dataConvert.DOI| date:'shortDate'}}</td>
                <td colspan="4"><b>ชื่อ</b> {{dataConvert.UserName}} &nbsp;&nbsp; <b>วันเกิด</b> : {{dataConvert.userDate}} &nbsp;&nbsp;
                    <b>เพศ</b> : {{dataConvert.Usersex}}
                </td>
            </tr>

            <tr>
                <td colspan="4" width="50%" *ngIf="dataConvert.DateATK == '' || dataConvert.DateATK == null"><b>date PCR/ATK</b> : -</td>
                <td colspan="4" width="50%" *ngIf="dataConvert.DateATK != '' && dataConvert.DateATK != null"><b>date PCR/ATK</b> : {{dataConvert.DateATK| date:'shortDate'}}</td>
                <td colspan="4"><b>อายุ</b> : {{dataConvert.UserAge}} ปี &nbsp;&nbsp; <b>โรงพยาบาลต้นสังกัด</b> : {{dataConvert.ReferHospitalAffiliation}}</td>
            </tr>

            <tr>
                <td colspan="4" width="50%"><b>วันเข้า Home Isolate</b> : {{dataConvert.HomeIsolate| date:'shortDate'}}</td>
                <td colspan="4"><b>ที่อยู่</b> : {{dataConvert.UserAdress}} ปี &nbsp;&nbsp; <b>สิทธิการรักษา</b> : {{dataConvert.ReferRightToTreatment}}</td>
            </tr>

            <tr>
                <td rowspan="2" colspan="4"><b>Risk Factor</b> : {{dataConvert.RiskFactor}}</td>
                <td colspan="4"><b>เบอร์ติดต่อผู้ป่วย / ญาติ</b> : {{dataConvert.UserphoneRelative}}</td>
            </tr>

            <tr>
                <td colspan="4"><b>เบอร์ติดต่อ Admin / ประสาน</b> : {{dataConvert.ReferPhoneAdmin}}</td>
            </tr>

            <tr>
                <td colspan="8"><b>Vital sign ล่าสุด</b> : <b>T</b> {{dataConvert.Vitalsign_T}} &nbsp;&nbsp;&nbsp; <b>P</b> {{dataConvert.Vitalsign_P}} &nbsp;&nbsp;&nbsp;
                    <b>BP</b> {{dataConvert.Vitalsign_BP}} &nbsp;&nbsp;&nbsp; <b>SpO2 At Rest</b> {{dataConvert.Vitalsign_SpO2AtRest}} &nbsp;&nbsp;&nbsp; <b>SpO2 After Ex</b> {{dataConvert.Vitalsign_SpO2AfterEx}}
                    &nbsp;&nbsp;&nbsp; <b>SpO2 On O2</b> {{dataConvert.Vitalsign_SpO2OnO2}}
                </td>
            </tr>

            <tr>
                <td colspan="8">
                    <b>ผลการวินิฉัย</b> : {{dataConvert.SelectYourtoppings2}}
                </td>
            </tr>

            <tr>
                <td style="vertical-align: top;" colspan="4">
                    <p><b>ยาที่ได้รับ</b></p>
                    <p *ngIf="dataConvert.MedicineReceivedFavipiravir != undefined"><b>Favipiravir</b> : {{dataConvert.MedicineReceivedFavipiravir}}</p>
                    <p *ngIf="dataConvert.MedicineReceivedDexamethasone != undefined"><b>Dexamethasone</b> : {{dataConvert.MedicineReceivedDexamethasone}}</p>
                    <p *ngIf="dataConvert.MedicineReceivedPrednisolone != undefined"><b>Prednisolone</b> : {{dataConvert.MedicineReceivedPrednisolone}}</p>
                    <p *ngIf="dataConvert.MedicineReceivedOmeprazole != undefined"><b>Omeprazole</b> : {{dataConvert.MedicineReceivedOmeprazole}}</p>
                    <p *ngIf="dataConvert.MedicineReceivedOxygen != undefined"><b>Oxygen</b> : {{dataConvert.MedicineReceivedOxygen}}</p>
                    <p *ngIf="dataConvert.MedicineReceivedOthers != undefined"><b>Others</b> : {{dataConvert.MedicineReceivedOthers}}</p>
                    <p *ngIf="dataNameDrugTH.length != 0"><b>ยาแผนไทย</b> : {{dataNameDrugTH}}</p>
                    <hr>
                    <p><b>ยาประจำ จากผู้ป่วยลงไว้</b></p>
                    <p>- {{dataConvert.RegularMedication}}</p>
                </td>
                <td style="vertical-align: top;" colspan="4">
                    <p><b>Medical Comments / การรักษาที่ดำเนินการแล้ว</b></p>
                    <div class="card-comment" *ngFor="let itemComment of dataConvert.MedicalComments">
                        <div class="comment-text" style="margin: 0px;">
                            <span class="username">
                                Doctor's name : <b>{{itemComment.dptDoctorName}}</b>
                                <span class="text-muted float-right">{{itemComment.dptDateTime |
                                    date:'medium'}}</span>
                            </span><!-- /.username -->

                            <p><b>Note : </b> {{itemComment.dptDetail}}</p>
                        </div>
                    </div>

                </td>
            </tr>


            <tr>
                <td rowspan="4" colspan="4" style="padding-left: 13px;padding-bottom: 5px;padding-top: 5px;">
                    <b>Comment</b><br>
                    - {{dataConvert.RefernoteDoctor}}
                    <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"></textarea> -->
                </td>
                <td colspan="4" style="padding-left: 13px;padding-bottom: 5px;padding-top: 5px; text-align:right">
                    <p>Refer to {{dataConvert.ReferHospital2}} hospital on {{dataConvert.ReferHospitalDate| date:'shortDate'}}</p>
                    <p>ลงชื่อแพทย์ {{dataConvert.ReferDoctorname}}</p>
                    <p>รหัส {{dataConvert.ReferIdDoctor}}</p>
                    <p>วันที่ {{dataConvert.ReferDate| date:'shortDate'}}</p>
                </td>
            </tr>


        </table>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="confirmPDF(false)">Back</button>
        <button mat-raised-button color="primary" (click)="makePDF()">Download</button>
    </mat-dialog-actions>
</div>
