<style>
    .symptom-covid-green {
        width: 15px;
        height: 15px;
        border-radius: 25px;
        border: 2px solid #000000;
        background-color: #000000;
    }

    /* .symptom-covid-red {
        width: 15px;
        height: 15px;
        border-radius: 25px;
        border: 2px solid #e81426;
        background-color: #e81426;
    } */
    #header {
        position: sticky;
        left: -25px;
        width: 250px;
        background-color: rgb(255, 255, 255);
    }

</style>

<section>
    <mat-spinner *ngIf="loadDateSy == false"></mat-spinner>
    <!-- <p style="padding: 0px;margin: 0px;"><i class=" fas fa-long-arrow-alt-down"></i> อาการดีขึ้น</p>
    <p style="padding: 0px;margin: 0px;"><i style="padding-top: 3px;padding-left: 2px;"><b>-</b></i> อาการเหมือนเดิม</p>
    <p style="padding: 0px;margin: 0px;"><i class=" fas fa-long-arrow-alt-down"></i> อาการแย่ลง</p> -->
    <div class="row" *ngIf="loadDateSy == true" style="width: max-content;max-height: 800px">
        <div id="header" style="padding-bottom: 12px;">
            <div class=" border-right border-bottom border-left border-bottom border-top"
                style="height: 70px;width: 250px;padding-top: 25px; margin-right: 7px;text-align: center;">
                <div>อาการ</div>
            </div>
            <div class=" border-right border-bottom border-left border-top" *ngIf="infromationCovid19.cFeverish == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>มีไข้</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cSoreThroat == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>เจ็บคอ</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cMusclePain == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>ปวดกล้ามเนื้อ</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cRunny == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>มีน้ำมูก</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cSputum == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>มีเสมหะ</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cHeadache == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>ปวดหัว</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cNoseNoSmell == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>จมูกไม่ได้กลิ่น</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cRash == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>มีผื่น</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cLiquidStool == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>ถ่ายเหลว</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cCoughAlittle == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>ไอเล็กน้อย</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cTongueNoTaste == true"
                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>ลิ้นไม่รับรส</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cConjunctivitis == true"
                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #2cff004d;">
                <div>ตาแดง</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cCanNotEat == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                <div>ทานอาหารไม่ได้</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cCoughAlot == true"
                style="height: 30px; width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                <div>ไอเยอะ</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cCoughingUpBlood == true"
                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                <div>ไอเป็นเลือด</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cBreathingDiff == true"
                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                <div>หายใจลำบาก แน่นหน้าอก</div>
            </div>
            <div class=" border-right border-bottom border-left"
                *ngIf="infromationCovid19.cTriedDailyActivities == true"
                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #e9ff004d;">
                <div>เหนื่อยในขณะที่ทำกิจวัตรประจำวัน</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cTiredSitStill == true"
                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #ff00004d;">
                <div>เหนื่อยขณะนั่งเฉยๆ</div>
            </div>
            <div class=" border-right border-bottom border-left" *ngIf="infromationCovid19.cLieDownBreath == true"
                style="height: 30px;width: 250px;padding: 5px; margin-right: 7px; background-color: #ff00004d;">
                <div>นอนราบแล้วหายใจไม่ออก</div>
            </div>
        </div>
        <div style="padding-bottom: 12px;" *ngIf="dataCovidSymptom != ''">
            <div class="row" style="min-width: 800px;">

                <div>
                    <div class=" border-right border-top"
                        style="font-weight: bold;font-size: 10px; width: 40px; height: 70px;padding: 5px;">
                        <div style="margin-left: auto;margin-right: auto;text-align: center;"></div>
                    </div>
                    <div class=" border-right " *ngIf="infromationCovid19.cFeverish == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cSoreThroat == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right " *ngIf="infromationCovid19.cMusclePain == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right " *ngIf="infromationCovid19.cRunny == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cSputum == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cHeadache == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cNoseNoSmell == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cRash == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cLiquidStool == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right " *ngIf="infromationCovid19.cCoughAlittle == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cTongueNoTaste == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cConjunctivitis == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cCanNotEat == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right " *ngIf="infromationCovid19.cCoughAlot == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right " *ngIf="infromationCovid19.cCoughingUpBlood == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cBreathingDiff == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right " *ngIf="infromationCovid19.cTriedDailyActivities == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cTiredSitStill == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class=" border-right" *ngIf="infromationCovid19.cLieDownBreath == true"
                        style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                    <div class="border-top" style="width: 40px; height: 30px;padding: 5px;">

                    </div>
                </div>

                <div *ngFor="let itemCovid of dataCovidSymptom">
                    <div *ngIf="itemCovid.dataCovid.cFeverish == true ||
                                        itemCovid.dataCovid.cSoreThroat == true ||
                                        itemCovid.dataCovid.cMusclePain == true ||
                                        itemCovid.dataCovid.cRunny == true ||
                                        itemCovid.dataCovid.cSputum == true ||
                                        itemCovid.dataCovid.cHeadache == true ||
                                        itemCovid.dataCovid.cNoseNoSmell == true ||
                                        itemCovid.dataCovid.cRash == true ||
                                        itemCovid.dataCovid.cLiquidStool == true ||
                                        itemCovid.dataCovid.cCoughAlittle == true ||
                                        itemCovid.dataCovid.cTongueNoTaste == true ||
                                        itemCovid.dataCovid.cConjunctivitis == true ||
                                        itemCovid.dataCovid.cCanNotEat == true ||
                                        itemCovid.dataCovid.cCoughAlot == true ||
                                        itemCovid.dataCovid.cCoughingUpBlood == true ||
                                        itemCovid.dataCovid.cBreathingDiff == true ||
                                        itemCovid.dataCovid.cTriedDailyActivities == true ||
                                        itemCovid.dataCovid.cTiredSitStill == true ||
                                        itemCovid.dataCovid.cLieDownBreath == true   ">
                        <div class=" border-right border-bottom border-bottom border-top"
                            style="font-weight: bold;font-size: 10px; width: 40px; height: 70px;padding: 1px;">
                            <div style="margin-left: auto;margin-right: auto;text-align: center;">
                                {{itemCovid.dataCovid.cDate |
                                date:'short'}}</div>
                        </div>
                        <!-- -------------------------------------------------------------------------Green--------------------- -->
                        <div class=" border-right border-bottom border-top" *ngIf="infromationCovid19.cFeverish == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cFeverish == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cFeverish'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cSoreThroat == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cSoreThroat == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cSoreThroat'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cMusclePain == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cMusclePain == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cMusclePain'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cRunny == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cRunny == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cRunny'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cSputum == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cSputum == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cSputum'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cHeadache == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cHeadache == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cHeadache'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cNoseNoSmell == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cNoseNoSmell == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cNoseNoSmell'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cRash == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cRash == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cRash'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cLiquidStool == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cLiquidStool == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cLiquidStool'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cCoughAlittle == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cCoughAlittle == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cCoughAlittle'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cTongueNoTaste == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cTongueNoTaste == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cTongueNoTaste'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cConjunctivitis == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cConjunctivitis == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cConjunctivitis'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <!-- -------------------------------------------------------------------------Green--------------------- -->
                        <!-- -------------------------------------------------------------------------Yellow--------------------- -->
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cCanNotEat == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cCanNotEat == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cCanNotEat'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cCoughAlot == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cCoughAlot == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cCoughAlot'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cCoughingUpBlood == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cCoughingUpBlood == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cCoughingUpBlood'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cBreathingDiff == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cBreathingDiff == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cBreathingDiff'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom"
                            *ngIf="infromationCovid19.cTriedDailyActivities == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cTriedDailyActivities == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cTriedDailyActivities'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <!-- -------------------------------------------------------------------------Yellow--------------------- -->
                        <!-- -------------------------------------------------------------------------Red--------------------- -->
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cTiredSitStill == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cTiredSitStill == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cTiredSitStill'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <div class=" border-right border-bottom" *ngIf="infromationCovid19.cLieDownBreath == true"
                            style="width: 40px; height: 30px;padding: 5px; display: flex;">
                            <div class="symptom-covid-green" *ngIf="itemCovid.dataCovid.cLieDownBreath == true"
                                style="margin-left: auto;margin-right: auto;margin-top: 2px;"></div>
                            <div *ngFor="let itemSymptom of itemCovid.dataSymptom">
                                <div *ngIf="itemSymptom.symptom == 'cLieDownBreath'; then thenSymptomBlock">
                                </div>
                                <ng-template #thenSymptomBlock>
                                    <i class=" fas fa-long-arrow-alt-down" *ngIf="itemSymptom.status == 0"
                                        style="padding-top: 3px;color: #2aa745;"></i>
                                    <i *ngIf="itemSymptom.status == 1"
                                        style="padding-top: 3px;padding-left: 2px;"><b>-</b></i>
                                    <i class=" fas fa-long-arrow-alt-up" *ngIf="itemSymptom.status == 2"
                                        style="padding-top: 3px;color: #d00d0d;"></i>
                                </ng-template>
                            </div>
                        </div>
                        <!-- -------------------------------------------------------------------------Red--------------------- -->
                    </div>
                </div>

            </div>
            <div class="row" style="position: fixed;">
                <div class="mr-5"><i class=" fas fa-long-arrow-alt-down" style="padding-top: 3px;color: #2aa745;">
                        ดีขึ้นจากเมื่อวาน</i> </div>
                <div class="mr-5"><i style="padding-top: 3px;padding-left: 2px;"><b>-</b> เหมือนเดิม</i></div>
                <div class="mr-5"><i class=" fas fa-long-arrow-alt-up" style="padding-top: 3px;color: #d00d0d;">
                        แย่ลงจากเมื่อวาน</i></div>
            </div>
        </div>
        <div style="padding-bottom: 12px;margin-top: 110px;" *ngIf="dataCovidSymptom == ''">
            <div style="min-width: 800px; text-align: center;">
                <img src="assets\adminlte\dist\img\covid-image.png" style="width: 460px;" alt="covid-image">
                <p style="font-weight: 600;font-size: 14px;">ไม่มีข้อมูล</p>
            </div>
        </div>
    </div>
</section>