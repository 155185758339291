import { Component, OnInit, LOCALE_ID, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../core/services/service.service';
import { FormGroup, FormBuilder } from '@angular/forms';

export interface DialogEditSubDistrict {
    idAdminSubDistrict: string;
    usernameSubDistrict: string;
    passwordSubDistrict: string;
    phoneSubDistrict: string;
}

@Component({
    selector: 'editAdminSubDistrict-modal',
    templateUrl: './editAdminSubDistrict-modal.html',
})
export class DialogEditAdminSubDistrictDialog {

    editAdminSubDistrictForm!: FormGroup;

    dataConvert!: any;
    dataConvertEditAdminSubDistrict: any[] = [];

    InvalidUsernameLength = false;
    InvalidUsername = false;
    hidePassword = true;
    hideConfirmPassword = true;
    InvalidConfirmPassword = false;
    InvalidOldPassword = false;
    InvalidNewAndOldPassword = false;
    constructor(
        public dialogRef: MatDialogRef<DialogEditAdminSubDistrictDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditSubDistrict,
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        private router: Router,
        private ngZone: NgZone,
        private ServiceService: ServiceService
    ) {

        this.editAdminSubDistrictForm = this.formBuilder.group({
            usernameEditSubDistrict: '',
            passwordEditSubDistrict: '',
            passwordNew: '',
            phoneEditSubDistrict: ''
        })

        this.editAdminSubDistrictForm.setValue({
            usernameEditSubDistrict: this.data.usernameSubDistrict,
            passwordEditSubDistrict: '',
            passwordNew: '',
            phoneEditSubDistrict: this.data.phoneSubDistrict
        })
    }

    onUpdateAdminSubDistrict() {
        var idAdminEditDistrict:any;
        this.dataConvert = {
            AdminID: this.data.idAdminSubDistrict,
            AdminUsername: this.editAdminSubDistrictForm.value.usernameEditSubDistrict,
            AdminPassword: this.editAdminSubDistrictForm.value.passwordEditSubDistrict,
            AdminPasswordNew: this.editAdminSubDistrictForm.value.passwordNew,
            AdminPhone: this.editAdminSubDistrictForm.value.phoneEditSubDistrict
        };

        if(this.editAdminSubDistrictForm.value.usernameEditSubDistrict.length > 2){
            this.InvalidUsername = false;
            // if (this.editAdminSubDistrictForm.value.passwordEditSubDistrict == this.editAdminSubDistrictForm.value.confirmPasswordEditSubDistrict) {
                // this.InvalidConfirmPassword = false;
                this.InvalidOldPassword = false;
                this.InvalidNewAndOldPassword = false;
                this.ServiceService.EditAdmin_(this.dataConvert).then((dataidAdminEditDistrict) => {
                    idAdminEditDistrict = dataidAdminEditDistrict;
                    // console.log("Data added successfully --> ", idAdminEditDistrict);
                    // if(idAdminEditDistrict != "Duplicate Username"){
                    //     this.dataConvertEditAdminSubDistrict = [
                    //         {
                    //             admin_Id: idAdminEditDistrict,
                    //             admin_Username: this.editAdminSubDistrictForm.value.usernameEditSubDistrict,
                    //             admin_Password: this.editAdminSubDistrictForm.value.passwordEditSubDistrict,
                    //             admin_Phone: this.editAdminSubDistrictForm.value.phoneEditSubDistrict
                    //         }
                    //     ]
                    //     this.dialogRef.close(this.dataConvertEditAdminSubDistrict);
                    // }else{
                    //     this.InvalidUsername = true;
                    // }


                    switch(idAdminEditDistrict) { 
                        case "Duplicate Username": { 
                            this.InvalidUsername = true;
                           break; 
                        } 
                        case "New and old passwords don't match.": { 
                           this.InvalidNewAndOldPassword = true;
                           break; 
                        } 
                        case "The old password is invalid.": { 
                            this.InvalidOldPassword = true;
                           break; 
                        } 
                        case "Fail Process Update Admin": { 
                           //statements; 
                           break; 
                        } 
                        default: { 
                            this.dataConvertEditAdminSubDistrict = [
                                {
                                    admin_Id: idAdminEditDistrict,
                                    admin_Username: this.editAdminSubDistrictForm.value.usernameEditSubDistrict,
                                    admin_Password: this.editAdminSubDistrictForm.value.passwordEditSubDistrict,
                                    admin_Phone: this.editAdminSubDistrictForm.value.phoneEditSubDistrict
                                }
                            ]
                            this.dialogRef.close(this.dataConvertEditAdminSubDistrict);
                           break; 
                        } 
                     } 

                }, (err) => {
                    console.log(" : : ", err);
                })
            // } else {
            //     this.InvalidConfirmPassword = true;
            // }
        }else{
            this.InvalidUsernameLength = true;
        }
    
    }
}