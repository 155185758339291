<style>
    ::ng-deep .mat-form-field-prefix {
        top: 0px !important;
        /* background-color: brown; */
    }
</style>

<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <h1 style="text-align: center;">Edit Operator</h1>
            </div>
            <div class="col-md-10">
                <hr>
            </div>
        </div>
    </div>
</section>


<div class="col-12">
    <div class="row" style="display: flex;justify-content: center; padding: 15px;">
        <div class="image-preview" *ngIf="previewUrl">
            <img [src]="previewUrl" style="border-radius: 50%;width: 100px;height: 100px;object-fit: cover;" />
        </div>
        <div *ngIf="noImg">
            <div *ngIf="imgProfileOperator != null; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <img src="{{imageUrl1}}/{{imgProfileOperator}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                    style="border-radius: 50%;width: 150px;height: 150px;object-fit: cover;" />
            </ng-template>
            <ng-template #elseBlock>
                <img src="assets/adminlte/dist/img/nouser.svg"
                    style="border-radius: 50%;width: 150px;height: 150px;object-fit: cover;" />
            </ng-template>
        </div>
        <div style="margin-left: 3px;margin-top: auto;">
            <button mat-mini-fab (click)="fileInput.click()">
                <mat-icon>photo_size_select_actual</mat-icon>
                <input #fileInput type="file" name="image" (change)="fileProgress($event)" style="display:none;"
                    accept="image/png, image/jpeg" />
            </button>
        </div>
    </div>
</div>

<div class="col-12">
    <form [formGroup]="editOperatorSubDistrictForm">
        <div class="row">
            <div class="col-md-6">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Enter your email</mat-label>
                                <input matInput formControlName="operatorEmail" maxlength="120"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9.@_-]/gi, '').trim();" required>
                                <mat-hint *ngIf="duplicateEmailCheck" style="color: red;"> {{duplicateUsername}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <!-- <mat-label>Username</mat-label> -->
                                <input matInput formControlName="operatorUsername" required placeholder="Username" maxlength="100"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9._-]/gi, '').trim();" readonly>
                                <span matPrefix>{{englishAbbreviation}}-</span>
                                <mat-hint *ngIf="duplicateUsernameCheck" style="color: red;"> {{duplicateUsername}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput placeholder="Password" formControlName="operatorPassword"
                                    [type]="hidePassword ? 'password' : 'text'" maxlength="100">
                                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint *ngIf="passwordCheck" style="color: red;"> Passwords do not match
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Confirm Password</mat-label>
                                <input matInput placeholder="Password" formControlName="operatorConfirmPassword"
                                    [type]="hidePasswordConfirm ? 'password' : 'text'" maxlength="100">
                                <button mat-icon-button matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordConfirm">
                                    <mat-icon>{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint *ngIf="passwordCheck" style="color: red;"> Passwords do not match
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>FirstName</mat-label>
                                <input matInput formControlName="operatorFirstname" maxlength="120"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9ก-๙]/gi, '').trim();" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>LastName</mat-label>
                                <input matInput formControlName="operatorLastname" maxlength="120"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9ก-๙]/gi, '').trim();" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Gender</mat-label>
                                <mat-select formControlName="operatorGender" required>
                                    <mat-option *ngFor="let gender of genders" [value]="gender.value">
                                        {{gender.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Age</mat-label>
                                <input matInput formControlName="operatorAge" oninput="this.value = this.value.replace(/[^0-9]/gi, '').trim();" maxlength="3" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-5">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Birthday</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="operatorDateofbirth" required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Phone</mat-label>
                                <input matInput formControlName="operatorPhone" minlength="10" maxlength="10"
                                oninput="this.value = this.value.replace(/[^0-9]/gi, '').trim();" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Address</mat-label>
                                <textarea matInput formControlName="operatorAddress" required></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                <mat-label>Province</mat-label>
                                <mat-select formControlName="operatorProvince" required [(ngModel)]="selectedProvince"
                                    (ngModelChange)="onProvinceSelection()">
                                    <mat-option *ngFor="let province of provinces" [value]="province.value">
                                        {{province.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md">
                            <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                <mat-label>District</mat-label>
                                <mat-select formControlName="operatorDistrict" required [(ngModel)]="selectedDistrict"
                                    (ngModelChange)="onDistrictSelection()">
                                    <mat-option *ngFor="let district of districts" [value]="district.value">
                                        {{district.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md">
                            <mat-form-field appearance="outline" style="width: -webkit-fill-available;">
                                <mat-label>Subdistrict</mat-label>
                                <mat-select formControlName="operatorSubdistrict" required
                                    [(ngModel)]="selectedSubdistrict" (ngModelChange)="onSubdistrictSelection()">
                                    <mat-option *ngFor="let subdistrict of subdistricts" [value]="subdistrict.value">
                                        {{subdistrict.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style="width: -webkit-fill-available;" appearance="outline">
                                <mat-label>Postal Code</mat-label>
                                <input matInput formControlName="operatorPostalcode" minlength="5" maxlength="5"
                                oninput="this.value = this.value.replace(/[^0-9]/gi, '').trim();" required>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="float-right" mat-raised-button type="submit" color="primary"
                            (click)="onUpdate()">Update
                            Operator</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
