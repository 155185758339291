<div class="mt-3">


    <div class="row">
        <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
          <h1 style="color: #222;" style="margin: 0px;">Fatigue Chart</h1>
        </div>
        <div class="col-md-4 text-right">

          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Enter a date range</mat-label>

            <mat-date-range-input [formGroup]="rangeDateStartAndEnd" [rangePicker]="picker_start_end">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onChangeDate()">
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
            <mat-date-range-picker #picker_start_end></mat-date-range-picker>
          </mat-form-field>
        </div>

      </div>

      <div *ngIf="countChartData != 0">

    <div class="card mt-2">
        <div class="card-body">
            <div #chartFatigue style="width: 100%; height: 400px;"></div>
            <div class="mt-1" #chartFatigueColl style="width: 100%; height: 500px;"></div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace h6size">Average (เฉลี่ย)</h6>
                    <h3 class="nospace txtindigo">{{averFatigueValue | number : '1.2-2'}}</h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">Max (มากสุด)</h6>
                    <h3 class="nospace txtindigo">{{maxFatigueValue}}</h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">Min (น้อยสุด)</h6>
                    <h3 class="nospace txtindigo">{{minFatigueValue}}</h3>
                </div>
            </div>
        </div>




        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="nospace"><span style="color: green;">ปกติ (มากกว่า 50)</span></h4>
                    <h3 class="nospace txtindigo">{{ countGood }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                            {{ dataChartFatigue.length }} ครั้ง คิดเป็น {{ (countGood / dataChartFatigue.length) * 100 | number : '1.2-2' }}%</span></h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="nospace"><span style="color: rgb(253, 143, 0);">ปานกลาง (25 - 50)</span></h4>
                    <h3 class="nospace txtindigo">{{ countMid }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                            {{ dataChartFatigue.length }} ครั้ง คิดเป็น {{ (countMid / dataChartFatigue.length) * 100 | number : '1.2-2' }}%</span></h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="nospace"><span style="color: red;">สูง (น้อยกว่า 25)</span></h4>
                    <h3 class="nospace txtindigo">{{ countBad }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                            {{ dataChartFatigue.length }} ครั้ง คิดเป็น {{ (countBad / dataChartFatigue.length) * 100 | number : '1.2-2' }}%</span></h3>
                </div>
            </div>
        </div>





        <div class="col-md-12 mt-3">
            <div class="card">
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-8">
                            <h6 class="nospace">Fatigue 24Hr Chart</h6>
                            <span *ngFor="let datelist of datetimeShowList" class="badge bg-primary"
                                style="margin-right: 2px;">{{ datelist.datetxtShow }}</span>
                        </div>

                        <div class="col-md-4 text-right">
                            <mat-form-field style="width: 100%;">
                                <input matInput [matDatepicker]="picker" placeholder="Choose a date" [value]="setNow"
                                    (dateChange)="onChangeDateTimeEvent($event, false)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="mt-2" #chartFatigueAver24Line style="width: 100%; height: 500px"></div>

                </div>
            </div>
        </div>


        <div class="col-md-6 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">Fatigue 7 วันเฉลี่ย</h6>
                    <div #chartFatigueAver7day style="width: 100%; height: 400px"></div>
                </div>
            </div>
        </div>


        <div class="col-md-6 mt-3">
            <div class="card">
                <div class="card-body text-center">
                    <h6 class="nospace">Fatigue Average Percent Chart</h6>
                    <div #chartFatigueValPercen style="width: 100%; height: 400px"></div>
                </div>
            </div>
        </div>


    </div>
</div>

<div *ngIf="countChartData == 0" style="display: flex;justify-content: center;"> <h2>*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>



    <br>
    <br>

</div>
