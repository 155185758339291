import { Component, Inject } from '@angular/core';
import { ServiceService } from '../../../core/services/service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'personal-health.component',
  templateUrl: './personal-health.component.html',
})
export class DialogPersonalHealth {
  dataPersonalHealth: any;
  dayInfection: any;
  dayOnsetDate: any;
  constructor(
    private ServiceService: ServiceService,
    public dialogRef: MatDialogRef<DialogPersonalHealth>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loadPersonalHealth(data);
  }

  loadPersonalHealth(idUser: string) {
    var dataPersonalHealth:any;
    this.ServiceService.GetPersonalHealth_(idUser).then(resPersonalHealth => {
      // console.log("resPersonalHealth > ", resPersonalHealth);
      dataPersonalHealth = resPersonalHealth;
      console.log(dataPersonalHealth.length);
      if(dataPersonalHealth.length != 0){
        this.dataPersonalHealth = dataPersonalHealth;
        var dayInfection_ = this.checkDatetoDay(this.dataPersonalHealth[0].pDateInfection);
        var dayOnsetDate_ = this.checkDatetoDay(this.dataPersonalHealth[0].pOnsetDate);
        if(dayInfection_ != 0){
          this.dayInfection = dayInfection_ + " วันก่อน";
        }else{
          this.dayInfection = "วันนี้";
        }
  
        if(dayOnsetDate_ != 0){
          this.dayOnsetDate = dayOnsetDate_ + " วันก่อน";
        }else{
          this.dayOnsetDate = "วันนี้";
        }
      }
    });
  }

  checkDatetoDay(date: string) {
    var currentDate = new Date(date);
    var today = new Date();
    var diffMs = (today.getTime() - currentDate.getTime());
    var diffDays = Math.floor(diffMs / 86400000);
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    console.log("diffDays ", diffDays, "diffHrs ", diffHrs, "diffMins ", diffMins);

    return diffDays;
  }
}