<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 style="text-align: center;"> Admin Alert </h1>
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content pl-2 pr-2">
    <div class="mat-elevation-z8 card card-solid">
        <div class="card-body" >
            <div class="container">
                <div class="row justify-content-around">
                  <div class="col-12">
                    <h3> <i class="fas fa-bullhorn"></i> Cov Admin Notification </h3>
                  </div>
                </div>
              </div>

            <mat-spinner *ngIf="loadDataCodiv19Alert == true"
                style="margin-top: 160px;margin-bottom: 160px;margin-left: auto;margin-right: auto;"></mat-spinner>
                <div *ngIf="noDataAlert == true" class="col-12" style="margin-top: 160px;margin-bottom: 160px;margin-left: auto;margin-right: auto;">
                    <p style="text-align: center;">No data Alert</p>
                </div>
            <div *ngIf="loadDataCodiv19Alert == false">
                <div *ngFor="let itemDataLogCodiv19 of resultDataLogCodiv19;let i = index">

                    <div class="container">
                        <div *ngIf="itemDataLogCodiv19.aaType == 'MedPresTH'"
                        (click)="onClickOpenDetail(itemDataLogCodiv19.aaId,itemDataLogCodiv19.aaType,itemDataLogCodiv19.aaSubId,itemDataLogCodiv19.userName,i)"
                            class="row justify-content-center pointer hoverBtn pulse callout callout-success mat-elevation-z8"
                            style="margin-bottom: 8px;">
                                    <div class="col-10">
                                        <div class="direct-chat-infos clearfix" style="margin: auto;">
                                            <div
                                                *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                                <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                    alt="User Image">
                                            </div>
                                            <ng-template #elseBlock>
                                                <img src="assets/adminlte/dist/img/nouser.svg"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;" alt="User Image">
                                            </ng-template>
                                            <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                            <h4><b>ประเภทการแจ้งเตือน : </b>
                                                <span *ngIf="itemDataLogCodiv19.aaType == 'MedPresTH'" class="badge badge-success"> การจ่ายยา</span>
                                                <span *ngIf="itemDataLogCodiv19.aaType == 'DoctorTask'"> การแสดงความคิดเห็น</span>
                                            </h4>
                                            <div class="row">
                                                <p class="font-size-1366" style="padding-right: 5px;"><b>รายละเอียด : </b> {{convertMedPresTHJsonStrToJon(itemDataLogCodiv19.detail)}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">

                                        <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.alertTime |
                                            date:'short'}}</span>

                                    </div>

                        </div>
                    </div>

                    <div class="container">
                        <div *ngIf="itemDataLogCodiv19.aaType == 'MedPres'"
                        (click)="onClickOpenDetail(itemDataLogCodiv19.aaId,itemDataLogCodiv19.aaType,itemDataLogCodiv19.aaSubId,itemDataLogCodiv19.userName,i)"
                        class="row justify-content-center pointer hoverBtn pulse callout callout-success mat-elevation-z8"
                        style="margin-bottom: 8px;">
                                    <div class="col-10">
                                        <div class="direct-chat-infos clearfix" style="margin: auto;">
                                            <div
                                                *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                                <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                    alt="User Image">
                                            </div>
                                            <ng-template #elseBlock>
                                                <img src="assets/adminlte/dist/img/nouser.svg"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;" alt="User Image">
                                            </ng-template>
                                            <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                            <h4><b>ประเภทการแจ้งเตือน : </b>
                                                <span *ngIf="itemDataLogCodiv19.aaType == 'MedPres'" class="badge badge-success"> การจ่ายยา</span>
                                                <span *ngIf="itemDataLogCodiv19.aaType == 'DoctorTask'"> การแสดงความคิดเห็น</span>
                                            </h4>
                                            <div class="row">
                                                <p class="font-size-1366" style="padding-right: 5px;"><b>รายละเอียด : </b> {{convertJsonStrToJon(itemDataLogCodiv19.detail)}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">

                                        <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.alertTime |
                                            date:'short'}}</span>

                                    </div>
                        </div>
                    </div>

                    <div class="container">
                        <div *ngIf="itemDataLogCodiv19.aaType == 'DoctorTask'"
                        (click)="onClickOpenDetail(itemDataLogCodiv19.aaId,itemDataLogCodiv19.aaType,itemDataLogCodiv19.aaSubId,itemDataLogCodiv19.userName,i)"
                            class="row justify-content-center pointer hoverBtn pulse callout callout-warning mat-elevation-z8"
                            style="margin-bottom: 8px;">
                                    <div class="col-10">
                                        <div class="direct-chat-infos clearfix" style="margin: auto;">
                                            <div
                                                *ngIf="itemDataLogCodiv19.userPic != '' && itemDataLogCodiv19.userPic != null; else elseBlock">
                                                <img src="{{imageUrl2}}/{{itemDataLogCodiv19.userPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;object-fit: cover;"
                                                    alt="User Image">
                                            </div>
                                            <ng-template #elseBlock>
                                                <img src="assets/adminlte/dist/img/nouser.svg"
                                                    class="float-left img-circle elevation-2"
                                                    style="width: 70px; height: 70px; margin-right: 10px;" alt="User Image">
                                            </ng-template>
                                            <span class="direct-chat-name">{{itemDataLogCodiv19.userName}}</span>
                                            <h4><b>ประเภทการแจ้งเตือน : </b>
                                                <span *ngIf="itemDataLogCodiv19.aaType == 'MedPres'"> การจ่ายยา</span>
                                                <span *ngIf="itemDataLogCodiv19.aaType == 'DoctorTask'" class="badge badge-warning"> การแสดงความคิดเห็น</span>
                                            </h4>
                                            <div class="row">
                                                <p class="font-size-1366" style="padding-right: 5px;"><b>รายละเอียด : </b> {{itemDataLogCodiv19.detail}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">

                                        <span class="direct-chat-timestamp float-right">{{itemDataLogCodiv19.alertTime |
                                            date:'short'}}</span>

                                    </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
