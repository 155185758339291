import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef,} from '@angular/material/dialog';

@Component({
    selector: 'success-job-modal',
    templateUrl: './success-job-modal.html',
  })
  export class DialogSuccessJob {
  
    constructor(
      public dialogRef: MatDialogRef<DialogSuccessJob>) {}
  
    onClick(): void {
      this.dialogRef.close();
    }
  
  }