import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ServiceService } from '../../../core/services/service.service';
import { formatDate } from '@angular/common';
import { DialogPersonalHealth } from '../personalHealth/personal-health.component';
import { DialogCovid19Chart } from 'src/app/admin/pages/covid/user-covid19/modal/chart-covid/chart-covid.component';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'detail-sos.component',
  templateUrl: './detail-sos.component.html',
})
export class DialogDetailSos implements OnInit, AfterViewInit {
  dataListHistoryBody: any;
  dataDetailHistory: any = [];
  dataIdUser: any;
  loadData: boolean = true;
  panelOpenState = false;
  showbtnPSCovid19 = true;
  @ViewChild('chartPressure', { static: false }) chartPressure!: ElementRef;
  @ViewChild('chartHR', { static: false }) chartHR!: ElementRef;
  @ViewChild('chartTemp', { static: false }) chartTemp!: ElementRef;
  @ViewChild('chartSpo2', { static: false }) chartSpo2!: ElementRef;

  constructor(
    public dialog: MatDialog,
    private ServiceService: ServiceService,
    public dialogRef: MatDialogRef<DialogDetailSos>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataIdUser = data.idUser;
    this.showbtnPSCovid19 = data.btnCheck;
  }
  ngAfterViewInit(): void {
    // console.log(this.chartPressure);
    // this.genarateGraph();
  }

  ngOnInit() {
    this.loadDataHistoryBody(this.dataIdUser);
    // console.log(this.chartPressure);
  }

  genarateGraph() {
    this.GraphBP();
    this.GraphHr();
    this.GraphTemp();
    this.GraphSpo2();
  }

  GraphSpo2() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    var chart = am4core.create(this.chartSpo2.nativeElement, am4charts.XYChart);

    chart.data = this.dataChartSpo2;

    // Create axes
    // var dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // dateAxis.renderer.minGridDistance = 50;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 60;
    valueAxis.max = 105;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    // series.dataFields.dateX = 'date';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 5;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('red');
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';
    // series.tooltip.pointerOrientation = "vertical";
    series.tensionX = 0.97;

    var axisRangetop = valueAxis.axisRanges.create();
    axisRangetop.value = 105;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#396478');
    axisRangetop.grid.strokeWidth = 2;

    var rangeareatop = valueAxis.axisRanges.create();
    rangeareatop.value = 100;
    rangeareatop.endValue = 105;
    rangeareatop.axisFill.fill = am4core.color('#396478');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    var axisRangebottom = valueAxis.axisRanges.create();
    axisRangebottom.value = 65;
    axisRangebottom.grid.strokeOpacity = 1;
    axisRangebottom.grid.stroke = am4core.color('#396478');
    axisRangebottom.grid.strokeWidth = 2;

    var rangeareabottom = valueAxis.axisRanges.create();
    rangeareabottom.value = 65;
    rangeareabottom.endValue = 70;
    rangeareabottom.axisFill.fill = am4core.color('#396478');
    rangeareabottom.axisFill.fillOpacity = 0.2;
    rangeareabottom.grid.strokeOpacity = 0;

    var labelbaselinetop = chart.createChild(am4core.Label);
    labelbaselinetop.text = 'Top Base Line (41)';
    labelbaselinetop.fontSize = 16;
    labelbaselinetop.isMeasured = false;
    labelbaselinetop.x = am4core.percent(80);
    labelbaselinetop.y = 15;

    var labelbaselinebottom = chart.createChild(am4core.Label);
    labelbaselinebottom.text = 'Bottom Base Line (30)';
    labelbaselinebottom.fontSize = 16;
    labelbaselinebottom.isMeasured = false;
    labelbaselinebottom.x = am4core.percent(80);
    labelbaselinebottom.y = 310;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }

  GraphTemp() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    var chart = am4core.create(this.chartTemp.nativeElement, am4charts.XYChart);

    chart.data = this.dataChartTemperature;

    // Create axes
    // var dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // dateAxis.renderer.minGridDistance = 50;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 30;
    valueAxis.max = 45;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    // series.dataFields.dateX = 'date';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 5;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('red');
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';
    // series.tooltip.pointerOrientation = "vertical";
    series.tensionX = 0.97;

    var axisRangetop = valueAxis.axisRanges.create();
    axisRangetop.value = 45;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#396478');
    axisRangetop.grid.strokeWidth = 2;

    var rangeareatop = valueAxis.axisRanges.create();
    rangeareatop.value = 41;
    rangeareatop.endValue = 45;
    rangeareatop.axisFill.fill = am4core.color('#396478');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    var axisRangebottom = valueAxis.axisRanges.create();
    axisRangebottom.value = 30;
    axisRangebottom.grid.strokeOpacity = 1;
    axisRangebottom.grid.stroke = am4core.color('#396478');
    axisRangebottom.grid.strokeWidth = 2;

    var rangeareabottom = valueAxis.axisRanges.create();
    rangeareabottom.value = 30;
    rangeareabottom.endValue = 35;
    rangeareabottom.axisFill.fill = am4core.color('#396478');
    rangeareabottom.axisFill.fillOpacity = 0.2;
    rangeareabottom.grid.strokeOpacity = 0;

    var labelbaselinetop = chart.createChild(am4core.Label);
    labelbaselinetop.text = 'Top Base Line (41)';
    labelbaselinetop.fontSize = 16;
    labelbaselinetop.isMeasured = false;
    labelbaselinetop.x = am4core.percent(80);
    labelbaselinetop.y = 5;

    var labelbaselinebottom = chart.createChild(am4core.Label);
    labelbaselinebottom.text = 'Bottom Base Line (30)';
    labelbaselinebottom.fontSize = 16;
    labelbaselinebottom.isMeasured = false;
    labelbaselinebottom.x = am4core.percent(80);
    labelbaselinebottom.y = 320;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }

  GraphHr() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    var chart = am4core.create(this.chartHR.nativeElement, am4charts.XYChart);

    chart.data = this.dataChartHeartRate;

    // Create axes
    // var dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // dateAxis.renderer.minGridDistance = 50;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 200;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    // series.dataFields.dateX = 'date';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 5;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('red');
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';
    // series.tooltip.pointerOrientation = "vertical";
    series.tensionX = 0.97;

    var axisRangetop = valueAxis.axisRanges.create();
    axisRangetop.value = 180;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#396478');
    axisRangetop.grid.strokeWidth = 2;

    var rangeareatop = valueAxis.axisRanges.create();
    rangeareatop.value = 160;
    rangeareatop.endValue = 180;
    rangeareatop.axisFill.fill = am4core.color('#396478');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    var axisRangebottom = valueAxis.axisRanges.create();
    axisRangebottom.value = 40;
    axisRangebottom.grid.strokeOpacity = 1;
    axisRangebottom.grid.stroke = am4core.color('#396478');
    axisRangebottom.grid.strokeWidth = 2;

    var rangeareabottom = valueAxis.axisRanges.create();
    rangeareabottom.value = 40;
    rangeareabottom.endValue = 60;
    rangeareabottom.axisFill.fill = am4core.color('#396478');
    rangeareabottom.axisFill.fillOpacity = 0.2;
    rangeareabottom.grid.strokeOpacity = 0;

    var labelbaselinetop = chart.createChild(am4core.Label);
    labelbaselinetop.text = 'Top Base Line (105)';
    labelbaselinetop.fontSize = 16;
    labelbaselinetop.isMeasured = false;
    labelbaselinetop.x = am4core.percent(80);
    labelbaselinetop.y = 10;

    var labelbaselinebottom = chart.createChild(am4core.Label);
    labelbaselinebottom.text = 'Bottom Base Line (75)';
    labelbaselinebottom.fontSize = 16;
    labelbaselinebottom.isMeasured = false;
    labelbaselinebottom.x = am4core.percent(80);
    labelbaselinebottom.y = 280;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }

  GraphBP() {
    // console.log(this.chartPressure);

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create(
      this.chartPressure.nativeElement,
      am4charts.XYChart
    );

    // Add data
    chart.data = this.dataChartPressure;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.ticks.template.disabled = false;
    categoryAxis.renderer.ticks.template.strokeOpacity = 0.5;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'close1';
    series.dataFields.openValueY = 'open1';
    // series.name = name;
    series.tooltipText = '[bold]{category}:[/] {open1}/{close1}\n[bold]';
    series.dataFields.categoryX = 'category';
    series.clustered = false;
    series.strokeWidth = 0;
    series.columns.template.width = am4core.percent(90);

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }

  dataChartPressure: any[] = [];
  dataChartPressure2: any[] = [];
  lineChartPressureData: ChartDataSets[] = [
    { data: this.dataChartPressure, label: 'Pressure' },
  ];

  dataChartHeartRate: any[] = [];
  lineChartHeartRateData: ChartDataSets[] = [
    { data: this.dataChartHeartRate, label: 'RateData' },
  ];

  dataChartTemperature: any[] = [];
  lineChartTemperatureData: ChartDataSets[] = [
    { data: this.dataChartTemperature, label: 'Temperature' },
  ];

  dataChartSpo2: any[] = [];
  lineChartSpo2Data: ChartDataSets[] = [
    { data: this.dataChartSpo2, label: 'Spo2' },
  ];

  lineChartLabels: Label[] = [];

  lineChartOptions: ChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      // dark grey
      backgroundColor: '#4B94C0',
      borderColor: '#4B94C0',
    },
  ];

  lineChartLegend = true;

  lineChartType: ChartType = 'line';

  lineChartPlugins = [];

  async loadDataHistoryBody(idUser: string) {
    await this.ServiceService.GetCovidSymptomV4_(idUser).then(
      (res) => {
        this.dataListHistoryBody = res;
        if (this.dataListHistoryBody.length != 0) {
          this.dataListHistoryBody.sort((a: any, b: any) =>
            a.cDate > b.cDate ? 1 : -1
          );
          // console.log(this.dataListHistoryBody);
          var detailJson = JSON.parse(
            this.dataListHistoryBody[this.dataListHistoryBody.length - 1].cCurrentSymptoms
          );
          // console.log('v ', detailJson);

          var data_ = {
            cFeverish: {
              isCheck: detailJson[1].isCheck,
              currentSymptoms: detailJson[1].status,
            },
            cSoreThroat: {
              isCheck: detailJson[4].isCheck,
              currentSymptoms: detailJson[4].status,
            },
            cMusclePain: {
              isCheck: detailJson[3].isCheck,
              currentSymptoms: detailJson[3].status,
            },
            cRunny: {
              isCheck: detailJson[6].isCheck,
              currentSymptoms: detailJson[6].status,
            },
            cSputum: {
              isCheck: detailJson[7].isCheck,
              currentSymptoms: detailJson[7].status,
            },
            cHeadache: {
              isCheck: detailJson[2].isCheck,
              currentSymptoms: detailJson[2].status,
            },
            cNoseNoSmell: {
              isCheck: detailJson[0].isCheck,
              currentSymptoms: detailJson[0].status,
            },
            cRash: {
              isCheck: detailJson[8].isCheck,
              currentSymptoms: detailJson[8].status,
            },
            cLiquidStool: {
              isCheck: detailJson[9].isCheck,
              currentSymptoms: detailJson[9].status,
            },
            cCoughAlittle: {
              isCheck: detailJson[5].isCheck,
              currentSymptoms: detailJson[5].status,
            },
            cTongueNoTaste: {
              isCheck: detailJson[11].isCheck,
              currentSymptoms: detailJson[11].status,
            },
            cConjunctivitis: {
              isCheck: detailJson[12].isCheck,
              currentSymptoms: detailJson[12].status,
            },
            cCanNotEat: {
              isCheck: detailJson[13].isCheck,
              currentSymptoms: detailJson[13].status,
            },
            cCoughAlot: {
              isCheck: detailJson[14].isCheck,
              currentSymptoms: detailJson[14].status,
            },
            cCoughingUpBlood: {
              isCheck: detailJson[15].isCheck,
              currentSymptoms: detailJson[15].status,
            },
            cBreathingDiff: {
              isCheck: detailJson[16].isCheck,
              currentSymptoms: detailJson[16].status,
            },
            cTriedDailyActivities: {
              isCheck: detailJson[17].isCheck,
              currentSymptoms: detailJson[17].status,
            },
            cTiredSitStill: {
              isCheck: detailJson[18].isCheck,
              currentSymptoms: detailJson[18].status,
            },
            cLieDownBreath: {
              isCheck: detailJson[19].isCheck,
              currentSymptoms: detailJson[19].status,
            },
          };
          this.dataDetailHistory = data_;

          for (let j = 0; j < this.dataListHistoryBody.length; j++) {
            this.dataChartPressure.push({
              category: formatDate(
                this.dataListHistoryBody[j].cDate,
                'yyyy/MM/dd HH:mm:ss',
                'en-US'
              ),
              open1: this.dataListHistoryBody[j].cPressureHigh,
              close1: this.dataListHistoryBody[j].cPressureLow,
            });
            this.dataChartHeartRate.push({
              date: formatDate(
                this.dataListHistoryBody[j].cDate,
                'yyyy/MM/dd hh:mm',
                'en-US'
              ),
              value1: this.dataListHistoryBody[j].cHeartrate,
            });
            this.dataChartTemperature.push({
              date: formatDate(
                this.dataListHistoryBody[j].cDate,
                'yyyy/MM/dd hh:mm',
                'en-US'
              ),
              value1: this.dataListHistoryBody[j].cTemp,
            });
            this.dataChartSpo2.push({
              date: formatDate(
                this.dataListHistoryBody[j].cDate,
                'yyyy/MM/dd hh:mm',
                'en-US'
              ),
              value1: this.dataListHistoryBody[j].cSpo2,
            });

            this.lineChartLabels.push(
              formatDate(this.dataListHistoryBody[j].cDate, 'hh:mm', 'en-US')
            );

            if (j == this.dataListHistoryBody.length - 1) {
              setInterval(() => {
                this.loadData = false;
              }, 2000);
            }
          }
        } else {
          setInterval(() => {
            this.loadData = false;
          }, 2000);
        }
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  onClickDetail(dataDetail: any) {
    this.genarateGraph();

    // console.log(dataDetail);
    // console.log(JSON.parse(dataDetail.cCurrentSymptoms));
    var detailJson = JSON.parse(dataDetail.cCurrentSymptoms);
    var data_ = {
      cFeverish: {
        isCheck: detailJson[1].isCheck,
        currentSymptoms: detailJson[1].status,
      },
      cSoreThroat: {
        isCheck: detailJson[4].isCheck,
        currentSymptoms: detailJson[4].status,
      },
      cMusclePain: {
        isCheck: detailJson[3].isCheck,
        currentSymptoms: detailJson[3].status,
      },
      cRunny: {
        isCheck: detailJson[6].isCheck,
        currentSymptoms: detailJson[6].status,
      },
      cSputum: {
        isCheck: detailJson[7].isCheck,
        currentSymptoms: detailJson[7].status,
      },
      cHeadache: {
        isCheck: detailJson[2].isCheck,
        currentSymptoms: detailJson[2].status,
      },
      cNoseNoSmell: {
        isCheck: detailJson[0].isCheck,
        currentSymptoms: detailJson[0].status,
      },
      cRash: {
        isCheck: detailJson[8].isCheck,
        currentSymptoms: detailJson[8].status,
      },
      cLiquidStool: {
        isCheck: detailJson[9].isCheck,
        currentSymptoms: detailJson[9].status,
      },
      cCoughAlittle: {
        isCheck: detailJson[5].isCheck,
        currentSymptoms: detailJson[5].status,
      },
      cTongueNoTaste: {
        isCheck: detailJson[11].isCheck,
        currentSymptoms: detailJson[11].status,
      },
      cConjunctivitis: {
        isCheck: detailJson[12].isCheck,
        currentSymptoms: detailJson[12].status,
      },
      cCanNotEat: {
        isCheck: detailJson[13].isCheck,
        currentSymptoms: detailJson[13].status,
      },
      cCoughAlot: {
        isCheck: detailJson[14].isCheck,
        currentSymptoms: detailJson[14].status,
      },
      cCoughingUpBlood: {
        isCheck: detailJson[15].isCheck,
        currentSymptoms: detailJson[15].status,
      },
      cBreathingDiff: {
        isCheck: detailJson[16].isCheck,
        currentSymptoms: detailJson[16].status,
      },
      cTriedDailyActivities: {
        isCheck: detailJson[17].isCheck,
        currentSymptoms: detailJson[17].status,
      },
      cTiredSitStill: {
        isCheck: detailJson[18].isCheck,
        currentSymptoms: detailJson[18].status,
      },
      cLieDownBreath: {
        isCheck: detailJson[19].isCheck,
        currentSymptoms: detailJson[19].status,
      },
    };
    // console.log(data_);

    this.dataDetailHistory = data_;
  }

  openDialogCovid19Chart(dataSos: any): void {
    const dialogRef = this.dialog.open(DialogCovid19Chart, {
      // width: '1800px',
      data: { dataSos },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
    });
  }

  openDialogPersonalHealth(idUser: any) {
    const dialogRef = this.dialog.open(DialogPersonalHealth, {
      data: idUser,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
    });
  }
}
