<div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
  <div>
    <div class="d-flex justify-content-center">
      <div class="spinner-grow text-danger mr-1"></div>
      <div class="spinner-grow text-danger mr-1"></div>
      <div class="spinner-grow text-danger"></div>
    </div>
    <h1 class="font-weight-bold text-danger mt-2">Case Loading...</h1>
  </div>
</div>
