<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <h1 style="text-align: center;">{{dataAdminDistrictName}}</h1>
            </div>
            <div class="col-md-10">
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content mr-3 ml-3">

    <!-- Default box -->
    <div class="card mat-elevation-z8">

        <div class="card-header">
            <h3 class="card-title" style="margin: 0px;">{{getNameProvince}}</h3>
            <h3 class="card-title" style="margin: 0px;"><i class="fas fa-caret-right mr-1 ml-1" style="font-size: 16px;"></i>{{getNameDistrict}}</h3>
            <div class="card-tools">
                <button mat-button class="mr-1" (click)="openDialogAddAdminSubDistrict()">Add Admin</button>

                <!-- <button mat-raised-button color="primary" routerLink="/operator-list">View All</button> -->
            </div>
        </div>

        <div class="card-body p-0">
            <mat-spinner *ngIf="loadData" style="margin-left: auto;margin-right: auto;margin-top: 100px;margin-bottom: 100px;"></mat-spinner>
            <table class="table table-hover" *ngIf="loadDataSuccess">
                <thead>
                    <tr>
                        <th scope="col" style="width: 5%;text-align: center;">.NO</th>
                        <th scope="col" style="width: 10%;">UserName Admin</th>
                        <th scope="col" style="width: 35%;">Operator Members</th>
                        <th scope="col" style="width: 10%;text-align: center;">Include cases today</th>
                        <th scope="col" style="width: 10%;text-align: center;">Province Name</th>
                        <th scope="col" style="width: 10%;text-align: center;">Phone Number</th>
                        <th scope="col" style="width: 20%;"></th>
                    </tr>
                </thead>
                <tbody *ngFor="let dataAdminSubDistrict of dataAdminSubDistricts; index as i;">
                    <tr>
                        <th scope="row" style="text-align: center;">{{i+1}}</th>
                        <td>{{dataAdminSubDistrict.dataAdmin.admin_Username}}</td>
                        <td>
                            <ul class="list-inline">
                                <li class="list-inline-item" *ngFor="let imgOperator of dataAdminSubDistrict.dataOparator.dataOparatorInfo; let i = index">
                                    <div *ngIf="i+1 < 7">

                                        <div
                                            *ngIf="imgOperator.operatorPic != null && imgOperator.operatorPic != ''; else elseImgProfileOperator">
                                            <img alt="Avatar" class="table-avatar"
                                                src="{{imageUrl1}}/{{imgOperator.operatorPic}}" onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                style="width: 40px;height: 40px;">
                                        </div>
                                        <ng-template #elseImgProfileOperator>
                                            <img alt="Avatar" class="table-avatar" src="assets/adminlte/dist/img/nouser.svg"
                                                style="width: 40px;height: 40px;">
                                        </ng-template>

                                    </div>

                                </li>
                                <li class="list-inline-item">
                                    <span class="badge badge-success">{{dataAdminSubDistrict.dataOparator.dataOparatorInfo.length}}
                                        people</span>
                                </li>
                            </ul>
                        </td>
                        <td style="text-align: center;">{{dataAdminSubDistrict.dataOparator.countJobOperator }} case  </td>
                        <td style="text-align: center;">{{dataAdminSubDistrict.dataAdmin.admin_Namerole}}</td>
                        <td style="text-align: center;">{{dataAdminSubDistrict.dataAdmin.admin_Phone}}</td>
                        <td class="project-actions text-right" style="display: flex; justify-content: space-around;">
                            <button class="mat-elevation-z8" mat-raised-button color="primary"
                                style="width: 100px; margin-left: 4px; margin-right: 2px;" routerLink="/operator-list/{{getNameProvince}}/{{getNameDistrict}}/{{dataAdminSubDistrict.dataAdmin.admin_Namerole}}/{{dataAdminSubDistrict.dataAdmin.admin_SubDistrictId}}/{{dataAdminSubDistrict.dataAdmin.admin_Parrent}}">
                                <i class="fas fa-eye">
                                </i>
                                View
                            </button>
                            <button class="mat-elevation-z8" mat-raised-button color="accent" (click)="openDialogEditAdminSubDistrict(dataAdminSubDistrict.dataAdmin.admin_Id,dataAdminSubDistrict.dataAdmin.admin_Username,dataAdminSubDistrict.dataAdmin.admin_Password,dataAdminSubDistrict.dataAdmin.admin_Phone)"
                                style="width: 100px; margin-left: 2px; margin-right: 2px;">
                                <i class="fas fa-pencil-alt">
                                </i>
                                Edit
                            </button>
                            <button class="mat-elevation-z8" mat-raised-button color="warn" (click)="deleteAdmin(dataAdminSubDistrict.dataAdmin.admin_Id,dataAdminSubDistrict.dataAdmin.admin_Parrent,i)"
                                style="width: 100px; margin-left: 2px; margin-right: 4px;">
                                <i class="fas fa-trash">
                                </i>
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</section>
