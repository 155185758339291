<body class="login-page" cz-shortcut-listen="true" style="min-height: 466px;">
    <div class="login-box mat-elevation-z8 bounceInDown" style="width: 500px;">
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <img src="assets/images/logo/RuxLogo-EN.png" alt="" width="200px" style="margin: 30px;">
                <a href="javascript:void(0)" class="h1"><b>Smartwatch</b></a>
                <a href="javascript:void(0)" class="h2"> Dashboard</a>
            </div>
            <div class="card-body">
                <p class="login-box-msg">Sign in to start your session</p>

                <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
                    <mat-form-field class="example-full-width" appearance="outline"
                        style="width: -webkit-fill-available;">
                        <mat-label>Username</mat-label>
                        <input  matInput placeholder="Username" formControlName="loginUsername"
                            oninput="this.value = this.value.trim();" required>
                        <mat-icon matSuffix>account_circle</mat-icon>
                    </mat-form-field>

                    <mat-form-field class="example-full-width" appearance="outline"
                        style="width: -webkit-fill-available;">
                        <mat-label>Enter your password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="loginPassword"
                            oninput="this.value = this.value.trim();" required>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <!-- <button mat-icon-button matSuffix [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button> -->
                    </mat-form-field>
                    <div class="social-auth-links text-center mt-2 mb-3">
                        <button mat-raised-button class="btn btn-block" color="primary" type="submit"
                            style="height: 45px;"><i class="fas fa-sign-in-alt"></i>
                            Sign in </button>
                        <!-- <button mat-button class="btn btn-block" style="height: 45px;">I forgot my password</button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- jQuery -->
    <script src="../../plugins/jquery/jquery.min.js"></script>
    <!-- Bootstrap 4 -->
    <script src="../../plugins/bootstrap/js/bootstrap.bundle.min.js"></script>
    <!-- AdminLTE App -->
    <script src="../../dist/js/adminlte.min.js"></script>


</body>