import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../../../core/services/service.service';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-treated-patients',
  templateUrl: './treated-patients.component.html',
  styleUrls: ['./treated-patients.component.scss']
})
export class TreatedPatientsComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  idOperatorAdmin : any;
  resultDataFavorite: any = [];
  resultDataNoFavorite: any = [];
  resultDataBackUp: any = [];
  resultDataDoctorMall: any;
  selected = '20';
  selectedFavorite = 'allFavorite';
  status: string = "all"
  loadDataUser: boolean = true;
  tableShow: boolean = false;
  cardShow: boolean = false;
  noDataInformation: boolean = false;
  favorite: boolean = false;
  noFavorite: boolean = false;
  allFavorite: boolean = true;

  constructor(
    private ServiceService: ServiceService
  ) {
    this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
  }

  ngOnInit(): void {
    this.loadDataDoctorMall("EndTreat", this.status, this.selected,this.idOperatorAdmin);
  }

  onClickRefresh() {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;
    this.loadDataDoctorMall("EndTreat", this.status, this.selected,this.idOperatorAdmin);
  }

  onChangeCountItem(event: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;

    this.resultDataDoctorMall = [];
    // console.log(event);
    this.selected = event;
    this.loadDataDoctorMall("EndTreat", this.status, this.selected,this.idOperatorAdmin);
  }

  onChangeStatus(status: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;

    this.resultDataDoctorMall = [];
    if (status == "red") {
      this.status = "Red";
    } else if (status == "yellow") {
      this.status = "Yellow";
    } else if (status == "green") {
      this.status = "Green";
    } else {
      this.status = "all";
    }
    this.loadDataDoctorMall("EndTreat", this.status, this.selected,this.idOperatorAdmin);
  }

  onClickShowData(type: string) {
    this.loadDataUser = true;
    this.tableShow = false;
    this.cardShow = false;
    if (type == "card") {
      setTimeout(() => {
        this.loadDataUser = false;
        this.tableShow = false;
        this.cardShow = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.loadDataUser = false;
        this.tableShow = true;
        this.cardShow = false;
      }, 2000);
    }
  }

  loadDataDoctorMall(status: string, codivStatus: string, countLists: string,operatorAdminID: string) {
    this.loadData(status, codivStatus, countLists,operatorAdminID).then(resultDaTa => {
      this.resultDataDoctorMall = [];
      this.resultDataBackUp = [];
      this.resultDataFavorite = [];
      this.resultDataNoFavorite = [];
      console.log("resDoctorMall ", resultDaTa);
      if (resultDaTa != false) {
        for (let i = 0; i < resultDaTa.length; i++) {
          if (resultDaTa[i].dpFavorite == true) {
            this.resultDataFavorite.push(resultDaTa[i])
          } else {
            this.resultDataNoFavorite.push(resultDaTa[i])
          }
        }
        if(this.favorite == true){
          this.resultDataDoctorMall = this.resultDataFavorite;
        }else if(this.noFavorite == true){
          this.resultDataDoctorMall = this.resultDataNoFavorite;
        }else if(this.allFavorite == true){
          this.resultDataDoctorMall = resultDaTa;
        }
        this.resultDataBackUp = resultDaTa;
        setTimeout(() => {
          this.noDataInformation = false;
          this.loadDataUser = false;
          this.tableShow = true;
          this.cardShow = false;
        }, 2000);
      } else {
        setTimeout(() => {
          this.noDataInformation = true;
          this.loadDataUser = false;
          this.tableShow = true;
          this.cardShow = false;
        }, 2000);
      }
    });
  }

  async loadData(status: string, codivStatus: string, countLists: string,operatorAdminID: string) {
    return new Promise<any>(async (resolve, rejects) => {
      var dataDoctorMall_: any;
      await this.ServiceService.GetDataDoctorMallList_(status, codivStatus, countLists,operatorAdminID).then(resDoctorMall => {
        dataDoctorMall_ = resDoctorMall;
        if (dataDoctorMall_.length != 0) {
          resolve(dataDoctorMall_);
        } else {
          resolve(false);
        }
      });

    });
  }


}
