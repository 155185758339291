<div [class]="!value ? 'mt-3' : 'container-fluid p-3'">

    <!-- <h1 style="color: #000000;">Blood Pressure Chart </h1> -->

    <!-- <select class="form-select" #selectImeiCode (change)='selectIMEI_onChange(selectImeiCode.value)'>
        <option selected="selected" disabled> Please Select SmartWatch </option>
        <option *ngFor="let datalist of imeiList" [value]="datalist">
            {{datalist}}
        </option>
    </select> -->

    <div class="row">
        <div class="col-md-8" style="margin-top:auto;margin-bottom: auto;">
          <h1 style="color: #222;" style="margin: 0px;">Blood Pressure Chart </h1>
        </div>
        <div class="col-md-4 text-right">

          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Enter a date range</mat-label>

            <mat-date-range-input [formGroup]="rangeDateStartAndEnd" [rangePicker]="picker_start_end">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onChangeDate()">
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker_start_end"></mat-datepicker-toggle>
            <mat-date-range-picker #picker_start_end></mat-date-range-picker>
          </mat-form-field>
        </div>

      </div>

      <div *ngIf="countChartData != 0">

    <div class="card mt-2">
        <div class="card-body">
            <div #chartBloodPre style="width: 100%; height: 650px;"></div>
            <div #chartBloodPreNew style="width: 100%; height: 450px;"></div>
            <div style="margin-top: 10px; text-align: right;">
                <h6 style="padding: 0; margin: 0;"><span class="txtred">Systolic - Diastolic ต่ำกว่า 20 = {{ countBloodPreLow20 }} ครั้ง</span> จากทั้งหมด {{ dataChartBloodPre.length }} ครั้ง <span class="txtred">คิดเป็น {{ (countBloodPreLow20 / dataChartBloodPre.length) * 100 | number : '1.2-2' }}%</span></h6>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace h6size">Average (เฉลี่ย)</h6>
                    <h3 class="nospace txtindigo">Systolic : {{averBloodPreValue | number : '1.2-2'}}</h3>
                    <h3 class="nospace txtred">Diastolic : {{averBloodPreValue_2 | number : '1.2-2'}}</h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">Max (มากสุด)</h6>
                    <h3 class="nospace txtindigo">Systolic : {{maxBloodPreValue}}</h3>
                    <h3 class="nospace txtred">Diastolic : {{maxBloodPreValue_2}}</h3>
                </div>
            </div>
        </div>

        <div class="col-md-4 mt-3">
            <div class="card">
                <div class="card-body">
                    <h6 class="nospace">Min (น้อยสุด)</h6>
                    <h3 class="nospace txtindigo">Systolic : {{minBloodPreValue}}</h3>
                    <h3 class="nospace txtred">Diastolic : {{minBloodPreValue_2}}</h3>
                </div>
            </div>
        </div>



        <div class="col-md-12 mt-3">
            <div class="card">
                <div class="card-body text-center">

                    <h6 class="nospace">BloodPre Average Percent Chart</h6>

                    <div class="row">
                        <div class="col-md-6"><img height="300px" style="border-radius: 10px;" src="https://i.ibb.co/ng08Gwm/Capture.jpg"></div>
                        <div class="col-md-6"><div #chartBloodPreValPercen style="width: 100%; height: 300px"></div></div>
                    </div>

                </div>
            </div>
        </div>



        <div class="col-md-9 row">

            <div class="col-md-3 mt-3">
                <div class="card">
                    <div class="card-body">
                        <h4 class="nospace"><span style="color: green;">NORMAL</span></h4>
                        <h3 class="nospace txtindigo">{{ countvalNormal }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                                {{ dataChartBloodPre.length }} ครั้ง คิดเป็น {{ (countvalNormal / dataChartBloodPre.length) * 100 | number : '1.2-2' }}%</span></h3>
                    </div>
                </div>
            </div>

            <div class="col-md-3 mt-3">
                <div class="card">
                    <div class="card-body">
                        <h4 class="nospace"><span style="color: orange;">ELEVATED BP</span></h4>
                        <h3 class="nospace txtindigo">{{ countelevated }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                                {{ dataChartBloodPre.length }} ครั้ง คิดเป็น {{ (countelevated / dataChartBloodPre.length) * 100 | number  : '1.2-2' }}%</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-3">
                <div class="card">
                    <div class="card-body">
                        <h4 class="nospace"><span style="color: orangered;">HIGH BP STAGE 1</span></h4>
                        <h3 class="nospace txtindigo">{{ countvalhighstate1 }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                                {{ dataChartBloodPre.length }} ครั้ง คิดเป็น {{ (countvalhighstate1 / dataChartBloodPre.length) * 100 | number  : '1.2-2' }}%</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-3">
                <div class="card">
                    <div class="card-body">
                        <h4 class="nospace"><span style="color: red;">HIGH BP STAGE 2</span></h4>
                        <h3 class="nospace txtindigo">{{ countvalhighstate2 }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                                {{ dataChartBloodPre.length }} ครั้ง คิดเป็น {{ (countvalhighstate2 / dataChartBloodPre.length) * 100 | number  : '1.2-2' }}%</span></h3>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-3 mt-3">
            <div class="card">
                <div class="card-body">
                    <h4 class="nospace"><span style="color: red;">HYPERTENSIVE CRISIS (วิกฤตความดันโลหิตสูง)</span></h4>
                    <h3 class="nospace txtindigo">{{ countvalhighcrisis }} ครั้ง / <span style="font-size: 20px; color: #222;">จาก
                            {{ dataChartBloodPre.length }} ครั้ง คิดเป็น {{ (countvalhighcrisis / dataChartBloodPre.length) * 100 | number  : '1.2-2' }}%</span></h3>
                </div>
            </div>
        </div>





        <div class="col-md-12 mt-3">
            <div class="card">
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-8">
                            <h6 class="nospace">BloodPre 24Hr Chart</h6>
                            <span *ngFor="let datelist of datetimeShowList" class="badge bg-primary" style="margin-right: 2px;">{{ datelist.datetxtShow }}</span>
                        </div>

                        <div class="col-md-4 text-right">
                            <mat-form-field style="width: 100%;">
                                <input matInput [matDatepicker]="picker" placeholder="Choose a date" [value]="setNow" (dateChange)="onChangeDateTimeEvent($event, false)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="mt-2" #chartBloodPreAver24Line style="width: 100%; height: 650px"></div>

                    <!-- <div class="mt-3">
                        <table class="table table-striped mt-1">
                          <thead>
                            <tr style="background-color: rgb(208, 242, 253);">
                              <th scope="col" width="80px">#</th>
                              <th scope="col">DateTime</th>
                              <th scope="col">type</th>
                              <th scope="col">SBP</th>
                              <th scope="col">DBP</th>
                              <th scope="col">SPB - DBP</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr>
                              <th scope="row">1</th>
                              <td>10/02/21 01:54</td>
                              <td>gobal</td>
                              <td style="color: rgb(12, 110, 175); font-weight: bold;">101</td>
                              <td style="color: red; font-weight: bold;">82</td>
                              <td>19</td>
                              <th scope="col">ค่าความดันโดยปกติหากนำค่าบนและค่าล่างมาลบกันควรมีค่า 20 ขึ้นไป</th>
                            </tr>
                              <tr *ngFor="let datelist of tickerDataToTableList index as i">
                                <th scope="row">{{i+1}}</th>
                                <td>{{ datelist.atTime }}</td>
                                <td>{{ datelist.atTickerGobalOrAvg }}</td>
                                <td style="color: rgb(12, 110, 175);; font-weight: bold;">{{ datelist.atValue.split(',')[0] }}</td>
                                <td style="color: red; font-weight: bold;">{{ datelist.atValue.split(',')[1] }}</td>
                                <td >{{ datelist.atValue.split(',')[0]-datelist.atValue.split(',')[1] }}</td>


                                <td colspan="2" style="color: rgb(12, 110, 175); font-weight: bold;">{{ datelist.atLogTxtDesc }}</td>
                                <td>{{ g_diffvalue }}</td>
                              </tr>
                          </tbody>
                        </table>
                      </div> -->

                </div>
            </div>
        </div>


    </div>
</div>


    <div *ngIf="countChartData == 0" style="display: flex;justify-content: center;"> <h2>*** ไม่มีข้อมูลในช่วงเวลาดังกล่าว ***</h2> </div>


    <br>
    <br>

</div>
