<div class="mb-3">
    <label for="minvalue" class="form-label">Min Value</label>
    <input type="text" #gminvalue value="{{ g_minvalue }}" class="form-control" id="minvalue" aria-describedby="minvaluehelp">
    <div id="minvaluehelp" class="form-text">Set Min Value</div>
  </div>

  <div class="mb-3">
    <label for="Maxvalue" class="form-label">Max Value</label>
    <input type="text" #gmaxvalue value="{{ g_maxvalue }}" class="form-control" id="Maxvalue" aria-describedby="maxvaluehelp">
    <div id="maxvaluehelp" class="form-text">Set Max Value</div>
  </div>

  <div class="mb-3">
    <label for="differencevalue" class="form-label">Difference Value</label>
    <input type="text" #gdiffvalue value="{{ g_diffvalue }}" class="form-control" id="differencevalue" aria-describedby="differencevaluehelp">
    <div id="differencevaluehelp" class="form-text">Set Difference Value</div>
  </div>

  <button type="submit" (click)="saveGobalDataValue(gminvalue.value,gmaxvalue.value,gdiffvalue.value)" class="btn btn-primary">Save changes</button>