import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../../../../core/services/service.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { SingleDataSet, MultiDataSet, Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { io } from 'socket.io-client';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-doctor-dashboard',
  templateUrl: './doctor-dashboard.component.html',
  styleUrls: ['./doctor-dashboard.component.scss']
})
export class DoctorDashboardComponent implements OnInit {
  idOperatorAdmin: any;
  nameOperator: any;
  roleAdmin: any;
  selected = 'all';
  status: string = "all"
  loadDataPage: boolean = false;
  dataAgeUsers: any[] = [];

  private socket: any;

  sexMale: number = 0;
  sexFeMale: number = 0;
  countAge_12: number = 0;
  countAge_12_18: number = 0;
  countAge_19_35: number = 0;
  countAge_35_50: number = 0;
  countAge_51_65: number = 0;
  countAge_65: number = 0;
  maxValue: any;
  maxValArr: number[] = [];
  topAge: any[] = [];

  userGreen: number = 0;
  userYellow: number = 0;
  userRed: number = 0;

  resultDataLogCodiv19: any = [];
  resultDataLogAlertUserCodiv19: any = [];

  logSos: any = [];

  constructor(
    private router: Router,
    private ServiceService: ServiceService
  ) {
    this.nameOperator = localStorage.getItem("nameOpertor")
    this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
    this.roleAdmin = localStorage.getItem("currentRole");
    this.socket = io(GlobalConstants.socket, { transports: ['websocket', 'polling', 'flashsocket'] });
  }

  ngOnInit(): void {
    // this.socket.on('datamessage', async (data: any) => {
    //   // console.log("data sos phone : ",data);
    //   this.loadSOS(this.idOperatorAdmin, true);
    // });

    this.socket.on('dataMessageAlertPending_Covid', async (dataAlertCovid19: any) => {
      // console.log("dataAlertCovid19 : ", dataAlertCovid19.data);
      if(dataAlertCovid19.data.idOperator == this.idOperatorAdmin){

        if (dataAlertCovid19.data.msg == "doctor-comment" || dataAlertCovid19.data.msg == "doctor-dispenseMedicine" ) {
          if (dataAlertCovid19.data.status == "read" || dataAlertCovid19.data.status == "unread") {
            this.loadDataLogCovid19(this.idOperatorAdmin);
          }
        }

        if(dataAlertCovid19.data.msg == "doctor-confirmAlert"){
          this.loadDataLogAlertUserCovid19(this.idOperatorAdmin);
        }

        if(dataAlertCovid19.data.msg == "doctor-selectUser"){
          console.log("Sssssssssssssssssssssss");

          this.loadDataDoctorMall("StartTreat", this.status, this.selected, this.idOperatorAdmin);
        }

      }
    });
    this.socket.on('alert_covidsym', async (dataAlertCovid19: any) => {
      // console.log("dataAlertCovid19 : ", dataAlertCovid19);

      this.loadDataLogAlertUserCovid19(this.idOperatorAdmin);
      this.loadDataDoctorMall("StartTreat", this.status, this.selected, this.idOperatorAdmin);

    });


    if (this.roleAdmin == "Operator") {
      this.loadDataDoctorMall("StartTreat", this.status, this.selected, this.idOperatorAdmin);
      this.loadDataLogCovid19(this.idOperatorAdmin);
      this.loadDataLogAlertUserCovid19(this.idOperatorAdmin);
    }
  }

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [['User Green'], ['User Yellow'], ['User Red']];
  public pieChartData: SingleDataSet = [10, 20, 30];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  pieChartColors: Array<any> = [{
    backgroundColor: ['#28A745', '#FFC107', '#DC3545'],
  }];

  public pieChartOptionsSex: ChartOptions = {
    responsive: true,
  };
  public pieChartLabelsSex: Label[] = [];
  public pieChartDataSex: SingleDataSet = [10, 20];
  public pieChartTypeSex: ChartType = 'pie';
  public pieChartLegendSex = true;
  public pieChartPluginsSex = [];
  pieChartColorsSex: Array<any> = [{
    backgroundColor: ['rgba(245, 105, 84)', 'rgba(75, 148, 192)'],
  }];

  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = ['under 12', '12-18', '19-35', '35-50', '51-65', '65+'];
  rangeAgeArr: String[] = ['under 12', '12-18', '19-35', '35-50', '51-65', '65+'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartColors: Array<any> = [{
    backgroundColor: ['rgba(245, 105, 84)', 'rgba(245, 105, 84)', 'rgba(245, 105, 84)', 'rgba(245, 105, 84)', 'rgba(245, 105, 84)', 'rgba(245, 105, 84)'],
  }];
  barChartData: ChartDataSets[] = [
    { data: this.dataAgeUsers, label: 'Total age' }
  ];

  loadDataDoctorMall(status: string, codivStatus: string, countLists: string, operatorAdminID: string) {
    this.loadData(status, codivStatus, countLists, operatorAdminID).then(resultDaTa => {

      // console.log("resDoctorMall ", resultDaTa);
      this.countAge_12 = 0;
      this.countAge_12_18 = 0;
      this.countAge_19_35 = 0;
      this.countAge_35_50 = 0;
      this.countAge_51_65 = 0;
      this.countAge_65 = 0;
      this.sexMale = 0;
      this.sexFeMale = 0;
      var userRed_=0;
      var userYellow_=0;
      var userGreen_=0;
      for (let i = 0; i < resultDaTa.length; i++) {

        if (resultDaTa[i].dpCovidStatus == "Red") {
          userRed_++;
        } else if (resultDaTa[i].dpCovidStatus == "Yellow") {
          userYellow_++;
        } else if (resultDaTa[i].dpCovidStatus == "Green") {
          userGreen_++;
        }
        // console.log("resultDaTa[i].userSex : ",resultDaTa[i].userSex);

        if (resultDaTa[i].userSex == "M") {
          this.sexMale++;
        } else if (resultDaTa[i].userSex == "FM" || resultDaTa[i].userSex == "F") {
          this.sexFeMale++;
        }

        if (Number(resultDaTa[i].userAge) <= 12) {
          this.countAge_12++;
        } else if (Number(resultDaTa[i].userAge) >= 13 && Number(resultDaTa[i].userAge) <= 18) {
          this.countAge_12_18++;
        } else if (Number(resultDaTa[i].userAge) >= 19 && Number(resultDaTa[i].userAge) <= 35) {
          this.countAge_19_35++;
        } else if (Number(resultDaTa[i].userAge) >= 36 && Number(resultDaTa[i].userAge) <= 50) {
          this.countAge_35_50++;
        } else if (Number(resultDaTa[i].userAge) >= 51 && Number(resultDaTa[i].userAge) <= 65) {
          this.countAge_51_65++;
        } else if (Number(resultDaTa[i].userAge) >= 66) {
          this.countAge_65++;
        }

      }

      this.userRed = userRed_;
      this.userYellow = userYellow_;
      this.userGreen = userGreen_;

      this.dataAgeUsers.push(this.countAge_12, this.countAge_12_18, this.countAge_19_35, this.countAge_35_50, this.countAge_51_65, this.countAge_65);

      this.pieChartLabelsSex[0] = 'Male'
      this.pieChartLabelsSex[1] = 'FeMale';
      this.pieChartDataSex = [];
      this.pieChartDataSex[0] = this.sexMale;
      this.pieChartDataSex[1] = this.sexFeMale;

      this.pieChartData = [];
      this.pieChartData[0] = this.userGreen;
      this.pieChartData[1] = this.userYellow;
      this.pieChartData[2] = this.userRed;


      this.maxValue = Math.max.apply(Math, this.dataAgeUsers.map(function (o) { return o; }));
      // console.log("ssss ",this.maxValue);

      this.dataAgeUsers.forEach((element, index) => {
        // console.log("element ",element);

        if (this.maxValue == element) {
          this.maxValArr.push(index);
        }
      });
      // console.log("this.maxValArr : ",this.maxValArr);

      this.maxValArr.forEach((element, index) => {
        // console.log(element);

        this.topAge.push(this.rangeAgeArr[element])
      });

      if (resultDaTa != false) {
        setTimeout(() => {
          this.loadDataPage = true;
        }, 1000);
      } else {
        setTimeout(() => {
          this.loadDataPage = true;
        }, 1000);
      }
    });
  }

  async loadData(status: string, codivStatus: string, countLists: string, operatorAdminID: string) {
    return new Promise<any>(async (resolve, rejects) => {
      var dataDoctorMall_: any;
      await this.ServiceService.GetDataDoctorMallList_(status, codivStatus, countLists, operatorAdminID).then(resDoctorMall => {
        dataDoctorMall_ = resDoctorMall;
        if (dataDoctorMall_.length != 0) {
          resolve(dataDoctorMall_);
        } else {
          resolve(false);
        }
      });

    });
  }

  loadDataLogCovid19(idOperatorAdmin: any) {
    this.dataLogCodiv19(idOperatorAdmin).then(resLogCodiv19 => {
      // console.log("resLogCodiv19 new : ", resLogCodiv19);

      if (resLogCodiv19 != false) {
        this.resultDataLogCodiv19 = resLogCodiv19;
      } else {
        this.resultDataLogCodiv19 = [];
      }

    });
  }

  dataLogCodiv19(idOperatorAdmin: any) {
    // console.log(idOperatorAdmin);

    var adminCovAlertList: any;
    return new Promise<any>(async (resolve, rejects) => {

      this.ServiceService.GetAdminCovAlert(idOperatorAdmin).then(adminCovAlert => {
        adminCovAlertList = adminCovAlert;
        if (adminCovAlertList.length != 0) {
          resolve(adminCovAlertList);
        } else {
          resolve(false);
        }
      });
    });
  };

  loadDataLogAlertUserCovid19(idOperatorAdmin: any) {
    this.dataLogAlertUserCodiv19(idOperatorAdmin).then(resLogCodiv19 => {
      // console.log("resLogCodiv19 new : ", resLogCodiv19);

      if (resLogCodiv19 != false) {
        this.resultDataLogAlertUserCodiv19 = resLogCodiv19;
        // this.loadSOS(this.idOperatorAdmin, true);
      }else{
        this.resultDataLogAlertUserCodiv19 = [];
        // this.loadSOS(this.idOperatorAdmin, true);
      }

    });
  }

  dataLogAlertUserCodiv19(idOperatorAdmin: any) {
    var dataLogCovid19: any;
    var dataNewUserSymptom: any = [];
    return new Promise<any>(async (resolve, rejects) => {

      await this.ServiceService.GetLogCovid19Alert_(idOperatorAdmin).then(resLogCovid19 => {
        dataLogCovid19 = resLogCovid19;
        const grouped = this.groupBy(dataLogCovid19, (pet: { daUserId: any; }) => pet.daUserId);
        grouped.forEach(element => {
          dataNewUserSymptom.push(element[element.length - 1]);
        });

        if (dataNewUserSymptom.length != 0) {
          resolve(dataNewUserSymptom);
        } else {
          resolve(false);
        }
      });
    });
  };

  groupBy(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  loadSOS(idOperator: string, loadFirst: boolean, checkSosUpdate?: any) {
    this.getLogSos(idOperator, loadFirst, checkSosUpdate).then(result => {
      // console.log("dataJob Start >>>>>>>>> ", result);
      if (result != false) {

        for (let i = 0; i < result.length; i++) {
          this.resultDataLogAlertUserCodiv19.push(result[i]);
        }

        // console.log(">>> ", this.resultDataLogAlertUserCodiv19);

      } else {
        if (this.resultDataLogAlertUserCodiv19.length <= 0) {
          this.resultDataLogAlertUserCodiv19 = [];

        }
      }
    })
  }

  getLogSos(idOperator: string, loadFirst: boolean, checkSosUpdate: any) {
    var resLogSos: any;
    var resDevices: any;
    var resUserPhone: any;
    var resLoadSOS: any;
    return new Promise<any>((resolve, rejects) => {
      this.ServiceService.loadSOS_(idOperator).then(async dataLoadSOS => {
        resLoadSOS = dataLoadSOS;
        // console.log("res -> ", resLoadSOS);

        if (loadFirst) {
          if (resLoadSOS.length != 0) {
            for (let i = 0; i < resLoadSOS.length; i++) {
              var wordsjectId = resLoadSOS[i].objectId.split(',');

              await this.ServiceService.GetLogSos_(wordsjectId[0]).then(async dataLogSos => {
                // console.log("resLogSos >>> ",resLogSos);
                resLogSos = dataLogSos;
                await this.ServiceService.GetDeviceBySerial_(resLogSos[0].watch_imeicode).then(async dataDevice => {
                  resDevices = dataDevice;
                  if (resDevices.length != 0) {
                    // console.log(i,"55 resDevices >>> ",resDevices);
                    this.logSos.push({ idJob: resLoadSOS[i].ojobId, logSos: resLogSos[0], dataDevice: resDevices[0], dataJob: resLoadSOS[i] });
                    if (i == resLoadSOS.length - 1) {

                      resolve(this.logSos)
                    }
                  } else {
                    await this.ServiceService.GetUserPhone_(resLogSos[0].watch_imeicode).then(async dataPhone => {
                      resUserPhone = dataPhone;
                      // console.log(i,"44 resUserPhone >>> ",resUserPhone," : : ",resLogSos[0].watch_imeicode);
                      if (resUserPhone.length != 0) {
                        this.logSos.push({ idJob: resLoadSOS[i].ojobId, logSos: resLogSos[0], dataUserPhone: resUserPhone[0], dataJob: resLoadSOS[i] });
                        if (i == resLoadSOS.length - 1) {

                          resolve(this.logSos)
                        }

                      }
                    }, error => {
                      console.log(error.status + ' ' + error.statusText);
                    });
                  }
                }, error => {
                  console.log(error.status + ' ' + error.statusText);
                });
              }, error => {
                console.log(error.status + ' ' + error.statusText);
              });
            }
          } else {
            resolve(false)
          }
        } else {
          if (checkSosUpdate.statusUpdate == true) {
            var wordsjectId = resLoadSOS[0].objectId.split(',');
            await this.ServiceService.GetLogSos_(wordsjectId[0]).then(async dataLogSos => {
              resLogSos = dataLogSos;
              await this.ServiceService.GetDeviceBySerial_(resLogSos[0].watch_imeicode).then(async dataDevice => {
                resDevices = dataDevice;
                if (resDevices.length != 0) {
                  this.logSos.push({ idJob: resLoadSOS[0].ojobId, logSos: resLogSos[0], dataDevice: resDevices[0], dataJob: resLoadSOS[0] });
                  resolve(this.logSos);
                } else {
                  await this.ServiceService.GetUserPhone_(resLogSos[0].watch_imeicode).then(async dataPhone => {
                    resUserPhone = dataPhone;
                    this.logSos.push({ idJob: resLoadSOS[0].ojobId, logSos: resLogSos[0], dataUserPhone: resUserPhone[0], dataJob: resLoadSOS[0] });
                    resolve(this.logSos);
                  }, error => {
                    console.log(error.status + ' ' + error.statusText);
                  });
                }
              }, error => {
                console.log(error.status + ' ' + error.statusText);
              });
            }, error => {
              console.log(error.status + ' ' + error.statusText);
            });
          }
        }
      });
    })
  }

}
