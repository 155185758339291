import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  compUserprofileValueDeviceID = new BehaviorSubject<string>('');
  deviceIDValue = this.compUserprofileValueDeviceID.asObservable();

  compMenuDashBoard = new BehaviorSubject<any>('');
  dataDeviceAndUseralue = this.compMenuDashBoard.asObservable();

  compChartDashBoard = new BehaviorSubject<any>('');
  dataOperatorOnline = this.compChartDashBoard.asObservable();

  bsSOSCaseStatus = new BehaviorSubject<any>('');
  sosCaseStatus = this.bsSOSCaseStatus.asObservable();

  constructor() { }

  selectIDDeviceCompUserprofile(val:string) {
    this.compUserprofileValueDeviceID.next(val);
  }

  updateDataUserAndDevice(val:any) {
    this.compMenuDashBoard.next(val);
  }

  updateOperatorOnline(val:any) {
    this.compChartDashBoard.next(val);
  }

  ongoingSOSCase(SocketData: any) {
    this.bsSOSCaseStatus.next(SocketData);
  }
}
